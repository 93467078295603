import { castArray, mergeWith } from 'lodash-es';
import { toJS } from 'mobx';
import { isPrimitive } from '../utils/utils';
import { types, getRoot, flow, Instance } from 'mobx-state-tree';
import { IRootStore } from './Root';
import { daylesfordService } from 'services';
import { IReqPriceCalcResults } from 'services/models';
import { find } from 'lodash';

const { model, string, number, map, array, maybeNull, maybe, identifier } = types;

interface ICultureOptionFromServer {
  id: number;
  name: string;
  subName: string;
  image: string;
  smartId: string;
  meta: boolean;
}

const CulturesListOptions = model('CulturesListOptions', {
  value: string,
  label: string,
});

const EXWPrice = model('Price', {
  warehouse: identifier,
  company: string,
  price: string,
  specs: string,
  distance: number,
  duration: number,
  totalPrice: string,
  city: string,
});
const CPTPrice = model('Price', {
  warehouse: identifier,
  company: string,
  price: number,
  specs: string,
});

export const Suggestion = model('Suggestion', {
  unrestricted_value: identifier,
  label: string,
  value: string,
  data: model({
    geo_lat: maybeNull(string),
    geo_lon: maybeNull(string),
  }),
});
export const PriceMonitoringStore = model('PriceMonitoringStore', {
  isLoading: false,
  hasResults: false,
  selectedCulture: maybe(string),
  selectedCultureSubName: maybe(string),
  selectedLogistic: maybe(string),
  inputAddress: maybeNull(string),
  suggestions: map(Suggestion),
  EXWPrices: maybeNull(array(EXWPrice)),
  CPTPrices: maybeNull(
    array(
      model({
        city: string,
        data: array(CPTPrice),
      }),
    ),
  ),
  cultureOptions: array(CulturesListOptions),
  proteinOptions: array(CulturesListOptions),
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .views((self) => ({
    get suggestionsList() {
      return Array.from(self.suggestions.values()).flat();
    },
  }))
  .views((self) => ({
    get resultsList() {
      return self.selectedLogistic === 'EXW' ? self.EXWPrices : self.CPTPrices;
    },
  }))
  .views((self) => ({
    get suggestionsListOptions() {
      return self.suggestionsList.map((suggestion) => ({
        value: suggestion.value,
        label: suggestion.label,
      }));
    },
  }))
  .actions((self) => ({
    setSelectedCulture(culture: string) {
      self.selectedCulture = culture;
    },
  }))
  .actions((self) => ({
    setSelectedCultureSubName(protein: string) {
      self.selectedCultureSubName = protein;
    },
  }))
  .actions((self) => ({
    setSelectedLogistic(logisticType: string) {
      self.selectedLogistic = logisticType;
    },
  }))
  .actions((self) => ({
    setInputAddress(address: string | null) {
      self.inputAddress = address;
    },
  }))
  .actions((self) => ({
    setPrices(data: any) {
      if (self.selectedLogistic === 'EXW') {
        self.EXWPrices = data;
      } else {
        self.CPTPrices = data;
      }
    },
  }))

  .actions((self) => ({
    // @ts-ignore
    processSuggestions(data): any {
      self.suggestions.clear();
      const dataList = castArray(data);

      const mapped = dataList.map((suggestion) => {
        suggestion.label = suggestion.value;
        suggestion.value = suggestion.unrestricted_value;
        if (isPrimitive(suggestion)) {
          return suggestion;
        }
        const existing = self.suggestions?.get(suggestion.value);

        return existing
          ? mergeWith(existing, suggestion, (_, next: any) => {
              if (Array.isArray(next)) return next;
              return;
            })
          : self.suggestions.put(suggestion);
      });
      return Array.isArray(data) ? mapped : mapped[0];
    },
  }))
  .actions((self) => ({
    processCulturesOptions(data: ICultureOptionFromServer[]) {
      self.cultureOptions.clear();
      self.proteinOptions.clear();
      // we want populate culture and protein(subName) options and merge duplicates
      const mapped = data.map((culture) => {
        const existing = find(self.cultureOptions, { value: culture.name });
        if (!existing) {
          self.cultureOptions.push({ value: culture.name, label: culture.name });
        }
        const existingProtein = find(self.proteinOptions, { value: culture.subName });
        if (!existingProtein) {
          self.proteinOptions.push({ value: culture.subName, label: culture.subName });
        }
      });
    },
  }))

  .actions((self) => {
    return {
      fetchCulturesOptions: flow(function* fetchCulturesOptions(): any {
        const cultures = yield daylesfordService
          .getCulturesCalculator()
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: загрузка доступных культур`, 'error'));
        return self.processCulturesOptions(cultures.data as ICultureOptionFromServer[]);
      }),
    };
  })

  .actions((self) => {
    return {
      getResults: flow(function* getResults(): any {
        self.isLoading = true;
        self.hasResults = false;
        const reqOptions: IReqPriceCalcResults = {
          culture: self.selectedCulture as string,
          subName: self.selectedCultureSubName,
          basis: self.selectedLogistic as string,
        };
        if (self.selectedLogistic === 'EXW') {
          reqOptions.address = self.inputAddress as string;
          reqOptions.lat = self.suggestions.get(self.inputAddress as string)?.data?.geo_lat as string;
          reqOptions.lon = self.suggestions.get(self.inputAddress as string)?.data?.geo_lon as string;
        }
        const results = yield daylesfordService
          .getPriceCalculator(reqOptions)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: подсказка адресов`, 'error'));
        self.isLoading = false;
        self.setPrices(results.data);
        self.hasResults = true;
      }),
    };
  })
  .actions((self) => {
    return {
      fetchSuggestions: flow(function* fetchSuggestions(query: string): any {
        const fetchAddressSuggestions = async (query: string) => {
          // suggestions
          const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
          const token = process.env.REACT_APP_DADATA_KEY;

          const options = {
            method: 'POST',
            mode: 'cors' as RequestMode,
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Token ' + token,
            },
            body: JSON.stringify({ query: query }),
          };

          return await fetch(url, options)
            .then((response) => response.json())
            .catch((error) => console.log('error', error));
        };
        const suggestions = yield fetchAddressSuggestions(query).catch((e) =>
          self.root.alertsStore.addNotification(`${e.message}: подсказка адресов`, 'error'),
        );
        self.processSuggestions(suggestions.suggestions);
        return suggestions;
      }),
    };
  });
