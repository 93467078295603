import { useQuery } from 'react-query';

const Providers_LIST_DATA_QUERY_KEY = 'Providers_LIST_DATA_QUERY_KEY';

export const useProviders = (hasProviders: boolean, getProvidersRequest: any) => {
  const result = useQuery([Providers_LIST_DATA_QUERY_KEY, hasProviders], () => getProvidersRequest(), {
    enabled: !hasProviders,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });
  return result;
};
