import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Flex, Input, Spacer, Typography } from 'uikit';
import Modal from 'react-bootstrap/esm/Modal';
import { Formik, Form } from 'formik';
import { useQuery } from 'react-query';
import { EMPTY_CHAR } from 'utils/consts';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/Root';
import { Loader } from 'uikit/Loader';

interface IModal {
  isActive: any;
  setShowModalState: any;
}

export const ModalPasswordRecovery: React.FC<IModal> = observer(({ isActive, setShowModalState }) => {
  const handleModalClose = () => setShowModalState(false);
  const [userCredentials, setUserCredentials] = useState('');
  const { authStore } = useStore();

  const emailRecoveryData = useQuery<any>(
    'RECOVERY_DATA_QUERY_KEY',
    () => authStore.requestRecovery({ email: userCredentials }),
    {
      enabled: !!userCredentials,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  );

  const handleRecovery = ({ email }: { email: string }) => {
    setUserCredentials(email);
  };
  const isError = false;

  return (
    <StyledModal show={isActive} onHide={handleModalClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton />
      <ModalContent>
        <Flex column>
          {isActive && !emailRecoveryData.isFetching ? (
            <>
              {!emailRecoveryData.isSuccess ? (
                <>
                  <Typography size="lg">Введите свою почту для восстановления пароля</Typography>
                  <Spacer space={24} />
                  <Flex column>
                    <Formik
                      initialValues={{
                        email: EMPTY_CHAR,
                        password: EMPTY_CHAR,
                      }}
                      onSubmit={handleRecovery}
                    >
                      <Form>
                        <Flex column>
                          <Input type="value" name="email" label="Ваш e-mail" isError={isError} variant="dark" />
                          <Spacer space={30} />
                          <Button variant="base" type="submit">
                            Отправить
                          </Button>
                          <Spacer />
                        </Flex>
                      </Form>
                    </Formik>
                  </Flex>
                </>
              ) : (
                <>
                  <Typography size="lg">Письмо с инструкцией было отправлено на вашу почту</Typography>
                  <Spacer space={24} />
                  <Button variant="base" type="button" onClick={handleModalClose}>
                    Закрыть
                  </Button>
                </>
              )}
            </>
          ) : (
            <Flex column>
              <Loader />
            </Flex>
          )}
          <Spacer space={24} />
        </Flex>
      </ModalContent>
    </StyledModal>
  );
});

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 500px;
  }
  .modal-header {
    border: none;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }
  .close {
    color: #f9f6ed;
    text-shadow: none;
    opacity: 1;
    position: absolute;
    right: -20px;
    top: 0px;
  }
`;

const ModalContent = styled.div`
  padding: 32px 40px 20px;
  background-color: #f9f6ed;
  border-radius: 10px;
`;
