import styled from 'styled-components';
import { Typography, Button } from 'uikit';

export const Heading = styled(Typography)`
  padding: 44px 0 0 44px;
`;

export const HeadingSteps = styled(Heading)`
  padding: 0;
  margin-bottom: 20px;
  display: inline-block;
`;

export const TypographyDate = styled(Typography)`
  display: inline-block;
  margin: 4px 0 0 auto;
`;

export const OrderWrapperStep = styled.div`
  padding-left: 63px;
  width: 100%;
  max-width: 900px;
  color: #191919;
`;

export const StepItems = styled.ul`
  a {
    white-space: nowrap;
  }
`;

export const Item = styled.li`
  display: flex;
`;
export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ItemTitle = styled.p`
  font-size: 14px;
  margin-bottom: 4px;
`;

export const ItemBox = styled.div`
  position: relative;
  padding: 20px;
  width: 100%;
  min-width: 400px;
  background: #e7e2d4;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 30px;
  gap: 10px;
  margin-bottom: 0;
`;
export const ItemInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const ItemFormats = styled.div`
  display: flex;
  gap: 3px;
`;
export const ItemDownload = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`;
export const ItemDownloadSub = styled.div`
  display: flex;
  gap: 10px;
`;
export const ItemSubText = styled.p`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 5px 0 0 10px;
  width: 100%;
  margin-bottom: 0;
  span {
    margin-right: 20px;
  }
`;

export const StyledButton = styled(Button)`
  padding: 0;
  color: #407ef5;
  height: initial;
`;

export const ButtonLoadDoc = styled(StyledButton)`
  min-width: 65px;
  white-space: nowrap;
`;

export const ItemText = styled(Typography)`
  /* position: absolute; */
  letter-spacing: -0.41px;
  color: #191919;
  flex: 1;
  min-width: 0;
  flex-basis: 50%;
  width: 95%;
  b {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ItemFilename = styled(ItemText)`
  margin-top: 4px;
  font-size: 13px;
  max-width: 300px;
  b {
    color: rgba(25, 25, 25, 0.64);
  }
`;
export const ItemSignStatus = styled(ItemText)`
  position: relative;
  margin-top: 12px;
  padding-left: 5px;
  font-size: 13px;
  &:before {
    position: absolute;
    left: 0;
    content: '';
    width: 2px;
    height: 100%;
    background-color: #33bc62;
  }
`;
export const ItemFormat = styled(ItemText)`
  /* position: absolute;
  right: 15px; */
  display: inline-block;
  /* width: 100%; */
  width: 50px;
  border: 0.5px solid #585858;
  border-radius: 5px;
  font-size: 11px;
  color: #585858;
  text-align: center;
`;

export const ItemFormatSign = styled(ItemFormat)`
  width: 20px;
  max-width: 25px;
`;
export const ItemFormatFile = styled(ItemFormat)`
  min-width: 50px;
  width: 100%;
  max-width: fit-content;
  padding: 0 5px;
`;
