import styled from 'styled-components';

interface InfoStatusProps {
  children: React.ReactNode;
}

export const InfoStatus: React.FC<InfoStatusProps> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 468px;
  padding: 20px 16px 24px;
  background: #e8e0ff;
  border-radius: 12px;
  border: 1px solid #d2c2ff;
  font-size: 13px;
  text-decoration: none;
  color: #8e66fe;
`;
