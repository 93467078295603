import React from 'react';
import { observer } from 'mobx-react-lite';

import { Flex, Spacer, Typography } from 'uikit';
import styled from 'styled-components';
import { useStore } from 'store/Root';
import { LineChartAnalitycs } from './partials/LineChart';
import { PieChartAnalitycs } from './partials/PieChart';
import { useQuery } from 'react-query';
import { Loader } from 'uikit/Loader';
import { Select } from 'uikit/Selects';
import { BlocksInfo } from './partials/BlocksInfo';

export interface ILoginValues {
  email: string;
  password: string;
}

export const AnalyticsPage: React.FC = observer(() => {
  const { analyticsStore } = useStore();

  const fetchChartsCategories = useQuery(['CHARTS_LIST_DATA_QUERY_KEY'], () => analyticsStore.getChartsCategories(), {
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
    staleTime: 60 * 1000,
  });

  interface ICategoryDisplayObject {
    value: string;
    label: string;
  }

  const listOptions: ICategoryDisplayObject[] = analyticsStore.chartsCategories.map(
    ({ visibleName: label, id: value }) => ({ label, value }),
  );

  const handleCategoryChange = (category: ICategoryDisplayObject) => {
    analyticsStore.getCharts(category.value);
  };

  const renderChart = (chartData: any) => {
    switch (chartData.type) {
      case 'line':
        return <LineChartAnalitycs key={chartData.type} chartData={chartData} />;
      case 'pie':
        return <PieChartAnalitycs key={chartData.type} chartData={chartData} />;
      case 'blocks':
        return <BlocksInfo key={chartData.type} blocks={chartData} />;
      default:
        return (
          <>
            <LineChartAnalitycs key={chartData.type} chartData={chartData} />
            <Spacer space={42} />
          </>
        );
    }
  };

  return (
    <Wrapper>
      <Flex column>
        <Typography size="lg2" bold>
          Моя аналитика
        </Typography>
        <Spacer space={28} />
        <SelectRow>
          <Select options={listOptions} onChange={handleCategoryChange} disabled={fetchChartsCategories.isFetching} />
          {fetchChartsCategories.isFetching && <Loader />}
        </SelectRow>
        <Spacer space={28} />
        <AnalitycsWrapper>
          {!analyticsStore.isLoading &&
            analyticsStore.getChartsArr &&
            analyticsStore.getChartsArr.map((chart) => renderChart(chart))}
        </AnalitycsWrapper>
      </Flex>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 44px;
`;
const SelectRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 500px;
`;
const AnalitycsWrapper = styled.div`
  position: relative;
  width: 645px;
  height: 400px;
`;
