import React from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { Flex, Spacer, Typography, Button } from 'uikit';
import Modal from 'react-bootstrap/esm/Modal';

interface IModal {
  isActive: any;
  setShowModalState: any;
  text: string;
  getTimeLineAction: () => void;
  isRequestBody: string;
  handleSubmitForm: any;
  modalCode?: boolean;
  btnText: string;
}

export const ModalTimeline: React.FC<IModal> = ({
  isActive,
  setShowModalState,
  getTimeLineAction,
  isRequestBody,
  handleSubmitForm,
  modalCode = false,
  btnText,
}) => {
  const handleModalClose = () => setShowModalState(false);

  return (
    <StyledModal show={isActive} onHide={handleModalClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton />
      <ModalContent>
        <Formik enableReinitialize initialValues={{ isRequestBodyText: '' }} onSubmit={handleSubmitForm}>
          <Form>
            <Flex column>
              {isActive && (
                <>
                  <Flex column vAlignContent="center" hAlignContent="center">
                    <Typography bold size="lg">
                      <span>{isRequestBody}</span>
                    </Typography>
                    {/* <Spacer space={15} /> */}
                    {/* <Typography size="md">{text}</Typography> */}
                    <Spacer space={25} />
                    <Button
                      variant={modalCode ? 'baseRed' : 'actionGreen'}
                      size="md2"
                      type="submit"
                      onClick={getTimeLineAction}
                    >
                      {btnText}
                    </Button>
                  </Flex>
                </>
              )}
              <Spacer space={24} />
              <StyledButton variant="link" type="button" onClick={handleModalClose}>
                Закрыть
              </StyledButton>
            </Flex>
          </Form>
        </Formik>
      </ModalContent>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 780px;
  }
  .modal-header {
    border: none;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }
  .close {
    color: #f9f6ed;
    text-shadow: none;
    opacity: 1;
    position: absolute;
    right: -20px;
    top: 0px;
  }
`;

const ModalContent = styled.div`
  padding: 32px;
  background-color: #f9f6ed;
  border-radius: 10px;
`;

const StyledButton = styled(Button)`
  padding: 0;
  color: #407ef5;
  height: initial;
`;
