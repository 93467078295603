import { throttle } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'store/Root';
import styled from 'styled-components';
import { Flex, Typography, Spacer, Button } from 'uikit';
import { Card } from 'uikit/Card/Card';
import { Loader } from 'uikit/Loader';
import { Select } from 'uikit/Selects';

const logisticsListOptions = [
  { value: 'EXW', label: 'От своих ворот' },
  { value: 'CPT', label: 'На складе покупателя' },
];

export const PriceMonitoringForm: React.FC = observer(() => {
  const { priceMonitoringStore } = useStore();

  const handleCultureChange = (value: { label: string; value: string }) => {
    priceMonitoringStore.setSelectedCulture(value.value);
  };
  const handleProteinChange = (value: { label: string; value: string }) => {
    priceMonitoringStore.setSelectedCultureSubName(value.value);
  };
  const handleLogisticsChange = (value: { label: string; value: string }) => {
    priceMonitoringStore.setSelectedLogistic(value.value);
  };

  const fetchSuggestionsList = (inputValue: string) => {
    const result = priceMonitoringStore.fetchSuggestions(inputValue).then((res: any) => {
      return priceMonitoringStore.suggestionsList.map((suggestion: any) => ({
        value: suggestion.unrestricted_value,
        label: suggestion.value,
      }));
    });

    return result;
  };
  const loadAddressSuggestionsOptions = (inputValue: string) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(fetchSuggestionsList(inputValue));
      }, 1000);
    });

  const handleAddressChange = (value: { label: string; value: string } | null) => {
    priceMonitoringStore.setInputAddress(value === null ? null : value.value);
  };

  const renderResultsList = () => {
    if (priceMonitoringStore.selectedLogistic === 'EXW') {
      return (
        <Flex gap="20px" column>
          {priceMonitoringStore.EXWPrices?.map((result) => (
            <Card key={result.warehouse}>
              <Flex gap="20px" column>
                <div>
                  <Typography size="lg">{result.city}</Typography>
                  <Divider />
                </div>
                <Typography size="md">{result.company}</Typography>
                <Typography size="xs">Цена на складе покупателя: {result.price} руб/т</Typography>
                <Typography size="xs">Расстояние: {result.distance / 1000} км.</Typography>
                <Typography size="xs">Цена от ворот: {result.totalPrice} руб/т</Typography>
              </Flex>
            </Card>
          ))}
        </Flex>
      );
    } else if (priceMonitoringStore.selectedLogistic === 'CPT') {
      return (
        <Flex gap="20px" column>
          {priceMonitoringStore.CPTPrices?.map((result) => (
            <div key={result.city}>
              <>
                <Card>
                  <Typography size="md">{result.city}</Typography>
                  <Spacer space={20} />
                  {result.data.map((price) => (
                    <Flex key={price.company} gap="20px">
                      <Typography size="xs">
                        {price.company} ({price.warehouse}) :
                      </Typography>
                      <Typography size="xs">{price.price} руб/т</Typography>
                    </Flex>
                  ))}
                </Card>
              </>
            </div>
          ))}
        </Flex>
      );
    }
    return <div>Ошибка, попробуйте перезагрузить страницу</div>;
  };

  return (
    <Wrapper>
      <Flex gap="20px" column>
        <SelectWrapper>
          <Typography size="xs">Культура</Typography>
          <Select
            placeholder="Не выбрано"
            options={priceMonitoringStore.cultureOptions}
            onChange={handleCultureChange}
          />
        </SelectWrapper>
        {priceMonitoringStore.selectedCulture === 'Пшеница' && (
          <SelectWrapper>
            <Typography size="xs">Протеин</Typography>
            <Select
              placeholder="Не выбрано"
              options={priceMonitoringStore.proteinOptions}
              onChange={handleProteinChange}
            />
          </SelectWrapper>
        )}
        <SelectWrapper>
          <Typography size="xs">Логистика</Typography>
          <Select placeholder="Не выбрано" options={logisticsListOptions} onChange={handleLogisticsChange} />
        </SelectWrapper>
        {priceMonitoringStore.selectedLogistic === 'EXW' && (
          <SelectWrapper>
            <Select
              placeholder="Где хранится ваше зерно?"
              cacheOptions
              isClearable
              isSearchable
              defaultOptions
              loadOptions={loadAddressSuggestionsOptions}
              selectOption={handleAddressChange}
              onChange={handleAddressChange}
              isAsync
            />
          </SelectWrapper>
        )}

        <Flex gap="20px">
          <Button
            disabled={!priceMonitoringStore.selectedCulture && !priceMonitoringStore.selectedLogistic}
            variant="base"
            type="button"
            onClick={() => priceMonitoringStore.getResults()}
          >
            Рассчитать
          </Button>
        </Flex>
        <Spacer space={28} />
        {priceMonitoringStore.isLoading && <Loader />}
        {priceMonitoringStore.hasResults && (
          <Flex gap="20px" column>
            <>
              <Typography size="md">Результаты</Typography>
              {!priceMonitoringStore.resultsList?.length ? (
                <Typography size="xs">Нет результатов</Typography>
              ) : (
                renderResultsList()
              )}
            </>
          </Flex>
        )}
      </Flex>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 0;
`;
const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Divider = styled.div`
  margin: 10px 0 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #555;
`;
