import React from 'react';
import styled from 'styled-components';
import { Flex, Spacer, Typography } from 'uikit';
import Modal from 'react-bootstrap/esm/Modal';

interface IModal {
  isActive: any;
  setShowModalState: any;
  additionalInfo?: string;
  tables: [
    {
      type: string;
      name: string;
      data: [
        {
          carrier_name: string;
          cars: [
            {
              actual_weight: string;
              car_number: string;
              deliver: string;
              trailer_number: string;
            },
          ];
        },
      ];
    },
  ];
}

export const ModalTimelineTableCar: React.FC<IModal> = ({ isActive, setShowModalState, additionalInfo, tables }) => {
  const handleModalClose = () => setShowModalState(false);

  return (
    <StyledModal show={isActive} onHide={handleModalClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton />
      <ModalContent>
        <Flex column>
          {isActive && (
            <>
              <Flex column>
                {tables?.map(({ name, data }, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <FlexTypeSryled column>
                        <Typography size="lg" color="#918F89" bold>
                          {name}
                        </Typography>
                        <Spacer space={16} />
                        {data?.map(({ carrier_name, cars }, idx: number) => {
                          return (
                            <React.Fragment key={idx}>
                              <FlexItem column>
                                <Typography size="lg">{carrier_name}</Typography>
                                <Spacer space={18} />
                                <Grid>
                                  <Typography size="xs" color="#807E7B">
                                    Кузов
                                  </Typography>
                                  <Typography size="xs" color="#807E7B">
                                    Прицеп
                                  </Typography>
                                  <Typography size="xs" color="#807E7B">
                                    Водитель
                                  </Typography>
                                  <Typography size="xs" color="#807E7B">
                                    Погружено, т
                                  </Typography>
                                </Grid>
                                <Spacer space={10} />
                                {cars?.map(({ actual_weight, car_number, deliver, trailer_number }, index: number) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <Grid>
                                        <Typography size="xs" color="#807E7B">
                                          {car_number}
                                        </Typography>
                                        <Typography size="xs" color="#807E7B">
                                          {trailer_number}
                                        </Typography>
                                        <Typography size="xs" color="#807E7B">
                                          {deliver}
                                        </Typography>
                                        <Typography size="xs" color="#807E7B">
                                          {actual_weight}
                                        </Typography>
                                      </Grid>
                                      <Spacer space={10} />
                                    </React.Fragment>
                                  );
                                })}
                              </FlexItem>
                            </React.Fragment>
                          );
                        })}
                      </FlexTypeSryled>
                    </React.Fragment>
                  );
                })}
                {additionalInfo && <Typography size="lg">{additionalInfo}</Typography>}
              </Flex>
            </>
          )}
          <Spacer space={24} />
        </Flex>
      </ModalContent>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 500px;
  }
  .modal-header {
    border: none;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }
  .close {
    color: #f9f6ed;
    text-shadow: none;
    opacity: 1;
    position: absolute;
    right: -20px;
    top: 0px;
  }
`;

const ModalContent = styled.div`
  padding: 32px 40px 20px;
  background-color: #f9f6ed;
  border-radius: 10px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 80px;
  gap: 0 25px;
`;

const FlexTypeSryled = styled(Flex)`
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const FlexItem = styled(Flex)`
  border-bottom: 0.5px solid #59585871;
  padding-bottom: 6px;
  margin-bottom: 16px;
  white-space: nowrap;
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;
