import React from 'react';
import { StyledButton } from 'components/Orders/components/TimeLine/styles';
import { Flex, Spacer } from '../index';

interface IActionBtn {
  handleModalOpen: (code?: string, e?: any) => void;
  getTextCode: string;
  disabled: any;
}

export const ActionBtn: React.FC<IActionBtn> = ({ handleModalOpen, getTextCode, disabled }) => {
  return (
    <>
      <Flex column hAlignContent="left">
        <StyledButton variant="link" onClick={handleModalOpen} disabled={disabled}>
          {getTextCode}
        </StyledButton>
        <Spacer space={20} />
      </Flex>
    </>
  );
};
