import React from 'react';

interface IProps {
  size?: string;
}

/**
 * Иконка "Рубль".
 */
export const RoubleIcon = ({ size }: IProps) => (
  <svg width="16" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 16.328c4.36 0 7.969-3.617 7.969-7.969C15.969 4 12.352.391 7.992.391 3.641.39.032 4 .032 8.359c0 4.352 3.616 7.97 7.968 7.97Zm-1.36-3.937a.515.515 0 0 1-.538-.532v-.812h-.704A.381.381 0 0 1 5 10.648c0-.226.164-.398.398-.398h.704v-.781H5.39c-.243 0-.399-.18-.399-.406 0-.227.156-.399.399-.399h.71v-3.46c0-.321.212-.54.532-.54h1.906c1.508 0 2.453 1.016 2.453 2.399 0 1.398-.953 2.398-2.46 2.398H7.155v.789H9.04c.234 0 .39.172.39.398 0 .235-.156.399-.39.399H7.156v.812c0 .313-.21.532-.515.532Zm.524-3.875 1.188.015c1.039 0 1.578-.562 1.578-1.46 0-.883-.532-1.438-1.578-1.438H7.164v2.883Z"
      fill="#8863F2"
    />
  </svg>
);
