export const colors = {
  black: '#000',
  white: '#fff',
  purple: '#8e66fe',
  blackAlt: '#2f3441',
  blue: '#1e4bd2',
  dirtyBlue: '#678bc1',
  paleBlue: '#F3F7FA',
  green: '#4cc864',
  darkGreen: '#338e79',
  red: '#e62632',
  cyan: '#00aaff',
  darkRed: '#ca181f',
  retailBlue: '#0a2896',
  lightBlue: '#cceeff',
  orange: '#f1a038',
  bodyBg: '#eaedf5',
  grayMain: '#eaedf5',
  strokeGray: '#dce0eb',
  darkGray: '#6B7683',
  lightGray: '#f3f7fa',
  inputGray: '#acb6c3',
  shadowGray: 'rgba(2, 2, 2, 0.1)',
  gray: {
    g50: '#fafafa',
    g100: '#f9f9f9',
    g200: '#eeeeee',
    g300: '#e0e0e0',
    g400: '#b3b3b3',
    g500: '#9e9e9e',
    g600: '#757575',
    g700: '#616161',
    g800: '#4d4d4d',
    g900: '#212121',
  },
};
