import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/esm/Modal';
import { Button, Flex, Spacer, TwistedArrowIcon } from 'uikit';
import { Counter } from './Counter';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/Root';
import { Loader } from 'react-feather';
import { getTaxTypeForm } from 'components/Orders/pages/helpers';
import { generatePath, useNavigate } from 'react-router-dom';
import { routes } from 'routes/consts';

export const CounterOffer: React.FC<{ roomId: string }> = observer(({ roomId }) => {
  const { chatStore } = useStore();
  const navigate = useNavigate();
  const room = chatStore.rooms.get(roomId);
  const bidDeadline = room!.bid?.expiredAt || '';

  const [acceptState, setAcceptState] = useState(false);
  const [changeState, setChangeState] = useState(false);
  const [newPrice, setNewPrice] = useState(room?.bid?.buyerPrice || '');
  const [agreedState, setAgreedState] = useState(room?.bid?.acceptedBuyer);
  const handleSubmit = () => {
    setChangeState(false);
    chatStore.sendCounterOffer(roomId, newPrice);
  };
  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPrice(e.target.value);
  };
  if (!room) return <Loader />;

  const agreeToBid = () => {
    room.acceptBid();
    setAcceptState(false);
    setAgreedState(true);
  };

  const handleFinishedOrderClick = () => {
    navigate(`../../orders/${generatePath(routes.orders.timeline.path, { id: room.orderId || '' })}`);
  };

  const fullPrice = Number(room!.bid?.providerPrice) * Number(room!.bid?.volume);

  return (
    <Flex column gap={5}>
      <Wrapper>
        {!acceptState || (!agreedState && <Counter deadline={bidDeadline} bidDuration={chatStore.bidDuration} />)}
        <Title>
          <Text>{room.title}</Text>
          <TextVolume>{room.bid!.volume} т</TextVolume>
        </Title>
        {!acceptState && (
          <>
            <CounterOfferColumn>
              <CounterOfferRow>
                <TwistedArrowIcon />
                <CounterOfferTitle>Предложение продавца</CounterOfferTitle>
              </CounterOfferRow>
              <CounterOfferRow>
                <CounterOfferPrice>{room.bid!.providerPrice}</CounterOfferPrice>
                <CounterOfferCurr>₽/т</CounterOfferCurr>
              </CounterOfferRow>
            </CounterOfferColumn>
            <CounterOfferColumn>
              <CounterOfferColumnInner isHighlighted={changeState}>
                <CounterOfferColumn>
                  <CounterOfferRow>
                    <TwistedArrowIcon reverse />
                    <CounterOfferTitle>Предложение покупателя</CounterOfferTitle>
                  </CounterOfferRow>
                  <CounterOfferRow isVisuallyHidden={changeState}>
                    <CounterOfferPrice>{room.bid!.buyerPrice}</CounterOfferPrice>
                    <CounterOfferCurr>₽/т</CounterOfferCurr>
                  </CounterOfferRow>
                </CounterOfferColumn>
                {changeState && (
                  <>
                    <StyledInput
                      type="text"
                      name="price"
                      id="counterOffer_price"
                      onChange={handlePriceChange}
                      placeholder="Введите цену"
                    />
                  </>
                )}
              </CounterOfferColumnInner>
            </CounterOfferColumn>
          </>
        )}

        <Divider />
        <InfoTextHeader>Продавец</InfoTextHeader>
        <InfoText>{room.provider.company || ''}</InfoText>
        <InfoTextHeader>Налогообложение</InfoTextHeader>
        <InfoText>{getTaxTypeForm(room.provider.taxType || 'simple')}</InfoText>
      </Wrapper>
      {!!acceptState && (
        <Wrapper>
          <Flex column>
            <Title>
              <Text>Вы уверены, что хотите принять сделку?</Text>
            </Title>
            <CounterOfferColumn>
              <InfoTextHeader>Объем, т</InfoTextHeader>
              <TextVolume>{room.bid!.volume}</TextVolume>
            </CounterOfferColumn>
            <CounterOfferColumn>
              <InfoTextHeader>Цена продавца CNCPT, ₽</InfoTextHeader>
              <TextVolume>{room.bid!.providerPrice}</TextVolume>
            </CounterOfferColumn>
          </Flex>
        </Wrapper>
      )}
      {!!agreedState && (
        <Wrapper>
          <Flex column>
            {acceptState && <Counter deadline={bidDeadline} bidDuration={chatStore.bidDuration} />}
            <Title>
              <Text>Вы приняли сделку</Text>
            </Title>
            <Text>Теперь очередь за продавцом</Text>
            <CounterOfferColumn>
              <InfoTextHeader>Объем, т</InfoTextHeader>
              <TextVolume>{room.bid!.volume}</TextVolume>
            </CounterOfferColumn>
            <CounterOfferColumn>
              <InfoTextHeader>Цена продавца CNCPT, ₽</InfoTextHeader>
              <TextVolume>{room.bid!.providerPrice}</TextVolume>
            </CounterOfferColumn>
          </Flex>
        </Wrapper>
      )}
      {room.bid?.status === 'accepted' && room.archived && (
        <>
          <Wrapper>
            <CounterOfferColumn>
              <InfoTextHeader>Объем, т</InfoTextHeader>
              <TextVolume>{room.bid!.volume}</TextVolume>
            </CounterOfferColumn>
            <CounterOfferColumn>
              <InfoTextHeader>Сделка</InfoTextHeader>
              <TextVolume>{fullPrice}</TextVolume>
            </CounterOfferColumn>
          </Wrapper>
          <Spacer space={10} />
          <ActionsBlock>
            <StyledButton isFullWidth variant="base" size="lg" type="button" onClick={handleFinishedOrderClick}>
              Открыть сделку
            </StyledButton>
          </ActionsBlock>
        </>
      )}
      {!room.bid!.acceptedBuyer && !room.archived && !agreedState && (
        <ActionsBlock>
          {!acceptState &&
            (!changeState ? (
              <>
                <StyledButton variant="base" size="lg" type="button" onClick={() => setChangeState(true)}>
                  Изменить предложение
                </StyledButton>
                <StyledButtonGreen variant="actionGreen" size="lg" type="button" onClick={() => setAcceptState(true)}>
                  Принять
                </StyledButtonGreen>
              </>
            ) : (
              <>
                <StyledButton variant="base" size="lg" type="button" onClick={handleSubmit as () => void}>
                  Сохранить
                </StyledButton>
                <StyledButton variant="baseRed" size="lg" type="button" onClick={() => setChangeState(false)}>
                  Назад
                </StyledButton>
              </>
            ))}
          {acceptState && (
            <>
              <StyledButton variant="baseRed" size="lg" type="button" onClick={() => setAcceptState(false)}>
                Отмена
              </StyledButton>
              <StyledButtonGreen variant="base" size="lg" type="button" onClick={agreeToBid}>
                Принять
              </StyledButtonGreen>
            </>
          )}
        </ActionsBlock>
      )}
      {acceptState && <Overlay />}
    </Flex>
  );
});
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20;
  height: 100vh;
`;
const Wrapper = styled.div`
  padding: 20px 20px;
  gap: 5px;
  display: flex;
  border-radius: 5px;
  flex-flow: column nowrap;
  background-color: #f9f6ed;
  max-width: 360px;
  z-index: 21;
`;
const ActionsBlock = styled.div`
  gap: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 21;
`;
const Title = styled.div`
  margin: 13px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 18px;
  line-height: 21px;
  & > span {
    font-weight: bold;
  }
`;
const Divider = styled.div`
  margin: 10px 0 15px;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #555;
`;
const Text = styled.span`
  flex-basis: max-content;
  font-size: 16px;
`;
const TextVolume = styled.span`
  flex-basis: max-content;
  white-space: nowrap;
`;
interface IStyledButton {
  isFullWidth?: boolean;
}
const StyledButton = styled(Button)<IStyledButton>`
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '175px')};
  height: 40px;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  background-color: #f9f6ed;
  &:hover {
    background-color: #f1f1f1;
  }
`;
const StyledButtonGreen = styled(Button)`
  width: 175px;
  height: 40px;
  border-color: #00bfa5;
  color: #3ab246;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  background-color: #f9f6ed;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const CounterOfferColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  gap: 10px;
  svg path {
    stroke: #918f89;
  }
`;

const InfoText = styled.span`
  font-size: 16px;
  line-height: 18px;
`;
const StyledInput = styled.input`
  text-align: left;
  margin: 10px 0;
  outline: none;
  padding: 17px 20px;

  color: #333333;
  background-color: #f2efe5;
  border: 1px solid #e7e2d1;
  border-radius: 6px;
  box-shadow: 0 0 0 30px #f5f2ea inset !important;
`;

interface CounterOfferColumnInnerProps {
  isHighlighted?: boolean;
}

const CounterOfferColumnInner = styled.div<CounterOfferColumnInnerProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ isHighlighted }) => (isHighlighted ? '#efebde' : 'transparent')};
  border: ${({ isHighlighted }) => (isHighlighted ? '1px solid #dbdbdb' : '1px solid transparent')};
  width: calc(100% + 20px);
  padding: 10px;
  margin: 0 -10px;
  border-radius: 10px;
`;

interface CounterOfferRowProps {
  isVisuallyHidden?: boolean;
}

const CounterOfferRow = styled.div<CounterOfferRowProps>`
  display: flex;
  gap: 4px;
  align-items: center;
  white-space: nowrap;
  opacity: ${({ isVisuallyHidden }) => (isVisuallyHidden ? 0 : 1)};
`;
const CounterOfferTitle = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #918f89;
`;
const InfoTextHeader = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #918f89;
`;

const CounterOfferPrice = styled.div`
  font-size: 18px;
  line-height: 21px;

  letter-spacing: -0.41px;
  color: #000000;
`;
const CounterOfferCurr = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #000000;
`;

const StyledModal = styled(Modal)`
  .modal-header {
    border: none;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }
  .close {
    color: #f9f6ed;
    text-shadow: none;
    opacity: 1;
    position: absolute;
    right: -20px;
    top: 0px;
  }
`;

const ModalContent = styled.div`
  padding: 32px;
  background-color: #f9f6ed;
  border-radius: 10px;
`;
