//  user_confirmed = 'user_confirm', 'Данные подтверждены пользователем' ??

export const EDMStatus = (edmStatus: string) => {
  switch (edmStatus) {
    case 'unknown':
      return 'Статус не определен';
    case 'created':
      return 'Создана заявка на пользователя';
    case 'user_confirmed':
      return 'Данные подтверждены пользователем';
    case 'user_confirm':
      return 'Данные подтверждены пользователем';
    case 'certificate_issued':
      return 'Сертификат выпущен';
    default:
      return 'Статус не определен';
  }
};
