import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: "SFPro";
    src: url("/SFProDisplay-Regular.woff2") format("woff2");
    font-weight: 400;
}
@font-face {
    font-family: "SFPro";
    src: url("/SFProDisplay-Bold.woff2") format("woff2");
    font-weight: 700;
}
@font-face {
    font-family: "SFPro";
    src: url("/SFProDisplay-Semibold.woff2") format("woff2");
    font-weight: 600;
}

@font-face {
    font-family: "SFPro";
    src: url("/SFProDisplay-Medium.woff2") format("woff2");
    font-weight: 500;
}


    html,
    body {
        height: 100%;
    }

    html {
        cursor: default;
        word-break: break-word;
    }
    body {
        margin: 0;
        background-color: #f4f6f6;
        font-family: SFPro !important;
        font-style: 400;
        font-weight: normal;
        font-size: 14px;
        color: #191919;
    }

    ol,
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    a:hover {
        text-decoration: none;
    }
    
    .tooltip.show {
        opacity: 1 !important;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
    -moz-appearance:textfield; /* Firefox */
    }

  .shipment-calendar {
    .react-datepicker__month-container, .react-datepicker__header {
      background-color: #fff;
    }
    .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected, .react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
      background-color: #8e66fe;
    }
    .react-datepicker__day:hover {
      color: #fff;
      background-color: #8e66fe;
    }
    .react-datepicker__current-month {
      text-transform: capitalize;
    }
  }
    
  .react-datepicker {
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 15px 5px #00000021;
  }

  .react-datepicker__month-container,
  .react-datepicker__header {
    background-color: #f2efe5;
  }

  .react-datepicker__header {
    border-bottom: 1px solid #e7e7e7;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #407ef5;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background-color: #918f89;
  }
`;
