import React from 'react';
import styled from 'styled-components';
import { Flex } from 'uikit';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/Root';

export const DashboardCounter: React.FC = observer(() => {
  const { contactsDashboardStore } = useStore();

  const usersStatus = 'Пользователи';
  const companiesStatus = 'Компании';

  return (
    <Flex>
      <TabFilter
        isActive={usersStatus === contactsDashboardStore.selectedCategory}
        onClick={() => {
          usersStatus !== contactsDashboardStore.selectedCategory &&
            contactsDashboardStore.setSelectedCategory(usersStatus);
        }}
      >
        {usersStatus}
      </TabFilter>
      <TabFilter
        isActive={companiesStatus === contactsDashboardStore.selectedCategory}
        onClick={() => {
          companiesStatus !== contactsDashboardStore.selectedCategory &&
            contactsDashboardStore.setSelectedCategory(companiesStatus);
        }}
      >
        {companiesStatus}
      </TabFilter>
    </Flex>
  );
});

const TabFilter = styled.div<{ isActive?: boolean; isDisabled?: boolean }>`
  font-size: 16px;
  opacity: ${({ isActive }) => (isActive ? 'initial' : 0.5)};
  cursor: ${({ isDisabled }) => (isDisabled ? 'progress' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'initial')};
  margin-right: 30px;
`;
