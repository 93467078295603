import React from 'react';
import { useParams } from 'react-router-dom';
import { Spacer } from 'uikit';
import styled from 'styled-components';
import { IRouteParams } from 'utils/models';
import { Steps } from './Steps';
import { useStore } from 'store/Root';
import { useQuery } from 'react-query';
import { Loader } from 'uikit/Loader';
import { observer } from 'mobx-react-lite';

export const TimeLine: React.FC = observer(() => {
  const { id }: IRouteParams = useParams();
  const paramId = id || '';

  const { ordersStore } = useStore();

  const timelinesData = useQuery<any>(
    ['TIMELINE_DATA_QUERY_KEY', paramId],
    () => ordersStore.timelineStore.getTimeline(paramId),
    {
      enabled: !!paramId,
      refetchOnWindowFocus: false,
      retry: 3,
      refetchInterval: 60 * 1000,
      cacheTime: 60 * 1000,
      staleTime: 60 * 1000,
    },
  );

  return (
    <>
      {timelinesData ? (
        <OrderWrapperSteps>
          {ordersStore.timelineStore.timelines &&
            ordersStore.timelineStore.timelines.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <Steps getNewTimeline={timelinesData.refetch} stages={item} />
                  <Spacer space={90} />
                </React.Fragment>
              );
            })}
        </OrderWrapperSteps>
      ) : (
        <Loader />
      )}
    </>
  );
});

const OrderWrapperSteps = styled.div`
  max-width: 1200px;
  padding: 44px;
`;
