import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Flex } from 'uikit';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/Root';

export const InquiriesCounter: React.FC = observer(() => {
  const { inquiriesStore } = useStore();

  useEffect(() => {
    inquiriesStore.getCounter();
  });

  const activeStatus = 'Открытые';
  const acceptedStatus = 'Принятые';
  const declinedStatus = 'Отклоненные';

  return (
    <Flex gap={8}>
      <TabFilter
        isActive={'open' === inquiriesStore.selectedStatus}
        onClick={() => {
          'open' !== inquiriesStore.selectedStatus && inquiriesStore.setSelectedCategory(activeStatus);
        }}
      >
        <span>{activeStatus}</span> - <span>{inquiriesStore.inquiriesCounter?.open || 0}</span>
      </TabFilter>
      <TabFilter
        isActive={'accepted' === inquiriesStore.selectedStatus}
        onClick={() => {
          'accepted' !== inquiriesStore.selectedStatus && inquiriesStore.setSelectedCategory(acceptedStatus);
        }}
      >
        <span>{acceptedStatus}</span> - <span>{inquiriesStore.inquiriesCounter?.accepted || 0}</span>
      </TabFilter>
      <TabFilter
        isActive={'declined' === inquiriesStore.selectedStatus}
        onClick={() => {
          'declined' !== inquiriesStore.selectedStatus && inquiriesStore.setSelectedCategory(declinedStatus);
        }}
      >
        <span>{declinedStatus}</span> - <span>{inquiriesStore.inquiriesCounter?.declined || 0}</span>
      </TabFilter>
    </Flex>
  );
});

const TabFilter = styled.div<{ isActive?: boolean; isDisabled?: boolean }>`
  padding: 10px 16px;
  font-size: 17px;
  border-radius: 12px;
  background-color: ${({ isActive }) => (isActive ? '#8E66FE' : 'transparent')};
  color: ${({ isActive }) => (isActive ? '#FFF' : '#292929')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'progress' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'initial')};
`;
