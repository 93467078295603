import { castArray, mergeWith } from 'lodash-es';
import { flow, types, getRoot } from 'mobx-state-tree';
import { isPrimitive } from '../utils/utils';
import { daylesfordService } from '../services';
import { IReqLinkedOrdersSummary } from 'services/models';
import { IRootStore } from './Root';

const { identifier, model, map, enumeration, string, number, maybeNull, maybe, reference } = types;

const LinkedOrder = model('LinkedOrder', {
  id: identifier,
  product: model({
    id: identifier,
    title: string,
  }),
  company: model({
    id: identifier,
    nameOfProvider: string,
  }),
  generalStatus: string,
  readableStatus: string,
  dateStartOfSpec: maybeNull(string),
  dateFinishOfContract: string,
});

export const LinkedOrdersStore = model('LinkedOrders', {
  isLoading: true,
  linkedOrders: map(LinkedOrder),
  hasNextPage: true,
  pageNumber: 1,
  linkedBy: maybeNull(
    model({
      linkBy: enumeration(['offer', 'company']),
      id: identifier,
    }),
  ),
  linkedOrdersCounter: maybeNull(
    model({
      ordersCount: number,
      activeCount: number,
      doneCount: number,
      failCount: number,
      totalActualVolume: maybeNull(number),
    }),
  ),
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .views((self) => ({
    get linkedOrdersList() {
      return Array.from(self.linkedOrders.values());
    },
  }))
  .actions((self) => ({
    // @ts-ignore
    process(data): any {
      const dataList = castArray(data);

      const mapped = dataList.map((linkedOrder) => {
        linkedOrder.id = linkedOrder.id.toString();

        if (isPrimitive(linkedOrder)) {
          return linkedOrder;
        }

        const existing = self.linkedOrders?.get(linkedOrder.id);

        return existing
          ? mergeWith(existing, linkedOrder, (_, next: any) => {
              if (Array.isArray(next)) return next; // Treat arrays like atoms
              return;
            })
          : self.linkedOrders?.put(linkedOrder);
      });
      return Array.isArray(data) ? mapped : mapped[0];
    },
  }))
  .actions((self) => {
    return {
      setHasNextPage(value: boolean) {
        self.hasNextPage = value;
      },
    };
  })
  .actions((self) => {
    return {
      setPageNumber(value: number) {
        self.pageNumber = value;
      },
    };
  })
  .actions((self) => {
    return {
      getLinkedOrders: flow(function* getlinkedOrders(
        ordersRequestOptions: IReqLinkedOrdersSummary,
        linkBy: 'company' | 'offer',
        id: string,
      ): any {
        self.isLoading = true;
        if (linkBy !== self.linkedBy?.linkBy || id !== self.linkedBy?.id) {
          self.linkedOrders.clear();
          self.setPageNumber(1);
        }
        self.linkedBy = { linkBy, id };
        const linkedOrders = yield daylesfordService
          .getLinkedOrders(ordersRequestOptions)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Связанные сделки`, 'error'));
        self.setHasNextPage((linkedOrders.data?.next ?? null) != null);

        self.isLoading = false;
        self.process(linkedOrders.data.results);
        return linkedOrders;
      }),
    };
  })
  .actions((self) => {
    return {
      getCounter: flow(function* getCounter(linkBy: 'company' | 'offer', id: string): any {
        self.isLoading = true;
        const linkedOrdersCounter = yield daylesfordService
          .getLinkedOrdersCounter({ [linkBy]: id })
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Счетчик связанных сделок`, 'error'));
        self.isLoading = false;
        self.linkedOrdersCounter = linkedOrdersCounter.data;

        return linkedOrdersCounter;
      }),
    };
  });
