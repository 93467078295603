import { castArray, mergeWith } from 'lodash-es';
import { flow, types, getRoot } from 'mobx-state-tree';
import { isPrimitive } from '../utils/utils';
import { daylesfordService } from '../services';
import { IRootStore } from './Root';

const { identifier, model, map, number, string, array, union, maybeNull, maybe, reference } = types;

const ChartLineData = model('LineChart', {
  name: maybeNull(string),
  color: maybeNull(string),
  value: maybeNull(number),
});
const Blocks = model('Blocks', {
  type: string,
  subtype: maybeNull(string),
  dateTime: maybe(string),
  blocks: maybeNull(
    array(
      model('blocks', {
        type: string,
        subtype: maybe(string),
        dateTime: maybe(string),
        value: string,
      }),
    ),
  ),
  value: identifier,
});

const Chart = model('Chart', {
  title: identifier,
  legend: model({ value: string, value2: maybeNull(string) }),
  type: string,
  data: array(ChartLineData),
});
const ChartsCategories = model('Chart', {
  id: string,
  visibleName: string,
  aggregation: maybe(array(maybeNull(string))),
  subtypes: maybe(array(maybeNull(string))),
});

export const AnalyticsStore = model('AnalyticsStore', {
  isLoading: true,
  charts: map(Chart),
  blocks: map(Blocks),
  chartsCategories: array(ChartsCategories),
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .views((self) => ({
    get getChartsArr() {
      return Array.from(self.charts.values());
    },
  }))
  .views((self) => ({
    get getBlocksArr() {
      return Array.from(self.blocks.values());
    },
  }))
  .actions((self) => ({
    // @ts-ignore
    process(data): any {
      if (data[0].stage) {
        const dataList = castArray(data[0].blocks);
        const fakeCharts = {
          title: 'Blocks',
          legend: { value: 'blocks' },
          type: 'blocks',
          data: [],
        };
        self.charts?.put(fakeCharts);
        dataList.map((blocks) => self.blocks.put(blocks));
        return;
      }
      const dataList = castArray(data);

      const mapped = dataList.map((charts) => {
        charts.title = charts.title ? charts.title.toString() : '';
        if (isPrimitive(charts)) {
          return charts;
        }
        const existing = self.charts?.get(charts.title);
        return existing
          ? mergeWith(existing, charts, (_, next: any) => {
              if (Array.isArray(next)) return next; // Treat arrays like atoms
              return;
            })
          : self.charts?.put(charts);
      });
      return Array.isArray(data) ? mapped : mapped[0];
    },
  }))
  .actions((self) => {
    return {
      getCharts: flow(function* getlinkedOrders(categoryId: string): any {
        self.isLoading = true;
        self.charts?.clear();
        self.blocks?.clear();
        const charts = yield daylesfordService
          .getAnalyticsCharts(categoryId)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Аналитика, графики`, 'error'));
        self.isLoading = false;
        self.process(charts.data);
        return charts;
      }),
    };
  })
  .actions((self) => {
    return {
      getChartsCategories: flow(function* getCounter(): any {
        self.isLoading = true;
        const chartsList = yield daylesfordService
          .getAnalyticsChartsList()
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Аналитика, список графиков`, 'error'));
        self.isLoading = false;
        self.chartsCategories = chartsList.data;

        return chartsList;
      }),
    };
  });
