import axios from 'axios';
import { HmacSHA1, MD5, enc } from 'crypto-js';

const host = process.env.REACT_APP_NOVOFON_HOST as string;
const key = process.env.REACT_APP_NOVOFON_KEY as string;
const secret = process.env.REACT_APP_NOVOFON_SECRET as string;

export const voiceApiInstance = axios.create({
  baseURL: `${host}/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
});

voiceApiInstance.interceptors.request.use((config) => {
  const sortedParams = config.params ? Object.keys(config.params).sort() : [];
  console.log('configParams:', config.params, sortedParams);

  // method here is url without host and params (e.g. /v1/sip/)
  const method = config.url?.split(host)[1];
  const paramsStr = config.params ? sortedParams.join('&') : '';

  const sign = HmacSHA1(method + paramsStr + MD5(paramsStr).toString(), secret).toString(enc.Base64);
  const header = `Authorization: ${key}:${sign}`;
  config.headers['Authorization'] = header;

  if (config.method === 'post') {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }

  return config;
});

export const voiceApiService = {
  balance: () => voiceApiInstance.get('/info/balance/'),
};
