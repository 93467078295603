import { observer } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { Flex, Spacer } from 'uikit';
import ru from 'date-fns/locale/ru';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useStore } from 'store/Root';
import { CloseIcon } from 'uikit/Icons/CloseIcon';
import { InquiryHeader } from 'components/Inquiries/pages/InquiryPage';
import { IndicatorInput } from 'components/Inquiries/pages/components/IndicatorInput';
import { useRef } from 'react';
import { toJS } from 'mobx';
import { formatDecimals } from 'utils/utils';

registerLocale('ru', ru);

interface IProps {
  orderId: string;
  showModalState: boolean;
  handleModalClose: () => void;
  onModalCloseWithoutSaving: () => void;
  orderIndicators: any[];
}

export const SpecOrderIndicatorsModal: React.FC<IProps> = observer(
  ({ orderId, showModalState, handleModalClose, orderIndicators, onModalCloseWithoutSaving }) => {
    const { specsOrdersStore } = useStore();

    const handleIndicatorValueChange = (id: number, value: string) => {
      specsOrdersStore.updateOrderIndicatorValue(id, value);
    };

    const handleIndicatorLimitChange = (id: number, limit: string) => {
      specsOrdersStore.updateOrderIndicatorLimit(id, limit);
    };

    const handleIndicatorWithdrawalChange = (id: number, withdrawal: string) => {
      specsOrdersStore.updateOrderIndicatorWithdrawal(id, withdrawal);
    };

    const handleIndicatorNameChange = (id: number, name: string) => {
      specsOrdersStore.updateOrderIndicatorName(id, name);
    };

    const handleIndicatorMeasureChange = (id: number, measure: 'percent' | 'string' | 'number') => {
      specsOrdersStore.updateOrderIndicatorMeasure(id, measure);
    };

    const handleAddIndicator = () => {
      specsOrdersStore.addOrderIndicator();
    };

    const handleDeleteIndicator = (id: number) => {
      specsOrdersStore.deleteIndicator(id);
    };

    const handleCounterOffer = () => {
      const reducedIndicators = orderIndicators.reduce((acc, indicator) => {
        acc.push({
          ...indicator,
          basisValue: formatDecimals(indicator.value || indicator.basisValue).toString(),
          value: formatDecimals(indicator.value || indicator.basisValue).toString(),
          limitValue: formatDecimals(indicator.limitValue).toString(),
          withdrawalValue: formatDecimals(indicator.withdrawalValue).toString(),
        });
        return acc;
      }, []);

      const dataToSend = {
        id: orderId,
        indicators: reducedIndicators,
      };

      specsOrdersStore.updateOrderDetails(dataToSend);
      setTimeout(() => {
        handleModalClose();
      }, 1000);
    };

    const handleModalCloseWithoutSaving = () => {
      onModalCloseWithoutSaving();
      handleModalClose();
    };

    const canSendIndicators = orderIndicators.every((indicator) => {
      return indicator.name !== '';
    });

    return (
      <StyledModal
        show={showModalState}
        onHide={handleModalCloseWithoutSaving}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeaderWrapper>
          <Modal.Header closeButton />
          <StyledCloseIcon>
            <CloseIcon />
          </StyledCloseIcon>
        </ModalHeaderWrapper>
        <ModalContent>
          <Flex column>
            <InquiryHeader>Показатели качества</InquiryHeader>
            <p>Ограничение по качеству товара. </p>

            <Flex column>
              <TableWrapper>
                <IndicatorsTableWrapper>
                  <IndicatorsHeaderCell>Параметр</IndicatorsHeaderCell>
                  <IndicatorsHeaderCell>
                    <span>Базис</span>
                    <span>Ограничение</span>
                    <span>Съёмы</span>
                  </IndicatorsHeaderCell>
                </IndicatorsTableWrapper>
                {orderIndicators.map((indicator: any) => (
                  <IndicatorInput
                    // onUpdate={handleIndicatorChange}
                    onValueChange={handleIndicatorValueChange}
                    onLimitChange={handleIndicatorLimitChange}
                    onWithdrawalValueChange={handleIndicatorWithdrawalChange}
                    onNameChange={handleIndicatorNameChange}
                    onMeasureChange={handleIndicatorMeasureChange}
                    onDelete={handleDeleteIndicator}
                    isDeleteDisabled={orderIndicators.length === 1}
                    key={indicator.id}
                    indicator={indicator}
                  />
                ))}
                <AddIndicatorButton type="button" onClick={handleAddIndicator}>
                  + Добавить строку
                </AddIndicatorButton>
                <ButtonsWrapper>
                  <DiscardChangesButton type="button" onClick={handleModalCloseWithoutSaving}>
                    Отмена
                  </DiscardChangesButton>
                  <CounterOfferButton type="button" disabled={!canSendIndicators} onClick={handleCounterOffer}>
                    Применить изменения
                  </CounterOfferButton>
                </ButtonsWrapper>
              </TableWrapper>
            </Flex>
            <Spacer space={20} />
          </Flex>
        </ModalContent>
      </StyledModal>
    );
  },
);

export const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 620px;
  }
  .modal-header {
    border: none;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }
  .btn-close {
    background: none;
    width: 40px;
    height: 40px;
    position: absolute;
    right: -11px;
    top: -8px;
    z-index: 1;
  }
`;

const ModalHeaderWrapper = styled.div`
  position: relative;
`;

const SubHeader = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 124%;
  padding-left: 8px;
  color: #292929;
`;
const DatePickerWrapper = styled.div`
  position: relative;
  margin-top: 12px;
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
    input {
      padding: 17px 30px;
      padding-left: 48px;
      border-radius: 12px;
      border: 1px solid #dcdee4;
      width: 100%;
    }
  }
`;

const StyledCloseIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  cursor: pointer;
`;

const StyledWarehouseSelect = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  .Select__control {
    max-width: 100%;
    border: 1px solid #dcdee4;
    max-height: 52px;
    border-radius: 12px;
  }
  .Select__value-container {
    padding: 10px 16px;
  }
`;

export const ModalContent = styled.div`
  padding: 16px;
  background-color: #fff;
  border-radius: 10px;
  max-width: 620px;
`;

const StyledInput = styled.input`
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 16px;
  padding-left: 48px;
  width: 100%;
  text-align: left;
  color: #656565;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #dcdee4;
  border-radius: 12px;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  width: 100%;
  background-color: #f4f6f6;
  border-radius: 12px;
`;

const IndicatorsTableWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 34px;
  padding-bottom: 16px;
  margin-bottom: 2px;
`;

const IndicatorsHeaderCell = styled.div`
  :nth-child(odd) {
    width: 148px;
    padding: 0px 16px;
    font-weight: 600;
    font-size: 15px;
  }
  :nth-child(even) {
    width: 324px;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: space-around;
    gap: 12px;
    text-align: center;
    color: #656565;
  }
`;

export const CounterOfferButton = styled.button`
  width: 50%;
  background-color: #8e66fe;
  border-radius: 12px;
  border: none;
  padding: 18px 0px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  :active {
    background-color: rgba(142, 102, 254, 0.5);
  }
  :hover {
    background-color: #825dec;
  }
  :disabled {
    background-color: #e5e5e5;
    color: #aeaeae;
  }
`;

export const DiscardChangesButton = styled.button`
  width: 50%;
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid #825dec;
  padding: 18px 0px;
  color: #8e66fe;
  font-weight: 600;
  font-size: 18px;
`;

const AddIndicatorButton = styled.button`
  background-color: transparent;
  border-radius: 12px;
  border: none;
  padding: 18px 0px;
  color: #8e66fe;
  font-weight: 600;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-top: 16px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const StyledRoubleIcon = styled.div`
  position: absolute;
  z-index: 1;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
`;
