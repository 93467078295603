import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Flex } from '..';
import styled from 'styled-components';
import ru from 'date-fns/locale/ru';
import addDays from 'date-fns/addDays';

registerLocale('ru', ru);

interface IProps {
  initialValues: {
    start?: string;
    end?: string;
  };
  startFieldName: string;
  endFieldName: string;
  hasCounter?: boolean;
  disabled?: boolean;
}

export const DatePickerField: React.FC<IProps> = ({
  initialValues,
  startFieldName,
  endFieldName,
  hasCounter = false,
  disabled,
}) => {
  const [startDate, setStartDate] = useState<Date>(
    new Date(initialValues?.start || Date.now()),
  );
  const _initEndDate = initialValues?.end
    ? new Date(initialValues?.end)
    : addDays(new Date(initialValues?.start || Date.now()), 29);
  const [endDate, setEndDate] = useState<Date>(_initEndDate);
  const { setFieldValue } = useFormikContext();
  const [days, setDays] = useState<number>();

  const defaultDaysInterval = 1000 * 60 * 60 * 24;

  useEffect(() => {
    if (startDate == null) {
      setStartDate(new Date());
      return;
    }

    startDate.getTime() >=
      Math.floor(Date.now() / defaultDaysInterval) * defaultDaysInterval &&
      setFieldValue(
        startFieldName,
        startDate ??
          Math.floor(Date.now() / defaultDaysInterval) * defaultDaysInterval,
      );

    if (endDate) {
      if (
        endDate.getTime() <
        Math.floor(startDate.getTime() / defaultDaysInterval) *
          defaultDaysInterval
      ) {
        setEndDate(addDays(startDate, 29));
        return;
      }
    } else {
      setEndDate(addDays(startDate, 29));
      setFieldValue(endFieldName, addDays(startDate, 29));
      return;
    }
    setFieldValue(endFieldName, endDate);
    setDays(
      Math.round(
        (endDate.getTime() - startDate.getTime()) / defaultDaysInterval,
      ) + 1,
    );
  }, [startDate]);

  useEffect(() => {
    if (startDate == null) {
      setStartDate(new Date());
      return;
    }

    if (endDate == null) {
      setEndDate(addDays(startDate, 29));
      setFieldValue(endFieldName, addDays(startDate, 29));
      return;
    }

    if (
      endDate.getTime() <
      Math.floor(startDate.getTime() / defaultDaysInterval) *
        defaultDaysInterval
    ) {
      setEndDate(addDays(startDate, 29));
      setFieldValue(endFieldName, addDays(startDate, 29));
      return;
    }

    setFieldValue(endFieldName, endDate);

    setDays(
      Math.round(
        (endDate.getTime() - startDate.getTime()) / defaultDaysInterval,
      ) + 1,
    );
  }, [endDate]);

  return (
    <Flex>
      <DatePickerWrapper fillWidth>
        <StyledDatePicker
          selected={startDate}
          onChange={(date: Date) => date && setStartDate(date)}
          dateFormat="dd.MM.yyyy"
          disabled={disabled}
          locale="ru"
          maxDate={endDate}
          selectsStart
          minDate={new Date()}
        />
        <StyledDatePicker
          selected={endDate}
          onChange={(date: Date) => date && setEndDate(date)}
          startDate={startDate}
          minDate={startDate}
          dateFormat="dd.MM.yyyy"
          disabled={disabled || !startDate}
          locale="ru"
          selectsEnd
        />
      </DatePickerWrapper>
      {hasCounter && <DaysCounter>{`${days} д.`}</DaysCounter>}
    </Flex>
  );
};

const DatePickerWrapper = styled(Flex)`
  min-width: 345px !important;
  justify-content: space-between;
`;

const DaysCounter = styled.div`
  display: flex;
  margin-left: 28px;
  height: 50px;
  min-width: 55px;
  align-items: center;
`;

const StyledDatePicker = styled(DatePicker)`
  height: 50px;
  border: 0px;
  border: 1px solid none;
  background-color: #f5f2ea;
  border-radius: 6px;
  color: #333333;
  text-align: center;
`;
