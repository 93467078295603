import React from 'react';
import {
  StepItems,
  Item,
  ItemText,
  ItemBox,
  ItemFormatFile,
  ButtonLoadDoc,
  ItemWrapper,
  ItemTitle,
} from 'components/Orders/components/TimeLine/styles';
import { Flex } from '../index';

interface IActionFile {
  value: any;
  fileType?: string;
  onChange?: (e: any) => void;
  files: any;
}

export const ActionFile: React.FC<IActionFile> = ({ value, fileType, onChange, files }) => {
  const { title } = JSON.parse(value) || {};
  const fileRef = React.createRef<HTMLInputElement>();
  const onLoadClick = () => fileRef.current?.click();
  return (
    <>
      <StepItems>
        <Item>
          <ItemWrapper>
            <ItemTitle>{title}</ItemTitle>
            <ItemBox>
              <ItemText>Загрузите документ</ItemText>
              <ItemFormatFile>{fileType || '...'}</ItemFormatFile>
            </ItemBox>
          </ItemWrapper>
          <Flex style={{ marginTop: '21px', gap: '30px' }}>
            <form style={{ display: 'none' }} onSubmit={(e: any) => e.preventDefault()}>
              <input type="file" ref={fileRef} onChange={onChange} style={{ display: 'none' }} />
            </form>
            <ButtonLoadDoc variant="link" type="button" onClick={onLoadClick}>
              {files ? 'Изменить...' : 'Выбрать...'}
            </ButtonLoadDoc>
          </Flex>
        </Item>
      </StepItems>
    </>
  );
};
