import { io } from 'socket.io-client';

const URL = process.env.REACT_APP_CHAT_HOST as string;

const socket = io(URL, {
  autoConnect: false,
  secure: true,
  // withCredentials: false,
  reconnectionDelay: 1000,
  reconnection: true,
  reconnectionAttempts: 3,
  // transports: ['websocket'],
  // agent: false,
  // rejectUnauthorized: false,
  // upgrade: false,
});

export default socket;
