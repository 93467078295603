import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from 'store/Root';
import styled from 'styled-components';
import { Flex, Typography, Spacer } from 'uikit';
import { PriceMonitoringForm } from '../components/PriceMonitoringForm';
import { useCultures } from 'hooks/useCultures';

export const PriceMonitoringPage: React.FC = observer(() => {
  const { priceMonitoringStore } = useStore();

  useEffect(() => {
    priceMonitoringStore.fetchCulturesOptions();
  }, []);
  return (
    <Wrapper>
      <Flex column>
        <Typography size="lg2" bold>
          Мониторинг цен
        </Typography>
        <Spacer space={28} />
        {/* <Tabs /> */}
        {/* <Spacer space={20} /> */}
        <PriceMonitoringForm />
      </Flex>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 44px;
`;
