import React, { useState } from 'react';

import { Flex, Spacer, Typography } from 'uikit';
import styled from 'styled-components';
import { DeliverySettings } from './partials/DeliverySettings';
import { TeamSettings } from './partials/TeamSettings';
import { CalendarSettings } from './partials/CalendarSettings';

// const deliveryPage = 'Доставка';
const teamPage = 'Команда';
const calendarPage = 'Календарь';

export const SettingsPage: React.FC = () => {
  const [chosenSubPage, setChosenSubPage] = useState<string>(teamPage);

  const handleSubPageChange = (page: string) => {
    setChosenSubPage(page);
  };

  const renderSubPage = (page: string) => {
    switch (page) {
      // case deliveryPage:
      //   return <DeliverySettings />;
      case teamPage:
        return <TeamSettings />;
      case calendarPage:
        return <CalendarSettings />;

      default:
        return <DeliverySettings />;
    }
  };

  return (
    <Wrapper>
      <Flex column>
        <Typography size="lg2" bold>
          Мои настройки
        </Typography>
        <Spacer space={28} />
        <Flex>
          {/* <TabFilter isActive={deliveryPage === chosenSubPage} onClick={() => handleSubPageChange(deliveryPage)}>
            <span>{deliveryPage}</span>
          </TabFilter> */}
          <TabFilter isActive={teamPage === chosenSubPage} onClick={() => handleSubPageChange(teamPage)}>
            <span>{teamPage}</span>
          </TabFilter>
          <TabFilter isActive={calendarPage === chosenSubPage} onClick={() => handleSubPageChange(calendarPage)}>
            <span>{calendarPage}</span>
          </TabFilter>
        </Flex>
        <Spacer space={28} />
        {renderSubPage(chosenSubPage)}
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 44px;
`;
const TabFilter = styled.div<{ isActive?: boolean; isDisabled?: boolean }>`
  font-size: 16px;
  opacity: ${({ isActive }) => (isActive ? 'initial' : 0.5)};
  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
  pointer-events: ${({ isActive }) => (isActive ? 'none' : 'initial')};
  margin-right: 30px;
`;
