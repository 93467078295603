import { castArray, mergeWith } from 'lodash-es';
import { flow, types, getRoot } from 'mobx-state-tree';
import { isPrimitive } from '../utils/utils';
import { daylesfordService } from '../services';
import { IOffer, IReqOffers } from 'services/models';
import { IRootStore } from './Root';

const { identifier, boolean, model, map, enumeration, string, number, maybeNull, maybe, optional, array, reference } =
  types;

const ProductSpecs = model('ProductSpecs', {
  id: identifier,
  unitOfMeasurement: model({
    id: number,
    unit: string,
  }),
  name: string,
  required: boolean,
  type: string,
  description: maybeNull(string),
  GOST: string,
  isAsIs: boolean,
  spec: string,
});
const Product = model('Product', {
  id: identifier,
  specifications: maybe(array(ProductSpecs)),
  basisSpecifications: maybe(array(ProductSpecs)),
  title: string,
  description: maybeNull(string),
  harvestYear: string,
  harvestType: maybeNull(string),
  culture: string,
});
const Warehouse = model('Warehouse', {
  id: identifier,
  title: string,
  address: string,
  owner: maybe(number), // ??
  longCoord: string,
  latCoord: string,
});

const Offer = model('Offer', {
  id: identifier,
  totalVolume: number,
  currentVolume: number,
  ordersQty: number,
  cost: maybe(number),
  costWithNds: maybe(number),
  createdAt: string,
  creator: string,
  dateFinishShipment: string,
  dateStartShipment: string,
  description: string,
  periodOfExport: number,
  specifications: array(
    model({
      id: identifier,
      specification: maybe(ProductSpecs),
      value: maybeNull(string),
      basisValue: maybeNull(string),
    }),
  ),
  basisSpecifications: array(
    model({
      id: identifier,
      specifications: maybe(ProductSpecs),
      value: maybeNull(string),
    }),
  ),
  product: Product,
  status: string,
  title: string,
  volume: maybe(number),
  warehouse: Warehouse,
  priceBuyer: string,
}).views((self) => ({
  get availableVolume() {
    return self.totalVolume - self.currentVolume;
  },
}));
export const OffersStore = model('OffersStore', {
  isLoading: false,
  isProductsLoading: false,
  isWarehousesLoading: false,
  offers: map(Offer),
  products: map(Product),
  warehouses: map(Warehouse),
  selectedOffer: maybeNull(reference(Offer)),
  hasNextPage: true,
  actualProductId: string,
  pageNumber: 1,
  selectedStatus: optional(enumeration(['Активные', 'Закрытые']), 'Активные'),
  offersCounter: maybeNull(
    model({
      active: number,
      archived: number,
    }),
  ),
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .views((self) => ({
    get offersList() {
      return Array.from(self.offers.values()).flat();
    },
  }))
  .views((self) => ({
    get productsList() {
      return Array.from(self.products.values()).flat();
    },
  }))
  .views((self) => ({
    get warehousesList() {
      return Array.from(self.warehouses.values()).flat();
    },
  }))
  .views((self) => ({
    get productSpecifications() {
      const product = self.products.get(self.actualProductId);

      if (!!product && product.specifications) return Array.from(product.specifications).flat();
      return [];
    },
  }))
  .views((self) => ({
    get productBasisSpecifications() {
      const product = self.products.get(self.actualProductId);

      if (!!product && product.basisSpecifications) return Array.from(product.basisSpecifications).flat();
      return [];
    },
  }))
  .actions((self) => ({
    // @ts-ignore
    process(data, type? = 'offer'): any {
      const dataList = castArray(data);

      const mapped = dataList.map((item) => {
        item.id = item.id.toString();

        if (isPrimitive(item)) {
          return item;
        }

        let existing;
        if (type === 'offer') {
          existing = self.offers?.get(item.id);
        }
        if (type === 'products') {
          existing = self.products?.get(item.id);
        }
        if (type === 'warehouses') {
          existing = self.warehouses?.get(item.id);
        }

        let dataMap;

        if (type === 'offer') {
          dataMap = self.offers;
        }
        if (type === 'products') {
          dataMap = self.products;
          item.basisSpecifications = Array.from(item.specifications);
        }
        if (type === 'warehouses') {
          dataMap = self.warehouses;
        }

        return existing
          ? mergeWith(existing, item, (_, next: any) => {
              if (Array.isArray(next)) return next;
              return;
            })
          : dataMap?.put(item);
      });
      return Array.isArray(data) ? mapped : mapped[0];
    },
  }))
  .actions((self) => {
    return {
      setHasNextPage(value: boolean) {
        self.hasNextPage = value;
      },
    };
  })
  .actions((self) => {
    return {
      setPageNumber(value: number) {
        self.pageNumber = value;
      },
    };
  })
  .actions((self) => {
    return {
      setActualProductId(id: string) {
        self.actualProductId = id;
      },
    };
  })
  .actions((self) => {
    return {
      setSelectedStatus(status: 'Активные' | 'Закрытые') {
        self.offers.clear();
        self.pageNumber = 1;
        self.selectedStatus = status;
      },
    };
  })
  .actions((self) => {
    return {
      setSelectedOffer(id: string | null) {
        self.selectedOffer = !!id ? self.offers.get(id) || null : null;
      },
    };
  })
  .actions((self) => {
    return {
      getOffers: flow(function* getOffers(ordersRequestOptions: IReqOffers): any {
        self.isLoading = true;
        const offers = yield daylesfordService
          .getOffersListInfinite(ordersRequestOptions)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Список предложений`, 'error'));
        self.setHasNextPage((offers.data?.next ?? null) != null);

        self.isLoading = false;
        self.process(offers.data.results);
        return offers;
      }),
    };
  })
  .actions((self) => {
    return {
      getOffer: flow(function* getOffer(id: string): any {
        self.isLoading = true;
        const offer = yield daylesfordService
          .getOffer(id)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Данные о предложении`, 'error'));
        self.setSelectedOffer(offer.data.id.toString());
        self.isLoading = false;
        return self.process(offer.data);
      }),
    };
  })
  .actions((self) => {
    return {
      editOffer: flow(function* editOffer(request: IOffer): any {
        self.isLoading = true;
        const offer = yield daylesfordService
          .editOffer(request)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Изменение предложения`, 'error'));
        self.isLoading = false;
        return self.process(offer.data);
      }),
    };
  })
  .actions((self) => {
    return {
      deleteOffer: flow(function* deleteOffer(id: string): any {
        self.isLoading = true;
        yield daylesfordService
          .deleteOffer(id)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Удаление предложения`, 'error'));
        self.isLoading = false;
        return self.offers.delete(id);
      }),
    };
  })
  .actions((self) => {
    return {
      createOffer: flow(function* createOffer(request: IOffer): any {
        self.isLoading = true;
        self.offers.clear();
        const offer = yield daylesfordService
          .createOffer(request)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Создание предложения`, 'error'));
        self.isLoading = false;
        return self.process(offer.data);
      }),
    };
  })
  .actions((self) => {
    return {
      getProducts: flow(function* getProducts(): any {
        self.isProductsLoading = true;
        const offer = yield daylesfordService
          .getProducts()
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Список культур`, 'error'));
        self.isProductsLoading = false;
        return self.process(offer.data, 'products');
      }),
    };
  })
  .actions((self) => {
    return {
      getWarehouses: flow(function* getWarehouses(): any {
        self.isWarehousesLoading = true;
        const offer = yield daylesfordService
          .getWarehouses()
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Список складов`, 'error'));
        self.isWarehousesLoading = false;
        return self.process(offer.data, 'warehouses');
      }),
    };
  })
  .actions((self) => {
    return {
      getCounter: flow(function* getCounter(): any {
        const offersCounter = yield daylesfordService
          .getOffersCounter()
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Счетчик предложений`, 'error'));

        self.offersCounter = { active: offersCounter.data.activeSummary, archived: offersCounter.data.archived };

        return offersCounter;
      }),
    };
  });
