import React, { useState } from 'react';
import { Flex, Spacer, Typography } from '..';
import { TypographyDate } from 'components/Orders/components/TimeLine/styles';
import { formatDate, FRONTEND_DATE_FORMAT_STEPS } from 'utils/utils';
import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';

registerLocale('ru', ru);

interface IProps {
  dateTime: string;
  actionDateTitle: string;
  setIsRequestData: any;
  actionDateCode: string;
}

export const DatePickerTimeline: React.FC<IProps> = ({
  dateTime,
  actionDateTitle,
  setIsRequestData,
  actionDateCode,
}) => {
  const nextDaty = new Date().setDate(new Date().getDate() + 1);
  const [startDate, setStartDate] = useState<any>(nextDaty);

  return (
    <>
      <Spacer space={20} />
      <Flex column>
        <Flex hAlignContent="center">
          <Typography size="lg">{actionDateTitle}</Typography>
          <TypographyDate size="sm" color="#918F88">
            {formatDate(dateTime, FRONTEND_DATE_FORMAT_STEPS)}
          </TypographyDate>
        </Flex>
        <Spacer space={20} />
        <StyledDatePicker
          selected={startDate}
          onChange={(date: Date) => {
            setStartDate(date);
            setIsRequestData({
              code: actionDateCode,
              date: date.toISOString().slice(0, 10),
            });
          }}
          dateFormat="dd MMMM, EE"
          disabled={false}
          locale="ru"
          selectsStart
        />
        <Spacer space={20} />
      </Flex>
    </>
  );
};

const StyledDatePicker = styled(DatePicker)`
  height: 50px;
  border: 0px;
  border: 1px solid none;
  background-color: #f5f2ea;
  border-radius: 6px;
  color: #333333;
  text-align: center;
`;
