import { OrderProgressTracker } from 'components/Orders/components/OrderProgressTracker';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useQuery } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';
import { routes } from 'routes/consts';
import { IReqOrders } from 'services/models';
import { useStore } from 'store/Root';
import styled from 'styled-components';
import { Card } from 'uikit/Card/Card';
import { Loader } from 'uikit/Loader';
import { getMergedStatuses } from 'utils/utils';

const limitFetchedRecords = 10;

interface IProps {
  isOwner: boolean;
}

export const SpecOrdersDataList: React.FC<IProps> = observer(({ isOwner }) => {
  const { specsOrdersStore, culturesStore } = useStore();

  const navigate = useNavigate();

  const setNotActiveStatuses = () => {
    return specsOrdersStore.selectedStatus === 'Завершенные' ? 'closed' : 'declined';
  };

  const ordersRequestOptions: IReqOrders = {
    limit: limitFetchedRecords,
    offset: limitFetchedRecords * (specsOrdersStore.pageNumber - 1),
    general_status: specsOrdersStore.selectedStatus !== 'Активные' ? setNotActiveStatuses() : 'open',
  };

  // if (!isOwner) ordersRequestOptions.provider = providersStore.providersList.map((provider) => provider.id).toString();

  const fetchSpecOrdersData = useQuery(
    [
      'SPEC_ORDER_LIST_DATA_QUERY_KEY',
      specsOrdersStore.selectedStatus,
      specsOrdersStore.selectedCultureFilter,
      specsOrdersStore.selectedProvider,
    ],
    () => specsOrdersStore.getSpecsOrders(ordersRequestOptions),
    {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      staleTime: 60 * 1000,
    },
  );

  const loadMore = function () {
    if (specsOrdersStore.hasNextPage && specsOrdersStore.dmHasNextPage) {
      specsOrdersStore.setPageNumber(specsOrdersStore.pageNumber + 1);
      specsOrdersStore.setdmPageNumber(specsOrdersStore.dmPageNumber + 1);
    } else if (!specsOrdersStore.hasNextPage && specsOrdersStore.dmHasNextPage) {
      specsOrdersStore.setdmPageNumber(specsOrdersStore.dmPageNumber + 1);
    } else if (specsOrdersStore.hasNextPage && !specsOrdersStore.dmHasNextPage) {
      specsOrdersStore.setPageNumber(specsOrdersStore.pageNumber + 1);
    }
    return fetchSpecOrdersData.refetch();
  };

  const [sentryRef] = useInfiniteScroll({
    loading: specsOrdersStore.isLoading,
    hasNextPage: specsOrdersStore.hasNextPage || specsOrdersStore.dmHasNextPage,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  const handleCardClick = (id: string, isDm: boolean) => {
    // specsOrdersStore.setSelectedOrder(id);
    isDm
      ? navigate(`../${generatePath(routes.specsOrders.dmTimeline.path, { id })}`)
      : navigate(`../${generatePath(routes.specsOrders.timeline.path, { id })}`);
  };

  const hasNextPage = specsOrdersStore.hasNextPage || specsOrdersStore.dmHasNextPage;

  const noOrdersMessage = specsOrdersStore.isLoading ? '' : 'С выбранными фильтрами сейчас нет активных сделок';

  return (
    <CardsListWrapper>
      {!specsOrdersStore.allOrdersList.length
        ? noOrdersMessage
        : specsOrdersStore.allOrdersList.map((order) => {
            const isDm = order.isDm;
            const status = getMergedStatuses(order.basis, order.status as number);
            const title = isDm ? order.culture : culturesStore.getCultureName(order.culture as number);
            const createdDate = !isDm
              ? format(new Date(order.createdAt || ''), 'dd MMMM yyyy', { locale: ru })
              : format(new Date(order.date || ''), 'dd.MM.yyyy', { locale: ru });
            const isNotOpen = order.status === 60 || order.status === 100;
            const buyers =
              isOwner && order.buyers !== null
                ? order.buyers.map((buyer) => buyer.lastName + ' ' + buyer.firstName).join(', ')
                : '';

            return (
              <Card key={order.id} noPadding transparent onClick={() => handleCardClick(order.id, isDm)}>
                <OrderCard>
                  <CardHeader>
                    <CardHeaderWrapper>
                      <span>{title}</span> <span>{order.contractVolume} т</span>
                    </CardHeaderWrapper>
                    <CardNumberWrapper>
                      {!isDm && <span>№ {order.number}</span>} <span>от {createdDate}</span>
                    </CardNumberWrapper>
                  </CardHeader>
                  {!isNotOpen && !isDm && (
                    <OrderProgressTracker statusArr={status.mergedStatuses} currentStatus={status.currentStatus} />
                  )}
                  <OrderTableWrapper>
                    {isOwner && (
                      <OrderTableRow fullWidth>
                        <ManagerWrapper>
                          <OrderTableName>Менеджер</OrderTableName>
                          <ManagersNames>{buyers}</ManagersNames>
                        </ManagerWrapper>
                      </OrderTableRow>
                    )}
                    <OrderTableRow>
                      <OrderTableName>Объем, т</OrderTableName>
                      <OrderTableValue>{order.contractVolume} т</OrderTableValue>
                    </OrderTableRow>

                    <OrderTableRow>
                      <OrderTableName>Продавец</OrderTableName>
                      <OrderTableValue>{order.providerName}</OrderTableValue>
                    </OrderTableRow>
                    <OrderTableRow>
                      <OrderTableName>Базис</OrderTableName>
                      <OrderTableValue>{order.basis}</OrderTableValue>
                    </OrderTableRow>
                    <OrderTableRow>
                      <OrderTableName>Цена продавца, руб</OrderTableName>
                      <OrderTableValue>
                        {order.taxable ? (
                          <OrderTableSubGrid>
                            <OrderTableValue>
                              {order.price}
                              <OrderTableNote>без НДС</OrderTableNote>
                            </OrderTableValue>
                            <OrderTableValue>
                              {order.priceWithNds as string}
                              <OrderTableNote>c НДС</OrderTableNote>
                            </OrderTableValue>
                          </OrderTableSubGrid>
                        ) : (
                          <OrderTableValue>
                            {order.price}
                            <OrderTableNote>без НДС</OrderTableNote>
                          </OrderTableValue>
                        )}
                      </OrderTableValue>
                    </OrderTableRow>
                  </OrderTableWrapper>
                </OrderCard>
              </Card>
            );
          })}
      {(specsOrdersStore.isLoading || hasNextPage) && (
        <div ref={sentryRef}>
          <Loader />
        </div>
      )}
    </CardsListWrapper>
  );
});

const CardsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const OrderCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 24px;
  gap: 16px;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 16px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardHeaderWrapper = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #292929;
`;

const CardNumberWrapper = styled.span`
  font-size: 15px;
  font-weight: 400;
  color: #656565;
`;

export const OrderTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface IOrderTableRow {
  fullWidth?: boolean;
}

export const OrderTableRow = styled.div<IOrderTableRow>`
  display: grid;
  grid-template-columns: ${({ fullWidth }) => (fullWidth ? '1fr' : '1fr 2fr')};
  gap: 8px;
  padding: 8px;
  border-radius: 6px;
  &:nth-child(odd) {
    background-color: #f3f7f9;
  }
`;

export const OrderTableName = styled.span`
  color: #292929;
  font-weight: 600;
  font-size: 15px;
`;

export const OrderTableValue = styled.span`
  color: #292929;
  font-size: 15px;
`;

export const OrderTableNote = styled.span`
  margin-left: 12px;
  color: #656565;
`;

const ManagerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ManagersNames = styled.span`
  color: #656565;
  font-size: 15px;
`;

export const OrderTableSubGrid = styled.span`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;
