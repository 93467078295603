import React from 'react';

interface IProps {
  size?: string;
}

/**
 * Иконка "Закрыть".
 */
export const CloseIcon = ({ size }: IProps) => (
  <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m11.491 10 8.2-8.2a1.054 1.054 0 0 0-1.49-1.491L10 8.509 1.8.309a1.054 1.054 0 1 0-1.491 1.49L8.509 10l-8.2 8.2a1.054 1.054 0 1 0 1.49 1.491l8.201-8.2 8.2 8.2a1.054 1.054 0 0 0 1.491-1.49L11.491 10Z"
      fill="#F9F6ED"
    />
  </svg>
);
