import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
/* eslint-disable react-hooks/exhaustive-deps */
import { IRouteParams } from 'utils/models';
import { Flex, Spacer, Typography, ModalTimeline } from 'uikit';
import { ISteps } from 'services/models';
import { HeadingSteps, OrderWrapperStep } from './styles';
import {
  TitleText,
  Docs,
  TitleAdress,
  ActionBtn,
  ActionFile,
  ActionValue,
  HeadingText,
  ProgressBar,
} from 'uikit/TimeLineBlocks';
import {
  getTextCode,
  getModalBtnText,
  getModalText,
  getStepNumber,
  getStepTitle,
  getModalSubText,
} from '../../pages/helpers';
import { DatePickerTimeline } from 'uikit/Datepicker/DatePickerTimeline';
import { ItemDay } from './stepFourItems';
import { useStore } from 'store/Root';
import { useQuery, useQueryClient } from 'react-query';
import { GridTable } from 'uikit/GridTable';

export const Steps: React.FC<ISteps> = ({ stages, getNewTimeline }) => {
  const { ordersStore } = useStore();
  const { blocks, stage } = stages || {};
  const nextDate = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10);

  const [showModalState, setShowModalState] = useState<boolean>(false);
  const [isRequestData, setIsRequestData] = useState<any>({});
  const [completed, setCompleted] = useState<number>(0);
  const [currentLoadingCode, setCurrentLoadingCode] = useState<string>('');
  const [files, setFiles] = useState<any>({
    code: null,
    file: null,
    progressCallBack: null,
  });
  const [actionValues, setActionValues] = useState<any>({
    code: null,
    value: null,
  });
  const { id }: IRouteParams = useParams();
  const paramId = id || '';

  const actionValueRef = useRef<HTMLInputElement>(null);

  const timelineActionData = useQuery<any>(
    ['TIMELINE_ACTION_QUERY_KEY', paramId, ordersStore.timelineStore.actionRequestOptions],
    () => ordersStore.timelineStore.createTimeLineAction(paramId),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  );

  const queryClient = useQueryClient();

  const timelineDocsData = useQuery<any>(
    ['TIMELINE_ACTION_DOCS', paramId, files],
    () => ordersStore.timelineStore.uploadTimeLineActionDocs(paramId, files.file, files),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      onSuccess: (data) => {
        ordersStore.root.alertsStore.addNotification(`Загрузка документа(ов) успешна`, 'notification');
        queryClient.setQueryData(['TIMELINE_DATA_QUERY_KEY', paramId], data);
      },
    },
  );
  const timelineActionValuesData = useQuery<any>(
    ['TIMELINE_ACTION_VALUES', paramId, actionValues],
    () => ordersStore.timelineStore.uploadActionValue(paramId, actionValues),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      onSuccess: (data) => {
        ordersStore.root.alertsStore.addNotification(`Отправка данных успешна`, 'notification');
        queryClient.setQueryData(['TIMELINE_DATA_QUERY_KEY', paramId], data);
      },
    },
  );
  const timelineDateData = useQuery<any>(
    ['TIMELINE_ACTION_DATE', paramId, isRequestData],
    () => ordersStore.timelineStore.uploadTimeLineDate(paramId, isRequestData),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (files.code && files.file) {
      timelineDocsData.refetch();
      setTimeout(() => {
        getNewTimeline();
      }, 1000);
    }
  }, [files.code, files.file]);
  // }, [files.code, files.file, getFileLoad, getNewTimeline])

  useEffect(() => {
    if (timelineActionData.isSuccess || !timelineActionData.isSuccess) {
      setFiles({
        code: null,
        file: null,
        progressCallBack: (progressEvent: ProgressEvent, code: string) => {
          setCurrentLoadingCode(code);
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setCompleted(percentCompleted);
          percentCompleted === 100 &&
            setTimeout(() => {
              setCurrentLoadingCode('');
            }, 1000);
        },
      });
      setActionValues({ code: null, value: null });

      ordersStore.timelineStore.setActionRequestOptions({ code: '' });
      getNewTimeline();
    }
  }, [timelineActionData.isSuccess]);
  // }, [actionIsSuccess, getNewTimeline]);

  useEffect(() => {
    if (isRequestData?.date) {
      timelineDateData.refetch();
    }
  }, [isRequestData]);
  // }, [isRequestData, getUploadDate]);

  const handleSendActionValue = () => {
    if (actionValues.code && actionValues.value) {
      timelineActionValuesData.refetch();
      setTimeout(() => {
        getNewTimeline();
      }, 1000);
    }
    actionValueRef?.current?.click();
  };

  const handleModalOpen = (code: string, e: any) => {
    const isActionDate = blocks.some((el: any) => el.type === 'action-date');
    setShowModalState(true);
    if (isActionDate && !isRequestData.code && !isRequestData.date) {
      setIsRequestData({
        code: 'loading_date_sent',
        date: nextDate,
      });
    }
    if (files.file && e.target.innerText === 'Качество подтверждено') {
      ordersStore.timelineStore.setActionRequestOptions({ code: 'quality_approved' });
    } else if (files.file && e.target.innerText === 'Зерно не соответствует качеству') {
      ordersStore.timelineStore.setActionRequestOptions({ code: 'quality_failed' });
    } else {
      ordersStore.timelineStore.setActionRequestOptions({ code: code });
    }
  };

  return (
    <Flex>
      <Typography size="lg" bold color="#918F88">
        {getStepNumber(stage)}
      </Typography>
      <OrderWrapperStep>
        <Flex>
          <HeadingSteps size="lg2" bold>
            {getStepTitle(stage)}
          </HeadingSteps>
        </Flex>
        {blocks?.map(
          (
            {
              value = '{}',
              type,
              dateTime,
              blocks: subBlock,
            }: {
              value: string;
              type: string;
              dateTime: string;
              subtype: string;
              blocks: any;
            },
            idx: number,
          ) => {
            const renderModal = (code: string, modalTitle?: string, modalText?: string) => {
              const modalCode = ordersStore.timelineStore.actionRequestOptions?.code === 'quality_failed';

              return (
                <ModalTimeline
                  isActive={showModalState}
                  setShowModalState={setShowModalState}
                  text={getModalSubText(ordersStore.timelineStore.actionRequestOptions?.code ?? code)}
                  getTimeLineAction={() => {
                    timelineActionData.refetch();
                    setShowModalState(false);
                    if (timelineActionData.isSuccess) {
                      return getNewTimeline();
                    } else {
                      return;
                    }
                  }}
                  isRequestBody={getModalText(
                    ordersStore.timelineStore.actionRequestOptions?.code ?? code,
                    isRequestData.date,
                    modalTitle,
                  )}
                  handleSubmitForm={() => {
                    ordersStore.timelineStore.setActionRequestOptions({ code });
                  }}
                  modalCode={modalCode}
                  btnText={getModalBtnText(ordersStore.timelineStore.actionRequestOptions?.code ?? code, modalText)}
                />
              );
            };

            const getActionBtn = () => {
              const { code, title, modalTitle, modalText } = JSON.parse(value) || {};
              const filesArr = blocks.filter((el: any) => el.type === 'action-file');

              if (!filesArr.length)
                return (
                  <React.Fragment key={idx}>
                    <ActionBtn
                      handleModalOpen={(e) => handleModalOpen(code, e)}
                      getTextCode={getTextCode(code, title)}
                      disabled={timelineDocsData.isFetching}
                    />
                    {renderModal(code, modalTitle, modalText)}
                  </React.Fragment>
                );

              const hasFiles = filesArr.every((el: any) => {
                const { title } = JSON.parse(el.value);
                if (title.includes('.pdf')) return true;
                return false;
              });

              return hasFiles ? (
                <React.Fragment key={idx}>
                  <ActionBtn
                    handleModalOpen={(e) => handleModalOpen(code, e)}
                    getTextCode={getTextCode(code, title)}
                    disabled={timelineDocsData.isFetching}
                  />
                  {renderModal(code, modalTitle, modalText)}
                </React.Fragment>
              ) : (
                <React.Fragment key={idx}>
                  <ActionBtn
                    handleModalOpen={(e) => handleModalOpen(code, e)}
                    getTextCode={getTextCode(code, title)}
                    disabled={files.file ? timelineDocsData.isFetching : true}
                  />
                  {renderModal(code, modalTitle, modalText)}
                </React.Fragment>
              );
            };

            const getActionFile = () => {
              const { code } = JSON.parse(value) || {};
              return (
                <React.Fragment key={idx}>
                  <ActionFile
                    value={value}
                    onChange={(e: any) => {
                      setFiles({ ...files, file: e.target.files[0], code });
                    }}
                    files={files?.file?.type}
                  />
                  {timelineDocsData.isFetching && code === currentLoadingCode && (
                    <ProgressBar currentValue={''} percent={completed} tottalValue={''} />
                  )}
                  <Spacer space={10} />
                </React.Fragment>
              );
            };

            const getProgressBar = () => {
              const { current: currentValue, percent, total: tottalValue } = JSON.parse(value) || {};
              return (
                <React.Fragment key={idx}>
                  <ProgressBar currentValue={currentValue} percent={percent} tottalValue={tottalValue} />
                </React.Fragment>
              );
            };

            const getActionDate = () => {
              const { code: actionDateCode, title: actionDateTitle } = JSON.parse(value) || {};
              return (
                <React.Fragment key={idx}>
                  <DatePickerTimeline
                    dateTime={dateTime}
                    actionDateTitle={actionDateTitle}
                    setIsRequestData={setIsRequestData}
                    actionDateCode={actionDateCode}
                  />
                </React.Fragment>
              );
            };
            const getActionValue = () => {
              const { code } = JSON.parse(value) || {};
              const handleActionValueChange = (value: string) => {
                setActionValues({ ...actionValues, value, code });
              };
              return (
                <React.Fragment key={idx}>
                  <ActionValue
                    value={value}
                    actionValueRef={actionValueRef}
                    onClickSend={handleSendActionValue}
                    onChange={(value: string) => handleActionValueChange(value)}
                  />
                </React.Fragment>
              );
            };

            const getSubBlocks = () => {
              const { title: subBlocksTitle, is_confirmed } = JSON.parse(value) || {};
              return (
                <React.Fragment key={idx}>
                  <ItemDay
                    title={subBlocksTitle}
                    is_confirmed={is_confirmed}
                    blocks={subBlock}
                    getNewTimeline={getNewTimeline}
                  />
                </React.Fragment>
              );
            };

            const getTable = () => {
              const { title: titleTable, row_names, col_names, additional_data, values } = JSON.parse(value) || {};

              return (
                <React.Fragment key={idx}>
                  <Flex column>
                    <Typography size="lg">{titleTable}</Typography>
                    <Spacer space={10} />
                    <GridTable values={values} rowNames={row_names} colNames={col_names} />
                    <Spacer space={10} />
                    {additional_data && <Typography size="lg">{additional_data}</Typography>}
                  </Flex>
                  <Spacer space={38} />
                </React.Fragment>
              );
            };

            switch (type) {
              case 'title':
                return (
                  <React.Fragment key={idx}>
                    <TitleText value={value} dateTime={dateTime} />
                  </React.Fragment>
                );
              case 'docs':
                return (
                  <React.Fragment key={idx}>
                    <Docs onDownloadAllClick={ordersStore.timelineStore.getTimelineDocs} value={value} />
                    <Spacer space={10} />
                  </React.Fragment>
                );
              case 'address':
                return (
                  <React.Fragment key={idx}>
                    <TitleAdress value={value} dateTime={dateTime} />
                  </React.Fragment>
                );
              case 'heading':
                return (
                  <React.Fragment key={idx}>
                    <HeadingText value={value} dateTime={dateTime} />
                  </React.Fragment>
                );
              case 'action':
                return getActionBtn();
              case 'action-file':
                return getActionFile();
              case 'progress-bar':
                return getProgressBar();
              case 'sub-block':
                return getSubBlocks();
              case 'action-date':
                return getActionDate();
              case 'action-value':
                return getActionValue();
              case 'table':
                return getTable();
              default:
                return '';
            }
          },
        )}
      </OrderWrapperStep>
    </Flex>
  );
};
