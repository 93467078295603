import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { Flex, Spacer } from 'uikit';
import { CloseIcon } from 'uikit/Icons/CloseIcon';
import { InquiryHeader, InquirySubHeader } from 'components/Inquiries/pages/InquiryPage';
import { useState } from 'react';

interface IProps {
  showModalState: boolean;
  onDecline: (comment?: string) => void;
  handleModalClose: () => void;
}

export const InquiryDeclineModal: React.FC<IProps> = ({ onDecline, showModalState, handleModalClose }) => {
  const [comment, setComment] = useState('');

  const handleDeclineClick = () => {
    onDecline(comment);
  };

  return (
    <StyledModal
      show={showModalState}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeaderWrapper>
        <Modal.Header closeButton />
        <StyledCloseIcon>
          <CloseIcon />
        </StyledCloseIcon>
      </ModalHeaderWrapper>
      <ModalContent>
        <Flex column>
          <InquiryHeader>Вы хотите отклонить заявку?</InquiryHeader>
          <DeclineSubHeader>
            После отклонения заявку нельзя будет переоткрыть. При необходимости поставщику надо будет завести новую
            заявку
          </DeclineSubHeader>

          <Flex column gap="4">
            <DeclineLabel>Комментарий</DeclineLabel>
            <DeclineSubLabel>
              Добавьте комментарий о причинах отклонения заявки для отображения поставщику
            </DeclineSubLabel>

            <Spacer space={12} />
            <DeclineTextarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Почему отклоняете заявку?"
            ></DeclineTextarea>
          </Flex>

          <ButtonsWrapper>
            <DeclineButton type="button" onClick={handleDeclineClick}>
              Да, отклонить
            </DeclineButton>
            <DiscardChangesButton type="button" onClick={handleModalClose}>
              Отмена
            </DiscardChangesButton>
          </ButtonsWrapper>
          <Spacer space={20} />
        </Flex>
      </ModalContent>
    </StyledModal>
  );
};

export const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 534px;
  }
  .modal-header {
    border: none;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }
  .btn-close {
    background: none;
    width: 40px;
    height: 40px;
    position: absolute;
    right: -11px;
    top: -8px;
    z-index: 1;
  }
`;

export const ModalHeaderWrapper = styled.div`
  position: relative;
`;

export const StyledCloseIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  cursor: pointer;
`;

export const ModalContent = styled.div`
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 12px;
  max-width: 534px;
`;

export const DeclineButton = styled.button`
  background-color: #e16060;
  border-radius: 12px;
  border: 1px solid #e16060;
  padding: 18px 0px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
`;

export const DiscardChangesButton = styled.button`
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #825dec;
  padding: 18px 0px;
  color: #8e66fe;
  font-weight: 600;
  font-size: 18px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  margin-top: 16px;
`;

export const DeclineSubHeader = styled.p`
  font-size: 14px;
  color: #656565;
`;
export const DeclineSubLabel = styled.span`
  font-size: 14px;
  color: #656565;
  font-weight: 500;
`;
export const DeclineLabel = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 124%;
  color: #292929;
`;
export const DeclineTextarea = styled.textarea`
  width: 100%;
  background: #e9ebf1;
  border: 1px solid #dcdee4;
  border-radius: 12px;
  padding: 16px;
  min-height: 100px;
`;
