/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Flex, Typography } from 'uikit';
import { IReqLinkedOrdersSummary, LINKED_ORDERS_COUNTER_STATUS } from 'services/models';
import styled from 'styled-components';
import { LinkedOrdersItem } from './LinkedOrdersItem';
import { STATUS_ALL, STATUS_NAME_MAPPING } from './consts';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/Root';
import { values } from 'mobx';
import { Loader } from 'uikit/Loader';

interface CompProps {
  linkBy: 'offer' | 'company';
  id: string;
}

const limitFetchedRecords = 10;

export const LinkedOrders: React.FC<CompProps> = observer((props) => {
  const { linkedOrdersStore } = useStore();
  const [selectedStatus, setSelectedStatus] = useState(STATUS_ALL);

  const viewportObserveOptions: IntersectionObserverInit = {
    root: document.getElementById('orders-container'),
    rootMargin: '0px',
    threshold: 0,
  };

  const viewportObserveCallback: IntersectionObserverCallback = function (entries: any[]) {
    entries.forEach((entry) => {
      linkedOrdersStore.hasNextPage &&
        entry.isIntersecting &&
        linkedOrdersStore.setPageNumber(linkedOrdersStore.pageNumber + 1);
    });
  };

  const ViewportObserver = new IntersectionObserver(viewportObserveCallback, viewportObserveOptions);

  const fetchOrdersData = (page: number) => {
    const ordersRequestOptions: IReqLinkedOrdersSummary = {
      limit: limitFetchedRecords,
      offset: limitFetchedRecords * (page - 1),
      [props.linkBy]: props.id,
      general_status__in: selectedStatus,
    };
    if (selectedStatus === STATUS_ALL) delete ordersRequestOptions['general_status__in'];
    linkedOrdersStore.getLinkedOrders(ordersRequestOptions, props.linkBy, props.id);
  };

  const fetchCountersData = () => linkedOrdersStore.getCounter(props.linkBy, props.id);

  useEffect(() => {
    if (
      linkedOrdersStore.pageNumber === 1 ||
      props.linkBy !== linkedOrdersStore.linkedBy?.linkBy ||
      props.id !== linkedOrdersStore.linkedBy?.id
    ) {
      fetchCountersData();
      return;
    }
    fetchOrdersData(linkedOrdersStore.pageNumber);
  }, [linkedOrdersStore.pageNumber]);

  useEffect(() => {
    fetchOrdersData(linkedOrdersStore.pageNumber);
  }, [selectedStatus, props.id]);

  useEffect(() => {
    const lastOrder = document.getElementById('last-order');
    if (!!lastOrder) {
      ViewportObserver.observe(lastOrder);
    }

    return () => {
      if (lastOrder) {
        ViewportObserver.unobserve(lastOrder);
      }
    };
  }, [linkedOrdersStore.hasNextPage]);

  const handleClickStatus = (key: string): void => {
    if (selectedStatus !== key) {
      setSelectedStatus(key);
      linkedOrdersStore.setPageNumber(1);
    }
  };

  if (linkedOrdersStore.isLoading) return <Loader />;
  if (!linkedOrdersStore.linkedOrdersCounter?.ordersCount) return <></>;
  return (
    <LinkedOrdersRoot column id="root-orders-container" gap={15}>
      <Flex gap={27}>
        <Typography>Связанные сделки:</Typography>

        <TypographyWrapper onClick={() => handleClickStatus(STATUS_ALL)}>
          <Typography color={selectedStatus !== STATUS_ALL ? '#918f89' : 'initial'}>
            Все -{' '}
            {Object.entries(LINKED_ORDERS_COUNTER_STATUS)
              .map((el) => el[1])
              .reduce((prev, cur) => {
                return prev + (linkedOrdersStore.linkedOrdersCounter?.[cur] || 0);
              }, 0)}
          </Typography>
        </TypographyWrapper>

        {linkedOrdersStore.linkedOrdersCounter &&
          STATUS_NAME_MAPPING.map((item) => (
            <TypographyWrapper key={item.key} onClick={() => handleClickStatus(item.key)}>
              <Typography color={selectedStatus !== item.key ? '#918f89' : 'initial'}>
                {item.title} - {linkedOrdersStore.linkedOrdersCounter?.[item.summaryStatus]}
              </Typography>
            </TypographyWrapper>
          ))}
      </Flex>

      <OrdersContainer id="orders-container" column fillWidth gap={15}>
        {!!linkedOrdersStore.linkedOrdersList &&
          linkedOrdersStore.linkedOrdersList.map((order, idx) => {
            if (!order) return;
            const props = { id: `order-${order.id}` };
            if (idx === values(linkedOrdersStore.linkedOrders).length - 1) props['id'] = 'last-order';

            return (
              <LinkedOrdersItem
                key={`order-${order.id}`}
                order={order}
                isViewAll={selectedStatus === STATUS_ALL}
                selectedStatus={selectedStatus}
                {...props}
              />
            );
          })}
      </OrdersContainer>

      {linkedOrdersStore.linkedOrdersCounter && (
        <Flex gap={28}>
          <Typography color="#918F89">
            Вывезено всего - {linkedOrdersStore.linkedOrdersCounter?.totalActualVolume} т
          </Typography>
        </Flex>
      )}
    </LinkedOrdersRoot>
  );
});

const LinkedOrdersRoot = styled(Flex)`
  position: relative;
  max-height: 92vh;
  padding: 0px 39px 39px 39px;
`;

const TypographyWrapper = styled.div`
  cursor: pointer;
`;

const OrdersContainer = styled(Flex)`
  position: relative;
  width: 100%;
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
