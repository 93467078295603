import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { CalendarIcon, Flex, LocationIcon, Spacer } from 'uikit';
import ru from 'date-fns/locale/ru';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useStore } from 'store/Root';
import { InquiryHeader } from '../InquiryPage';
import { RoubleIcon } from 'uikit/Icons/RoubleIcon';
import { CloseIcon } from 'uikit/Icons/CloseIcon';
import { Select } from 'uikit/Selects';
import { format } from 'date-fns';
import { ISaleRequestBidRequest } from 'services/models';

registerLocale('ru', ru);

interface IProps {
  showModalState: boolean;
  isCPT: boolean;
  handleModalClose: () => void;
  startDate: string;
  endDate: string;
  inquiryId: string;
  inquiryPrice: string;
  selectedWarehouse:
    | {
        label: string;
        value: string;
      }
    | string;
}

type IDate = Date | null;

export const InquiryActionsModal: React.FC<IProps> = observer(
  ({ isCPT, showModalState, handleModalClose, inquiryId, inquiryPrice, startDate, endDate, selectedWarehouse }) => {
    const { inquiriesStore, priceMonitoringStore } = useStore();

    const [inputPrice, setInputPrice] = useState<string>(inquiryPrice);
    const [activeWarehouse, setActiveWarehouse] = useState<any>(selectedWarehouse);
    const [warehouseAddress, setWarehouseAddress] = useState<string>(selectedWarehouse as string);

    const [dateRange, setDateRange] = useState<IDate[]>([new Date(startDate), new Date(endDate)]);
    const [activeStartDate, activeEndDate] = dateRange;

    const handleInquiryCounterOfferChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputPrice(e.target.value);
    };

    // const fetchSuggestionsList = (inputValue: string) => {
    //   const result = priceMonitoringStore.fetchSuggestions(inputValue).then((res: any) => {
    //     return priceMonitoringStore.suggestionsList.map((suggestion: any) => ({
    //       value: suggestion.unrestricted_value,
    //       label: suggestion.value,
    //     }));
    //   });

    //   return result;
    // };
    // const loadAddressSuggestionsOptions = (inputValue: string) =>
    //   new Promise((resolve) => {
    //     setTimeout(() => {
    //       resolve(fetchSuggestionsList(inputValue));
    //     }, 1000);
    //   });

    // const handleAddressChange = (value: { label: string; value: string } | null) => {
    //   priceMonitoringStore.setInputAddress(value === null ? null : value.value);
    //   setWarehouseAddress(value === null ? '' : value.value);
    // };

    // const onInputChange = (value: string) => {
    //   console.log(value);
    // };

    const handleCounterOffer = () => {
      const dataToSend: ISaleRequestBidRequest = {
        sale_request: inquiryId,
        price: inputPrice,
        startDate: format(activeStartDate as Date, 'yyyy-MM-dd'),
        endDate: format(activeEndDate as Date, 'yyyy-MM-dd'),
      };
      if (isCPT) {
        dataToSend.warehouse = activeWarehouse.value;
      } else {
        dataToSend.warehouseAddress = warehouseAddress;
      }
      inquiriesStore.sendInquiryCounterOffer(dataToSend);
      setTimeout(() => {
        // priceMonitoringStore.setInputAddress(null);
        handleModalClose();
      }, 1000);
    };

    const handleWarehouseSelectChange = (warehouse: { label: string; value: string }) => {
      setActiveWarehouse(warehouse);
    };

    const warehousesOptions = inquiriesStore.warehousesList.map((warehouse) => ({
      label: warehouse.title,
      value: warehouse.id,
    }));

    return (
      <StyledModal
        show={showModalState}
        onHide={handleModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeaderWrapper>
          <Modal.Header closeButton />
          <StyledCloseIcon>
            <CloseIcon />
          </StyledCloseIcon>
        </ModalHeaderWrapper>
        <ModalContent>
          <Flex column>
            <InquiryHeader>Предложите изменения</InquiryHeader>
            <p>Вы можете предложить новую цену за тонну, новые даты поставки и / или другой склад</p>

            <SubHeader>Цена</SubHeader>
            <InputWrapper>
              <StyledInput
                type="number"
                name="price"
                value={inputPrice}
                onChange={handleInquiryCounterOfferChange}
                placeholder="Введите предлагаемую цену"
              />
              <StyledRoubleIcon>
                <RoubleIcon />
              </StyledRoubleIcon>
            </InputWrapper>
            <Flex column>
              <SubHeader>Даты поставки</SubHeader>
              <DatePickerWrapper>
                <StyledRoubleIcon>
                  <CalendarIcon />
                </StyledRoubleIcon>
                <DatePicker
                  selectsRange={true}
                  startDate={activeStartDate}
                  endDate={activeEndDate}
                  calendarClassName="shipment-calendar"
                  dateFormat="dd.MM.yyyy"
                  locale="ru"
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  // isClearable={true}
                />
              </DatePickerWrapper>
            </Flex>
            {isCPT ? (
              <StyledWarehouseSelect>
                <SubHeader>Склад</SubHeader>
                <Select
                  placeholder={(typeof selectedWarehouse !== 'string' && selectedWarehouse.label) || 'Склад'}
                  options={warehousesOptions}
                  onChange={handleWarehouseSelectChange}
                />
              </StyledWarehouseSelect>
            ) : (
              <StyledWarehouseWrapper>
                <SubHeader>Адрес погрузки</SubHeader>
                <InputWrapper>
                  <StyledRoubleIcon>
                    <LocationIcon />
                  </StyledRoubleIcon>
                  <StyledInput
                    type="text"
                    name="warehouseAddress"
                    value={warehouseAddress}
                    onChange={(e) => setWarehouseAddress(e.target.value)}
                    placeholder="Введите адрес пункта погрузки"
                  />
                </InputWrapper>
              </StyledWarehouseWrapper>
              // <StyledWarehouseSelect>
              //   <SubHeader>Адрес погрузки</SubHeader>
              //   <Select
              //     placeholder="Введите адрес пункта погрузки"
              //     cacheOptions
              //     defaultValue={{ label: warehouseAddress, value: warehouseAddress }}
              //     isClearable
              //     isSearchable
              //     defaultOptions
              //     loadOptions={loadAddressSuggestionsOptions}
              //     selectOption={handleAddressChange}
              //     onChange={handleAddressChange}
              //     onInputChange={onInputChange}
              //     isAsync
              //   />
              // </StyledWarehouseSelect>
            )}

            <ButtonsWrapper>
              <CounterOfferButton type="button" onClick={handleCounterOffer}>
                Предложить изменения
              </CounterOfferButton>
            </ButtonsWrapper>
            <Spacer space={20} />
          </Flex>
        </ModalContent>
      </StyledModal>
    );
  },
);

export const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 534px;
  }
  .modal-header {
    border: none;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }
  .btn-close {
    background: none;
    width: 40px;
    height: 40px;
    position: absolute;
    right: -11px;
    top: -8px;
    z-index: 1;
  }
`;

const StyledWarehouseWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ModalHeaderWrapper = styled.div`
  position: relative;
`;

const SubHeader = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 124%;
  padding-left: 8px;
  color: #292929;
`;
const DatePickerWrapper = styled.div`
  position: relative;
  margin-top: 12px;
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
    input {
      padding: 17px 30px;
      padding-left: 48px;
      border-radius: 12px;
      border: 1px solid #dcdee4;
      width: 100%;
    }
  }
`;

const StyledCloseIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  cursor: pointer;
`;

const StyledWarehouseSelect = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  .Select__control {
    max-width: 100%;
    border: 1px solid #dcdee4;
    max-height: 52px;
    border-radius: 12px;
  }
  .Select__value-container {
    padding: 10px 16px;
  }
`;

export const ModalContent = styled.div`
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 10px;
  max-width: 534px;
`;

const StyledInput = styled.input`
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 16px;
  padding-left: 48px;
  width: 100%;
  text-align: left;
  color: #656565;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #dcdee4;
  border-radius: 12px;
`;

export const CounterOfferButton = styled.button`
  width: 50%;
  background-color: #8e66fe;
  border-radius: 12px;
  border: 1px solid #825dec;
  padding: 18px 0px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
`;

export const DiscardChangesButton = styled.button`
  width: 50%;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #825dec;
  padding: 18px 0px;
  color: #8e66fe;
  font-weight: 600;
  font-size: 18px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-top: 16px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const StyledRoubleIcon = styled.div`
  position: absolute;
  z-index: 1;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
`;
