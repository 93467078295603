import React, { useEffect, useState } from 'react';
import { Button, Spacer, Typography } from 'uikit';
import styled from 'styled-components';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useStore } from 'store/Root';
import { Loader } from 'uikit/Loader';
import { observer } from 'mobx-react-lite';
import { Message } from './components/Message';
import { ChatInput } from './components/ChatInput';
import { CounterOffer } from './components/CounterOffer';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { routes } from 'routes/consts';

export const DialogPage: React.FC = observer(() => {
  const { chatStore } = useStore();
  const { id } = useParams();
  const navigate = useNavigate();
  const paramId = id || '';
  chatStore.setSelectRoom(paramId);

  const [isFirstBatchLoaded, setIsFirstBatchLoaded] = useState(false);
  const [newMessagesCount, setNewMessagesCount] = useState(0);

  const messagesEndRef = React.useRef<HTMLDivElement>(null);

  if (!chatStore.roomsList.length) {
    !isFirstBatchLoaded && chatStore.requestRoom(paramId);
  }

  const room = chatStore.getRoom(paramId);
  const messages = chatStore.messagesHistory(paramId);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  useEffect(() => {
    console.log('getting messages');
    !isFirstBatchLoaded && chatStore.getMessages(paramId);

    scrollToBottom();
    setNewMessagesCount(chatStore.newMessagesCount);
  }, [chatStore.newMessagesCount]);

  const loadMore = function () {
    if (chatStore.hasNextPage) {
      chatStore.getMessages(paramId, room!.earliestMessageTime);
    }
  };

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading: chatStore.isLoading,
    hasNextPage: chatStore.hasNextPage,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 0px 0px',
  });

  const scrollableRootRef = React.useRef<HTMLDivElement | null>(null);
  const lastScrollDistanceToBottomRef = React.useRef<number>();

  React.useEffect(() => {
    const scrollableRoot = scrollableRootRef.current;
    const lastScrollDistanceToBottom = lastScrollDistanceToBottomRef.current ?? 0;
    const isNewMessageAdded = chatStore.newMessagesCount > newMessagesCount;
    if (scrollableRoot && !isNewMessageAdded) {
      scrollableRoot.scrollTop = scrollableRoot.scrollHeight - lastScrollDistanceToBottom;
    }
    chatStore.getActiveStatus();
  }, [messages, rootRef]);

  const rootRefSetter = React.useCallback(
    (node: HTMLDivElement) => {
      rootRef(node);
      scrollableRootRef.current = node;
    },
    [rootRef],
  );

  const handleRootScroll = React.useCallback(() => {
    const rootNode = scrollableRootRef.current;
    if (rootNode) {
      const scrollDistanceToBottom = rootNode.scrollHeight - rootNode.scrollTop;
      lastScrollDistanceToBottomRef.current = scrollDistanceToBottom;
    }
  }, []);

  useEffect(() => {
    !isFirstBatchLoaded && scrollToBottom();
    messages?.length && setIsFirstBatchLoaded(true);
  }, [messages?.length]);

  if (!messages) {
    return <Loader />;
  }

  const isLoading = chatStore.isLoading;

  const handleClickBack = (): void => {
    navigate(`../${generatePath(routes.chats.list.path)}`);
  };
  const handleSendMessage = (message: string, time: string): void => {
    chatStore.addMessage(message, paramId, time);
  };

  return !isLoading ? (
    <Wrapper>
      <BackButton variant="base" size="lg" onClick={handleClickBack}>
        ❮ Назад
      </BackButton>
      <Typography size="lg2" bold>
        <Spacer space={32} />
        {room!.title}
        <Spacer space={28} />
      </Typography>
      <DialogWrapper>
        <DialogWindow ref={rootRefSetter} onScroll={handleRootScroll}>
          {isFirstBatchLoaded && chatStore.hasNextPage && (
            <div ref={infiniteRef}>
              <Loader />
            </div>
          )}
          {messages &&
            messages.map((message, index) => {
              return <Message key={message.time + index} {...message} />;
            })}
          <div ref={messagesEndRef} />
        </DialogWindow>

        <CounterOffer roomId={paramId} />
      </DialogWrapper>
      <Spacer space={28} />
      <ChatInput sendMessage={handleSendMessage} isDisabled={room?.archived} />
    </Wrapper>
  ) : (
    <Loader />
  );
});

const Wrapper = styled.div`
  padding: 44px;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
  height: 100%;
  width: 100%;
  max-width: calc(100vw - 335px);
`;
const DialogWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;
const DialogWindow = styled.div`
  flex: 1;
  padding: 0 30px;
  max-width: 600px;
  border-radius: 2px;
  max-height: 60vh;
  overflow-y: scroll;
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
`;

const BackButton = styled(Button)`
  position: relative;
  width: 210px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #407ef5;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  color: #407ef5;
`;
