export const dimensions = {
  input: {
    height: 40,
  },
  button: {
    height: 34,
  },
  dropdown: {
    width: 196,
  },
  navBar: {
    height: 48,
  },

  navSidebar: {
    width: 72,
  },
  formGroupMarkable: {
    paddingLeft: 46,
  },
  page: {
    header: {
      height: 64,
    },
    logo: {
      wrapper: {
        width: 32,
        height: 32,
        widthExpanded: 192,
        heightExpanded: 192,
      },
      icon: {
        width: 48,
        height: 17,
        widthExpanded: 96,
        heightExpanded: 34,
      },
    },
    content: {
      padding: 24,
      paddingLeft: 40,
    },
    bottomBar: {
      height: 64,
    },
  },
};
