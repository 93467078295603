import { castArray } from 'lodash-es';
import { flow, types, getRoot, detach } from 'mobx-state-tree';
import { daylesfordService } from '../services';
import { IRootStore } from './Root';

const { boolean, model, string, number, array, maybeNull, maybe, union, literal, identifierNumber } = types;

export const Indicator = model('Indicator', {
  id: identifierNumber,
  name: string,
  measure: union(literal('number'), literal('percent'), literal('string')),
  worstDirection: union(literal('max'), literal('min')),
  maxValue: number,
  minValue: number,
  step: string,
  editable: boolean,
  basisValue: string,
  value: maybe(string),
  limitValue: string,
  withdrawalValue: string,
  position: number,
  isCustom: maybe(boolean),
});

export const Culture = model('Culture', {
  id: number,
  name: string,
  subName: string,
  indicators: array(Indicator),
  inquiryIndicators: array(Indicator),
}).actions((self) => {
  return {
    getFormattedIndicators() {
      return self.indicators.map((indicator) => {
        if (indicator.measure === 'string') return indicator;
        if (indicator.value) {
          const value = indicator.value.toString().replace('.', ',');
          if (!value.includes(',')) {
            indicator.value = value + ',0';
          } else {
            indicator.value = value;
          }
        }
        if (indicator.basisValue) {
          const basisValue = indicator.basisValue.toString().replace('.', ',');
          if (!basisValue.includes(',')) {
            indicator.basisValue = basisValue + ',0';
          } else {
            indicator.basisValue = basisValue;
          }
        }
        if (indicator.limitValue) {
          const limitValue = indicator.limitValue.toString().replace('.', ',');
          if (!limitValue.includes(',')) {
            indicator.limitValue = limitValue + ',0';
          } else {
            indicator.limitValue = limitValue;
          }
        }
        if (indicator.withdrawalValue) {
          const withdrawalValue = indicator.withdrawalValue.toString().replace('.', ',');
          if (!withdrawalValue.includes(',')) {
            indicator.withdrawalValue = withdrawalValue + ',0';
          } else {
            indicator.withdrawalValue = withdrawalValue;
          }
        }
        return indicator;
      });
    },
  };
});

export const CulturesStore = model('CulturesStore', {
  isLoading: false,
  cultures: array(Culture),
  selectedCulture: maybeNull(number),
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .views((self) => ({
    get hasCultures(): boolean {
      return !!self.cultures.length;
    },
  }))
  .views((self) => ({
    get culturesList() {
      return self.cultures.map((culture) => {
        return {
          value: culture.id.toString(),
          label: `${culture.name} ${culture.subName.length > 1 ? ' ' + culture.subName + '%' : ''}`,
        };
      });
    },
  }))
  .views((self) => ({
    getCultureName(cultureId: number): string {
      const culture = self.cultures.find((c) => c.id === cultureId);
      const title = culture && culture.name + (culture.subName.length > 1 ? ' ' + culture.subName + '%' : '');
      return title ?? '';
    },
  }))
  .views((self) => ({
    get selectedCultureIndicators() {
      return self.cultures.find((c) => c.id === self.selectedCulture)?.inquiryIndicators;
    },
  }))

  .actions((self) => {
    return {
      setSelectedCulture(id: number, indicators: any[]) {
        self.selectedCulture = id;

        if (indicators) {
          const culture = self.cultures.find((c) => c.id === id);
          if (culture) {
            culture.inquiryIndicators.forEach((indicator) => {
              detach(indicator);
            });
          }
          const inquiryIndicators = culture?.indicators.map((indicator) => {
            const updatedIndicator = indicators.find((i) => i.id === indicator.id);
            return {
              ...indicator,
              value: updatedIndicator?.value,
            };
          });

          if (inquiryIndicators) culture?.inquiryIndicators.replace(inquiryIndicators);
        }
      },
    };
  })

  .actions((self) => {
    return {
      process(data: any) {
        const dataList = castArray(data);

        dataList.forEach((cultureData) => {
          cultureData.indicators = cultureData.indicators.map((indicator: any) => {
            return { ...indicator, id: indicator.id };
          });
          const existing = self.cultures.find((c) => c.id === cultureData.id);

          if (existing) {
            existing.name = cultureData.name;
            existing.subName = cultureData.subName;
            existing.indicators.replace(cultureData.indicators);
          } else {
            self.cultures.push(cultureData);
          }
        });
      },
    };
  })

  .actions((self) => {
    return {
      getCultures: flow(function* getCultures(): any {
        self.isLoading = true;
        const cultures = yield daylesfordService
          .getCultures()
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: список культур`, 'error'));
        self.isLoading = false;
        return self.process(cultures.data);
      }),
    };
  });
