import { IProductSpecs } from 'services/models';
import { BLANK_CHAR, EMPTY_CHAR } from 'utils/consts';

export const getSpecifications = (specifications?: IProductSpecs[], offerSpecs?: any[], isBasis?: boolean) => {
  return specifications?.map((spec) => {
    const { unitOfMeasurement, id: specId, GOST, description, name: nameOfSpecification, spec: specification } = spec;

    const offerSpec = offerSpecs?.find((offerSpec) => specId === offerSpec?.specification?.id);
    const { value, basisValue } = offerSpec || {};

    let offerMax, offerMin;
    if (isBasis) {
      offerMax = basisValue && JSON.parse(basisValue).max;
      offerMin = basisValue && JSON.parse(basisValue).min;
    } else {
      offerMax = value && JSON.parse(value).max;
      offerMin = value && JSON.parse(value).min;
    }

    const { max, min, isEditableMax, isEditableMin } = (specification && JSON.parse(specification)) || {};

    return {
      max: offerMax || max || BLANK_CHAR,
      min: offerMin || min || BLANK_CHAR,
      defaultMax: max,
      defaultMin: min,
      GOST,
      description,
      isEditableMax,
      isEditableMin,
      nameOfSpecification: unitOfMeasurement?.unit
        ? `${nameOfSpecification}, ${unitOfMeasurement?.unit}`
        : nameOfSpecification || EMPTY_CHAR,
      id: specId || EMPTY_CHAR,
    };
  });
};
