import React, { useState } from 'react';
import { IFarmer } from 'services/models';
import styled from 'styled-components';
import { Button, Flex, Typography } from 'uikit';
import { getTaxTypeForm } from 'components/Orders/pages/helpers';
import { EDMStatus } from './helpers';
import { accreditationOptions, ID_DISPLAY_SYMBOLS } from 'utils/consts';
import { Select } from 'uikit/Selects';

export const FarmerListItem: React.FC<{
  farmer: IFarmer;
  isEditable?: boolean;
  onClick?: () => void;
  setFarmerStatus?: (id: string, accreditationStatus: string) => void;
}> = ({ farmer, isEditable, onClick = () => {}, setFarmerStatus = () => {} }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectValue, setSelectValue] = useState(farmer.accreditationStatus);
  const displayID = farmer.id.slice(-ID_DISPLAY_SYMBOLS);

  interface ICategoryDisplayObject {
    value: string;
    label: string;
  }
  const handleCategoryChange = (category: ICategoryDisplayObject) => {
    setSelectValue(category.value);
  };

  const listOptions = accreditationOptions.map(({ label, id: value }) => ({ label, value }));
  const accStatus = accreditationOptions.find((option) => option.id === farmer.accreditationStatus)?.label;
  return (
    <FarmerItemRootWrapper $isClickable={!isEditable} column gap={24} onClick={onClick}>
      <Typography size="lg2">{farmer.nameOfProvider}</Typography>

      <FarmerIdContainer color="#918F89" size="xs">
        id {displayID}
      </FarmerIdContainer>

      <Flex column gap={15}>
        <FarmerInfoRow>
          <Typography color="#918F89">Сделок / тонн</Typography>
          <Typography>
            {farmer.ordersCount} / {farmer.currentVolume}
          </Typography>
        </FarmerInfoRow>

        <Line />

        <FarmerInfoRow>
          <Typography color="#918F89">Система налогообложения</Typography>
          <Typography>{getTaxTypeForm(farmer.taxType)}</Typography>
        </FarmerInfoRow>
        <FarmerInfoRow>
          <Typography color="#918F89">Юр. адрес</Typography>
          <Typography>{farmer.ulAddress}</Typography>
        </FarmerInfoRow>
        <FarmerInfoRow>
          <Typography color="#918F89">Бухгалтерия</Typography>
          <Typography>
            {farmer.documentFlow?.fio}; {farmer.documentFlow?.phoneNumber}
          </Typography>
        </FarmerInfoRow>
        <FarmerInfoRow>
          <Typography color="#918F89">ИНН</Typography>
          <Typography>{farmer.inn}</Typography>
        </FarmerInfoRow>
        <FarmerInfoRow>
          <Typography color="#918F89">Статус ЭДО</Typography>
          <Typography>{EDMStatus(farmer.edmStatus)}</Typography>
        </FarmerInfoRow>
        {isEditable && (
          <FarmerInfoRow>
            <Flex gap={10} vAlignContent="center">
              <Typography color="#918F89">Статус Аккредитации</Typography>
            </Flex>
            <Flex gap={10} vAlignContent="center">
              <Typography>
                {!isEditing ? (
                  accStatus
                ) : (
                  <Select
                    options={listOptions}
                    onChange={handleCategoryChange}
                    // disabled={fetchChartsCategories.isFetching}
                  />
                )}
              </Typography>
              {selectValue !== farmer.accreditationStatus && (
                <Typography>
                  <FarmerActionButton
                    variant="action"
                    type="button"
                    onClick={() => setFarmerStatus(farmer.id, selectValue as string)}
                  >
                    Сохранить
                  </FarmerActionButton>
                </Typography>
              )}
              {!isEditing && (
                <FarmerActionButton variant="actionGreen" type="button" onClick={() => setIsEditing(true)}>
                  Изменить
                </FarmerActionButton>
              )}
            </Flex>
          </FarmerInfoRow>
        )}
      </Flex>
    </FarmerItemRootWrapper>
  );
};

const FarmerItemRootWrapper = styled(Flex)`
  position: relative;
  padding: 30px 30px 18px 30px;
  background: #f9f6ed;
  border-radius: 10px;
  cursor: ${({ $isClickable }: { $isClickable: boolean }) => ($isClickable ? 'pointer' : 'default')};
`;

const FarmerInfoRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
`;

const FarmerIdContainer = styled(Typography)`
  position: absolute;
  top: 17px;
  right: 20px;
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px solid #e7e2d1;
`;
const FarmerActionButton = styled(Button)`
  padding: 10px 20px;
  height: auto;
  width: auto;
`;
