const ORDERS = 'orders';
const OFFERS = 'offers';
const FARMERS = 'farmers';

/**
 * Содержит конфигурацию роутов приложения.
 */
export const routes = {
  root: {
    key: 'root',
    path: '/',
  },
  orders: {
    key: ORDERS,
    path: `/${ORDERS}`,
    list: {
      key: 'list',
      path: `list`,
    },
    timeline: {
      key: 'timeline',
      path: `timeline/:id`,
    },
  },
  specsOrders: {
    key: 'specs-orders',
    path: '/specs-orders',
    list: {
      key: 'list',
      path: `list`,
    },
    timeline: {
      key: 'timeline',
      path: `timeline/:id`,
    },
    dmTimeline: {
      key: 'dmTimeline',
      path: `dmTimeline/:id`,
    },
  },
  offers: {
    key: OFFERS,
    path: `/${OFFERS}`,
    list: {
      key: 'list',
      path: `list`,
    },
    create: {
      key: 'create',
      path: `create`,
    },
    edit: {
      key: 'edit',
      path: `edit/:id`,
    },
  },
  chats: {
    key: 'messages',
    path: '/messages',
    list: {
      key: 'list',
      path: `list`,
    },
    dialog: {
      key: 'dialog',
      path: `dialog/:id`,
    },
  },
  inquiries: {
    key: 'inquiries',
    path: '/inquiries',
    list: {
      key: 'list',
      path: `list`,
    },
    inquiry: {
      key: 'inquiry',
      path: `inquiry/:id`,
    },
  },
  priceMonitoring: {
    key: 'price-monitoring',
    path: '/price-monitoring',
  },
  analytics: {
    key: 'analytics',
    path: '/analytics',
  },
  farmers: {
    key: FARMERS,
    path: `/${FARMERS}`,
    list: {
      key: 'list',
      path: `list`,
    },
    view: {
      key: 'view',
      path: `view/:id`,
    },
  },
  providers: {
    key: 'providers',
    path: '/providers',
  },
  settings: {
    key: 'settings',
    path: '/settings',
  },
  dash: {
    key: 'contact-dashboard',
    path: '/contact-dashboard',
  },
  phone: {
    key: 'phone',
    path: '/phone',
  },
};
