export const DownloadIcon = () => {
  return (
    <svg width="13" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.316 16.564h7.778c1.516 0 2.27-.77 2.27-2.293v-6.65c0-.945-.11-1.355-.695-1.956L7.633 1.556C7.077.985 6.615.86 5.795.86h-3.48c-1.508 0-2.27.777-2.27 2.3v11.111c0 1.53.755 2.293 2.27 2.293Zm.051-1.18c-.754 0-1.142-.403-1.142-1.135V3.182c0-.725.388-1.142 1.15-1.142h3.259v4.255c0 .923.461 1.377 1.377 1.377h4.174v6.577c0 .732-.388 1.135-1.15 1.135H2.368Zm4.775-8.818c-.292 0-.402-.117-.402-.41v-3.89l4.218 4.3H7.142Zm-.38 2.49c0-.3-.257-.542-.557-.542-.3 0-.557.242-.557.542v2.659l.044 1.194-.622-.645-.66-.674a.555.555 0 0 0-.395-.168.49.49 0 0 0-.513.505c0 .169.066.293.183.396l2.11 1.94c.154.14.27.191.41.191.14 0 .256-.051.41-.19l2.11-1.941a.498.498 0 0 0 .183-.396.495.495 0 0 0-.513-.505.543.543 0 0 0-.396.168l-.659.674-.622.645.044-1.194V9.056Z"
        fill="#667EFE"
      />
    </svg>
  );
};
