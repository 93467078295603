import { Divider } from 'components/Inquiries/pages/InquiryPage';
import { useProviders } from 'hooks/useProviders';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'store/Root';
import styled from 'styled-components';
import { Flex, Spacer, Typography } from 'uikit';
import { Loader } from 'uikit/Loader';

export const ProvidersPage: React.FC = observer(() => {
  const { providersStore } = useStore();

  useProviders(providersStore.hasProviders, providersStore.getOwnerProviders);

  return (
    <Wrapper>
      <Flex column>
        <Typography size="lg2" bold>
          Мои поставщики
        </Typography>
      </Flex>
      <Spacer space={24} />
      <CardsListWrapper>
        {providersStore.isLoading ? (
          <Loader />
        ) : (
          providersStore.ownerProvidersList.map((provider) => (
            <ProviderCard key={provider.id}>
              <ProviderHeader>{provider.nameOfProvider}</ProviderHeader>
              <Divider />
              <ProviderTableWrapper>
                <ProviderTableRow>
                  <ProviderTableName>ИНН</ProviderTableName>
                  <ProviderTableValue>{provider.inn}</ProviderTableValue>
                </ProviderTableRow>
                <ProviderTableRow>
                  <ProviderTableName>Юридический адрес компании</ProviderTableName>
                  <ProviderTableValue>{provider.ulAddress}</ProviderTableValue>
                </ProviderTableRow>
              </ProviderTableWrapper>
            </ProviderCard>
          ))
        )}
      </CardsListWrapper>
      <Spacer space={24} />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 44px;
`;

const ProviderCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 24px;
  gap: 16px;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 16px;
`;

const CardsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 643px;
`;

const ProviderHeader = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: #191919;
`;
const ProviderTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ProviderTableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8px;
  padding: 8px;
  border-radius: 6px;
  &:nth-child(even) {
    background-color: #f3f7f9;
  }
`;

const ProviderTableName = styled.span`
  color: #292929;
  font-weight: 600;
  font-size: 15px;
`;

const ProviderTableValue = styled.span`
  color: #656565;
  font-size: 15px;
`;
