import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'store/Root';
import styled from 'styled-components';
import { Toast } from '../uikit/Toast';

interface Props {
  children: React.ReactNode;
}
export const NotificationsProvider: React.FC<Props> = observer(({ children }) => {
  const { alertsStore } = useStore();
  return (
    <>
      <ToastsWrapper>
        <Toasts>
          {alertsStore.notifications?.map(({ text, type }, idx) => {
            return <Toast key={`${idx}-${text}`} text={text} type={type} />;
          })}
        </Toasts>
      </ToastsWrapper>
      {children}
    </>
  );
});

const Toasts = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  margin: 20px;
`;

const ToastsWrapper = styled.div`
  position: relative;
  z-index: 9999;
`;
