import { flow, types, getRoot } from 'mobx-state-tree';
import { daylesfordService } from '../services';
import { IRootStore } from './Root';

const { model, string, maybeNull, maybe, array } = types;

interface IReqAction {
  code: string;
}

const errMsg = (e: any) => (e.response.data.detail ? e.response.data.detail : 'Ошибка сервера');

const Timeline = model('Timeline', {
  stage: string,
  blocks: maybeNull(
    array(
      model('blocks', {
        type: string,
        subtype: maybe(string),
        dateTime: maybe(string),
        blocks: maybeNull(
          array(
            model('blocks', {
              type: string,
              subtype: maybe(string),
              dateTime: maybe(string),
              value: string,
            }),
          ),
        ),
        value: maybeNull(string),
      }),
    ),
  ),
});

export const TimelineStore = model('TimelineStore', {
  isLoading: false,
  isDocsLoading: false,
  actionRequestOptions: maybe(
    model('actionRequest', {
      code: string,
    }),
  ),
  timelines: maybe(array(Timeline)),
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .actions((self) => {
    return {
      getTimeline: flow(function* getTimeline(id: string): any {
        self.isLoading = true;
        const timeline = yield daylesfordService.getTimeLine(id).catch((e) => {
          if (e.response) {
            self.root.alertsStore.addNotification(errMsg(e), 'error');
          } else {
            self.root.alertsStore.addNotification(`${e.message}: Подробности сделки`, 'error');
          }
        });
        self.isLoading = false;

        return (self.timelines = timeline.data);
      }),
    };
  })
  .actions((self) => {
    return {
      getTimelineDocs: flow(function* getTimelineDocs(docCodes: string[]): any {
        self.isLoading = true;
        self.isDocsLoading = true;
        const archive = yield daylesfordService.getTimeLineDocs(docCodes).catch((e) => {
          if (e.response) {
            self.root.alertsStore.addNotification(errMsg(e), 'error');
          } else {
            self.root.alertsStore.addNotification(`${e.message}: Загрузка архива`, 'error');
          }
        });
        self.isLoading = false;
        self.isDocsLoading = false;

        const fileName = archive.headers['content-disposition'].split('filename=')[1].split(';')[0];
        const blob = new Blob([archive.data]);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();

        return;
      }),
    };
  })
  .actions((self) => {
    return {
      uploadTimeLineActionDocs: flow(function* uploadTimeLineActionDocs(id: string, file: File, request: any): any {
        self.isLoading = true;
        const timeline = yield daylesfordService.uploadTimeLineActionDocs(id, file, request).catch((e) => {
          if (e.response) {
            self.root.alertsStore.addNotification(errMsg(e), 'error');
          } else {
            self.root.alertsStore.addNotification(`${e.message}: Подробности сделки`, 'error');
          }
        });
        self.isLoading = false;

        return (self.timelines = timeline.data);
      }),
    };
  })
  .actions((self) => {
    return {
      setActionRequestOptions(request: IReqAction) {
        self.actionRequestOptions = request;
      },
    };
  })
  .actions((self) => {
    return {
      createTimeLineAction: flow(function* createTimeLineAction(id: string): any {
        self.isLoading = true;
        const timeline = yield daylesfordService.createTimeLineAction(id, self.actionRequestOptions).catch((e) => {
          if (e.response) {
            self.root.alertsStore.addNotification(errMsg(e), 'error');
          } else {
            self.root.alertsStore.addNotification(`${e.message}: Подробности сделки`, 'error');
          }
        });
        self.isLoading = false;

        return (self.timelines = timeline.data);
      }),
    };
  })
  .actions((self) => {
    return {
      uploadTimeLineDate: flow(function* uploadTimeLineDate(id: string, request: any): any {
        self.isLoading = true;
        const timeline = yield daylesfordService.uploadTimeLineDate(id, request).catch((e) => {
          if (e.response) {
            self.root.alertsStore.addNotification(errMsg(e), 'error');
          } else {
            self.root.alertsStore.addNotification(`${e.message}: Подробности сделки`, 'error');
          }
        });
        self.isLoading = false;

        return (self.timelines = timeline.data);
      }),
    };
  })
  .actions((self) => {
    return {
      uploadActionValue: flow(function* uploadActionValue(id: string, request: any): any {
        self.isLoading = true;
        const timeline = yield daylesfordService.uploadActionValue(id, request).catch((e) => {
          if (e.response) {
            self.root.alertsStore.addNotification(errMsg(e), 'error');
          } else {
            self.root.alertsStore.addNotification(`${e.message}: Подробности сделки`, 'error');
          }
        });
        self.isLoading = false;

        return (self.timelines = timeline.data);
      }),
    };
  });
