import React from 'react';
import styled from 'styled-components';
import { Flex } from '..';
import { EMPTY_CHAR } from 'utils/consts';
import { Typography } from '../Text';

interface IProps {
  title?: string;
  transparent?: boolean;
  noPadding?: boolean;
  statusText?: JSX.Element;
  onClick?: () => void;
  children: React.ReactNode;
}

export const Card: React.FC<IProps> = ({ title, statusText, children, transparent, noPadding, onClick, ...rest }) => {
  return (
    <Wrapper noPadding={noPadding} isTransparent={transparent} onClick={onClick} {...rest}>
      {!!title && (
        <StyledFlex>
          <Flex column>
            <Typography size="lg" bold>
              {title}
            </Typography>
          </Flex>
          {statusText || EMPTY_CHAR}
        </StyledFlex>
      )}
      {children}
    </Wrapper>
  );
};

interface IWrapperProps {
  isTransparent?: boolean;
  noPadding?: boolean;
}
const Wrapper = styled.div<IWrapperProps>`
  width: 832px;
  background-color: ${(p) => (p.isTransparent ? 'transparent' : '#f9f6ed')};
  border-radius: 10px;
  cursor: pointer;
  padding: ${(p) => (p.noPadding ? '0' : '30px 40px')};
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.02);
`;

const StyledFlex = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;
