import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from 'routes/consts';
import { Button, Flex, Spacer, Typography } from 'uikit';

import { OffersCounter } from './OffersCounter';
import { OffersDataList } from './OffersDataList';

export const OffersListPage: React.FC = () => {
  const navigate = useNavigate();

  const handleCreateOffer = () => navigate(`../${generatePath(routes.offers.create.path)}`);

  return (
    <Wrapper>
      <Flex column>
        <Typography size="lg2" bold>
          Мои предложения
        </Typography>
        <Spacer space={28} />
        <Button variant="base" size="lg" onClick={handleCreateOffer}>
          Создать предложение
        </Button>
        <Spacer space={28} />
        <OffersCounter />
        <Spacer space={20} />
        <OffersDataList />
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 44px;
`;
