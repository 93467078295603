/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from 'routes/consts';
import { Spacer, Typography } from 'uikit';
import { Card } from 'uikit/Card/Card';
import { Table } from 'uikit/Table/Table';
import { BLANK_CHAR, OFFER_ACTIVE_STATUSES_LIST, OFFER_FINISHED_STATUSES_LIST, ID_DISPLAY_SYMBOLS } from 'utils/consts';
import { formatDate, formatMoney, getTrimText, numberWithSeparators } from 'utils/utils';

import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/Root';
import { IReqOffers } from 'services/models';
import { Loader } from 'uikit/Loader';
import { useQuery } from 'react-query';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const limitFetchedRecords = 10;

export const OffersDataList: React.FC = observer(() => {
  const navigate = useNavigate();
  const { offersStore } = useStore();
  const handleOfferClick = (id: string) => navigate(`../${generatePath(routes.offers.edit.path, { id })}`);

  const activeStatuses = OFFER_ACTIVE_STATUSES_LIST.join(',');
  const closedStatuses = OFFER_FINISHED_STATUSES_LIST.join(',');

  const ordersRequestOptions: IReqOffers = {
    limit: limitFetchedRecords,
    offset: limitFetchedRecords * (offersStore.pageNumber - 1),
    status__in: offersStore.selectedStatus === 'Активные' ? activeStatuses : closedStatuses,
  };

  const fetchOffersData = useQuery(
    ['OFFER_LIST_DATA_QUERY_KEY', offersStore.selectedStatus],
    () => offersStore.getOffers(ordersRequestOptions),
    {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      staleTime: 60 * 1000,
    },
  );

  const loadMore = function () {
    if (offersStore.hasNextPage) {
      offersStore.setPageNumber(offersStore.pageNumber + 1);
      fetchOffersData.refetch();
    }
  };

  // TODO: handle error loading
  const [sentryRef] = useInfiniteScroll({
    loading: offersStore.isLoading,
    hasNextPage: offersStore.hasNextPage,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  const currentSelectedStatuses =
    offersStore.selectedStatus === 'Активные' ? OFFER_ACTIVE_STATUSES_LIST : OFFER_FINISHED_STATUSES_LIST;
  return (
    <div>
      {(offersStore?.offersList || []).map((item) => {
        const { title } = item?.product || {};
        if (!currentSelectedStatuses.includes(item.status, 0)) return null;

        const periodOfShippment = getTrimText(
          `${formatDate(item?.dateStartShipment)} ${item?.dateFinishShipment && BLANK_CHAR} ${formatDate(
            item?.dateFinishShipment,
          )} (${item?.periodOfExport} д.)`,
        );

        const volumeInfo = `${numberWithSeparators(item?.currentVolume)} / ${numberWithSeparators(
          item?.totalVolume,
        )} (сделок: ${item?.ordersQty})`;

        const dataList = [
          {
            title: 'Объем, т',
            content: [volumeInfo],
          },
          {
            title: 'Цена покупателя CNCPT, руб',
            content: [
              <PriceBuyer key={Math.floor(Math.random() * 500)}>
                <Typography color="#191919">{formatMoney(item?.priceBuyer)}</Typography>
                <TaxPresence>без НДС</TaxPresence>
              </PriceBuyer>,
            ],
          },
          {
            title: 'Период поставки',
            content: [periodOfShippment],
          },
          {
            title: 'Порт',
            content: [item?.warehouse?.title],
          },
        ];
        const displayID = item.id.slice(-ID_DISPLAY_SYMBOLS);
        return (
          <React.Fragment key={item.id}>
            <Card
              title={getTrimText(title)}
              statusText={
                <RightTitleContainer>
                  <IdWithDate>
                    <strong>{`#${displayID}`}</strong>
                    <span>
                      {format(new Date(item?.createdAt), 'dd MMMM yyyy', {
                        locale: ru,
                      })}
                    </span>
                  </IdWithDate>
                </RightTitleContainer>
              }
              onClick={() => handleOfferClick(item?.id)}
            >
              <Spacer space={30} />
              <Table data={dataList} />
            </Card>
            <Spacer space={10} />
          </React.Fragment>
        );
      })}
      {(offersStore.isLoading || offersStore.hasNextPage) && (
        <div ref={sentryRef}>
          <Loader />
        </div>
      )}
    </div>
  );
});

const PriceBuyer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: fles-start;
  align-items: center;
  gap: 18px;
`;

const TaxPresence = styled.div`
  font-weight: 900;
  font-size: 16px;
  color: black;
`;

const IdWithDate = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0 8px;

  strong {
    font-size: 14px;
    line-height: 17px;

    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.41px;

    color: #918f89;
  }

  span {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.59px;
    text-transform: uppercase;

    color: #807e7b;
  }
`;

const RightTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-gap: 5px 0;
`;
