import React from 'react';
import {
  ItemDownload,
  ItemDownloadSub,
  ItemFilename,
  ItemFormats,
  ItemFormatSign,
  ItemInfo,
  ItemSignStatus,
  StyledButton,
} from 'components/Orders/components/TimeLine/styles';
import { StepItems, Item, ItemText, ItemFormat, ItemBox } from 'components/Orders/components/TimeLine/styles';
import { useStore } from 'store/Root';
import { observer } from 'mobx-react-lite';
import { Loader } from 'uikit/Loader';
import styled from 'styled-components';
import { downloadFile } from 'services/fileDownload';

interface IDocs {
  value: any;
  onDownloadAllClick: (docCodes: string[]) => Promise<unknown>;
}

const signedByTitle = (value: string) => {
  switch (value) {
    case 'nobody':
      return 'Никем не подписано';
    case 'ready_to_sign_provider':
      return 'Готов к подписанию со стороны продавца';
    case 'ready_to_sign_customer':
      return 'Готов к подписанию со стороны покупателя';
    case 'waiting_provider':
      return 'Ожидает подписи продавца';
    case 'waiting_customer':
      return 'Ожидает подписи покупателя';
    case 'provider':
      return 'Подписан продавцом';
    case 'customer':
      return 'Подписан покупателем';
    case 'both':
      return 'Подписано двумя сторонами';
    case 'error_provider':
      return 'Ошибка подписи продавцом';
    case 'error_customer':
      return 'Ошибка подписи покупателем';
    case 'error':
      return 'Другая ошибка';

    default:
      return 'Неизвестно';
  }
};

export const Docs: React.FC<IDocs> = observer(({ value, onDownloadAllClick }) => {
  const { ordersStore } = useStore();
  const docsIdArray: string[] = [];
  const handleDownloadAll = () => onDownloadAllClick(docsIdArray);

  const docs = JSON.parse(value);

  return (
    <StepItems>
      {docs.map(
        (
          {
            title,
            filename,
            format,
            id,
            sign_status,
            sign_file,
            link,
          }: {
            id: string;
            title: string;
            format: string;
            sign_status: string;
            sign_file: string;
            link: string;
            filename: string;
          },
          index: number,
        ) => {
          docsIdArray.push(id);
          const href = `${process.env.REACT_APP_BACKEND_HOST}/api${link}`;
          const signHref = `${process.env.REACT_APP_BACKEND_HOST}/api${sign_file}`;

          const extReg = /(?:\.([^.]+))?$/;

          // @ts-ignore
          const filenameExt = extReg.exec(filename)[1];

          return (
            <Item key={index}>
              <ItemBox>
                <ItemInfo title={title}>
                  <ItemText>{title}</ItemText>
                  <ItemFilename>{filename}</ItemFilename>
                  <ItemSignStatus>{signedByTitle(sign_status)}</ItemSignStatus>
                </ItemInfo>
                <ItemFormats>
                  <ItemFormat>.{format}</ItemFormat>
                  {!!sign_file && (
                    <ItemFormatSign>
                      <svg width="8" height="15" fill="none">
                        <path
                          d="M4.205.962A3.49 3.49 0 0 0 .698 4.464c0 1.44.848 2.723 2.191 3.255v5.215c0 .156.048.29.167.403l.929.908a.35.35 0 0 0 .483-.006l1.692-1.692a.362.362 0 0 0 0-.52l-.977-.967 1.37-1.375c.139-.14.139-.36-.012-.51L5.21 7.831c1.612-.683 2.503-1.913 2.503-3.368A3.497 3.497 0 0 0 4.205.962Zm0 3.266a.948.948 0 0 1-.945-.951.94.94 0 0 1 .945-.945.948.948 0 0 1 0 1.896Z"
                          fill="#585858"
                        />
                      </svg>
                    </ItemFormatSign>
                  )}
                </ItemFormats>
              </ItemBox>
              <ItemDownload>
                <span>Скачать:</span>
                <ItemDownloadSub>
                  <StyledButton as="span" onClick={() => downloadFile(href, link)}>
                    Документ (.{filenameExt})
                  </StyledButton>
                  {!!sign_file && (
                    <StyledButton as="span" onClick={() => downloadFile(signHref, sign_file)}>
                      Файл подписи (.sig)
                    </StyledButton>
                  )}
                </ItemDownloadSub>
              </ItemDownload>
            </Item>
          );
        },
      )}
      {docs.length > 1 && (
        <StyledDocsLoadingWrapper style={{ marginTop: 10 }}>
          <StyledButton variant="base" onClick={handleDownloadAll}>
            Скачать все документы
          </StyledButton>
          {ordersStore.timelineStore.isDocsLoading && <Loader />}
        </StyledDocsLoadingWrapper>
      )}
    </StepItems>
  );
});

const StyledDocsLoadingWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  button {
    max-width: 133px;
  }
`;
