import React from 'react';
import { HeadingSteps, TypographyDate } from 'components/Orders/components/TimeLine/styles';
import { Flex } from '../index';
import { ITitleText } from 'services/models';
import { dateFormatTimeline } from 'components/Orders/pages/helpers';

export const TitleText: React.FC<ITitleText> = ({ value, dateTime }) => {
  return (
    <React.Fragment>
      <Flex hAlignContent="center">
        <HeadingSteps size="lg">{JSON.parse(value)}</HeadingSteps>
        <TypographyDate size="sm" color="#918F88">
          {dateFormatTimeline(dateTime)}
        </TypographyDate>
      </Flex>
    </React.Fragment>
  );
};
