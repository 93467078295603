import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

interface INavSidebarItemProps {
  label: string;
  path: string;
  hasIndicator?: boolean;
}

/**
 * Компонент элемента главного меню.
 */
export const NavSidebarItem: React.FC<INavSidebarItemProps> = ({ label, path, hasIndicator = false, ...rest }) => {
  return (
    <StyledListItem>
      <StyledNavLink end to={path} title={label} className={(navData) => (navData.isActive ? 'active' : '')}>
        <StyledNavLabel hasIndicator={hasIndicator}>{label}</StyledNavLabel>
      </StyledNavLink>
    </StyledListItem>
  );
};

interface ISidebarItemProps {
  hasIndicator?: boolean;
}

const StyledNavLabel = styled.span<ISidebarItemProps>`
  position: relative;
  &:after {
    content: '';
    display: ${({ hasIndicator }) => (hasIndicator ? 'block' : 'none')};
    position: absolute;
    top: 0;
    right: -10px;
    width: 8px;
    height: 8px;
    background: #d94c4c;
    border-radius: 50%;
  }
`;
const StyledListItem = styled.li`
  padding-bottom: 20px;
`;

const StyledNavLink = styled(NavLink)<NavLinkProps>`
  color: #656565;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  transition: 0.3s;
  &.active {
    color: #8e66fe;
  }
`;
