import { flow, getRoot, types } from 'mobx-state-tree';
import { IRootStore } from './Root';
import { daylesfordService } from 'services';
import { IReqProvidersRequest } from 'services/models';
import { castArray, mergeWith } from 'lodash-es';
import { getFilterStatus } from 'utils/utils';

const { identifier, boolean, model, map, string, maybeNull } = types;

const Provider = model('Provider', {
  id: identifier,
  nameOfProvider: string,
  inn: string,
  ulAddress: string,
});

const ProviderForOwner = model('ProviderForOwner', {
  id: identifier,
  nameOfProvider: string,
  inn: string,
  ulAddress: string,
  taxType: string,
});

export const ProvidersStore = model('ProvidersStore', {
  isLoading: boolean,
  providerInnSearchQuery: maybeNull(string),
  providers: map(Provider),
  ownerProviders: map(ProviderForOwner),
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .views((self) => ({
    get hasProviders(): boolean {
      return !!self.providers.entries.length;
    },
  }))
  .views((self) => ({
    get providersList() {
      return Array.from(self.providers.values()).flat();
    },
  }))
  .views((self) => ({
    get ownerProvidersList() {
      return Array.from(self.ownerProviders.values()).flat();
    },
  }))
  .views((self) => ({
    get providersDetailedList() {
      const providersForSelect = Array.from(self.providers.values())
        .flat()
        .map((provider) => {
          return {
            value: provider.id,
            label: provider.nameOfProvider,
            inn: provider.inn,
            address: provider.ulAddress,
          };
        });
      return providersForSelect.filter((provider) => provider.inn.includes(self.providerInnSearchQuery ?? ''));
    },
  }))
  .actions((self) => ({
    setProvidersInnSearch(inn: string) {
      self.providerInnSearchQuery = inn;
    },
  }))

  .actions((self) => ({
    process(data: any, isOwner = false): any {
      const dataList = castArray(data);

      const mapped = dataList.map((item) => {
        const existing = isOwner ? self.ownerProviders?.get(item.id) : self.providers?.get(item.id);
        const dataMap = isOwner ? self.ownerProviders : self.providers;

        return existing
          ? mergeWith(existing, item, (_, next: any) => {
              if (Array.isArray(next)) return next;
              return;
            })
          : dataMap?.put(item);
      });
      return Array.isArray(data) ? mapped : mapped[0];
    },
  }))
  .actions((self) => {
    return {
      getOwnerProviders: flow(function* getOwnerProviders(): any {
        self.isLoading = true;
        const providers = yield daylesfordService
          .getOwnerProviders()
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Список поставщиков`, 'error'));

        self.process(providers.data, true);
        self.isLoading = false;
        return providers;
      }),
    };
  })
  .actions((self) => {
    return {
      getProviders: flow(function* getProviders(): any {
        self.providers.clear();
        const reqOptions: IReqProvidersRequest = {
          status: getFilterStatus(self.root.specsOrdersStore.selectedStatus),
        };
        if (self.root.specsOrdersStore.selectedCultureFilter !== null) {
          reqOptions.culture_name = self.root.specsOrdersStore.selectedCultureFilter;
        }
        const providers = yield daylesfordService
          .getProviders(reqOptions)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Список поставщиков`, 'error'));

        self.process(providers.data);
        return providers;
      }),
    };
  });
