import { useQuery } from 'react-query';

const PAYMENT_PROCEDURES_QUERY_KEY = 'PAYMENT_PROCEDURES_QUERY_KEY';

export const usePaymentProcedures = (hasPaymentProcedures: boolean, getPaymentProceduresRequest: any) => {
  const result = useQuery([PAYMENT_PROCEDURES_QUERY_KEY, hasPaymentProcedures], () => getPaymentProceduresRequest(), {
    enabled: !hasPaymentProcedures,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });
  return result;
};
