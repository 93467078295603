import { camelCase, castArray, isArray, isObject, mergeWith, transform } from 'lodash-es';
import { types, getRoot, Instance } from 'mobx-state-tree';
import { isPrimitive } from '../utils/utils';

import { IRootStore } from './Root';
import { toJS } from 'mobx';
import socket from 'services/socket';
import { WS_ERROR_MESSAGES } from 'utils/consts';
import { getLocalAccessToken } from 'services/token-service';

const { identifier, model, map, boolean, string, enumeration, maybeNull, maybe, number, optional } = types;

const camelize = (obj: Record<string, unknown>) =>
  transform(obj, (result: Record<string, unknown>, value: unknown, key: string, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    result[camelKey] = isObject(value) ? camelize(value as Record<string, unknown>) : value;
  });

const Message = model('Message', {
  author: string,
  data: model({ v: string, price: maybe(string) }),
  id: identifier,
  isOwn: boolean,
  time: string,
});

//  # активно, ожидает ответа
// active = 'active'
// # Нет активного предложения
// inactive = 'inactive'
// # Предложение принято
// accepted = 'accepted'
// error = 'error'

const Bid = model('Bid', {
  acceptedBuyer: maybeNull(boolean),
  status: enumeration(['active', 'inactive', 'accepted', 'error']),
  expiredAt: string,
  volume: number,
  buyerPrice: maybeNull(string),
  providerPrice: maybeNull(string),
});

const Provider = model('Provider', {
  id: string,
  company: string,
  email: string,
  fullName: string,
  profileType: string,
  taxType: string,
});

// ROOM STORE

const Room = model('Room', {
  id: identifier,
  title: string,
  offerId: maybe(string),
  orderId: maybeNull(string),
  warehouseId: maybeNull(string),
  lastBidPrice: maybeNull(string),
  bid: maybeNull(Bid),
  message: maybeNull(
    model({ author: string, data: model({ v: string, price: maybe(string) }), id: string, time: string }),
  ),
  messages: map(Message),
  provider: Provider,
  archived: boolean,
  unread: boolean,
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .views((self) => ({
    get messagesList() {
      const messages = Array.from(self.messages.values()).flat();
      return messages.sort((a, b) => {
        return new Date(a.time).getTime() - new Date(b.time).getTime();
      });
    },
  }))
  .views((self) => ({
    get earliestMessageTime() {
      return self.messagesList[0]!.time;
    },
  }))
  .actions((self) => {
    return {
      setLastMessage(data: any) {
        self.message = data;
      },
    };
  })
  .actions((self) => {
    return {
      setLastBidPrice(price: string) {
        self.lastBidPrice = price;
      },
    };
  })
  .actions((self) => {
    return {
      setUnread(isUnread: boolean) {
        self.unread = isUnread;
      },
    };
  })
  .actions((self) => {
    return {
      setRoomAsRead() {
        self.setUnread(false);

        socket.emit('mark_message_as_read', { message_id: self.messagesList.at(-1)!.id });

        self.root.chatStore.setCounter(self.root.chatStore.tradesCounter.unreadMessages - 1);
        setTimeout(() => {
          self.root.chatStore.getActiveStatus();
        }, 1000);
      },
    };
  })
  .actions((self) => {
    return {
      acceptBid() {
        socket.emit('accept_bid', {
          price: self.lastBidPrice || self.bid?.providerPrice,
          room: self.id,
        });
      },
    };
  })
  .actions((self) => {
    return {
      processMessages(data: any) {
        const dataList = castArray(data);

        const mapped = dataList.map((message) => {
          message.id = message.id.toString();
          message.isOwn = message.author === self.root.chatStore.chatId;
          // message.isHidden = typeof message.data.v === 'number' || message.data.v.length < 1;
          message.data.v = message.data.v.toString();
          if (isPrimitive(message)) {
            return message;
          }

          const existing = self.messages?.get(message.id);

          return existing
            ? mergeWith(existing, message, (_, next: any) => {
                if (Array.isArray(next)) return next;
                return;
              })
            : self.messages.put(message);
        });
        return Array.isArray(data) ? mapped : mapped[0];
      },
    };
  });
export interface IRoom extends Instance<typeof Room> {}

// CHAT STORE

export const ChatStore = model('ChatStore', {
  isLoading: false,
  rooms: map(Room),
  selectedRoom: maybe(string),
  selectedStatus: optional(enumeration(['Активные', 'Закрытые']), 'Активные'),
  chatId: string,
  bidDuration: number,
  newMessagesCount: number,
  hasNextPage: boolean,
  hasNextPageRooms: boolean,
  tradesCounter: model({
    unreadMessages: number,
    active: number,
    archived: number,
  }),
})
  .views((self) => ({
    get roomsList() {
      return Array.from(self.rooms.values())
        .flat()
        .filter((room) => (self.selectedStatus === 'Активные' ? room.archived === false : room.archived === true));
    },
  }))
  .views((self) => ({
    get hasUnreads() {
      return self.tradesCounter.unreadMessages > 0;
    },
  }))
  .views((self) => ({
    getRoom(roomId: string) {
      return self.rooms.get(roomId);
    },
  }))
  .views((self) => ({
    messagesHistory(id: string) {
      const room = self.rooms.get(id);
      console.log('room', toJS(room));

      return room?.messagesList;
    },
  }))
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))

  .actions((self) => ({
    setLoading(state: boolean) {
      self.isLoading = state;
    },
  }))
  .actions((self) => ({
    addNewMessageCount() {
      self.newMessagesCount = self.newMessagesCount + 1;
    },
  }))
  .actions((self) => ({
    setSelectRoom(roomId: string) {
      self.selectedRoom = roomId;
    },
  }))
  .actions((self) => ({
    // @ts-ignore
    processRooms(data): any {
      const dataList = castArray(data);

      const mapped = dataList.map((room) => {
        room = camelize(room);
        room.id = room.id.toString();
        const existing = self.rooms?.get(room.id);

        if (!!room.message) {
          room.message.id = room.message.id.toString();
          room.message.isOwn = room.message.author === self.root.authStore.simpleUser.id;
        }
        if (isPrimitive(room)) {
          return room;
        }

        existing
          ? mergeWith(existing, room, (_, next: any) => {
              if (Array.isArray(next)) return next;
              return;
            })
          : self.rooms.put(room);

        return;
      });
      return Array.isArray(data) ? mapped : mapped[0];
    },
  }))

  .actions((self) => {
    return {
      setChatId(id: string) {
        self.chatId = id;
      },
    };
  })
  .actions((self) => {
    return {
      setBidDuration(duration: number) {
        self.bidDuration = duration;
      },
    };
  })
  .actions((self) => {
    return {
      setHasNextPage(hasNextPage: boolean) {
        self.hasNextPage = hasNextPage;
      },
    };
  })
  .actions((self) => {
    return {
      setHasNextPageRooms(hasNextPageRooms: boolean) {
        self.hasNextPageRooms = hasNextPageRooms;
      },
    };
  })
  .actions((self) => {
    return {
      getRooms(lastMessageTime?: string, isArchived?: boolean) {
        self.setLoading(true);
        socket.emit('get_rooms', {
          archived: isArchived,
          last_message_time: lastMessageTime,
        });
      },
    };
  })
  .actions((self) => {
    return {
      setCounter(messages: number, active?: number, archived?: number) {
        self.tradesCounter = {
          unreadMessages: messages || 0,
          active: active || 0,
          archived: archived || 0,
        };
      },
    };
  })
  .actions(() => {
    return {
      getActiveStatus() {
        socket.emit('active_status');
      },
    };
  })
  .actions(() => {
    return {
      connect() {
        socket.emit('disconnect_request');
      },
    };
  })
  .actions(() => {
    return {
      disconnect() {
        socket.emit('disconnect_request');
      },
    };
  })

  .actions((self) => {
    return {
      requestRoom(roomId: string) {
        socket.emit('get_room', {
          room: roomId,
        });
      },
    };
  })
  .actions((self) => {
    return {
      reconnect() {
        self.root.authStore.getMe();
        self.root.alertsStore.addNotification(`Ошибка подключения, переподключаемся`, 'error');
        // setTimeout(() => {
        //   socket.connect();
        // }, 2000);
      },
    };
  })

  .actions((self) => ({
    afterCreate() {
      if (!!self.root.authStore.isAuthenticated) {
        socket.auth = { token: getLocalAccessToken() };
        // socket.connect();
      }

      socket.on('error', (error) => {
        console.error('AAAAAAAAAAA', error);
      });

      socket.onAny((event, ...args) => {
        console.log('event', event, args);

        const errorCode = args[0].error;
        if (errorCode) {
          self.root.alertsStore.addNotification(WS_ERROR_MESSAGES(errorCode), 'error');
          if (errorCode === 401) {
            self.reconnect();
          }
        }
      });

      socket.on('connect_error', (error) => {
        console.log('connect_error', error);
        setTimeout(() => {
          self.reconnect();
        }, 2000);
      });

      setTimeout(() => {
        self.getActiveStatus();
      }, 1000);

      socket.on('get_rooms', (data: any) => {
        console.log('get_rooms response', data);
        if (data.length <= 5) {
          self.setHasNextPageRooms(false);
        } else {
          self.setHasNextPageRooms(true);
        }
        self.setLoading(false);
        self.processRooms(data);
      });

      socket.on('get_room', (data: any) => {
        console.log('get_room response', data);
        self.processRooms(data);
      });

      socket.on('room_archived', (data: any) => {
        self.getActiveStatus();
        self.rooms.delete(data.room_id);
      });

      socket.on('offer_accepted', (data: any) => {
        self.getActiveStatus();
        self.root.alertsStore.addNotification(`Сделка заключена. Ожидается подписание документов`, 'notification');
      });

      socket.on('active_status', (data: any) => {
        console.log('active_status', data);
        self.setCounter(data.messages, data.active, data.archive);
      });

      socket.on('create_room_with_bid', (data: any) => {
        console.log('create_room_with_bid response', data);
        self.getActiveStatus();
        self.processRooms(data);
      });

      socket.on('accept_bid', (data: any) => {
        console.log('accept_bid', data);
        self.getActiveStatus();
      });

      socket.on('get_messages', (data: any) => {
        console.log('get_messages response', data, self.selectedRoom);
        if (data.length === 0) {
          self.setHasNextPage(false);
        } else {
          self.setHasNextPage(true);
        }
        const room = self.rooms.get(self.selectedRoom!);
        if (!!room) {
          room.processMessages(data);
          console.log('send it when get messages');

          setTimeout(() => {
            room.setRoomAsRead();
          }, 500);
        } else {
          console.log('No selected room');
          self.root.alertsStore.addNotification(`Ошибка получения сообщений, неправильный id комнаты`, 'error');
        }
      });

      socket.on('send_bid', (data: any) => {
        console.log('send_message_with_bid response', data);
        self.processRooms(data);
        const room = self.rooms.get(data.id);
        room?.processMessages(data.message);
        room?.setLastMessage(data.message);
        room?.setLastBidPrice(data.message.price);
        self.addNewMessageCount();

        if (!!self.selectedRoom && self.selectedRoom === data.id) {
          room?.setUnread(false);
        } else {
          room?.setUnread(true);
          self.root.alertsStore.addNotificationOutOfPage(`Новая ставка`, 'notification', 'data.id');
        }
      });

      socket.on('send_message', (data: any) => {
        console.log('send_message response', data);
        self.getActiveStatus();
        if (!!self.selectedRoom && self.selectedRoom === data.room_id) {
          const room = self.getRoom(self.selectedRoom);
          room?.processMessages(data);
          room?.setLastMessage(data);
          room?.setUnread(false);
          self.addNewMessageCount();
          self.setCounter(self.tradesCounter.unreadMessages - 1);
        } else {
          // handle new message outside room
          const room = self.getRoom(data.room_id);
          if (!!room) {
            room.setLastMessage(data);
            self.addNewMessageCount();
            room.setUnread(true);
          } else {
            self.requestRoom(data.room_id);
          }
        }
      });
    },
  }))

  .actions((self) => {
    return {
      setSelectedStatus(status: 'Активные' | 'Закрытые') {
        self.rooms.clear();
        self.getRooms(undefined, status === 'Закрытые');
        // self.pageNumber = 1;
        self.selectedStatus = status;
      },
    };
  })

  .actions((self) => {
    return {
      getMessages(roomId: string, lastMessageTime?: string) {
        socket.emit('get_messages', {
          room: roomId,
          last_message_time: lastMessageTime,
        });
      },
    };
  })
  .actions((self) => {
    return {
      addMessage(message: string, roomId: string, time: string, messageType = 'message') {
        socket.emit('send_message', {
          type: 't',
          room: roomId,
          data: message,
        });
      },
    };
  })
  .actions((self) => {
    return {
      sendCounterOffer(roomId: string, price: string, message?: string) {
        socket.emit('send_bid', {
          price: price,
          room: roomId,
          data: message || '',
        });
      },
    };
  });
