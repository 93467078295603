import React from 'react';
import ReactSelect, { DropdownIndicatorProps, GroupBase, OptionProps, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import styled from 'styled-components';
import { ChevronDownIcon } from 'uikit/Icons';

interface ISelect {
  value: string | number | Date;
  label: string | number;
  data?: string;
}

interface IProps {
  options?: ISelect[];
  disabled?: boolean;
  placeholder?: string;
  onChange?: (value: any | any[]) => void;
  isAsync?: boolean;
  cacheOptions?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  defaultOptions?: boolean;
  defaultValue?: ISelect;
  onInputChange?: (value: string) => void;
  loadOptions?: (value: any | any[]) => void;
  selectOption?: (value: any | any[]) => void;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  filterOption?: (option: FilterOptionOption<unknown>, rawInput: string) => boolean;
}
interface ICustomOptionProps {
  options?: ISelect[];
  disabled?: boolean;
  placeholder?: string;
  onChange?: (value: any | any[]) => void;
  isAsync?: boolean;
  cacheOptions?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  defaultOptions?: boolean;
  defaultValue?: ISelect;
  onInputChange?: (value: string) => void;
  loadOptions?: (value: any | any[]) => void;
  selectOption?: (value: any | any[]) => void;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  filterOption?: (option: FilterOptionOption<unknown>, rawInput: string) => boolean;
  customOption?: React.ComponentType<OptionProps<unknown, boolean, GroupBase<unknown>>> | undefined;
}

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon />
    </components.DropdownIndicator>
  );
};

export const Select: React.FC<IProps> = ({
  placeholder,
  options,
  defaultValue,
  isAsync = false,
  onChange,
  disabled,
  onInputChange,
  menuPlacement = 'auto',
  filterOption,
  ...rest
}) => (
  <StyledReactSelect
    classNamePrefix="Select"
    options={options}
    components={{ DropdownIndicator }}
    placeholder={placeholder || 'Выберите . . .'}
    onChange={onChange}
    isDisabled={disabled}
    defaultValue={defaultValue}
    onInputChange={onInputChange}
    menuPlacement={menuPlacement}
    filterOption={filterOption}
    as={isAsync ? AsyncSelect : undefined}
    {...rest}
  />
);
export const CustomOptionSelect: React.FC<ICustomOptionProps> = ({
  placeholder,
  options,
  defaultValue,
  isAsync = false,
  onChange,
  disabled,
  onInputChange,
  menuPlacement = 'auto',
  filterOption,
  customOption,
  ...rest
}) => (
  <StyledReactSelect
    classNamePrefix="Select"
    options={options}
    components={{ DropdownIndicator, Option: customOption }}
    placeholder={placeholder || 'Выберите . . .'}
    onChange={onChange}
    isDisabled={disabled}
    defaultValue={defaultValue}
    onInputChange={onInputChange}
    menuPlacement={menuPlacement}
    filterOption={filterOption}
    as={isAsync ? AsyncSelect : undefined}
    {...rest}
  />
);

const StyledReactSelect = styled(ReactSelect)`
  color: #292929;
  transition: 0.3s;

  .Select__value-container {
    padding: 6px 16px;
    color: #292929;
    font-size: 15px;
  }

  .Select__placeholder {
    color: #292929;
    font-size: 15px;
  }

  .Select__control {
    cursor: pointer;
    max-width: 320px;
    max-height: 43px;
    border-radius: 8px;
    border: 1px solid #825dec;
    background-color: transparent;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__indicator {
    padding-right: 16px;
  }

  .Select__menu {
    max-width: 320px;
    border-radius: 8px;
  }

  .Select__menu-list {
    padding: 0;
    border-radius: 4px;
  }

  .Select__option {
    color: #292929;
    font-size: 15px;
    cursor: pointer;

    &:hover {
      background-color: #f4f0ff;
    }

    &--is-focused {
      background-color: #f4f0ff;
    }

    &--is-selected {
      background-color: #e8e0ff;
    }
  }
`;
