import styled from 'styled-components';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { observer } from 'mobx-react-lite';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';

import { Select } from 'uikit/Selects';
import { Button, Flex, Spacer, Typography } from 'uikit';
import { useStore } from 'store/Root';
registerLocale('ru', ru);

export const CalendarSettings: React.FC = observer(() => {
  const { settingsStore } = useStore();
  const [startDate, setStartDate] = useState(new Date());
  const highlightWithRanges = [
    {
      'react-datepicker__day--highlighted-custom-1': settingsStore.getCalendarHighlightedArr,
    },
  ];
  const handleDatesChange = (date: Date) => {
    setStartDate(date);
    settingsStore.setCalendarHighlightedArr(date);
  };
  // const handleDatesSelect = (date: Date) => {};
  const selectVisibleYear = (date: { label: string; value: string }) => {
    settingsStore.setCurrentYear(date.value);
  };

  return (
    <Wrapper>
      <Flex column>
        <Flex gap={24} vAlignContent="center" marginRight={159}>
          <SelectWrap>
            <Select
              placeholder={settingsStore.calendarCurrentYear}
              options={settingsStore.getCalendarYears}
              onChange={selectVisibleYear}
            />
          </SelectWrap>
          <Flex column>
            <Typography size="sm">Рабочих дней</Typography>
            <Typography bold size="sm">
              {settingsStore.getCalendarWorkingDays}
            </Typography>
          </Flex>
          <Flex column>
            <Typography size="sm">Не рабочих</Typography>
            <Typography color="#FA3E3E" bold size="sm">
              {settingsStore.getCalendarNonWorkingDays}
            </Typography>
          </Flex>
          <Flex width="auto" grow hAlignContent="right">
            <Button onClick={settingsStore.saveCalendar} variant="base">
              Сохранить
            </Button>
          </Flex>
        </Flex>
        <Spacer />

        <DatePicker
          calendarClassName="customDatePicker"
          disabledKeyboardNavigation
          inline
          highlightDates={highlightWithRanges}
          monthsShown={12}
          locale="ru"
          selected={startDate}
          // onSelect={(date: Date) => handleDatesSelect(date)}
          onChange={(date: Date) => handleDatesChange(date)}
          openToDate={settingsStore.startOfCurrentYear}
        />
      </Flex>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 0;
  .customDatePicker {
    max-width: 1024px;
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
    box-shadow: none;
    border: none;
    gap: 15px;
  }
  .react-datepicker__day--outside-month {
    user-select: none;
    pointer-events: none;
    background-color: transparent;
    border-color: transparent;
    color: transparent;
  }
  .react-datepicker__current-month {
    text-transform: capitalize;
  }
  .react-datepicker__navigation {
    display: none;
  }
  .react-datepicker__month-container {
    background: #f2efe5;
    border: 1px solid #e7e7e7;
    border-radius: 6px;
  }
  .react-datepicker__day {
    border: 1px solid transparent;
  }
  .react-datepicker__day--highlighted-custom-1 {
    color: initial;
    border: 1px solid #407ef5;
    border-radius: 3px;
    &.react-datepicker__day--outside-month {
      border-color: transparent;
      background-color: transparent;
    }
  }
`;
const SelectWrap = styled.div`
  max-width: 146px;
  margin-right: -8px;
  .Select__control {
    max-width: 146px;
  }
`;
