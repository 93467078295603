import React from 'react';
import styled from 'styled-components';

interface IProps {
  colNames?: string[];
  rowNames?: string[];
  values: [string[] | number[]];
}

export const GridTable: React.FC<IProps> = ({ values, colNames, rowNames }) => {
  const getTableType = () => {
    if (!!rowNames && !!colNames) return 'full';
    else if (!!rowNames && !colNames) return 'withRowNames';
    else if (!rowNames && !!colNames) return 'withColNames';
    else return 'onlyValues';
  };
  const cellCount = values[0].length;
  const colCount = colNames?.length;
  const rowsCount = !!rowNames ? rowNames.length : values.length;
  return (
    <Container tableType={getTableType()} cellCount={cellCount} rowsCount={rowsCount} colCount={colCount}>
      {colNames &&
        colNames.map((header, idx) => (
          <TableCell areaName={`header${idx}`} key={header + idx}>
            {header}
          </TableCell>
        ))}
      {rowNames &&
        rowNames.map((rowNames, idx) => (
          <TableCell areaName={`rowName${idx}`} key={rowNames + idx}>
            {rowNames}
          </TableCell>
        ))}
      {values &&
        values.map((valuesIn, idx1) =>
          valuesIn.map((value, idx2) => {
            return (
              <TableCell areaName={`cell${idx1}${idx2}`} key={value}>
                {value}
              </TableCell>
            );
          }),
        )}
    </Container>
  );
};

interface IGenGridAreasProps {
  cellCount: number;
  colCount?: number;
  rowsCount: number;
  tableType: 'full' | 'withRowNames' | 'withColNames' | 'onlyValues';
}
const generateTableGridAreas = ({ cellCount, colCount, rowsCount, tableType }: IGenGridAreasProps) => {
  let cells = '';
  const rows = [];
  for (let k = 0; k < rowsCount; k++) {
    for (let j = 0; j < cellCount; j++) {
      const currentCell = `cell${k}${j} `;
      cells = cells + currentCell;
    }
    rows.push(`'${cells.trim()}`);
    cells = '';
  }
  if (tableType === 'full') {
    // Таблица c col_names и row_names
    const colsCount = colCount || 1;
    const rowsWithNames = rows.map((row, idx) => `'rowName${idx} ${row.substring(1)}`);
    let cols = '';
    for (let j = 0; j < colsCount; j++) {
      cols = cols + `header${j} `;
    }
    return `
    '${cols.trim()}'\n${rowsWithNames.join("'\n")}'
    `;
  } else if (tableType === 'withRowNames') {
    // Таблица c row_names
    const rowsWithNames = rows.map((row, idx) => `'rowName${idx} ${row.substring(1)}`);
    return `${rowsWithNames.join("'\n")}'`;
  } else if (tableType === 'withColNames') {
    // Таблица c col_names
    let cols = '';
    const colsCount = colCount || 1;
    for (let j = 0; j < colsCount; j++) {
      cols = cols + `header${j} `;
    }
    return `
    '${cols.trim()}'\n${rows.join("'\n")}'
    `;
  } else {
    // Таблица рандомных данных
    return `${rows.join("'\n")}'`;
  }
};

/* grid-template-columns: ${tableSize.repeat(exampleTable.headers.length).trim()}; */
const tableSize = '1fr ';
const Container = styled.div<IGenGridAreasProps>`
  display: grid;
  grid-template-columns: ${(p) => tableSize.repeat(p.colCount ? p.colCount : p.cellCount).trim()};
  grid-template-rows: ${(p) => tableSize.repeat(p.colCount ? p.rowsCount + 1 : p.rowsCount).trim()};
  gap: 5px 5px;
  grid-template-areas: ${(p) => generateTableGridAreas(p)};
`;
interface TableCellProps {
  areaName: string;
}
const TableCell = styled.div<TableCellProps>`
  grid-area: ${(p) => p.areaName};
  border-bottom: 1px solid #ccc;
`;
