import React from 'react';

interface IProps {
  reverse?: boolean;
  isWhite?: boolean;
}

/**
 * Иконка "Стрелка с поворотом".
 */
export const TwistedArrowIcon = ({ reverse, isWhite }: IProps) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
    <path
      d={
        !reverse
          ? 'M9 13V7C9 3.68629 6.31371 1 3 1H0M9 13L14 8M9 13L4 8'
          : 'M6 2V8C6 11.3137 8.68629 14 12 14H15M6 2L1 7M6 2L11 7'
      }
      stroke={isWhite ? 'white' : 'black'}
      strokeWidth="2"
    />
  </svg>
);
