import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/Root';
import { IRoom } from 'store/ChatStore';
import { generatePath, useNavigate } from 'react-router-dom';
import format from 'date-fns/esm/format';
import { ru } from 'date-fns/locale';
import ArchivedBgSvg from 'assets/img/archived_bg.svg';
import { routes } from 'routes/consts';
import styled from 'styled-components';
import { Flex, Spacer, TwistedArrowIcon, Typography } from 'uikit';
import { Card } from 'uikit/Card/Card';
import { Table } from 'uikit/Table/Table';
import { Counter } from './Counter';
import { getTaxTypeForm } from 'components/Orders/pages/helpers';

interface IProps {
  room: IRoom;
}

export const Room: React.FC<IProps> = observer(({ room }) => {
  const { chatStore } = useStore();
  const navigate = useNavigate();
  const handleRoomClick = (id: string) => {
    chatStore.setSelectRoom(id);
    navigate(`../${generatePath(routes.chats.dialog.path, { id })}`);
  };
  const handleFinishedOrderClick = (id: string) => {
    navigate(`../../orders/${generatePath(routes.orders.timeline.path, { id })}`);
  };

  const dataList = [
    {
      title: 'Объём',
      content: [room.bid!.volume],
    },
    {
      title: 'Продавец',
      content: [room.provider?.company || ''],
    },
    {
      title: 'Система налогообложения',
      content: [getTaxTypeForm(room.provider?.taxType || 'simple')],
    },
  ];

  const bidDeadline = room.bid?.expiredAt || '';

  const archivedAccepted = room.archived && room?.bid?.status === 'accepted';
  const archivedInactive = room.archived && room?.bid?.status === 'inactive';

  const fullPrice = Number(room.bid?.providerPrice) * Number(room.bid?.volume);

  return (
    <React.Fragment key={room.id}>
      <CardWrapper>
        <Card noPadding transparent onClick={() => handleRoomClick(room.id)}>
          <CardInner
            style={{
              backgroundImage: archivedInactive ? `url(${ArchivedBgSvg})` : 'none',
              backgroundColor: archivedAccepted ? '#F9F6ED' : 'transparent',
            }}
          >
            <CardInnerWrapper isArchived={room.archived}>
              <CardHeader>
                <Typography size="lg" bold>
                  {room.title}
                </Typography>
                {!room.archived && <Counter deadline={bidDeadline} bidDuration={chatStore.bidDuration} />}
              </CardHeader>
              <Spacer space={30} />
              <Flex column>
                <Table data={dataList} />
              </Flex>
            </CardInnerWrapper>
            {room.bid && !room.archived && (
              <CardOfferInnerWrapper hasUnreads={room.unread} isArchived={room.archived}>
                <CounterOfferSection>
                  <CounterOfferColumn>
                    <CounterOfferTitle>Предложение продавца</CounterOfferTitle>
                    <CounterOfferRow>
                      <TwistedArrowIcon />
                      <CounterOfferPrice>{room.bid.providerPrice}</CounterOfferPrice>
                      <CounterOfferCurr>₽/т</CounterOfferCurr>
                    </CounterOfferRow>
                  </CounterOfferColumn>

                  <CounterOfferColumn>
                    <CounterOfferTitle>Предложение покупателя</CounterOfferTitle>
                    <CounterOfferRow>
                      <TwistedArrowIcon reverse />
                      <CounterOfferPrice>{room.bid.buyerPrice}</CounterOfferPrice>
                      <CounterOfferCurr>₽/т</CounterOfferCurr>
                    </CounterOfferRow>
                  </CounterOfferColumn>
                  {room.message && <LastMessage>{room.message.data.v || `${room.message.data.price} ₽/т`}</LastMessage>}
                </CounterOfferSection>
              </CardOfferInnerWrapper>
            )}
          </CardInner>
        </Card>
        {archivedAccepted && (
          <TotalOfferColumn
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              handleFinishedOrderClick(room.orderId || 'b94d9904-75de-4c46-8852-46879a08bb17');
            }}
          >
            <CounterOfferColumn>
              <AcceptedTotalInfo>
                {room.message && (
                  <Typography color="#918F89" size="md">
                    {format(new Date(room.message?.time || ''), 'dd MMMM yyyy', { locale: ru })}
                  </Typography>
                )}
                <Typography size="lg" bold>
                  <StyledOfferLink>Предложение принято</StyledOfferLink>
                </Typography>
              </AcceptedTotalInfo>
            </CounterOfferColumn>
            <AcceptedTotalInfo>
              <Typography color="#918F89" size="sm">
                Сделка
              </Typography>
              <Typography size="md">{fullPrice}</Typography>
              <CounterOfferPrice style={{ fontSize: 14 }}>без НДС</CounterOfferPrice>
            </AcceptedTotalInfo>
          </TotalOfferColumn>
        )}
      </CardWrapper>
      <Spacer space={20} />
    </React.Fragment>
  );
});

const CardWrapper = styled.div`
  display: flex;
  gap: 2px;
  max-width: 800px;
  & > div {
    max-width: 800px;
    width: 100%;
  }
`;
const CardInner = styled.div`
  display: flex;
  gap: 2px;
`;
const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface ICardInnerProps {
  hasUnreads?: boolean;
  isArchived?: boolean;
}
const CardInnerWrapper = styled.div<ICardInnerProps>`
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: ${({ isArchived }) => (isArchived ? 'transparent' : ' #f9f6ed')};
  flex: 1;
  max-width: 600px;
`;

const CardOfferInnerWrapper = styled(CardInnerWrapper)<ICardInnerProps>`
  padding: 15px 15px 10px 15px;
  flex: 0;
  min-width: 200px;
  position: relative;
  &:after {
    content: '';
    display: ${({ hasUnreads, isArchived }) => (hasUnreads && !isArchived ? 'block' : 'none')};
    position: absolute;
    top: -4px;
    right: -5px;
    width: 12px;
    height: 12px;
    background: #d94c4c;
    border-radius: 50%;
  }
`;

const CounterOfferSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 200px;
  justify-content: space-between;
  flex: 1;
`;
const CounterOfferColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const TotalOfferColumn = styled.button`
  padding: 21px 22px 30px;
  border: none;
  display: flex;
  flex-direction: column;
  flex: 0;
  flex-basis: 244px;
  justify-content: space-between;
  border-radius: 4px;
  background-color: #f9f6ed;
`;
const CounterOfferTitle = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #918f89;
`;
const CounterOfferRow = styled.div`
  margin-bottom: 13px;
  display: flex;
  gap: 4px;
  align-items: baseline;
`;
const CounterOfferPrice = styled.div`
  font-size: 18px;
  line-height: 21px;

  letter-spacing: -0.41px;
  color: #000000;
`;
const CounterOfferCurr = styled.div`
  font-size: 14px;
  line-height: 17px;

  letter-spacing: -0.41px;
  color: #000000;
  opacity: 0.5;
`;
const AcceptedTotalInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  gap: 3px;
`;
const LastMessage = styled.div`
  padding: 6px 10px;
  background: #4582de;
  border-radius: 5px;
  color: white;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 23px;
`;

const StyledOfferLink = styled.span`
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  text-align: left;
  letter-spacing: -0.41px;
  color: #8e66fe;
  border: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border-radius: 0;
  margin: 0;
  text-decoration: none;
  &.active {
    color: #407ef5;
  }
`;
