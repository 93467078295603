export enum LINKED_ORDERS_STATUS_GENERAL {
  ACTIVE = 'active',
  DONE = 'done',
  FAIL = 'fail',
}

export enum LINKED_ORDERS_COUNTER_STATUS {
  ACTIVE_COUNT = `activeCount`,
  DONE_COUNT = `doneCount`,
  FAIL_COUNT = `failCount`,
}
export interface ILinkedOrdersCounterResponse {
  ordersCount: number;
  [LINKED_ORDERS_COUNTER_STATUS.ACTIVE_COUNT]: number;
  [LINKED_ORDERS_COUNTER_STATUS.DONE_COUNT]: number;
  [LINKED_ORDERS_COUNTER_STATUS.FAIL_COUNT]: number;
  totalActualVolume: number | null;
}
export interface IOrdersCounterResponse {
  step1: number;
  step2_onway: number;
  step2_tests_taken: number;
  step2_quality: number;
  step2_quality_accepted: number;
  step2_quality_failed: number;
  step3: number;
  step3_docs_ready: number;
  step3_sign_ready: number;
  step3_prebill_send: number;
  step4: number;
  step4_plan_ready: number;
  step4_selected_date: number;
  step4_date_approved: number;
  step4_loading_started: number;
  step4_over_today: number;
  step4_over_contract: number;
  step4_done: number;
  step5_verification_started: number;
  step5_verificated: number;
  step5_letter_for_refund_approved: number;
  step6_order_closed: number;
}

export interface IOffersCounterResponse {
  archived?: number;
  accepted?: number;
  partial?: number;
  active?: number;
  pending?: number;
}
export interface IOffersListResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: IOffer[];
}

export interface ILinkedOrder {
  id: string;
  product: {
    id: string;
    title: string;
  };
  company: {
    id: string;
    nameOfProvider: string;
  };
  generalStatus: string;
  readableStatus: string;
  dateStartOfSpec: string | null;
  dateFinishOfContract: string;
}

export interface ILinkedOrderResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: ILinkedOrder[];
}
export interface IOrdersListResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: IOrder[];
}
export interface IReqOrders {
  limit: number;
  offset: number;
  provider?: string;
  status?: string;
  culture_name?: string;
  general_status?: string;
}
export interface IReqOffers {
  limit: number;
  offset: number;
  status__in?: string;
}
export interface IOfferResponse {
  data: IOffer;
}
export interface IOffer {
  totalVolume: number;
  currentVolume: number;
  ordersQty: number;
  cost: number;
  costWithNds: number;
  createdAt: string;
  creator: string;
  dateFinishShipment: string;
  dateStartShipment: string;
  description: string;
  id: string;
  periodOfExport: number;
  specifications: IOrderSpecs[];
  product: IProduct;
  status: string;
  title: string;
  volume: number;
  warehouse: {
    // TODO: проверить корректность модели, при возможности, использовать интерфейс IWarehouse
    id: string;
    title: string;
  };
  priceBuyer: string;
}

export interface IProduct {
  id: string;
  specifications: IProductSpecs[];
  title: string;
  description: string;
  harvestYear: string;
  harvestType: string;
  culture: string;
}

export interface IOrderSpecs {
  id: string;
  specification: IProductSpecs;
  value: string;
}

export interface IProductSpecs {
  id: string;
  unitOfMeasurement: {
    id: number;
    unit: string;
  };
  name: string;
  required: boolean;
  type: string;
  description: string | null;
  GOST: string;
  isAsIs: boolean;
  spec: string;
}

export interface IWarehouse {
  id: string;
  title: string;
  address: string;
  owner: number;
  longCoord: string;
  latCoord: string;
}

export interface ICompany {
  addressSending: string;
  basisOfDoc: string;
  bik: string;
  correspondentAccount: string;
  emailOfHead: string;
  headOfProvider: string;
  id: string;
  inn: string;
  kpp: string;
  mailAddress: string;
  nameOfBank: string;
  nameOfProvider: string;
  ogrn: string;
  paymentAccount: string;
  phoneNumber: string;
  positionHeadOfProvider: string;
  shortFio: string;
  ulAddress: string;
  staff: [];
  taxType: string;
}

export interface IOrder {
  id: string;
  title: string;
  costWithNds: number;
  cost: number;
  periodOfExport: number;
  costByTonne: number;
  company: ICompany;
  offer: {
    id: string;
    costWithNds: number;
    periodOfExport: number;
    product: IProduct;
    specifications: IOrderSpecs[];
    warehouse: IWarehouse;
    daysTillEnd: number;
    title: string;
    volume: number;
    description: string;
    status: string;
    createdAt: string;
    dateStartShipment: string;
    dateFinishShipment: string;
    cost: number;
    taxType: string;
    creator: number;
    totalVolume: number;
    currentVolume: number;
    freeVolume: number;
  };
  taxType: string;
  providerName: string;
  farmerCostWithNds: number;
  totalWithNds: number;
  priceForDelivery: number;
  amountOfNds: number;
  status: string;
  acceptedVolume?: number;
  actualVolume?: number;
  nameOfContract: string;
  numberOfSpec: string;
  dateStartOfSpec: string;
  dateFinishOfSpec: string;
  dateStartOfContract: string;
  dateFinishOfContract: string;
  dateStartShipment: string;
  dateFinishShipment: string;
  farmerCost: number;
  total: number;
  provider: number;
  customer: number;
  priceBuyer: string;
  priceTransport: number;
  priceFarmer: string;
  priceFarmerWithNds: string;
  costTotal: string;
  costTotalWithNds: string;
  selectedWarehouse: IWarehouse;
  documents: number[];
  readableStatus: string;
  activeReadableStatus: string;
  generalStatus:
    | LINKED_ORDERS_STATUS_GENERAL.ACTIVE
    | LINKED_ORDERS_STATUS_GENERAL.DONE
    | LINKED_ORDERS_STATUS_GENERAL.FAIL;
}

export interface ITimeline {
  stage: string;
  blocks: [
    {
      type: string;
      subtyme: string;
      dateTime: Date;
      blocks: any;
      value: string;
    },
  ];
}

export interface ISteps {
  stages: any;
  getNewTimeline?: any;
}
export interface ITitleText {
  value: string;
  dateTime: string;
}

export interface IOrdersByOffer {
  offerId?: IOffer['id'];
  company?: number;
}

export type IAccreditationStatus = 'new' | 'accredited' | 'denied' | 'expired';

export interface IFarmer {
  id: string;
  nameOfProvider: string;
  taxType: string;
  ulAddress: string;
  inn: string;
  documentFlow: {
    type: string;
    fio: string;
    phoneNumber: string;
    emailOfHead: string;
  } | null;
  ordersCount?: number;
  edmStatus: string;
  currentVolume?: number;
  accreditationStatus?: IAccreditationStatus | string;
}

export interface IPatchFarmer {
  id: string;
  accreditationStatus?: IAccreditationStatus | string;
}
export interface IReqFarmers {
  limit: number;
  offset: number;
  searchInput?: string;
  accreditation_status?: IAccreditationStatus;
}

export interface IFarmerResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: IFarmer[];
}
export interface IReqUsers {
  limit: number;
  offset: number;
  search?: string;
  verified?: boolean;
  blocked?: boolean;
  edm_released?: boolean;
}

export interface IPatchUser {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  verified?: boolean;
  blocked?: boolean;
  edmReleased?: boolean;
  profileType?: string;
}
export interface IUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  verified: boolean;
  blocked: boolean;
  edmReleased: boolean;
  profileType: string;
}

export namespace NSFarmer {
  export interface Staff {
    type: string;
    fio: string;
    phoneNumber: string;
    emailOfHead: string;
  }

  export interface Farmer {
    id: string;
    nameOfProvider: string;
    taxType: string;
    ulAddress: string;
    mailAddress: string;
    inn: string;
    kpp: string;
    ogrn: string;
    nameOfBank: string;
    paymentAccount: string;
    correspondentAccount: string;
    bik: string;
    staff: Staff[];
  }
}

export interface IReqLinkedOrdersSummary {
  limit: number;
  offset: number;
  company?: number;
  offer?: number;
  general_status?: string;
  general_status__in?: string;
}
export interface IReqLinkedOrdersCounter {
  company?: number;
  offer?: number;
}

export interface IReqAnalyticsList {
  limit: number;
  offset: number;
}
export interface IAnalyticsCharts {
  title: string;
  type: string;
  data: any[];
}
export interface IReqOffers {
  limit: number;
  offset: number;
}
export interface ITeamMember {
  type: string;
  fio: string;
  phoneNumber?: string;
  emailOfHead?: string;
  company?: string;
  id?: string;
}
export interface IReqRecovery {
  email: string;
}
export interface IReqPasswordReset {
  email: string;
  token: string;
  password: string;
  passwordTwo: string;
}

// export interface ISaleRequestSpecs {
//   id: number;
//   name: string;
//   value: string;
// }

export interface ISaleRequest {
  id: string;
  culture: string;
  cultureId: number;
  subName?: string;
  basis: string;
  fileQuality?: string;
  bids?: {
    createdAt: string;
    price: string;
    user: string;
    indicators?: any[];
    endDate: string;
    startDate: string;
    warehouse: string | null;
    warehouseAddress: string;
  }[];
  buyers?: {
    blocked: boolean;
    docFoundation: string;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    phone: string;
    verified: boolean;
  }[];
  indicators?: any[];
  volume: string;
  price: string;
  status: 'open' | 'accepted' | 'declined';
  comment?: string;
  reason?: string;
  createdAt: string;
  startDate: string;
  endDate: string;
  user: string;
  providerName: string;
  warehouse: string;
  warehouseName: string;
  warehouseAddress: string;
  providerTaxType: string;
  order: string | null;
  smartOrder: string | null;
}
export interface IReqSaleRequest {
  status: 'open' | 'accepted' | 'declined';
}

export interface ISaleRequestsCounterResponse {
  total: number;
  open: number;
  accepted: number;
  declined: number;
}

export interface IReqPriceCalcResults {
  culture: string;
  subName?: string;
  basis: string;
  address?: string;
  lat?: string;
  lon?: string;
}

export interface ISaleRequestBidRequest {
  sale_request: string;
  price: string;
  startDate: string;
  endDate: string;
  warehouseAddress?: string;
  warehouse?: string;
}
export interface IOrderDetailsRequest {
  id: string;
  startDate?: string;
  endDate?: string;
  warehouseAddress?: string;
  destinationWarehouse?: string;
  docFoundation?: string;
  indicators?: any[];
  culture?: string;
  contractVolume?: string;
  number?: number;
  price?: string;
  specDate?: string;
  taxable?: boolean;
  includeWithdrawals?: boolean;
  paymentProcedure?: number | null;
  contractNumber?: string;
  buyerCompany?: string;
  weightOption?: string;
}

export interface IReqCulturesFilterOptions {
  status?: 'open' | 'closed' | 'declined';
  provider?: string;
}

export interface ISendToSmartRequest {
  id: string;
  logisticCosts: string;
  agencyCosts: string;
  additionalCosts: string;
  taxable: boolean;
  includeWithdrawals: boolean;
  paymentProcedure: number | null;
  warehouseAddress?: string;
  destinationWarehouse?: string;
}

export interface IReqProvidersRequest {
  status: string;
  culture_name?: string;
}
