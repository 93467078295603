import React, { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Flex, Spacer, Typography } from 'uikit';
import { IRouteParams } from 'utils/models';
import Modal from 'react-bootstrap/esm/Modal';
import { Loader } from 'uikit/Loader';
import { Table } from 'uikit/Table/Table';
import { formatDate, formatMoney, getTrimText } from 'utils/utils';
import { TimeLine } from '../components/TimeLine/TimeLine';
import { getTaxTypeForm, getStaffType } from './helpers';
import { ReactComponent as PhoneIcon } from 'assets/img/phone.svg';
import { ReactComponent as MailIcon } from 'assets/img/mail.svg';
import { ID_DISPLAY_SYMBOLS } from 'utils/consts';
import { useQuery } from 'react-query';
import { useStore } from 'store/Root';
import { routes } from 'routes/consts';

export const OrderPage: React.FC = () => {
  const { id }: IRouteParams = useParams();
  const paramId = id ?? '';
  const { ordersStore } = useStore();
  const orderData = useQuery(['ORDER_DATA_QUERY_KEY', paramId], () => ordersStore.getOrder(paramId), {
    enabled: !!paramId,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });

  const { product, daysTillEnd } = ordersStore.orders?.get(paramId)?.offer || {};
  const [showModalState, setShowModalState] = useState(false);
  const [modalType, setModalType] = useState<string | null>(null);

  const handleModalOpen = (type: string) => {
    setModalType(type);
    setShowModalState(true);
  };

  const handleModalClose = () => {
    setModalType(null);
    setShowModalState(false);
  };

  const navigate = useNavigate();

  const handleCancel = (): void => {
    navigate(`../${generatePath(routes.orders.list.path)}`);
  };

  const renderModal = () => {
    const dataList = ordersStore.orders?.get(paramId)?.offer?.specifications?.map((item) => {
      const { value } = item;
      const getValues = (spec: string | null): string => {
        const { max, min } = (spec && JSON.parse(spec)) || {};

        return min && max ? `${min} - ${max}` : !min ? `≤ ${max}` : !max ? `≥ ${min}` : '';
      };

      const content = [
        item.specification?.description || `${getValues(value)} ${item.specification?.unitOfMeasurement?.unit}`,
      ];

      return { title: item.specification?.name || 'Ошибка данных', content };
    });

    return (
      <StyledModal
        show={showModalState}
        onHide={handleModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton />
        <ModalContent>
          <Flex column>
            {modalType === 'seller' ? (
              <>
                <Flex column vAlignContent="center" hAlignContent="center">
                  <Typography bold size="lg">
                    Продавец
                  </Typography>
                </Flex>
                <Spacer space={16} />
                <Flex column>
                  <Typography bold size="md">
                    {ordersStore.orders?.get(paramId)?.company?.nameOfProvider}
                  </Typography>
                  <Spacer space={16} />
                  <Flex column>
                    <GridModal>
                      <Typography size="sm" color="#918F88">
                        Система налогообложения
                      </Typography>
                      <Typography size="sm">
                        {getTaxTypeForm(ordersStore.orders?.get(paramId)?.company?.taxType)}
                      </Typography>
                    </GridModal>
                    <Spacer space={15} />
                    <GridModal>
                      <Typography size="sm" color="#918F88">
                        Юридический адрес
                      </Typography>
                      <Typography size="sm">{ordersStore.orders?.get(paramId)?.company?.ulAddress}</Typography>
                    </GridModal>
                    <Spacer space={15} />
                    <GridModal>
                      <Typography size="sm" color="#918F88">
                        Адрес отгрузки
                      </Typography>
                      <Typography size="sm">{ordersStore.orders?.get(paramId)?.company?.addressSending}</Typography>
                    </GridModal>
                    <Spacer space={15} />
                    <GridModal>
                      <Typography size="sm" color="#918F88">
                        ИНН
                      </Typography>
                      <Typography size="sm">{ordersStore.orders?.get(paramId)?.company?.inn}</Typography>
                    </GridModal>
                    <Spacer space={15} />
                    <GridModal>
                      <Typography size="sm" color="#918F88">
                        КПП
                      </Typography>
                      <Typography size="sm">{ordersStore.orders?.get(paramId)?.company?.kpp}</Typography>
                    </GridModal>
                    <Spacer space={15} />
                    <GridModal>
                      <Typography size="sm" color="#918F88">
                        ОГРН
                      </Typography>
                      <Typography size="sm">{ordersStore.orders?.get(paramId)?.company?.ogrn}</Typography>
                    </GridModal>
                    <Spacer space={15} />
                    <GridModal>
                      <Typography size="sm" color="#918F88">
                        Банк
                      </Typography>
                      <Typography size="sm">{ordersStore.orders?.get(paramId)?.company?.nameOfBank}</Typography>
                    </GridModal>
                    <Spacer space={15} />
                    <GridModal>
                      <Typography size="sm" color="#918F88">
                        Р/С
                      </Typography>
                      <Typography size="sm">{ordersStore.orders?.get(paramId)?.company?.paymentAccount}</Typography>
                    </GridModal>
                    <Spacer space={15} />
                    <GridModal>
                      <Typography size="sm" color="#918F88">
                        К/С
                      </Typography>
                      <Typography size="sm">
                        {ordersStore.orders?.get(paramId)?.company?.correspondentAccount}
                      </Typography>
                    </GridModal>
                    <Spacer space={15} />
                    <GridModal>
                      <Typography size="sm" color="#918F88">
                        БИК
                      </Typography>
                      <Typography size="sm">{ordersStore.orders?.get(paramId)?.company?.bik}</Typography>
                    </GridModal>
                    <Spacer space={15} />
                  </Flex>
                </Flex>
                <Spacer space={20} />
                <Typography size="md">Контакты</Typography>
                <Spacer space={25} />
                <Flex>
                  <StaffItems>
                    {ordersStore.orders?.get(paramId)?.company?.staff?.map((item: any) => {
                      const { fio, type, id, phoneNumber, emailOfHead: staffImail } = item || {};
                      return (
                        <React.Fragment key={id}>
                          <StaffItem>
                            <Typography bold size="md">
                              {fio}
                            </Typography>
                            <Spacer space={8} />
                            <Typography size="sm" color="#918F88">
                              {getStaffType(type)}
                            </Typography>
                            <Spacer space={8} />
                            <GridStaff>
                              <Typography size="sm">
                                <PhoneIcon /> {phoneNumber}
                              </Typography>
                              <Typography size="sm">
                                <MailIcon /> {staffImail}
                              </Typography>
                            </GridStaff>
                          </StaffItem>
                        </React.Fragment>
                      );
                    })}
                  </StaffItems>
                </Flex>
              </>
            ) : modalType === 'harvest' ? (
              <>
                <Flex column vAlignContent="center" hAlignContent="center">
                  <Typography bold size="lg">
                    Культура
                  </Typography>
                </Flex>
                <Spacer space={16} />
                <Flex column>
                  <Typography bold size="lg">
                    {getTrimText(product?.title ?? '')}
                    {/* {getTrimText(product?.title ?? '')}{product?.harvestYear && product?.harvestYear != '' && `, урожай ${product?.harvestYear}`} */}
                    {/* {getTrimText(`${product?.title} ${product?.harvestType}${product?.harvestYear && ", "} ${product?.harvestYear && formatDate(product?.harvestYear)}`)} */}
                  </Typography>
                  <Spacer />
                  {!!dataList?.length && (
                    <Table
                      data={[
                        {
                          title: <></>,
                          content: [],
                        },
                        ...dataList,
                      ]}
                    />
                  )}
                </Flex>
              </>
            ) : null}
            <Spacer space={24} />
            <StyledButtonClose variant="link" onClick={handleModalClose}>
              Закрыть
            </StyledButtonClose>
          </Flex>
        </ModalContent>
      </StyledModal>
    );
  };

  const getLeftVolume = () => {
    const acceptedVolume = ordersStore.orders?.get(paramId)?.acceptedVolume;
    const actualVolume = ordersStore.orders?.get(paramId)?.actualVolume;
    if (!!acceptedVolume && !!actualVolume) {
      return acceptedVolume - actualVolume;
    } else return ordersStore.orders?.get(paramId)?.acceptedVolume;
  };

  const tableHeader = [
    {
      title: 'Суммарный объем',
      value: `${ordersStore.orders?.get(paramId)?.acceptedVolume} т`,
    },
    {
      title: 'Погружено',
      value: `${ordersStore.orders?.get(paramId)?.actualVolume || 0} т`,
    },
    {
      title: 'Осталось отгрузить',
      value: `${getLeftVolume()} т`,
    },
  ];

  const tableData = [
    {
      title: 'Сделка',
      content: [
        <Grid key={Math.floor(Math.random() * 4000)}>
          <span>
            {ordersStore.orders?.get(paramId)?.costTotal &&
              formatMoney(ordersStore.orders?.get(paramId)?.costTotal ?? 0)}
          </span>
          <TaxPresence>без НДС</TaxPresence>
        </Grid>,
        <Grid key={Math.floor(Math.random() * 5000)}>
          {ordersStore.orders?.get(paramId)?.company?.taxType !== 'simple' && (
            <>
              <span>
                {ordersStore.orders?.get(paramId)?.costTotalWithNds &&
                  formatMoney(ordersStore.orders?.get(paramId)?.costTotalWithNds ?? 0)}
              </span>
              <TaxPresence>с НДС</TaxPresence>
            </>
          )}
        </Grid>,
      ],
    },
    {
      title: 'Культура',
      content: [
        <StyledButton
          key={Math.floor(Math.random() * (getTrimText(product?.title ?? '').length + 50))}
          variant="link"
          onClick={() => handleModalOpen('harvest')}
        >
          {getTrimText(product?.title ?? '')}
        </StyledButton>,
      ],
    },
    {
      title: 'Продавец',
      content: [
        <StyledButton
          key={Math.floor(
            Math.random() * (getTrimText(ordersStore.orders?.get(paramId)?.company?.nameOfProvider ?? '').length + 50),
          )}
          variant="link"
          onClick={() => handleModalOpen('seller')}
        >
          {ordersStore.orders?.get(paramId)?.company?.nameOfProvider}
        </StyledButton>,
      ],
    },
    {
      title: 'Система налогообложения',
      content: [getTaxTypeForm(ordersStore.orders?.get(paramId)?.company?.taxType)],
    },
    {
      title: 'Цена продавца, руб',
      content: [
        <Grid key={Math.floor(Math.random() * 4000)}>
          <span>
            {ordersStore.orders?.get(paramId)?.priceFarmer &&
              formatMoney(ordersStore.orders?.get(paramId)?.priceFarmer ?? 0)}
          </span>
          <TaxPresence>без НДС / CNEXW</TaxPresence>
        </Grid>,
        <Grid key={Math.floor(Math.random() * 4000)}>
          {ordersStore.orders?.get(paramId)?.company.taxType !== 'simple' && (
            <>
              <span>
                {ordersStore.orders?.get(paramId)?.priceFarmerWithNds &&
                  formatMoney(ordersStore.orders?.get(paramId)?.priceFarmerWithNds ?? 0)}
              </span>
              <TaxPresence>с НДС / CVEXW</TaxPresence>
            </>
          )}
        </Grid>,
      ],
    },
    {
      title: 'Расчетная цена за транспорт, руб',
      content: [
        <Grid key={Math.floor(Math.random() * 4000)}>
          <span>
            {ordersStore.orders?.get(paramId)?.priceTransport &&
              formatMoney(ordersStore.orders?.get(paramId)?.priceTransport ?? 0)}
          </span>
          <TaxPresence>без НДС / CNTP</TaxPresence>
        </Grid>,
      ],
    },
    {
      title: 'Цена покупателя, руб',
      content: [
        <Grid key={Math.floor(Math.random() * 400)}>
          <span>
            {ordersStore.orders?.get(paramId)?.priceBuyer &&
              formatMoney(ordersStore.orders?.get(paramId)?.priceBuyer ?? 0)}
          </span>
          <TaxPresence>без НДС / CNCPT</TaxPresence>
        </Grid>,
      ],
    },
    {
      title: 'Адрес вывоза',
      content: [ordersStore.orders?.get(paramId)?.selectedWarehouse?.address],
    },
    {
      title: 'Срок окончания контракта',
      content: [`${daysTillEnd} д.`],
    },
  ];

  const displayID = paramId.slice(-ID_DISPLAY_SYMBOLS);

  if (orderData.isFetching && !orderData.isFetched) return <Loader />;
  return (
    <Flex column>
      <BackButton variant="base" size="lg" onClick={handleCancel as () => void}>
        ❮ Назад
      </BackButton>

      <Spacer space={16} />
      <Heading size="lg2" bold>
        <Typography size="sm" color="#918F88">
          {ordersStore.orders?.get(paramId)?.dateStartOfContract &&
            formatDate(ordersStore.orders?.get(paramId)?.dateStartOfContract ?? '0')}
        </Typography>
        <Spacer space={8} />
        {`#${displayID} ${product?.title}`}
      </Heading>

      <OrderWrapper>
        <Table data={tableData} headerData={tableHeader} />
      </OrderWrapper>
      {renderModal()}
      <Spacer space={50} />
      <TimeLine />
    </Flex>
  );
};

export const StyledButton = styled(Button)`
  padding: 0;
  color: #407ef5;
  height: initial;
  text-align: left;
  line-height: 1.2;
`;

export const StyledButtonClose = styled(StyledButton)`
  text-align: center;
  margin-top: 20px;
`;

export const OrderWrapper = styled.div`
  max-width: 700px;
  padding: 44px;
`;

export const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 780px;
  }
  .modal-header {
    border: none;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }
  .close {
    color: #f9f6ed;
    text-shadow: none;
    opacity: 1;
    position: absolute;
    right: -20px;
    top: 0px;
  }
`;

export const ModalContent = styled.div`
  padding: 32px;
  background-color: #f9f6ed;
  border-radius: 10px;
`;

export const Heading = styled(Typography)`
  padding: 44px 0 0 44px;
`;

export const TaxPresence = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 100px 135px;
`;
const GridModal = styled.div`
  display: grid;
  grid-template-columns: 175px 1fr;
  gap: 15px;
`;
const GridStaff = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
  justify-content: flex-end;
`;
const StaffItems = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;
const StaffItem = styled.li`
  border: 0.5px solid #dbdbdb;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const BackButton = styled(Button)`
  width: 210px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #407ef5;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  margin-top: 33px;
  margin-left: 46px;
  color: #407ef5;
`;
