import React from 'react';
import styled from 'styled-components';
import { Flex } from 'uikit';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/Root';

export const SpecOrdersCounter: React.FC = observer(() => {
  const { specsOrdersStore } = useStore();

  const activeStatus = 'Активные';
  const closedStatus = 'Завершенные';
  const declinedStatus = 'Отменённые';

  return (
    <Flex gap={8}>
      <TabFilter
        isActive={'Активные' === specsOrdersStore.selectedStatus}
        onClick={() => {
          'Активные' !== specsOrdersStore.selectedStatus && specsOrdersStore.setSelectedStatus(activeStatus);
        }}
      >
        <span>{activeStatus}</span> (<span>{specsOrdersStore.specOrdersCounter?.open || 0}</span>)
      </TabFilter>
      <TabFilter
        isActive={'Завершенные' === specsOrdersStore.selectedStatus}
        onClick={() => {
          'Завершенные' !== specsOrdersStore.selectedStatus && specsOrdersStore.setSelectedStatus(closedStatus);
        }}
      >
        <span>{closedStatus}</span> (<span>{specsOrdersStore.specOrdersCounter?.closed || 0}</span>)
      </TabFilter>
      <TabFilter
        isActive={'Отменённые' === specsOrdersStore.selectedStatus}
        onClick={() => {
          'Отменённые' !== specsOrdersStore.selectedStatus && specsOrdersStore.setSelectedStatus(declinedStatus);
        }}
      >
        <span>{declinedStatus}</span> (<span>{specsOrdersStore.specOrdersCounter?.declined || 0}</span>)
      </TabFilter>
    </Flex>
  );
});

const TabFilter = styled.div<{ isActive?: boolean; isDisabled?: boolean }>`
  padding: 10px 16px;
  font-size: 17px;
  border-radius: 12px;
  background-color: ${({ isActive }) => (isActive ? '#8E66FE' : 'transparent')};
  color: ${({ isActive }) => (isActive ? '#FFF' : '#292929')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'progress' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'initial')};
`;
