import React from 'react';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from './components';
import { RootRoutes } from './routes';
import { Layout } from './components/Layout/Layout';
import '@fontsource/rubik';
import { Normalize } from 'styled-normalize';
import { GlobalStyle } from './globalStyles';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Loader } from './uikit/Loader';
import { NotificationsProvider, ModalsProvider } from './popups';
import { Provider, rootStore } from './store/Root';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      structuralSharing: false,
      getNextPageParam(response) {
        if (response) {
          // @ts-ignore
          return response?.length;
        }

        return null;
      },
    },
  },
});

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={true} position={'top-left'} /> */}
      <Provider value={rootStore}>
        <ErrorBoundary>
          <GlobalStyle />
          <Normalize />
          {!!rootStore ? (
            <BrowserRouter>
              <ModalsProvider>
                <NotificationsProvider>
                  <Layout>
                    <RootRoutes />
                  </Layout>
                </NotificationsProvider>
              </ModalsProvider>
            </BrowserRouter>
          ) : (
            <Loader />
          )}
        </ErrorBoundary>
      </Provider>
    </QueryClientProvider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);
