import React, { useState } from 'react';
import { Button } from 'uikit';
import styled from 'styled-components';
import { getUnixTime } from 'date-fns';
import { useId } from 'react';
import ts from 'typescript';

interface IChatInput {
  sendMessage: (message: string, time: string) => void;
  isDisabled?: boolean;
}

export const ChatInput: React.FC<IChatInput> = ({ sendMessage, isDisabled }) => {
  const id = useId();
  const [message, setMessage] = useState('');

  const handleMesssageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setMessage(value);
  };

  const submitMessage = () => {
    const time = getUnixTime(Date.now()).toString();
    sendMessage(message, time);
    setMessage('');
  };

  const enterSubmit = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.shiftKey == false) {
      submitMessage();
      e.preventDefault();
      (e.currentTarget as HTMLElement).blur();

      setTimeout(() => {
        (e.currentTarget as HTMLElement).focus();
      }, 100);
    }
  };

  return (
    <Wrapper
      onSubmit={(e) => {
        e.preventDefault();
        submitMessage();
      }}
    >
      <InputArea
        placeholder={!isDisabled ? 'Введите сообщение...' : 'Чат закрыт'}
        onKeyDown={enterSubmit}
        id={id}
        disabled={isDisabled}
        value={message}
        onChange={handleMesssageChange}
        name="message"
      />
      {!isDisabled && (
        <SubmitButton variant="base" type="submit">
          Отправить
        </SubmitButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.form`
  position: relative;
  padding: 7px 20px;
  gap: 5px;
  display: flex;
  border-radius: 5px;
  flex-flow: row nowrap;
  max-width: 100%;
  align-items: center;
  max-width: 600px;
`;
// const CounterOfferProposal = styled(Button)`
//   top: -69px;
//   right: 10px;
//   width: auto;
//   position: absolute;
//   padding: 0 20px;
//   font-weight: bold;
//   color: #555;
//   background-color: #fff;
//   &:hover {
//     background-color: #f2f2f2;
//     color: rgb(23, 114, 211);
//   }
// `;
const InputArea = styled.textarea`
  font-size: 16px;
  resize: none;
  flex: 1;
  height: 50px;
  padding: 17px 20px 12px;
  border-radius: 4px;
  background-color: #f2efe5;
  border: 1px solid #e7e2d1;

  &::placeholder {
    color: rgba(25, 25, 25, 0.5);
  }
`;
const SubmitButton = styled(Button)`
  height: 32px;
  width: 90px;
`;
