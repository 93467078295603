import React, { useState } from 'react';
import { StepItems, Item, ButtonLoadDoc, ItemWrapper, ItemTitle } from 'components/Orders/components/TimeLine/styles';
import { Flex } from '../index';
import styled from 'styled-components';

interface IActionValue {
  value: any;
  actionValueRef: any;
  onChange: (e: any) => void;
  onClickSend: () => void;
}

export const ActionValue: React.FC<IActionValue> = ({ value, actionValueRef, onChange, onClickSend }) => {
  const { title, init_value } = JSON.parse(value) || {};
  const [inputValue, setInputValue] = React.useState(init_value);
  const handleChange = (e: any) => {
    setInputValue(e.target.value);
    onChange(e.target.value);
  };
  return (
    <>
      <StepItems>
        <Item>
          <ItemWrapper style={{ marginBottom: '10px' }}>
            <ItemTitle>{title}</ItemTitle>
            <form onSubmit={(e: any) => e.preventDefault()}>
              <StyledInput
                ref={actionValueRef}
                type="value"
                name="actionValue"
                value={inputValue}
                onChange={handleChange}
                placeholder="Введите данные"
              />
            </form>
          </ItemWrapper>
          <Flex style={{ marginTop: '21px', gap: '30px' }}>
            <ButtonLoadDoc variant="link" type="button" onClick={onClickSend}>
              Отправить
            </ButtonLoadDoc>
          </Flex>
        </Item>
      </StepItems>
    </>
  );
};

const StyledInput = styled.input`
  text-align: left;
  padding-left: 20px;
  margin-right: 10px;
  width: 400px;
  height: 50px;
  border: 0px;
  border-radius: 10px;
  outline: none;
  background-color: #f5f2ea;
  color: #333333;
  box-shadow: 0 0 0 30px #f5f2ea inset !important;
  -webkit-box-shadow: 0 0 0 30px #f5f2ea inset !important;
`;
