import { castArray, mergeWith } from 'lodash-es';
import { flow, types, getRoot, detach, Instance } from 'mobx-state-tree';
import { isPrimitive } from '../utils/utils';
import { daylesfordService } from '../services';
import { IRootStore } from './Root';
import { ISaleRequest, ISaleRequestBidRequest } from 'services/models';

const {
  identifier,
  identifierNumber,
  boolean,
  model,
  map,
  string,
  number,
  array,
  maybeNull,
  maybe,
  optional,
  enumeration,
  union,
} = types;

export const IndicatorsSpecs = model('IndicatorsSpecs', {
  id: identifierNumber,
  name: string,
  value: maybe(string),
  basisValue: maybe(string),
  limitValue: maybe(string),
  measure: maybe(string),
  withdrawalValue: maybe(string),
  worstDirection: maybe(string),
  maxValue: maybe(number),
  minValue: maybe(number),
  step: maybe(string),
  editable: maybe(boolean),
  position: maybe(number),
});
// IIndicatorsSpecs

export const EditableIndicatorSpecs = model('EditableIndicatorSpecs', {
  id: identifierNumber,
  name: string,
  value: union(maybe(string), maybe(number)),
  basisValue: union(maybe(string), maybe(number)),
  limitValue: union(maybe(string), maybe(number)),
  measure: maybe(string),
  withdrawalValue: union(maybe(string), maybe(number)),
  worstDirection: maybe(string),
  maxValue: maybe(number),
  minValue: maybe(number),
  step: maybe(string),
  editable: maybe(boolean),
  position: maybe(number),
});

export interface IIndicatorsSpecs extends Instance<typeof IndicatorsSpecs> {}

const BuyerWarehouse = model('BuyerWarehouse', {
  id: identifier,
  address: string,
  city: string,
  latCoord: string,
  longCoord: string,
  loadingNorm: number,
  title: string,
});
const InquiryBid = model('InquiryBid', {
  createdAt: string,
  price: string,
  user: string,
  owner: maybe(string),
  id: maybe(string),
  saleRequest: maybe(string),
  startDate: maybe(string),
  endDate: maybe(string),
  warehouse: maybeNull(string),
  warehouseAddress: maybe(string),
  indicators: maybeNull(array(IndicatorsSpecs)),
});
const SpecBuyer = model('SpecBuyer', {
  blocked: boolean,
  docFoundation: maybe(string),
  email: string,
  firstName: string,
  id: string,
  lastName: string,
  phone: string,
  verified: maybe(boolean),
});
const Inquiry = model('Inquiry', {
  id: identifier,
  culture: string,
  cultureId: number,
  subName: maybe(string),
  basis: string,
  volume: string,
  price: string,
  status: enumeration(['open', 'accepted', 'declined']),
  comment: maybe(string),
  reason: maybe(string),
  electronicSignature: maybe(boolean),
  user: string,
  buyers: maybeNull(array(SpecBuyer)),
  fileQuality: maybeNull(string),
  order: maybeNull(string),
  smartOrder: maybeNull(string),
  warehouse: string,
  warehouseName: string,
  warehouseAddress: string,
  createdAt: string,
  startDate: string,
  endDate: string,
  providerName: string,
  providerTaxType: string,
  bids: maybeNull(array(InquiryBid)),
  indicators: maybe(array(IndicatorsSpecs)),
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .actions((self) => ({
    // @ts-ignore
    processBid(data): any {
      const nameOfMyCompany = self.root.authStore.user?.company.nameOfProvider;
      data.user = nameOfMyCompany;
      if (self.warehouse !== data.warehouse) {
        self.warehouse = data.warehouse;
      }
      if (self.warehouseAddress !== data.warehouseAddress) {
        self.warehouse = data.warehouse;
      }
      if (self.startDate !== data.startDate || self.endDate !== data.endDate) {
        self.startDate = data.startDate;
        self.endDate = data.endDate;
      }
      self.bids?.push(data);
    },
  }));

export const InquiriesStore = model('InquiriesStore', {
  isLoading: false,
  hasNextPage: true,
  pageNumber: 1,
  inquiries: map(Inquiry),
  selectedStatus: optional(enumeration(['open', 'accepted', 'declined']), 'open'),
  buyerWarehouses: map(BuyerWarehouse),
  inquiriesCounter: maybeNull(
    model({
      open: number,
      accepted: number,
      declined: number,
      total: number,
    }),
  ),
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .views((self) => ({
    get inquiriesList() {
      return Array.from(self.inquiries.values()).flat();
    },
  }))
  .views((self) => ({
    get filteredList(): any {
      return self.inquiriesList;
    },
  }))
  .views((self) => ({
    get warehousesList() {
      return self.buyerWarehouses ? Array.from(self.buyerWarehouses.values()).flat() : [];
    },
  }))
  .views((self) => ({
    get hasWarehouses(): boolean {
      return !!self.warehousesList.length;
    },
  }))
  .actions((self) => {
    return {
      resetFetchedLists() {
        self.inquiries.clear();
      },
    };
  })
  .actions((self) => {
    return {
      setSelectedCategory(status: 'Открытые' | 'Принятые' | 'Отклоненные') {
        self.resetFetchedLists();
        switch (status) {
          case 'Открытые':
            return (self.selectedStatus = 'open');
          case 'Принятые':
            return (self.selectedStatus = 'accepted');
          case 'Отклоненные':
            return (self.selectedStatus = 'declined');

          default:
            return (self.selectedStatus = 'open');
        }
      },
    };
  })
  .actions((self) => {
    return {
      setHasNextPage(value: boolean) {
        self.hasNextPage = value;
      },
    };
  })
  .actions((self) => {
    return {
      setPageNumber(value: number) {
        self.pageNumber = value;
      },
    };
  })
  .actions((self) => ({
    process(data: any): any {
      const dataList = castArray(data);

      const mapped = dataList.map((inquiry) => {
        inquiry.id = inquiry.id.toString();

        if (isPrimitive(inquiry)) {
          return inquiry;
        }
        const existing = self.inquiries?.get(inquiry.id);
        return existing
          ? mergeWith(existing, inquiry, (_, next: any) => {
              if (Array.isArray(next)) return next;
              return;
            })
          : self.inquiries.put(inquiry);
      });
      return Array.isArray(data) ? mapped : mapped[0];
    },
  }))
  .actions((self) => ({
    processBuyerWarehouses(data: any): any {
      const dataList = castArray(data);
      const mapped = dataList.map((warehouse) => {
        warehouse.id = warehouse.id.toString();
        if (isPrimitive(warehouse)) {
          return warehouse;
        }
        const existing = self.buyerWarehouses?.get(warehouse.id);
        return existing
          ? mergeWith(existing, warehouse, (_, next: any) => {
              if (Array.isArray(next)) return next;
              return;
            })
          : self.buyerWarehouses?.put(warehouse);
      });
      return Array.isArray(data) ? mapped : mapped[0];
    },
  }))
  .actions((self) => {
    return {
      getInquiries: flow(function* getInquiries(): any {
        self.isLoading = true;
        detach(self.inquiries);
        const inquiries = yield daylesfordService
          .getSaleRequests({ status: self.selectedStatus as 'open' | 'accepted' | 'declined' })
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: список заявок`, 'error'));
        self.setHasNextPage((inquiries.data?.next ?? null) != null);
        self.isLoading = false;

        return self.process(inquiries.data);
      }),
    };
  })
  .actions((self) => {
    return {
      getInquiry: flow(function* getInquiry(id: string): any {
        self.isLoading = true;
        const inquiry = yield daylesfordService
          .getSaleRequest(id)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: заявка`, 'error'));
        self.isLoading = false;
        return self.process(inquiry.data);
      }),
    };
  })
  .actions((self) => {
    return {
      sendInquiryCounterOffer: flow(function* getInquiry(request: ISaleRequestBidRequest, cultureId?: string): any {
        self.isLoading = true;
        const inquiryBid = yield daylesfordService
          .sendSaleRequestCounterOffer(request)
          .catch((e) => self.root.alertsStore.addNotification(`${e.response.data.error}`, 'error'));
        self.isLoading = false;
        const inq = self.inquiries.get(inquiryBid.data.saleRequest);

        return inq?.processBid(inquiryBid.data);
      }),
    };
  })
  .actions((self) => {
    return {
      updateInquiry: flow(function* updateInquiry(request: ISaleRequest): any {
        self.isLoading = true;
        const inquiry = yield daylesfordService
          .editSaleRequest(request)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: данные о заявке`, 'error'));
        self.isLoading = false;
        self.root.alertsStore.addNotification(`${inquiry.data.culture}: статус заявки изменен`, 'success');
        self.resetFetchedLists();
        return self.process(inquiry.data);
      }),
    };
  })
  .actions((self) => {
    return {
      acceptInquiry(id: string, electronicSignature: boolean) {
        const inquiry = self.inquiries.get(id);
        if (inquiry) {
          inquiry.status = 'accepted';
          inquiry.electronicSignature = electronicSignature;
          self.updateInquiry(inquiry as ISaleRequest);
        }
      },
    };
  })

  .actions((self) => {
    return {
      declineInquiry(id: string, reason?: string) {
        const inquiry = self.inquiries.get(id);
        if (inquiry) {
          inquiry.status = 'declined';
          inquiry.reason = reason;
          self.updateInquiry(inquiry as ISaleRequest);
        }
      },
    };
  })

  .actions((self) => {
    return {
      getBuyerWarehouses: flow(function* getBuyerWarehouses(): any {
        const warehouses = yield daylesfordService
          .getBuyerWarehouses()
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Склады закупщика`, 'error'));
        return self.processBuyerWarehouses(warehouses.data);
      }),
    };
  })
  .actions((self) => {
    return {
      getCounter: flow(function* getCounter(): any {
        const inquiriesCounter = yield daylesfordService
          .getSaleRequestsCounter()
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Счетчик предложений`, 'error'));

        self.inquiriesCounter = {
          open: inquiriesCounter.data.open,
          accepted: inquiriesCounter.data.accepted,
          declined: inquiriesCounter.data.declined,
          total: inquiriesCounter.data.total,
        };

        return inquiriesCounter;
      }),
    };
  });
