import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, Flex, Spacer, Typography } from 'uikit';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/Root';
import { StyledSearchInput } from 'components/Farmer/pages/FarmersListPage';
import { Check } from 'react-feather';
import { Select } from 'uikit/Selects';
import { blockUserOptions, edmStatusUserOptions, verifiedUserOptions } from 'utils/consts';

export const DashboardUserFilters: React.FC = observer(() => {
  const { contactsDashboardStore } = useStore();

  const searchInput = contactsDashboardStore.searchInput;

  //    combobox with filters user statuses and search input
  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('search');
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    contactsDashboardStore.setSearchInput(event.target.value);
  };

  const blockListOptions = blockUserOptions.map(({ label, value }) => ({ label, value }));
  const verifiedListOptions = verifiedUserOptions.map(({ label, value }) => ({ label, value }));
  const edmStatusListOptions = edmStatusUserOptions.map(({ label, value }) => ({ label, value }));

  const handleBlockListChange = (value: { label: string; value: 'null' | 'true' | 'false' }) => {
    contactsDashboardStore.setUserStatusIsBlocked(value.value);
  };
  const handleVerifiedListChange = (value: { label: string; value: 'null' | 'true' | 'false' }) => {
    contactsDashboardStore.setUserStatusIsVerified(value.value);
  };
  const handleEDMStatusListChange = (value: { label: string; value: 'null' | 'true' | 'false' }) => {
    contactsDashboardStore.setUserStatusIsEDMReleased(value.value);
  };

  return (
    <div>
      <Flex gap={20}>
        <StyledSearchInputWrapper>
          <StyledSearchInput
            type="value"
            name="search"
            value={searchInput || ''}
            onChange={handleSearchInputChange}
            placeholder="Введите данные для поиска"
          />
          {!!searchInput && (
            <StyledSearchInputClearInputCross onClick={() => contactsDashboardStore.setSearchInput('')} />
          )}
        </StyledSearchInputWrapper>

        {/* <CheckButton variant="icon" onClick={contactsDashboardStore.toggleUserStatusIsBlocked}>
          {contactsDashboardStore.userStatusIsBlocked && <Check />}
          
        </CheckButton>
        <CheckButton variant="icon" onClick={contactsDashboardStore.toggleUserStatusIsVerified}>
          {contactsDashboardStore.userStatusIsVerified && <Check />}
          
        </CheckButton>
        <CheckButton variant="icon" onClick={contactsDashboardStore.toggleUserStatusIsEDMReleased}>
          {contactsDashboardStore.userStatusIsEDMReleased && <Check />}
          
        </CheckButton> */}
      </Flex>
      <Spacer space={20} />
      <Flex wrap gap={10}>
        <SelectWrapper>
          <Typography size="xs">Заблокированные</Typography>
          <Select placeholder="Не выбрано" options={blockListOptions} onChange={handleBlockListChange} />
        </SelectWrapper>
        <SelectWrapper>
          <Typography size="xs">Верифицированные</Typography>
          <Select options={verifiedListOptions} onChange={handleVerifiedListChange} />
        </SelectWrapper>
        <SelectWrapper>
          <Typography size="xs">Мобильная ЭЦП выпущена</Typography>
          <Select options={edmStatusListOptions} onChange={handleEDMStatusListChange} />
        </SelectWrapper>
      </Flex>
      <Spacer space={20} />
      <Flex gap="20px">
        <Button variant="base" type="button" onClick={() => contactsDashboardStore.fetchWithReset()}>
          Поиск
        </Button>
        <Button variant="baseRed" type="button" onClick={() => contactsDashboardStore.resetFilters()}>
          Сбросить фильтры
        </Button>
      </Flex>
    </div>
  );
});
const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const CheckButton = styled(Button)`
  position: relative;
  background: none;
  border: none;
  padding-left: 35px;
  border-radius: 0;
  flex-basis: fit-content;

  &::before {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 11px;
    left: 0;
    content: '';
    border: 1px solid #222;
    transition: all 0.3s ease-in-out;
    b {
      transition: all 0.3s ease-in-out;
    }
  }
  &:hover {
    b {
      color: #407ef5;
      transition: color 0.3s ease-in-out;
    }
    &::before {
      border-color: #407ef5;
      transition: border-color 0.3s ease-in-out;
    }
  }
  svg {
    position: absolute;
    top: 13px;
    left: 1px;
  }
`;

const StyledSearchInputWrapper = styled.div`
  position: relative;
`;

const StyledSearchInputClearInputCross = styled.button`
  position: absolute;
  top: 0;
  right: 10px;
  width: 35px;
  height: 50px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    top: 40%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 2px;
    height: 15px;
    background: #222;
    transform: rotate(45deg);
    transition: background 0.3s ease-in-out;
  }
  &:after {
    content: '';
    position: absolute;
    top: 40%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 2px;
    height: 15px;
    background: #222;
    transform: rotate(-45deg);
    transition: background 0.3s ease-in-out;
  }
  &:hover {
    background: #f2f2f2;
    &:before,
    &:after {
      background: #407ef5;
      transition: background 0.3s ease-in-out;
    }
  }
`;
