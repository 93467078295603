import { types } from 'mobx-state-tree';

const { model, array, enumeration, string, optional } = types;

const Notification = model('Notification', {
  text: string,
  type: optional(enumeration(['success', 'error', 'notification']), 'notification'),
});
const Modal = model('Notification', {
  text: string,
  type: optional(enumeration(['success', 'error', 'info']), 'info'),
});

export const AlertsStore = model('Notifications', {
  notifications: array(Notification),
  modals: array(Modal),
})
  .actions((self) => {
    return {
      addNotification(text: string, type: 'success' | 'error' | 'notification' = 'notification') {
        self.notifications.push({ text, type });
      },
    };
  })
  // add notification if page doesn't include id in url
  .actions((self) => {
    return {
      addNotificationOutOfPage(text: string, type: 'success' | 'error' | 'notification' = 'notification', id: string) {
        if (!window.location.href.includes(id)) {
          self.notifications.push({ text, type });
        }
      },
    };
  })

  .actions((self) => {
    return {
      addModal(text: string, type: 'success' | 'error' | 'info' = 'info') {
        self.modals.push({ text, type });
      },
    };
  });
