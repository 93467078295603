import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { FormikField } from '../Field';
import { Input } from '../Input';
import { Flex, Spacer, Typography, Button } from 'uikit';
import Modal from 'react-bootstrap/esm/Modal';

interface IModal {
  isActive: any;
  setShowModalState: any;
  headText: string;
  handleSubmitForm: any;
  tottalValue: string;
}

export const ModalTottalValume: React.FC<IModal> = ({
  isActive,
  setShowModalState,
  headText,
  handleSubmitForm,
  tottalValue,
}) => {
  const handleModalClose = () => setShowModalState(false);

  const initialValues = useMemo(() => {
    return {
      tottalValue: tottalValue,
    };
  }, [tottalValue]);

  return (
    <StyledModal
      show={isActive}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton />
      <ModalContent>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmitForm}
        >
          <Form>
            <FlexStyled column>
              {isActive && (
                <>
                  <FlexStyled
                    column
                    vAlignContent="center"
                    hAlignContent="center"
                  >
                    <Typography
                      bold
                      size="lg"
                    >{`Итог погрузки ${headText}`}</Typography>
                    <Spacer space={10} />
                    <Flex>
                      <Typography size="sm">{`За день погружено:`}</Typography>
                      <Spacer width={8} />
                      <Typography
                        size="sm"
                        color="#8E66FE"
                      >{`${tottalValue} т`}</Typography>
                    </Flex>
                    <Typography size="sm">
                      Для изменения - введите данные ниже:
                    </Typography>
                    <Spacer space={22} />
                    <Flex column>
                      <FormikField
                        name="tottalValue"
                        title="Итого погружено, т"
                      >
                        <Input
                          name="tottalValue"
                          variant="light"
                          type="number"
                          size="modal"
                          value={initialValues.tottalValue}
                        />
                      </FormikField>
                    </Flex>
                    <Spacer space={22} />
                    <Button variant="base" size="md2" type="submit">
                      Сохранить
                    </Button>
                  </FlexStyled>
                </>
              )}
              <Spacer space={24} />
              <StyledButton variant="link" onClick={handleModalClose}>
                Отмена
              </StyledButton>
            </FlexStyled>
          </Form>
        </Formik>
      </ModalContent>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 550px;
  }
  .modal-header {
    border: none;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }
  .close {
    color: #f9f6ed;
    text-shadow: none;
    opacity: 1;
    position: absolute;
    right: -20px;
    top: 0px;
  }
`;

const ModalContent = styled.div`
  padding: 32px;
  background-color: #f9f6ed;
  border-radius: 10px;
`;

const StyledButton = styled(Button)`
  padding: 0;
  color: #407ef5;
  height: initial;
`;

const FlexStyled = styled(Flex)`
  max-width: 260px;
  margin: 0 auto;
  letter-spacing: -0.5px;
`;
