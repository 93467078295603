import axios from 'axios';
import { getLocalAccessToken } from './token-service';

const host = process.env.NODE_ENV !== 'production' ? '' : process.env.REACT_APP_BACKEND_HOST;

const axiosInstance = axios.create({
  baseURL: `${host}`,
  timeout: 8000,
});

axiosInstance.interceptors.request.use((config) => {
  const token: string | null = getLocalAccessToken();
  if (token) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers['authorization'] = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  const { data } = response;
  if (data instanceof Blob) {
    return response;
  } else {
    return data;
  }
});

export const downloadFile = async (url: string, fileName: string) => {
  const response = await axiosInstance.get(url, {
    responseType: 'blob',
  });

  // @ts-ignore
  const blob = new Blob([response.data], { type: response.type });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.target = '_blank';

  link.click();
  window.URL.revokeObjectURL(link.href);
};
