import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { Flex, Spacer } from 'uikit';
import { CloseIcon } from 'uikit/Icons/CloseIcon';
import { InquiryHeader } from 'components/Inquiries/pages/InquiryPage';

interface IProps {
  showModalState: boolean;
  handleModalClose: () => void;
  handleReSign: () => void;
}

export const SpecOrderReSignModal: React.FC<IProps> = ({ handleReSign, showModalState, handleModalClose }) => {
  return (
    <StyledModal
      show={showModalState}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeaderWrapper>
        <Modal.Header closeButton />
        <StyledCloseIcon>
          <CloseIcon />
        </StyledCloseIcon>
      </ModalHeaderWrapper>
      <ModalContent>
        <Flex column>
          <InquiryHeader>Вы уверены, что хотите изменить и переподписать сделку?</InquiryHeader>

          <ButtonsWrapper>
            <CounterOfferButton type="button" onClick={handleReSign}>
              Да, внести изменения
            </CounterOfferButton>
            <DiscardChangesButton type="button" onClick={handleModalClose}>
              Отмена
            </DiscardChangesButton>
          </ButtonsWrapper>
          <Spacer space={20} />
        </Flex>
      </ModalContent>
    </StyledModal>
  );
};

export const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 534px;
  }
  .modal-header {
    border: none;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }
  .btn-close {
    background: none;
    width: 40px;
    height: 40px;
    position: absolute;
    right: -11px;
    top: -8px;
    z-index: 1;
  }
`;

export const ModalHeaderWrapper = styled.div`
  position: relative;
`;

export const StyledCloseIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  cursor: pointer;
`;

export const ModalContent = styled.div`
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 10px;
  max-width: 534px;
`;

export const CounterOfferButton = styled.button`
  background-color: #8e66fe;
  border-radius: 12px;
  border: 1px solid #825dec;
  padding: 18px 0px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
`;

export const DiscardChangesButton = styled.button`
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #825dec;
  padding: 18px 0px;
  color: #8e66fe;
  font-weight: 600;
  font-size: 18px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  margin-top: 16px;
`;
