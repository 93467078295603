import axios from 'axios';
import { apiNames } from '../config/AppConfig';
import { getLocalAccessToken, getLocalRefreshToken, logout, setTokens } from './token-service';

const SESSION_EXPIRED_STATUS_CODE = 401;

const host = process.env.NODE_ENV !== 'production' ? '' : process.env.REACT_APP_BACKEND_HOST;

let isRefreshing = false;
const refreshSubscribers: any[] = [];

export const apiInstance = axios.create({
  baseURL: `${host}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const refreshAccessToken = async () => {
  const rs = await apiInstance.post(apiNames.refreshToken, {
    refresh: getLocalRefreshToken(),
  });
  const { access, refresh } = rs.data;
  setTokens({ token: access, refreshToken: refresh });
  return access;
};

apiInstance.interceptors.request.use(
  (config) => {
    const accessToken = getLocalAccessToken();
    if (accessToken) {
      config.headers!['Authorization'] = 'Bearer ' + accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === SESSION_EXPIRED_STATUS_CODE) {
      if (!isRefreshing) {
        isRefreshing = true;
        refreshAccessToken().then((newToken) => {
          isRefreshing = false;
          onRrefreshed(newToken);
        });
      }

      if (isRefreshing && config.url === apiNames.refreshToken) {
        logout();
      }

      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh((token: string) => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    } else {
      return Promise.reject(error);
    }
  },
);

function subscribeTokenRefresh(cb: any) {
  refreshSubscribers.push(cb);
}

function onRrefreshed(token: any) {
  refreshSubscribers.map((cb) => cb(token));
}
