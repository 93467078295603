import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Formik, Form } from 'formik';

import { EMPTY_CHAR } from 'utils/consts';
import { Button, Flex, Spacer, Typography } from 'uikit';
import styled from 'styled-components';
import { useStore } from 'store/Root';
import { useQuery } from 'react-query';

import { Link, useSearchParams } from 'react-router-dom';
import PasswordChecklist from 'react-password-checklist';
import { Loader } from 'uikit/Loader';

export const PassRecoveryPage: React.FC = observer(() => {
  const { authStore } = useStore();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const [isValid, setIsValid] = useState(false);

  const token = searchParams.get('token') || '';
  const email = searchParams.get('email') || '';

  const options = {
    token,
    email,
    password,
    passwordTwo,
  };

  const emailRecoveryData = useQuery<any>('RECOVERY_DATA_QUERY_KEY', () => authStore.passwordReset(options), {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
  });

  const handleSubmit = () => {
    emailRecoveryData.refetch();
  };
  const handlePassCheck = (valid: boolean) => {
    setIsValid(valid);
  };
  const handlePasswordChange = (e: any) => {
    e.preventDefault();
    setPassword(e.target.value);
  };
  const handlePasswordTwoChange = (e: any) => {
    e.preventDefault();
    setPasswordTwo(e.target.value);
  };

  return (
    <>
      <Spacer space={180} />
      <Flex hAlignContent="center">
        <AuthInnerWrapper>
          {!emailRecoveryData.isFetching ? (
            <>
              <Flex column hAlignContent="center">
                {authStore.status === 'success' ? (
                  <>
                    <Typography size="lg">Ваш пароль был успешно изменен</Typography>
                    <Spacer space={32} />
                    <Link to="/">Вернуться к авторизации</Link>
                  </>
                ) : (
                  <>
                    <Typography size="lg">Создайте новый пароль для вашего аккаунта</Typography>
                    <Spacer space={32} />
                    <Formik
                      initialValues={{
                        password: EMPTY_CHAR,
                      }}
                      onSubmit={handleSubmit}
                    >
                      <Form>
                        <Flex column>
                          <StyledInput
                            type="password"
                            name="password"
                            placeholder="Пароль"
                            value={password}
                            onChange={handlePasswordChange}
                          />
                          <StyledInput
                            type="password"
                            name="passwordTwo"
                            placeholder="Повторите пароль"
                            value={passwordTwo}
                            onChange={handlePasswordTwoChange}
                          />
                          <Spacer space={30} />
                          <Button variant={!isValid ? 'baseRed' : 'actionGreen'} type="submit" disabled={!isValid}>
                            Сохранить
                          </Button>
                          <Spacer />
                          <PasswordChecklist
                            rules={['minLength', 'specialChar', 'number', 'match']}
                            minLength={8}
                            value={password}
                            valueAgain={passwordTwo}
                            onChange={handlePassCheck}
                            messages={{
                              minLength: 'Пароль длинее 8 символов',
                              specialChar: 'Пароль содержит специальные символы',
                              number: 'Пароль содержит числа',
                              capital: 'Пароль содержит заглавную букву',
                              match: 'Пароли совпадают',
                            }}
                          />
                        </Flex>
                      </Form>
                    </Formik>
                  </>
                )}
              </Flex>
            </>
          ) : (
            <Flex column>
              <Loader />
            </Flex>
          )}
        </AuthInnerWrapper>
      </Flex>
    </>
  );
});

const AuthInnerWrapper = styled.div`
  width: 445px;
`;
const StyledInput = styled.input`
  text-align: left;
  padding-left: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 400px;
  height: 50px;
  border: 0px;
  border-radius: 10px;
  outline: none;
  background-color: #e7e2d4;
  color: #333333;
  box-shadow: 0 0 0 30px #f5f2ea inset !important;
`;
