import { castArray, mergeWith, isEqual } from 'lodash-es';
import { flow, types, getRoot } from 'mobx-state-tree';
import { isPrimitive } from '../utils/utils';
import { daylesfordService } from '../services';
import { IRootStore } from './Root';
import { TimelineStore } from './TimelineStore';
import { IReqOrders } from 'services/models';

const { identifier, boolean, model, map, enumeration, string, number, maybeNull, maybe, optional, array, reference } =
  types;

const ProductSpecs = model('ProductSpecs', {
  id: identifier,
  unitOfMeasurement: maybe(
    model({
      id: number,
      unit: string,
    }),
  ),
  name: maybe(string),
  required: maybe(boolean),
  type: maybe(string),
  description: maybeNull(string),
  GOST: maybeNull(string),
  isAsIs: maybe(boolean),
  spec: maybe(string),
});

const OrderSpecs = model('OrderSpecs', {
  id: identifier,
  specification: maybe(ProductSpecs),
  value: maybeNull(string),
});

const Product = model('Product', {
  id: identifier,
  specification: maybe(ProductSpecs),
  title: string,
  description: maybeNull(string),
  harvestYear: string,
  harvestType: maybeNull(string),
  culture: string,
});
const Warehouse = model('Warehouse', {
  id: identifier,
  title: string,
  address: string,
  owner: maybe(number), // ??
  longCoord: string,
  latCoord: string,
});

const Company = model('Company', {
  addressSending: string,
  basisOfDoc: string,
  bik: string,
  correspondentAccount: string,
  emailOfHead: string,
  headOfProvider: string,
  id: identifier,
  inn: string,
  kpp: string,
  mailAddress: string,
  nameOfBank: string,
  nameOfProvider: string,
  ogrn: string,
  paymentAccount: string,
  phoneNumber: string,
  positionHeadOfProvider: string,
  shortFio: string,
  ulAddress: string,
  staff: array(
    model({
      type: string,
      fio: string,
      phoneNumber: string,
      emailOfHead: string,
    }),
  ),
  taxType: string,
});

const Order = model('Order', {
  id: identifier,
  title: string,
  costWithNds: maybe(number),
  cost: maybe(number),
  periodOfExport: number,
  costByTonne: maybe(number),
  company: Company,
  offer: model({
    id: string,
    costWithNds: maybe(number),
    periodOfExport: number,
    product: Product,
    specifications: maybe(array(OrderSpecs)),
    warehouse: Warehouse,
    daysTillEnd: number,
    title: string,
    volume: maybe(number),
    description: string,
    status: string,
    createdAt: string,
    dateStartShipment: maybe(string),
    dateFinishShipment: maybe(string),
    cost: maybe(number),
    taxType: maybe(string),
    creator: maybe(string),
    totalVolume: number,
    currentVolume: number,
    freeVolume: maybe(number),
  }),
  taxType: maybe(string),
  providerName: maybe(string),
  farmerCostWithNds: maybe(number),
  totalWithNds: maybe(number),
  priceForDelivery: maybe(number),
  amountOfNds: number,
  status: string,
  acceptedVolume: maybe(number),
  actualVolume: maybe(number),
  nameOfContract: string,
  numberOfSpec: string,
  dateStartOfSpec: maybeNull(string),
  dateFinishOfSpec: maybeNull(string),
  dateStartOfContract: maybeNull(string),
  dateFinishOfContract: maybeNull(string),
  dateStartShipment: maybeNull(string),
  dateFinishShipment: maybeNull(string),
  farmerCost: maybe(number),
  total: string,
  provider: string,
  customer: string,
  priceBuyer: string,
  priceTransport: string,
  priceFarmer: string,
  priceFarmerWithNds: string,
  costTotal: string,
  costTotalWithNds: string,
  selectedWarehouse: maybe(Warehouse),
  documents: array(
    model({
      id: string,
      name: maybeNull(string),
      typeDoc: maybeNull(string),
      file: maybeNull(string),
      filePdf: maybeNull(string),
      signFile: maybeNull(string),
      number: maybeNull(number),
      createdAt: maybeNull(string),
    }),
  ),
  readableStatus: maybe(string),
  activeReadableStatus: maybeNull(string),
  generalStatus: enumeration(['active', 'done', 'fail']),
});

export const OrdersStore = model('OrdersStore', {
  isLoading: false,
  isProductsLoading: false,
  isWarehousesLoading: false,
  orders: map(Order),
  timelineStore: TimelineStore,
  selectedOrder: maybeNull(reference(Order)),
  hasNextPage: true,
  actualProductId: string,
  pageNumber: 1,
  selectedStatus: optional(enumeration(['Активные', 'Закрытые', 'Аннулированные']), 'Активные'),
  ordersCounter: maybeNull(
    model({
      active: number,
      archived: number,
      canceled: maybe(number),
    }),
  ),
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .views((self) => ({
    get ordersList() {
      return Array.from(self.orders.values()).flat();
    },
  }))
  .actions((self) => ({
    // @ts-ignore
    process(data, isSingle: boolean): any {
      const dataList = castArray(data);

      const mapped = dataList.map((item) => {
        item.id = item.id.toString();

        if (isPrimitive(item)) {
          return item;
        }

        const existing = self.orders?.get(item.id);
        const dataMap = self.orders;

        if (isSingle && !!self.selectedOrder && !isEqual(self.selectedOrder, existing)) {
          self.root.alertsStore.addNotification(`Данные от фермера обновлены`, 'notification');
        }

        return existing
          ? mergeWith(existing, item, (_, next: any) => {
              if (Array.isArray(next)) return next;
              return;
            })
          : dataMap?.put(item);
      });
      return Array.isArray(data) ? mapped : mapped[0];
    },
  }))
  .actions((self) => {
    return {
      setSelectedOrder(id: string | null) {
        self.selectedOrder = !!id ? self.orders.get(id) || null : null;
      },
    };
  })
  .actions((self) => {
    return {
      setHasNextPage(value: boolean) {
        self.hasNextPage = value;
      },
    };
  })
  .actions((self) => {
    return {
      setPageNumber(value: number) {
        self.pageNumber = value;
      },
    };
  })
  .actions((self) => {
    return {
      setActualProductId(id: string) {
        self.actualProductId = id;
      },
    };
  })
  .actions((self) => {
    return {
      setSelectedStatus(status: 'Активные' | 'Закрытые' | 'Аннулированные') {
        self.orders.clear();
        self.pageNumber = 1;
        self.selectedStatus = status;
      },
    };
  })
  .actions((self) => {
    return {
      getOrders: flow(function* getOrders(ordersRequestOptions: IReqOrders): any {
        self.isLoading = true;
        const orders = yield daylesfordService
          .getOrdersListInfinite(ordersRequestOptions)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Список предложений`, 'error'));
        self.setHasNextPage((orders.data?.next ?? null) != null);

        self.isLoading = false;
        self.process(orders.data.results, false);
        return orders;
      }),
    };
  })
  .actions((self) => {
    return {
      getOrder: flow(function* getOrder(id: string): any {
        self.isLoading = true;
        const offer = yield daylesfordService
          .getOrder(id)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Данные о предложении`, 'error'));
        self.setSelectedOrder(offer.data.id.toString());
        self.isLoading = false;
        return self.process(offer.data, true);
      }),
    };
  })
  .actions((self) => {
    return {
      setCounter(active: any, archived: any, canceled: any) {
        self.ordersCounter = {
          active: active,
          archived: archived,
          canceled: canceled,
        };
      },
    };
  })
  .actions((self) => {
    return {
      getCounter: flow(function* getCounter(): any {
        const ordersCounter = yield daylesfordService
          .getOrdersCounter()
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Счетчик предложений`, 'error'));

        const archived = ordersCounter.data.doneCount;
        const canceled = ordersCounter.data.failCount;
        const active = ordersCounter.data.activeCount;

        self.setCounter(active, archived, canceled);

        return ordersCounter;
      }),
    };
  });
