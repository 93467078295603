import { appConfig } from 'config';
import { ORDER_ACTIVE_STATUSES_LIST } from 'utils/consts';
import { apiInstance } from './api';
import {
  IOffer,
  IOrder,
  IProduct,
  IWarehouse,
  ITimeline,
  IOffersCounterResponse,
  IOrdersCounterResponse,
  IOffersListResponse,
  IOrdersListResponse,
  ILinkedOrdersCounterResponse,
  ILinkedOrderResponse,
  IFarmer,
  ITeamMember,
  NSFarmer,
  IReqLinkedOrdersSummary,
  IReqLinkedOrdersCounter,
  IReqOffers,
  IReqOrders,
  IReqFarmers,
  IReqAnalyticsList,
  IAnalyticsCharts,
  IReqRecovery,
  IReqPasswordReset,
  IReqUsers,
  IUser,
  IPatchUser,
  IPatchFarmer,
  ISaleRequest,
  IReqSaleRequest,
  ISaleRequestsCounterResponse,
  IReqPriceCalcResults,
  ISaleRequestBidRequest,
  IOrderDetailsRequest,
  IReqCulturesFilterOptions,
  ISendToSmartRequest,
  IReqProvidersRequest,
} from './models';
import { AxiosProgressEvent } from 'axios';

const {
  auth,
  offers,
  products,
  warehouses,
  orders,
  specsOrders,
  dmOrders,
  steps,
  farmers,
  documents,
  analytics,
  staff,
  company,
  saleRequests,
  saleRequestBids,
  cultures,
  recovery,
  users,
  passwordReset,
  prices,
  providers,
  ownerProviders,
  paymentProcedures,
  profiles,
} = appConfig.api;

// const DEFAULT_OFFER_STATUS_FILTER = OFFER_ACTIVE_STATUSES_LIST.join(',');
// const OFFER_DEFAULT_PAGE_PARAM = `?limit=10&status__in=${DEFAULT_OFFER_STATUS_FILTER}`;

const DEFAULT_ORDER_STATUS_FILTER = ORDER_ACTIVE_STATUSES_LIST.join(',');
const ORDER_DEFAULT_PAGE_PARAM = `?limit=10&status__in=${DEFAULT_ORDER_STATUS_FILTER}`;

/**
 * Методы auth-service.
 */
export const daylesfordService = {
  // auth
  login: (request: any) => apiInstance.post<any>(`${auth}/login/`, request), // TODO: прописать модели
  me: () => apiInstance.get<any>(`${auth}/me/`),
  logout: () => apiInstance.post<any>(`${auth}/logout/`), // TODO: прописать модели
  requestRecovery: (options: IReqRecovery) => apiInstance.post<any>(`${recovery}/`, options),
  passwordReset: (options: IReqPasswordReset) => apiInstance.post<any>(`${passwordReset}/`, options),
  getProfiles: () => apiInstance.get<any>(`${profiles}/`),

  // offers
  getOffersCounter: () => apiInstance.get<IOffersCounterResponse>(`${offers}/general_info/`),
  getOfferList: () => apiInstance.get<IOffer[]>(`${offers}/`),
  getOffersListInfinite: (options: IReqOffers) =>
    apiInstance.get<IOffersListResponse>(`${offers}`, { params: options }),
  getOffer: (id: string) => apiInstance.get<IOffer>(`${offers}/${id}/`),
  editOffer: (request: IOffer) => apiInstance.patch<IOffer>(`${offers}/${request.id}/`, request),
  createOffer: (request: IOffer) => apiInstance.post<IOffer>(`${offers}/`, request),
  deleteOffer: (id: string) => apiInstance.delete(`${offers}/${id}/`),

  // product
  getProducts: () => apiInstance.get<IProduct[]>(`${products}/`),
  editProduct: (request: any) => apiInstance.patch<any>(`${products}/${request.id}/`, request),

  // warehouses
  getWarehouses: () => apiInstance.get<IWarehouse[]>(`${warehouses}/`),
  getBuyerWarehouses: () => apiInstance.get<IWarehouse[]>(`${warehouses}/buyers_list/`),

  //orders
  getOrdersCounter: () => apiInstance.get<IOrdersCounterResponse>(`${orders}/summary/`),
  getOrdersByOfferCounter: (offerId: number) =>
    apiInstance.get<ILinkedOrdersCounterResponse>(`${orders}/summary/?offer=${offerId}`),
  getOrderList: () => apiInstance.get<IOrder[]>(`${orders}/`),
  getOrdersListInfinite: (options: IReqOrders) =>
    apiInstance.get<IOrdersListResponse>(`${orders}`, { params: options }),
  getOrdersByOfferListInfinite: ({ pageParam = ORDER_DEFAULT_PAGE_PARAM }) =>
    apiInstance.get<ILinkedOrderResponse>(`${orders}/brief/${pageParam}`),
  getOrder: (id: string) => apiInstance.get<IOrder>(`${orders}/${id}/`),

  // dm_orders
  getDMOrderList: (options: IReqOrders) => apiInstance.get<IOrder[]>(`${dmOrders}/v2/`, { params: options }),
  getDMOrder: (id: string) => apiInstance.get<IOrder>(`${dmOrders}/v2/${id}/`),
  getDMOrdersCounter: (providerId?: string, cultureName?: string) => {
    const url = `${dmOrders}/v2/summary/?status=open${providerId ? `&provider=${providerId}` : ''}${
      cultureName ? `&culture_name=${cultureName}` : ''
    }`;
    return apiInstance.get<IOrder>(url);
  },

  //specs orders
  getSpecsOrdersCounter: (providerId?: string, cultureName?: string) => {
    const url = `${specsOrders}/summary/?status=open${providerId ? `&provider=${providerId}` : ''}${
      cultureName ? `&culture_name=${cultureName}` : ''
    }`;
    return apiInstance.get<IOrdersCounterResponse>(url);
  },
  getSpecsOrdersListInfinite: (options: IReqOrders) =>
    apiInstance.get<IOrdersListResponse>(`${specsOrders}`, { params: options }),
  getSpecsOrder: (id: string) => apiInstance.get<IOrder>(`${specsOrders}/${id}/`),

  resignOrder: (id: string) => apiInstance.post(`${specsOrders}/${id}/resign/`),
  sendOrderForSign: (id: string) => apiInstance.post(`${specsOrders}/${id}/status/`, { status: 10 }),

  paymentProcedures: () => apiInstance.get(`${paymentProcedures}/`),

  editOrderDetails: (request: IOrderDetailsRequest) =>
    apiInstance.post<IOrder>(`${specsOrders}/${request.id}/params/`, request),
  sendToSmart: (request: ISendToSmartRequest) =>
    apiInstance.post<IOrder>(`${specsOrders}/${request.id}/send_to_smart/`, request),

  // culture filter
  getCulturesFilterOptions: (options: IReqCulturesFilterOptions) =>
    apiInstance.get(`${dmOrders}/v2/cultures/`, { params: options }),

  //TimeLine
  getTimeLine: (id: string) => apiInstance.get<ITimeline>(`${steps}/${id}/web/`),
  createTimeLineAction: (id: string, request: any) => apiInstance.post<any>(`${steps}/${id}/action/`, request),
  getTimeLineDocs: (uidsArr: string[]) =>
    apiInstance.get<ITimeline>(`${documents}/download/?id__in=${uidsArr.join()}`, { responseType: 'blob' }),
  uploadTimeLineActionDocs: (id: string, file: File, request: any) => {
    const { progressCallBack } = request;

    const formData = new FormData();
    formData.append('code', request.code);
    formData.append('file', request.file);
    return apiInstance.post(`${steps}/${id}/doc_action/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        progressCallBack && progressCallBack(progressEvent, request.code);
      },
    });
  },
  uploadTimeLineDate: (id: string, request: any) => apiInstance.post<any>(`${steps}/${id}/date_action/`, request),
  uploadActionValue: (id: string, request: any) => apiInstance.post<any>(`${steps}/${id}/value_action/`, request),

  uploadOrderFile: (id: string, file: File, request: any) => {
    const formData = new FormData();
    formData.append('code', request.code);
    formData.append('file', request.file);
    apiInstance.post(`${steps}/${id}/upload_doc/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  // users
  getUsers: (options: IReqUsers) => apiInstance.get<IUser[]>(`${users}`, { params: options }),
  updateUser: (request: IPatchUser) => apiInstance.patch<IPatchUser>(`${users}/${request.id}/`, request),
  // farmers
  // getFarmers: (pageParam = OFFER_DEFAULT_PAGE_PARAM) => apiInstance.get<IFarmer[]>(`${farmers}/${pageParam}`),
  getFarmers: (options: IReqFarmers) => apiInstance.get<IFarmer[]>(`${farmers}`, { params: options }),
  getFarmer: (id: string) => apiInstance.get<NSFarmer.Farmer>(`${farmers}/${id}/`),
  updateFarmer: (request: IPatchFarmer) => apiInstance.patch<IPatchFarmer>(`${farmers}/${request.id}/`, request),

  //linked Orders
  getLinkedOrders: (options: IReqLinkedOrdersSummary) =>
    apiInstance.get<ILinkedOrderResponse>(`${orders}/brief/`, { params: options }),

  getLinkedOrdersCounter: (options: IReqLinkedOrdersCounter) =>
    apiInstance.get<ILinkedOrdersCounterResponse>(`${orders}/summary/`, { params: options }),

  // analytics
  // /analytics/data_sources/
  getAnalyticsChartsList: () => apiInstance.get<IReqAnalyticsList[]>(`${analytics}`),
  getAnalyticsCharts: (id: string) => apiInstance.get<IAnalyticsCharts[]>(`${analytics}/${id}`),

  // Team
  getCompany: () => apiInstance.get(company),
  createTeamMember: (request: ITeamMember) => apiInstance.post<ITeamMember>(`${staff}/`, request),
  editTeamMember: (request: ITeamMember) => apiInstance.put<ITeamMember>(`${staff}/${request.id}/`, request),

  getCultures: () => apiInstance.get(`${cultures}`),
  // Sale requests
  getSaleRequests: (options: IReqSaleRequest) => apiInstance.get(`${saleRequests}`, { params: options }),
  getSaleRequest: (id: string) => apiInstance.get<ISaleRequest>(`${saleRequests}/${id}/`),
  editSaleRequest: (request: ISaleRequest) =>
    apiInstance.patch<ISaleRequest>(`${saleRequests}/${request.id}/`, request),
  getSaleRequestsCounter: () => apiInstance.get<ISaleRequestsCounterResponse>(`${saleRequests}/summary/`),
  sendSaleRequestCounterOffer: (request: ISaleRequestBidRequest) => apiInstance.post(`${saleRequestBids}/`, request),

  // Price calculator
  getPriceCalculator: (options: IReqPriceCalcResults) => apiInstance.get(`${prices}/calc/`, { params: options }),
  getCulturesCalculator: () => apiInstance.get(`${cultures}/calculator/`),

  // Providers
  getProviders: (options: IReqProvidersRequest) => apiInstance.get(providers, { params: options }),

  getOwnerProviders: () => apiInstance.get(ownerProviders),
};
