import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import styled from 'styled-components';
import { DeleteIcon } from 'uikit/Icons/DeleteIcon';
import { formatDecimals } from 'utils/utils';

type Indicator = {
  id: number;
  name: string;
  measure: 'number' | 'percent' | 'string';
  worstDirection: 'max' | 'min';
  maxValue: number;
  minValue: number;
  step: string;
  editable: boolean;
  basisValue: string;
  value?: string;
  limitValue: string;
  position: number;
  withdrawalValue: string;
};

interface IndicatorInputProps {
  indicator: Indicator;
  onValueChange: (id: number, value: string) => void;
  onLimitChange: (id: number, limit: string) => void;
  onWithdrawalValueChange: (id: number, withdrawals: string) => void;
  onNameChange: (id: number, name: string) => void;
  onMeasureChange: (id: number, measure: 'percent' | 'string' | 'number') => void;
  onDelete: (id: number) => void;
  isDeleteDisabled?: boolean;
}

export const IndicatorInput: React.FC<IndicatorInputProps> = observer(
  ({
    indicator,
    onValueChange,
    onLimitChange,
    onWithdrawalValueChange,
    onNameChange,
    onMeasureChange,
    onDelete,
    isDeleteDisabled,
  }) => {
    const [value, setValue] = useState<string | number>(indicator.value || indicator.basisValue);
    const [limit, setLimit] = useState<string | number>(indicator.limitValue);
    const [withdrawals, setWithdrawals] = useState<string | number>(indicator.withdrawalValue);

    const handleInputValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const newValue = event.target.value;
      setValue(newValue);
      onValueChange(indicator.id, formatDecimals(newValue));
    };
    const handleInputLimitChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const newLimit = event.target.value;
      setLimit(newLimit);
      onLimitChange(indicator.id, formatDecimals(newLimit));
    };
    const handleInputWithdrawalsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newWithdrawals = event.target.value;
      setWithdrawals(newWithdrawals);
      onWithdrawalValueChange(indicator.id, formatDecimals(newWithdrawals));
    };
    const handleDelete = () => {
      onDelete(indicator.id);
    };

    const renderMeasureChangeTitle = () => {
      switch (indicator.measure) {
        case 'string':
          return 'A|';
        case 'percent':
          return '%';
        case 'number':
          return '123';
      }
    };

    const handleMeasureChange = (measure: 'number' | 'percent' | 'string') => {
      onMeasureChange(indicator.id, measure);
    };

    const handleIndicatorNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value;
      onNameChange(indicator.id, newName);
    };

    const renderIndicatorInputs = () => {
      switch (indicator.measure) {
        case 'string':
          return (
            <>
              <MeausureChangeButton onClick={() => handleMeasureChange('percent')}>
                {renderMeasureChangeTitle()}
              </MeausureChangeButton>
              <InputWrapper>
                <StyledInput
                  type="text"
                  id={`indicator-${indicator.id}`}
                  name={`indicator-${indicator.id}`}
                  value={value}
                  onChange={handleInputValueChange}
                />
              </InputWrapper>
            </>
          );
        default:
          return (
            <>
              <MeausureChangeButton
                onClick={() => handleMeasureChange(indicator.measure === 'number' ? 'string' : 'number')}
              >
                {renderMeasureChangeTitle()}
              </MeausureChangeButton>
              <InputWrapper>
                {indicator.measure === 'number' ? (
                  <>
                    <StyledInput
                      type="number"
                      id={`indicator-${indicator.id}-value`}
                      name={`indicator-${indicator.id}-value`}
                      value={value}
                      onChange={handleInputValueChange}
                      step={0.1}
                    />
                    <StyledInput
                      type="number"
                      id={`indicator-${indicator.id}-limit`}
                      name={`indicator-${indicator.id}-limit`}
                      value={limit}
                      step={0.1}
                      onChange={handleInputLimitChange}
                    />
                  </>
                ) : (
                  <>
                    <StyledPercentWrapper>
                      <StyledInput
                        type="number"
                        id={`indicator-${indicator.id}-value`}
                        name={`indicator-${indicator.id}-value`}
                        value={value}
                        step={0.1}
                        onChange={handleInputValueChange}
                      />
                      <StyledPercent>%</StyledPercent>
                    </StyledPercentWrapper>
                    <StyledPercentWrapper>
                      <StyledInput
                        type="number"
                        id={`indicator-${indicator.id}-limit`}
                        name={`indicator-${indicator.id}-limit`}
                        value={limit}
                        onChange={handleInputLimitChange}
                        step={0.1}
                      />
                      <StyledPercent>%</StyledPercent>
                    </StyledPercentWrapper>
                  </>
                )}

                <StyledInput
                  type="number"
                  id={`indicator-${indicator.id}-withdrawals`}
                  name={`indicator-${indicator.id}-withdrawals`}
                  value={withdrawals}
                  step={0.1}
                  onChange={handleInputWithdrawalsChange}
                />
              </InputWrapper>
            </>
          );
      }
    };

    const isCustom = indicator.id < 0;
    return (
      <InputRowWrapper>
        {isCustom ? (
          <CustomInputName
            type="text"
            name={`indicator-${indicator.id}`}
            value={indicator.name}
            onChange={handleIndicatorNameChange}
          />
        ) : (
          <InputLabel>{indicator.name}</InputLabel>
        )}
        {renderIndicatorInputs()}
        {!isDeleteDisabled ? (
          <DeleteIndicatorButton onClick={handleDelete}>
            <DeleteIcon />
          </DeleteIndicatorButton>
        ) : (
          <div style={{ width: 22 }} />
        )}
      </InputRowWrapper>
    );
  },
);

const InputRowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 12px 8px 12px 0px;
  border-top: 1px solid rgba(205, 205, 205, 0.2);

  /* background-color: #dcdee4; */
  * {
    min-width: 0;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 324px;
`;

interface ILabelProps {
  isDisabled?: boolean;
}
const InputLabel = styled.span<ILabelProps>`
  width: 156px;
  /* padding: 20px 16px;
  padding-right: 0px; */
  padding-left: 16px;
  color: ${(props) => (props.isDisabled ? '#AEAEAE' : '#292929')};
  font-weight: 600;
  font-size: 15px;
`;

const CustomInputName = styled.input`
  flex-grow: 1;
  width: 156px;
  padding: 16px 10px;
  background-color: white;
  border: none;
  border-radius: 12px;
  color: #292929;
  font-size: 15px;
  font-weight: 500;

  border: 1px solid #dcdee4;

  &:disabled {
    color: #aeaeae;
  }
`;

const StyledInput = styled.input`
  flex-grow: 1;
  width: 100%;
  min-width: 100px;
  padding: 16px 10px;
  background-color: white;
  border: none;
  border-radius: 12px;
  color: #292929;
  font-size: 15px;
  font-weight: 500;

  border: 1px solid #dcdee4;

  &:disabled {
    color: #aeaeae;
  }
  :nth-child(3) {
    background-color: #f0f2ff;
  }
`;

const StyledSelect = styled.select`
  appearance: none;
  flex-grow: 1;
  width: 100%;
  padding: 16px 10px;
  background-color: white;
  border: none;
  border-radius: 12px;
  color: #292929;
  font-size: 15px;
  font-weight: 500;
`;

const SelectWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  min-width: 100px;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #dcdee4;
`;

const StyledSelectIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledPercentWrapper = styled.label`
  display: block;
  position: relative;
  flex-grow: 1;
  width: 100%;
  min-width: 100px;
  ${StyledInput} {
    padding-right: 40px;
  }
`;
const StyledPercent = styled.span`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  font-size: 15px;
  font-weight: 500;
  color: #292929;
  border-left: 1px solid rgba(101, 101, 101, 0.3);
  display: block;
  padding-left: 8px;
  cursor: text;
`;

const MeausureChangeButton = styled.button`
  background-color: #8e66fe;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  width: 33px;
  padding: 4px;
`;

const DeleteIndicatorButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
