/**
 * Иконка "Информация".
 */

export const InfoIcon = () => (
  <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.726 23.587c-.708 0-1.138-.494-1.138-1.268v-3.093h-.301c-2.976 0-5.081-1.998-5.081-5.253V5.454C.206 2.188 2.183.19 5.47.19h13.47c3.287 0 5.264 1.998 5.264 5.264v8.519c0 3.254-1.977 5.253-5.264 5.253h-6.67l-4.072 3.62c-.59.537-.977.74-1.472.74Zm5.479-17.048c.849 0 1.547-.709 1.547-1.547a1.552 1.552 0 1 0-3.105 0c0 .838.699 1.547 1.558 1.547Zm-1.87 8.84h4.222c.548 0 .978-.375.978-.944 0-.527-.43-.935-.978-.935h-.934V9.192c0-.74-.365-1.224-1.063-1.224h-2.03c-.549 0-.968.419-.968.934 0 .57.42.956.967.956h.956V13.5h-1.15c-.547 0-.977.408-.977.935 0 .569.43.945.978.945Z"
      fill="#8E66FE"
    />
  </svg>
);
