import { castArray, mergeWith } from 'lodash-es';
import { flow, types, getRoot } from 'mobx-state-tree';
import { isPrimitive } from '../utils/utils';
import { daylesfordService } from '../services';
import { IRootStore } from './Root';
import { IAccreditationStatus, IPatchFarmer, IReqFarmers } from 'services/models';
import { LIMIT_FETCH_RECORDS } from 'utils/consts';

const { identifier, model, map, array, string, number, maybeNull, maybe, reference } = types;

const Farmer = model('Farmer', {
  id: identifier,
  nameOfProvider: string,
  taxType: string,
  mailAddress: maybeNull(string),
  ulAddress: string,
  inn: string,
  kpp: maybeNull(string),
  ogrn: maybeNull(string),
  nameOfBank: maybeNull(string),
  paymentAccount: maybeNull(string),
  correspondentAccount: maybeNull(string),
  bik: maybeNull(string),
  documentFlow: maybeNull(
    model({
      type: string,
      fio: string,
      phoneNumber: string,
      emailOfHead: string,
    }),
  ),
  staff: maybeNull(
    array(
      model({
        type: string,
        fio: string,
        phoneNumber: string,
        emailOfHead: string,
      }),
    ),
  ),
  ordersCount: maybe(number),
  currentVolume: maybe(number),
  edmStatus: string,
  accreditationStatus: string,
});

export const FarmersStore = model('FarmersStore', {
  isLoading: true,
  hasNextPage: true,
  pageNumber: 1,
  farmers: map(Farmer),
  selectedFarmer: maybeNull(reference(Farmer)),
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .views((self) => ({
    get farmersList() {
      return Array.from(self.farmers.values()).flat();
    },
  }))
  .actions((self) => {
    return {
      setHasNextPage(value: boolean) {
        self.hasNextPage = value;
      },
    };
  })
  .actions((self) => {
    return {
      setPageNumber(value: number) {
        self.pageNumber = value;
      },
    };
  })
  .actions((self) => {
    return {
      clearFarmers() {
        self.farmers.clear();
      },
    };
  })
  .actions((self) => {
    return {
      setSelectedFarmer(id: string | null) {
        self.selectedFarmer = !!id ? self.farmers.get(id) || null : null;
      },
    };
  })
  .actions((self) => ({
    // @ts-ignore
    process(data): any {
      const dataList = castArray(data);

      const mapped = dataList.map((farmer) => {
        farmer.id = farmer.id.toString();
        if (isPrimitive(farmer)) {
          return farmer;
        }

        const existing = self.farmers?.get(farmer.id);

        return existing
          ? mergeWith(existing, farmer, (_, next: any) => {
              if (Array.isArray(next)) return next; // Treat arrays like atoms
              return;
            })
          : self.farmers.put(farmer);
      });
      return Array.isArray(data) ? mapped : mapped[0];
    },
  }))
  .actions((self) => {
    return {
      getFarmers: flow(function* getFarmers(searchInput?: string, accreditationStatus?: string): any {
        self.isLoading = true;

        const farmersRequestOptions: IReqFarmers = {
          limit: LIMIT_FETCH_RECORDS,
          offset: LIMIT_FETCH_RECORDS * (self.pageNumber - 1),
        };
        if (!!searchInput?.length) {
          farmersRequestOptions.searchInput = searchInput;
        }
        if (!!accreditationStatus?.length) {
          farmersRequestOptions.accreditation_status = accreditationStatus as IAccreditationStatus;
        }

        const farmers = yield daylesfordService
          .getFarmers(farmersRequestOptions)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: список фермеров`, 'error'));
        self.setHasNextPage((farmers.data?.next ?? null) != null);
        self.isLoading = false;
        return self.process(farmers.data.results);
      }),
    };
  })
  .actions((self) => {
    return {
      loadMoreFarmers() {
        if (self.hasNextPage) {
          self.setPageNumber(self.pageNumber + 1);
          self.getFarmers();
        }
      },
    };
  })
  .actions((self) => {
    return {
      getFarmer: flow(function* getFarmer(id: string): any {
        self.isLoading = true;
        const farmer = yield daylesfordService
          .getFarmer(id)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: данные о фермере`, 'error'));
        self.setSelectedFarmer(farmer.data.id.toString());
        self.isLoading = false;
        return self.process(farmer.data);
      }),
    };
  })
  .actions((self) => {
    return {
      updateFarmer: flow(function* updateFarmer(request: IPatchFarmer): any {
        self.isLoading = true;
        const farmer = yield daylesfordService
          .updateFarmer(request)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: данные о компании`, 'error'));
        self.isLoading = false;
        self.root.alertsStore.addNotification(`${farmer.id}: данные о компании изменены`, 'success');
        return self.process(farmer.data);
      }),
    };
  });
