import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import { Flex, Spacer, Typography } from 'uikit';
import { ReactComponent as ArrowDown } from 'assets/img/arrow_down.svg';
import { TitleText, Docs, ActionBtn, ActionFile, ProgressBar } from 'uikit/TimeLineBlocks';
import { getTextCode, dateFormat, getModalBtnText, getModalText, getModalSubText } from '../../../pages/helpers';
import { ModalTimeline, ModalTimelineTableCar, ModalTottalValume } from 'uikit/Modals';
import { IRouteParams } from 'utils/models';
import { useQuery, useQueryClient } from 'react-query';
import { useStore } from 'store/Root';
import { GridTable } from 'uikit/GridTable';

interface IItemDay {
  title: string;
  is_confirmed: boolean;
  blocks: any;
  getNewTimeline: () => void;
}

export const ItemDay: React.FC<IItemDay> = ({ title, is_confirmed, blocks, getNewTimeline }) => {
  const { ordersStore } = useStore();
  const [showModalState, setShowModalState] = useState<any>(false);
  const [showTableModalState, setShowTableModalState] = useState<any>(false);
  const [showTottalValumeModalState, setShowTottalValumeModalState] = useState<any>(false);
  const [toggleDay, setToggleDay] = useState<boolean>(is_confirmed);
  const [completed, setCompleted] = useState<any>(0);
  const [currentLoadingCode, setCurrentLoadingCode] = useState<string>('');
  const [files, setFiles] = useState<any>({
    code: null,
    file: null,
    progressCallBack: null,
  });
  const [filesType, setFilesType] = useState<any>({
    planShipping: [],
    tableCars: [],
  });
  const [tottalValue, setTottalValue] = useState<any>({
    code: '',
    value: '',
  });
  const { id }: IRouteParams = useParams();
  const paramId = id || '';

  const totalValueData = useQuery<any>(
    ['TIMELINE_ACTION_VALUE', paramId],
    () => ordersStore.timelineStore.getTimeline(paramId),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  );

  const timelineActionData = useQuery<any>(
    ['TIMELINE_ACTION_QUERY_KEY', paramId, ordersStore.timelineStore.actionRequestOptions],
    () => ordersStore.timelineStore.createTimeLineAction(paramId),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  );

  const queryClient = useQueryClient();

  const timelineDocsData = useQuery<any>(
    ['TIMELINE_ACTION_DOCS', paramId, files],
    () => ordersStore.timelineStore.uploadTimeLineActionDocs(paramId, files.file, files),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      onSuccess: (data) => {
        queryClient.setQueryData(['TIMELINE_DATA_QUERY_KEY', paramId], data);
      },
    },
  );

  useEffect(() => {
    // if (timelineActionData.actionIsSuccess || !actionIsSuccess) {
    //TODO: что-то в этом условии есть...
    setFiles({
      code: null,
      file: null,
      progressCallBack: (progressEvent: ProgressEvent, code: string) => {
        setCurrentLoadingCode(code);
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setCompleted(percentCompleted);
        percentCompleted === 100 &&
          setTimeout(() => {
            setCurrentLoadingCode('');
          }, 1000);
      },
    });
    ordersStore.timelineStore.setActionRequestOptions({ code: '' });
    getNewTimeline();
    // }
  }, [timelineActionData.isSuccess]);
  // }, [timelineActionData.actionIsSuccess, getNewTimeline]);

  useEffect(() => {
    if (files.code && files.file) {
      timelineDocsData.refetch();
      getNewTimeline();
    }
  }, [files.code, files.file, timelineDocsData.refetch]);

  useEffect(() => {
    if (tottalValue.code && tottalValue.value) {
      totalValueData.refetch();
      getNewTimeline();
    }
  }, [tottalValue]);
  // }, [tottalValue, getLoadTottalValue, getNewTimeline])

  const handleModalOpen = (code: string, e: any) => {
    setShowModalState(true);
    if (files.file && e.target.innerText === 'Завершить погрузку на сегодня') {
      ordersStore.timelineStore.setActionRequestOptions({ code: 'loading_finished' });
    } else if (files.file && e.target.innerText === 'Завершить погрузку по контракту') {
      ordersStore.timelineStore.setActionRequestOptions({ code: 'contract_loading_finished' });
    } else {
      ordersStore.timelineStore.setActionRequestOptions({ code: code });
    }
  };

  const renderItemDay = () => {
    return (
      <ItemDayDiv className={toggleDay ? 'is_toggle' : ''}>
        {blocks?.map(
          (
            {
              value,
              type,
              dateTime,
              subtype,
            }: {
              value: string;
              type: string;
              dateTime: string;
              subtype: string;
            },
            idx: number,
          ) => {
            const renderModal = (code: string, modalTitle?: string, modalText?: string) => {
              return (
                showModalState && (
                  <ModalTimeline
                    isActive={showModalState}
                    setShowModalState={setShowModalState}
                    text={getModalSubText(ordersStore.timelineStore.actionRequestOptions?.code ?? code)}
                    getTimeLineAction={() => {
                      timelineActionData.refetch();
                      setShowModalState(false);
                      if (timelineActionData.isSuccess) {
                        return getNewTimeline();
                      } else {
                        return;
                      }
                    }}
                    isRequestBody={getModalText(
                      ordersStore.timelineStore.actionRequestOptions?.code ?? code,
                      '',
                      modalTitle,
                    )}
                    handleSubmitForm={() => {
                      ordersStore.timelineStore.setActionRequestOptions({ code });
                    }}
                    btnText={getModalBtnText(ordersStore.timelineStore.actionRequestOptions?.code ?? code, modalText)}
                  />
                )
              );
            };

            const getActionBtn = () => {
              const { code, title, modalTitle, modalText } = JSON.parse(value) || {};
              const filesArr = blocks.filter((el: any) => el.type === 'action-file');

              if (!filesArr.length)
                return (
                  <React.Fragment key={idx}>
                    <ActionBtn
                      handleModalOpen={(e) => handleModalOpen(code, e)}
                      getTextCode={getTextCode(code, title)}
                      disabled={timelineDocsData.isFetching}
                    />
                    {renderModal(code, modalTitle, modalText)}
                  </React.Fragment>
                );

              const hasFiles = filesArr.every((el: any) => {
                const { title } = JSON.parse(el.value);
                if (title.includes('.xlsx') || title.includes('.pdf')) return true;
                return false;
              });

              return hasFiles ? (
                <React.Fragment key={idx}>
                  <ActionBtn
                    handleModalOpen={(e) => handleModalOpen(code, e)}
                    getTextCode={getTextCode(code, title)}
                    disabled={timelineDocsData.isFetching}
                  />
                  {renderModal(code, modalTitle, modalText)}
                </React.Fragment>
              ) : (
                <React.Fragment key={idx}>
                  <ActionBtn
                    handleModalOpen={(e) => handleModalOpen(code, e)}
                    getTextCode={getTextCode(code, title)}
                    disabled={files.file ? timelineDocsData.isFetching : true}
                  />
                  {renderModal(code, modalTitle, modalText)}
                </React.Fragment>
              );
            };

            const getActionFile = () => {
              if (subtype === 'step4_action_file_daily_loading_plan_uploaded') {
                const { code } = JSON.parse(value) || {};
                return (
                  <React.Fragment key={idx}>
                    <ActionFile
                      value={value}
                      fileType={filesType?.planShipping?.type?.slice(0, 15)}
                      onChange={(e: any) => {
                        setFiles({
                          ...files,
                          file: e.target.files[0],
                          code: code,
                        });
                        setFilesType({
                          ...filesType,
                          planShipping: e.target.files[0],
                        });
                      }}
                      files={filesType.planShipping?.type}
                    />
                    {timelineDocsData.isFetching && code === currentLoadingCode && (
                      <ProgressBar currentValue={''} percent={completed} tottalValue={''} />
                    )}
                    <Spacer space={10} />
                  </React.Fragment>
                );
              }
              if (subtype === 'step4_action_file_daily_cars_table_uploaded') {
                const { code } = JSON.parse(value) || {};
                return (
                  <React.Fragment key={idx}>
                    <ActionFile
                      value={value}
                      fileType={filesType?.tableCars?.type?.slice(0, 15)}
                      onChange={(e: any) => {
                        setFiles({
                          ...files,
                          file: e.target.files[0],
                          code: code,
                        });
                        setFilesType({
                          ...filesType,
                          tableCars: e.target.files[0],
                        });
                      }}
                      files={filesType.tableCars?.type}
                    />
                    <Spacer space={10} />
                  </React.Fragment>
                );
              } else {
                const { code } = JSON.parse(value) || {};
                return (
                  <React.Fragment key={idx}>
                    <ActionFile
                      value={value}
                      fileType={files.file?.type?.slice(0, 15)}
                      onChange={(e: any) => {
                        setFiles({ ...files, file: e.target.files[0], code });
                      }}
                      files={files?.file?.type}
                    />
                    <Spacer space={10} />
                  </React.Fragment>
                );
              }
            };

            const getChangeableTitle = () => {
              const { text, value: tottalValue, code: codeDayVolume } = JSON.parse(value) || {};

              // if (subtype === "step4_title_total_actual_weight") {
              //     setInterval(() => getNewTimeline(), 3000)
              // } else {
              //     clearInterval(setInterval(() => getNewTimeline(), 3000))
              // }
              return (
                <React.Fragment key={idx}>
                  <Flex>
                    <Typography size="lg">{text}</Typography>
                    <Spacer width={8} />
                    <div onClick={() => setShowTottalValumeModalState(true)}>
                      <TypographyTable size="lg" color="#8E66FE">
                        {tottalValue} т
                      </TypographyTable>
                    </div>
                  </Flex>
                  <Spacer space={20} />
                  {showTottalValumeModalState && (
                    <ModalTottalValume
                      isActive={showTottalValumeModalState}
                      setShowModalState={setShowTottalValumeModalState}
                      headText={dateFormat(title)}
                      tottalValue={tottalValue}
                      handleSubmitForm={(values: any) => {
                        setTottalValue({
                          ...{},
                          value: values.tottalValue,
                          code: codeDayVolume,
                        });
                        setShowTottalValumeModalState(false);
                        if (totalValueData.isSuccess) {
                          return getNewTimeline();
                        } else return;
                      }}
                    />
                  )}
                </React.Fragment>
              );
            };

            const getTitleTable = () => {
              const { title: titleTable, table_name, additional_data, tables } = JSON.parse(value) || {};

              return (
                <React.Fragment key={idx}>
                  <Flex>
                    <Typography size="lg">{titleTable}</Typography>
                    <Spacer width={8} />
                    <div onClick={() => setShowTableModalState(true)}>
                      <TypographyTable size="lg" color="#8E66FE">
                        {table_name}
                      </TypographyTable>
                    </div>
                  </Flex>
                  <Spacer space={20} />
                  {showTableModalState && (
                    <ModalTimelineTableCar
                      isActive={showTableModalState}
                      setShowModalState={setShowTableModalState}
                      additionalInfo={`${Object.keys(additional_data)}: ${Object.values(additional_data)}`}
                      tables={tables}
                    />
                  )}
                </React.Fragment>
              );
            };

            const getTable = () => {
              const { title: titleTable, row_names, col_names, additional_data, values } = JSON.parse(value) || {};

              return (
                <React.Fragment key={idx}>
                  <Flex column>
                    <Typography size="lg">{titleTable}</Typography>
                    <Spacer space={10} />
                    <GridTable values={values} rowNames={row_names} colNames={col_names} />
                    <Spacer space={10} />
                    {additional_data && <Typography size="lg">{additional_data}</Typography>}
                  </Flex>
                  <Spacer space={38} />
                </React.Fragment>
              );
            };

            switch (type) {
              case 'title':
                return (
                  <React.Fragment key={idx}>
                    <TitleText value={value} dateTime={dateTime} />
                  </React.Fragment>
                );

              case 'docs':
                return (
                  <React.Fragment key={idx}>
                    <Docs onDownloadAllClick={ordersStore.timelineStore.getTimelineDocs} value={value} />
                    <Spacer space={10} />
                  </React.Fragment>
                );

              case 'action':
                return getActionBtn();

              case 'action-file':
                return getActionFile();

              case 'changeable-title':
                return getChangeableTitle();

              case 'title-with-table':
                return getTitleTable();
              case 'table':
                return getTable();

              default:
                return '';
            }
          },
        )}
      </ItemDayDiv>
    );
  };

  return (
    <React.Fragment>
      <Spacer space={10} />
      <ArrowDownFlex
        onClick={() => {
          setToggleDay(toggleDay ? false : true);
        }}
      >
        <ArrowDownSpan className={toggleDay ? '' : 'is_toggle'}>
          <ArrowDown />
        </ArrowDownSpan>
        <Spacer width={12} />
        <Typography size="lg" bold>
          {dateFormat(title)}
        </Typography>
      </ArrowDownFlex>
      <Spacer space={10} />
      {renderItemDay()}
    </React.Fragment>
  );
};

const TypographyTable = styled(Typography)`
  cursor: pointer;
`;

const ItemDayDiv = styled.div`
  padding-left: 22px;
  max-height: 1800px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  &.is_toggle {
    max-height: 0;
    transform: scale(0);
  }
`;

const ArrowDownFlex = styled(Flex)`
  cursor: pointer;
  width: fit-content !important;
`;

const ArrowDownSpan = styled.span`
  transition: all 0.4s ease-in-out;
  &.is_toggle {
    transform: rotate(180deg);
  }
`;
