import { Modal } from 'react-bootstrap';
import { useStore } from 'store/Root';
import styled from 'styled-components';
import { Flex, Spacer } from 'uikit';
import { CloseIcon } from 'uikit/Icons/CloseIcon';
import { InquiryHeader } from 'components/Inquiries/pages/InquiryPage';
import {
  ModalHeaderWrapper,
  ModalContent,
  StyledCloseIcon,
  SubHeader,
} from 'components/SpecsOrders/components/SpecOrderBaseActionsModal';
import { Select } from 'uikit/Selects';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

interface IProps {
  orderId: string;
  showModalState: boolean;
  handleModalClose: () => void;
  isTaxable?: boolean;
  includeWithdrawals: boolean;
  paymentProcedure: number | null;
  isCPT: boolean;
  selectedWarehouse:
    | {
        label: string;
        value: string;
      }
    | string;
  logisticCosts: string;
  agencyCosts: string;
  additionalCosts: string;
}

export const SpecOrderSendToSmartModal: React.FC<IProps> = observer(
  ({
    orderId,
    showModalState,
    handleModalClose,
    isTaxable,
    includeWithdrawals,
    paymentProcedure,
    isCPT,
    selectedWarehouse,
    logisticCosts,
    agencyCosts,
    additionalCosts,
  }) => {
    const { specsOrdersStore, inquiriesStore } = useStore();

    const [activeWarehouse, setActiveWarehouse] = useState<any>(selectedWarehouse);
    const [warehouseAddress, setWarehouseAddress] = useState<string>(selectedWarehouse as string);

    const [logisticsValue, setLogisticsValue] = useState<string>(logisticCosts);
    const [agentsValue, setAgentsValue] = useState<string>(agencyCosts);
    const [additionalCostsValue, setadditionalCostsValue] = useState<string>(additionalCosts);

    const selectedProcedure =
      paymentProcedure !== null &&
      specsOrdersStore.paymentProceduresOptions.find((procedure) => procedure.value === paymentProcedure);

    const [isOrderTaxable, setIsOrderTaxable] = useState<string>(isTaxable ? 'true' : 'false');
    const [isWithdrawalsIncluded, setIsWithdrawalsIncluded] = useState<boolean>(includeWithdrawals);
    const [activePaymentProcedure, setActivePaymentProcedure] = useState<number | null>(paymentProcedure || null);

    const handleWarehouseSelectChange = (warehouse: { label: string; value: string }) => {
      setActiveWarehouse(warehouse);
    };

    const warehousesOptions = inquiriesStore.warehousesList.map((warehouse) => ({
      label: warehouse.title,
      value: warehouse.id,
    }));

    const handleSendToSmart = () => {
      interface IDataToSmart {
        id: string;
        logisticCosts: string;
        agencyCosts: string;
        additionalCosts: string;
        taxable: boolean;
        includeWithdrawals: boolean;
        paymentProcedure: number | null;
        warehouseAddress?: string;
        destinationWarehouse?: string;
      }

      const dataToSend: IDataToSmart = {
        id: orderId,
        logisticCosts: logisticsValue,
        agencyCosts: agentsValue,
        additionalCosts: additionalCostsValue,
        taxable: isOrderTaxable === 'true' ? true : false,
        includeWithdrawals: isWithdrawalsIncluded,
        paymentProcedure: activePaymentProcedure,
      };
      if (isCPT) {
        dataToSend.destinationWarehouse = activeWarehouse.value;
      } else {
        dataToSend.warehouseAddress = warehouseAddress;
      }

      specsOrdersStore.sendDetailsToSmart(dataToSend);

      handleModalClose();
    };

    return (
      <StyledModal
        show={showModalState}
        onHide={handleModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeaderWrapper>
          <Modal.Header closeButton />
          <StyledCloseIcon>
            <CloseIcon />
          </StyledCloseIcon>
        </ModalHeaderWrapper>
        <ModalContent>
          <Flex column>
            <InquiryHeader>Вы хотите отправить заявку в Смарт?</InquiryHeader>
            <Spacer space={20} />
          </Flex>
          <ContentWrapper>
            <InputsWrapper>
              <div>
                <InputLabel>Логистика</InputLabel>
                <StyledInputWrapper>
                  <StyledInput
                    type="text"
                    id="logistics"
                    name="logistics"
                    value={logisticsValue}
                    pattern="[0-9.,]*"
                    onChange={(e) => setLogisticsValue(e.target.value)}
                  />
                  <StyledMeasure>Р/т</StyledMeasure>
                </StyledInputWrapper>
              </div>
              <div>
                <InputLabel>Агентские</InputLabel>
                <StyledInputWrapper>
                  <StyledInput
                    type="text"
                    id="agents"
                    name="agents"
                    value={agentsValue}
                    pattern="[0-9.,]*"
                    onChange={(e) => setAgentsValue(e.target.value)}
                  />
                  <StyledMeasure>Р/т</StyledMeasure>
                </StyledInputWrapper>
              </div>
              <div>
                <InputLabel>Доп. расходы</InputLabel>
                <StyledInputWrapper>
                  <StyledInput
                    type="text"
                    id="additionalCosts"
                    name="additionalCosts"
                    value={additionalCostsValue}
                    pattern="[0-9.,]*"
                    onChange={(e) => setadditionalCostsValue(e.target.value)}
                  />
                  <StyledMeasure>Р/т</StyledMeasure>
                </StyledInputWrapper>
              </div>
            </InputsWrapper>
            <InputsWrapper>
              <StyledSelect>
                <SubHeader>Есть ли НДС в сделке</SubHeader>
                <Select
                  defaultValue={isTaxable ? { label: 'С НДС', value: 'true' } : { label: 'Без НДС', value: 'false' }}
                  options={[
                    { label: 'С НДС', value: 'true' },
                    { label: 'Без НДС', value: 'false' },
                  ]}
                  onChange={(e) => setIsOrderTaxable(e.value)}
                />
              </StyledSelect>
              <StyledSelect>
                <SubHeader>Съёмы</SubHeader>
                <Select
                  defaultValue={
                    isWithdrawalsIncluded
                      ? { label: 'Считать съёмы', value: 'true' }
                      : { label: 'Не считать съёмы', value: 'false' }
                  }
                  options={[
                    { label: 'Считать съёмы', value: 'true' },
                    { label: 'Не считать съёмы', value: 'false' },
                  ]}
                  onChange={(e) => setIsWithdrawalsIncluded(e.value === 'true' ? true : false)}
                />
              </StyledSelect>
            </InputsWrapper>

            <StyledSelect>
              <SubHeader>{isCPT ? 'Склад' : 'Пункт погрузки'}</SubHeader>
              {isCPT ? (
                <Select
                  placeholder={typeof selectedWarehouse !== 'string' ? selectedWarehouse.label : 'Склад'}
                  options={warehousesOptions}
                  onChange={handleWarehouseSelectChange}
                />
              ) : (
                <StyledInput
                  type="text"
                  name="warehouseAddress"
                  value={warehouseAddress}
                  onChange={(e) => setWarehouseAddress(e.target.value)}
                  placeholder="Введите адрес пункта погрузки"
                />
              )}
            </StyledSelect>
            <StyledSelect>
              <SubHeader>Порядок оплаты</SubHeader>
              <Select
                defaultValue={
                  selectedProcedure !== false ? selectedProcedure : { label: 'Выберите порядок оплаты', value: '' }
                }
                options={specsOrdersStore.paymentProceduresOptions}
                onChange={(e) => setActivePaymentProcedure(e.value)}
                menuPlacement="top"
              />
            </StyledSelect>
            <SendToSmartButton type="button" onClick={handleSendToSmart}>
              Отправить в Смарт
            </SendToSmartButton>
            <DiscardChangesButton type="button" onClick={handleModalClose}>
              Отмена
            </DiscardChangesButton>
          </ContentWrapper>
        </ModalContent>
      </StyledModal>
    );
  },
);

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 446px;
  }
  .modal-header {
    border: none;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }
  .btn-close {
    background: none;
    width: 40px;
    height: 40px;
    position: absolute;
    right: -11px;
    top: -8px;
    z-index: 1;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const InputsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const StyledInput = styled.input`
  padding: 16px;
  width: 100%;
  text-align: left;
  color: #292929;
  font-weight: 500;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #dcdee4;
  border-radius: 12px;
  &:disabled {
    background-color: #e9ebf1;
    color: #292929;
  }
`;

const StyledInputWrapper = styled.div`
  display: block;
  position: relative;
  flex-grow: 1;
  width: 100%;
  min-width: 100px;
  ${StyledInput} {
    padding-right: 40px;
  }
`;

const StyledMeasure = styled.span`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  font-size: 12px;
  font-weight: 500;
  color: #292929;
  border-left: 1px solid rgba(101, 101, 101, 0.3);
  display: block;
  padding-left: 8px;
  cursor: text;
`;

const InputLabel = styled.label`
  margin-bottom: 6px;
  padding-left: 8px;
  color: #292929;
  font-weight: 600;
  font-size: 15px;
`;

const StyledSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  .Select__control {
    max-width: 100%;
    border: 1px solid #dcdee4;
    max-height: 52px;
    border-radius: 12px;
    background-color: #fff;
  }
  .Select__value-container {
    padding: 10px 16px;
  }
`;

const SendToSmartButton = styled.button`
  width: 100%;
  background-color: #8e66fe;
  border-radius: 12px;
  border: 1px solid #825dec;
  padding: 18px 0px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
`;

const DiscardChangesButton = styled.button`
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #825dec;
  padding: 18px 0px;
  color: #8e66fe;
  font-weight: 600;
  font-size: 18px;
`;
