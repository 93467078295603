import React from 'react';

interface IProps {
  size?: string;
}

/**
 * Иконка "Стрелка вниз".
 */
export const ChevronDownIcon = ({ size }: IProps) => (
  <svg width="15" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.205 9.352c-.383 0-.703-.141-1.023-.461L.4 2.977a1.186 1.186 0 0 1-.351-.86C.049 1.422.603.86 1.29.86c.344 0 .664.141.922.399l5 5.156 4.992-5.156c.258-.258.578-.399.914-.399.688 0 1.242.563 1.242 1.258 0 .344-.117.625-.351.86L8.228 8.89c-.304.312-.632.46-1.023.46Z"
      fill="#8E66FE"
    />
  </svg>
);
