import styled from 'styled-components';

interface IProgressBarProps {
  title: string;
  currentValue: number;
  totalValue: number;
  isPurple?: boolean;
}

export const ProgressBar: React.FC<IProgressBarProps> = ({ title, currentValue, totalValue, isPurple }) => {
  const progress = (currentValue / totalValue) * 100;

  return (
    <ProgressBarWrapper isPurple={isPurple}>
      <ProgressBarTitle isPurple={isPurple}>{title}</ProgressBarTitle>
      <ProgressBarInnerWrapper>
        <ProgressBarTotal isPurple={isPurple}>
          <ProgressBarDone progress={progress} isPurple={isPurple} />
        </ProgressBarTotal>
        <ProgressBarNumbers>
          <ProgressBarNumbersDone>{currentValue}</ProgressBarNumbersDone>
          <ProgressBarNumbersTotal>{totalValue}</ProgressBarNumbersTotal>
        </ProgressBarNumbers>
      </ProgressBarInnerWrapper>
    </ProgressBarWrapper>
  );
};

interface IProgressBarTheme {
  isPurple?: boolean;
}
const ProgressBarWrapper = styled.div<IProgressBarTheme>`
  display: flex;
  gap: 22px;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  background-color: ${(p) => (p.isPurple ? '#f4f0ff' : '#F0F2FF')};
  border: ${(p) => (p.isPurple ? '1px solid #e8e0ff' : '1px solid #e0e5ff')};
  border-radius: 8px;
`;

const ProgressBarTitle = styled.span<IProgressBarTheme>`
  font-size: 14px;
  font-weight: 700;
  color: ${(p) => (p.isPurple ? '#a485fe' : '#8598FE')};
`;

const ProgressBarInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ProgressBarTotal = styled.div<IProgressBarTheme>`
  width: 100%;
  height: 10px;
  border-radius: 8px;
  background-color: ${(p) => (p.isPurple ? '#e8e0ff' : '#E0E5FF')};
`;

interface IProgressBarDone extends IProgressBarTheme {
  progress: number;
}

const ProgressBarDone = styled.div<IProgressBarDone>`
  width: ${(p) => p.progress}%;
  height: 100%;
  border-radius: 8px;
  background-color: ${(p) => (p.isPurple ? '#8e66fe' : '#667EFE')};
`;

const ProgressBarNumbers = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ProgressBarNumbersDone = styled.span`
  font-size: 15px;
  font-weight: 600;
  color: #292929;
`;

const ProgressBarNumbersTotal = styled.span`
  font-size: 15px;
  font-weight: 400;
  color: #656565;
`;
