export const getLocalRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem('user') as string);
  return user?.refreshToken;
};
export const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem('user') as string);
  return user?.token;
};
export const updateLocalAccessToken = (token: string) => {
  const user = JSON.parse(localStorage.getItem('user') as string);
  user.token = token;
  localStorage.setItem('user', JSON.stringify(user));
};
export const getUser = () => {
  return JSON.parse(localStorage.getItem('user') as string);
};
export const getProfile = () => {
  return JSON.parse(localStorage.getItem('profile') as string);
};
export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('profile');
};
export const setTokens = (user: any) => {
  // const tokens = { accessToken: user.token, refreshToken: user.refreshToken };
  localStorage.setItem('user', JSON.stringify(user));
};
export const setProfile = (profile: any) => {
  localStorage.setItem('profile', JSON.stringify(profile));
};
export const removeUser = () => {
  localStorage.removeItem('user');
};
export const getSavedCalendar = () => {
  return JSON.parse(localStorage.getItem('calendar') as string);
};
export const setSavedCalendar = (calendar: any) => {
  localStorage.setItem('calendar', JSON.stringify(calendar));
};
