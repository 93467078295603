import { EMPTY_CHAR } from 'utils/consts';

// export const getOrderStatusName = (statusCode: string) => {
//   switch (statusCode) {
//     case 'step1':
//     case 'step2_onway':
//     case 'step2_tests_taken':
//     case 'step2_quality':
//     case 'step2_quality_accepted':
//     case 'step3':
//     case 'step3_docs_ready':
//     case 'step3_sign_ready':
//     case 'step3_prebill_send':
//     case 'step4':
//     case 'step4_plan_ready':
//     case 'step4_selected_date':
//     case 'step4_date_approved':
//     case 'step4_loading_started':
//     case 'step4_over_today':
//     case 'step4_over_contract':
//     case 'step4_done':
//     case 'step5_verification_started':
//     case 'step5_verificated':
//     case 'step5_letter_for_refund_approved':
//       return 'Сделка создана';
//     case 'accepted':
//       return 'Сделка принята';
//     case 'pending':
//       return 'Сделка в процессе';
//     case 'partial':
//       return 'Сделка частично завершена';
//     case 'step6_order_closed':
//       return 'Сделка завершена';
//     case 'step2_quality_failed':
//       return 'Сделка аннулирована';
//     default:
//       return EMPTY_CHAR;
//   }
// };

export const getTextCode = (modalTitle: string, title?: string) => {
  switch (modalTitle) {
    case 'specialist-on-way':
      return 'Специалист выехал';
    case 'examples_collected':
      return 'Пробы отобраны';
    case 'document_signed':
      return 'Документы подписаны';
    case 'examples_delivered_to_lab':
      return 'Пробы доставлены в лабораторию';
    case 'call_specialist':
      return 'Специалист выехал';
    case 'quality_approved':
      return 'Подтвердить качество';
    case 'quality_failed':
      return 'Зерно не соответствует качеству';
    case 'step3_ready':
    case 'letter_for_refund_approved':
    case 'daily_docs_confirmed':
    case 'surcharge_made':
      return 'Готово';
    case 'step3_prebill_ready':
      return 'Предоплата отправлена';
    case 'loading_plan_confirmed':
      return 'План погрузки утвержден';
    case 'loading_date_selected':
      return 'Подтвердить';
    case 'loading_started':
      return 'Погрузка началась';
    case 'loading_finished':
      return 'Завершить погрузку на сегодня';
    case 'contract_loading_finished':
      return 'Завершить погрузку по контракту';
    case 'refund_made':
      return 'Деньги пришли';
    case 'order_closed':
      return 'Закрыть сделку';
    default:
      return title || EMPTY_CHAR;
  }
};

export const getTaxTypeForm = (modalTitle: string | any) => {
  switch (modalTitle) {
    case 'simple':
      return 'Упрощённая';
    case 'main':
      return 'Основная';
    default:
      return EMPTY_CHAR;
  }
};
export const getStaffType = (modalTitle: string | any) => {
  switch (modalTitle) {
    case 'quality control':
      return 'Контроль качества';
    case 'shipment':
      return 'Отгрузка';
    case 'senior accountant':
      return 'Главный бухгалтер';
    case 'document flow':
      return 'Документооборот';
    case 'general director':
      return 'Генеральный директор';
    default:
      return EMPTY_CHAR;
  }
};

export const getModalText = (modalTitle: string, code: string, additionalTitle?: string) => {
  switch (modalTitle) {
    case 'specialist-on-way':
      return 'Специалист выехал';
    case 'examples_collected':
      return 'Пробы отобраны';
    case 'document_signed':
      return 'Документы подписаны';
    case 'examples_delivered_to_lab':
      return 'Пробы доставлены в лабораторию';
    case 'call_specialist':
      return 'Специалист выехал';
    case 'quality_approved':
      return 'Подтвердить качество';
    case 'quality_failed':
      return 'Сделка будет аннулирована';
    case 'step3_ready':
      return 'Отправить документы';
    case 'step3_prebill':
    case 'step3_prebill_ready':
      return 'Предоплата отправлена';
    case 'daily_docs_confirmed':
    case 'letter_for_refund_approved':
      return 'Отправить документы';
    case 'loading_started':
      return 'Погрузка началась';
    case 'loading_finished':
      return 'Завершить погрузку на сегодня';
    case 'contract_loading_finished':
      return 'Завершить погрузку по контракту';
    case 'refund_made':
    case 'surcharge_made':
      return 'Подтверждаете получение денег';
    case 'loading_plan_confirmed':
      return 'План погрузки утвержден';
    case 'loading_date_selected':
      return `Назначить дату следующей погрузки на ${code || EMPTY_CHAR}`;
    case 'order_closed':
      return 'Закрыть сделку';
    default:
      return additionalTitle || EMPTY_CHAR;
  }
};
export const getModalSubText = (modalTitle: string) => {
  switch (modalTitle) {
    case 'quality_failed':
      return 'Сделка будет аннулирована';
    case 'document_signed':
      return 'Следующий этап — Проверка качества';
    case 'call_specialist':
    case 'examples_collected':
    case 'examples_delivered_to_lab':
    case 'quality_approved':
      return 'Следующий этап — предоплата';
    case 'step3_ready':
    case 'step3_prebill':
    case 'step3_prebill_ready':
      return 'Следующий этап — погрузка';
    case 'loading_date_selected':
    case 'loading_started':
    case 'loading_finished':
    case 'daily_cars_table_uploaded':
    case 'contract_loading_finished':
    case 'loading_plan_confirmed':
    case 'daily_docs_confirmed':
      return 'Следующий этап — сверка';
    case 'refund_made':
    case 'surcharge_made':
      return 'Следующий этап — отзыв';
    default:
      return EMPTY_CHAR;
  }
};

export const getModalBtnText = (modalTitle: string, additionalText?: string) => {
  switch (modalTitle) {
    case 'call_specialist':
    case 'examples_collected':
    case 'document_signed':
    case 'refund_made':
    case 'surcharge_made':
      return 'Да, подтверждаю';
    case 'examples_delivered_to_lab':
      return 'Да, доставлены';
    case 'quality_approved':
      return 'Качество подтверждаю';
    case 'quality_failed':
      return 'Аннулировать сделку';
    case 'step3_ready':
    case 'daily_docs_confirmed':
    case 'letter_for_refund_approved':
      return 'Да, отправить';
    case 'step3_prebill':
    case 'step3_prebill_ready':
      return 'Да, отправлена';
    case 'loading_plan_confirmed':
      return 'Да, утвержден';
    case 'loading_date_selected':
      return 'Да, назначить';
    case 'loading_started':
      return 'Да, началась';
    case 'loading_finished':
    case 'contract_loading_finished':
      return 'Да, завершить';
    case 'order_closed':
      return 'Да, закрыть';
    default:
      return additionalText || EMPTY_CHAR;
  }
};

export const getStepNumber = (text: string) => {
  switch (text) {
    case 'step1':
      return '1';
    case 'step2':
      return '2';
    case 'step3':
      return '3';
    case 'step4':
      return '4';
    case 'step5':
      return '5';
    case 'step6':
      return '6';
    default:
      return EMPTY_CHAR;
  }
};

export const getStepTitle = (text: string) => {
  switch (text) {
    case 'step1':
      return 'Предложение принято';
    case 'step2':
      return 'Проверка качества';
    case 'step3':
      return 'Предоплата';
    case 'step4':
      return 'Погрузка';
    case 'step5':
      return 'Сверка';
    case 'step6':
      return 'Отзыв';
    default:
      return '';
  }
};

export const dateFormat = (date: string) => {
  const formatter = new Intl.DateTimeFormat('ru', {
    month: 'long',
    day: 'numeric',
  });
  return formatter.format(new Date(date));
};

export const dateFormatTimeline = (date: string) => {
  const formatter = new Intl.DateTimeFormat('ru', {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
  return formatter.format(new Date(date));
};
