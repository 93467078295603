import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Formik, Form } from 'formik';

import { EMPTY_CHAR } from 'utils/consts';
import { Button, Flex, Input, Spacer, Typography } from 'uikit';
import styled from 'styled-components';
import { theme } from 'theme';
import { Loader } from 'uikit/Loader';
import { useStore } from 'store/Root';
import { useQuery } from 'react-query';
import { ModalPasswordRecovery } from 'uikit/Modals/ModalPasswordRecovery';

export interface ILoginValues {
  email: string;
  password: string;
}

export const AuthPage: React.FC = observer(() => {
  const { authStore } = useStore();

  const [userCredentials, setUserCredentials] = useState<ILoginValues>();
  const [showPassRecovery, setShowPassRecovery] = useState(false);

  const fetchUser = useQuery<any>('AUTH_DATA_QUERY_KEY', () => authStore.login(userCredentials), {
    enabled: !!userCredentials,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
  });

  const { isError, refetch: fetchUserData, isFetching } = fetchUser;

  const handleLogin = (values: ILoginValues) => {
    setUserCredentials(values);
  };

  useEffect(() => {
    const { email, password } = userCredentials || {};
    const hasData = !!email && !!password;
    hasData && fetchUserData();
  }, [fetchUserData, userCredentials]);

  const toggleRecoveryModal = (event: any) => {
    event.preventDefault();
    setShowPassRecovery(!showPassRecovery);
  };

  return isFetching ? (
    <Loader />
  ) : (
    <>
      <Spacer space={180} />
      <Flex hAlignContent="center">
        <AuthInnerWrapper>
          <Flex column hAlignContent="center">
            <Typography bold size="xl">
              Daylesford
            </Typography>
            <Spacer />
            <Typography align="center">Закупка зерновых по всей России покупка и продажа аграрной продукции</Typography>
            <Spacer space={32} />
            {isError && (
              <Flex column vAlignContent="center" hAlignContent="center">
                <Typography color={theme.palette.common.colors.red}>
                  Что-то пошло не так. Пожалуйста, попробуйте напечатать заново
                </Typography>
                <Spacer space={32} />
              </Flex>
            )}

            <Formik
              initialValues={{
                email: EMPTY_CHAR,
                password: EMPTY_CHAR,
              }}
              onSubmit={handleLogin}
            >
              <Form>
                <Flex column>
                  <Input type="value" name="email" label="Имя пользователя" isError={isError} variant="dark" />
                  <Spacer />
                  <Input type="password" name="password" label="Пароль" isError={isError} variant="dark" />
                  <div tabIndex={-1}>
                    <RecoveryButton onClick={toggleRecoveryModal}>Забыли пароль?</RecoveryButton>
                  </div>
                  <Spacer space={30} />
                  <Button variant="base" type="submit">
                    Отправить
                  </Button>
                  <Spacer />
                </Flex>
              </Form>
            </Formik>
          </Flex>
        </AuthInnerWrapper>
        {showPassRecovery && (
          <ModalPasswordRecovery isActive={showPassRecovery} setShowModalState={setShowPassRecovery} />
        )}
      </Flex>
    </>
  );
});

const AuthInnerWrapper = styled.div`
  width: 345px;
`;
const RecoveryButton = styled.div`
  width: max-content;
  width: 192px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  border-radius: 10px;
  color: #918f89;
  -webkit-transition: 0.3s;
  transition: 0.3s;
`;
