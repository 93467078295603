import React from 'react';
import { observer } from 'mobx-react-lite';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import styled from 'styled-components';
import { Spacer, Typography } from 'uikit';

export interface LineChartProps {
  chartData: {
    title: string;
    legend: { value: string; value2?: string };
    data: [
      {
        name: string;
        count?: number | null;
        value?: number;
        value2?: string | null;
      },
    ];
  };
}

export const LineChartAnalitycs: React.FC<LineChartProps> = observer(({ chartData }) => {
  const renderLegend = (props: any) => {
    const { payload } = props;
    return (
      <>
        <LegendWrapper>
          <ul>
            {payload.map((entry: any, index: any) => (
              <li style={{ color: entry.color }} key={`item-${index}`}>
                {/* @ts-ignore */}
                {chartData.legend[entry.dataKey]}
              </li>
            ))}
          </ul>
        </LegendWrapper>
      </>
    );
  };

  return (
    <>
      <AnalitycsWrapper>
        <Typography bold size="lg2">
          {chartData.title}
        </Typography>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={800}
            height={300}
            data={chartData.data}
            margin={{
              top: 40,
              right: 30,
              left: 20,
              bottom: 15,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend content={renderLegend} />
            <Line type="monotone" dataKey="value" stroke="#8884d8" />
            {!!chartData.data[0].value2 && <Line type="monotone" dataKey="value2" stroke="#82ca9d" />}
          </LineChart>
        </ResponsiveContainer>
      </AnalitycsWrapper>
      <Spacer space={60} />
    </>
  );
});
const AnalitycsWrapper = styled.div`
  width: 800px;
  height: 390px;
`;
const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
