import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { Flex } from '..';

interface IProps {
  fillHeight?: boolean;
  fillWidth?: boolean;
}

export const Loader: React.FC<IProps> = ({
  fillHeight = true,
  fillWidth = true,
}: IProps) => {
  return (
    <Flex
      vAlignContent="center"
      hAlignContent="center"
      fillHeight={fillHeight}
      fillWidth={fillWidth}
    >
      <Spinner animation="border" variant="primary" />
    </Flex>
  );
};
