import React from 'react';
import styled from 'styled-components';
import { Flex } from 'uikit';

import { NavSidebar } from './NavSidebar';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/Root';

interface ILayoutProps {
  children: React.ReactNode;
}

/**
 * Компонент лейаута страницы приложения.
 */
export const Layout: React.FC<ILayoutProps> = observer(({ children }) => {
  const { authStore } = useStore();
  const { isAuthenticated } = authStore;
  return (
    <Wrapper>
      {isAuthenticated && <NavSidebar />}
      <Content column grow $isAuthenticated={isAuthenticated}>
        {children}
      </Content>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: #f4f6f6;
`;

const Content = styled<any>(Flex)`
  margin-left: ${({ $isAuthenticated }) => $isAuthenticated && 276}px;
  flex: 1 0 auto !important;
`;
