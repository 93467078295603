import { STORAGE_TOKEN_NAME, STORAGE_TOKEN_TYPE } from '../hooks/consts';

export const apiNames = {
  auth: 'auth',
  offers: 'offers',
  products: 'products',
  warehouses: 'warehouses',
  orders: 'orders',
  specsOrders: 'order_specs',
  dmOrders: 'dm_orders',
  steps: 'steps',
  farmers: 'farmers',
  users: 'users',
  documents: 'documents',
  analytics: 'analytics/data_sources',
  staff: 'profiles/staff',
  providers: 'union_orders/providers',
  ownerProviders: 'providers',
  company: 'profiles/profile',
  refreshToken: 'token/refresh/',
  recovery: 'auth/password_recovery',
  passwordReset: 'auth/password_reset',
  saleRequests: 'sale_requests',
  saleRequestBids: 'sale_request_bids',
  prices: 'prices',
  cultures: 'cultures',
  paymentProcedures: 'payment_procedures',
  profiles: 'profiles',
};

/**
 * Хранилище конфигурации приложения.
 */
class AppConfig {
  get api() {
    const {
      auth,
      offers,
      products,
      warehouses,
      orders,
      specsOrders,
      dmOrders,
      steps,
      farmers,
      documents,
      analytics,
      staff,
      company,
      users,
      recovery,
      passwordReset,
      saleRequests,
      saleRequestBids,
      prices,
      cultures,
      providers,
      ownerProviders,
      paymentProcedures,
      profiles,
    } = apiNames;

    return {
      auth: auth,
      offers: offers,
      products: products,
      warehouses: warehouses,
      orders: orders,
      specsOrders: specsOrders,
      dmOrders: dmOrders,
      steps: steps,
      farmers: farmers,
      documents: documents,
      analytics: analytics,
      prices: prices,
      staff: staff,
      users: users,
      providers: providers,
      ownerProviders: ownerProviders,
      company: company,
      saleRequests: saleRequests,
      saleRequestBids: saleRequestBids,
      cultures: cultures,
      recovery: recovery,
      passwordReset: passwordReset,
      token: localStorage.getItem(STORAGE_TOKEN_NAME),
      tokenType: localStorage.getItem(STORAGE_TOKEN_TYPE),
      paymentProcedures: paymentProcedures,
      profiles: profiles,
    };
  }
}

export const appConfig = new AppConfig();
