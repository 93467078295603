import React from 'react';
import styled from 'styled-components';
import { Button, Flex, Spacer } from 'uikit';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/Root';
import { StyledSearchInput } from 'components/Farmer/pages/FarmersListPage';
import { Select } from 'uikit/Selects';
import { accreditationOptions } from 'utils/consts';

export const DashboardFarmerFilters: React.FC = observer(() => {
  const { contactsDashboardStore } = useStore();

  const searchInput = contactsDashboardStore.searchInput;

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    contactsDashboardStore.setSearchInput(event.target.value);
  };

  const listOptions = accreditationOptions.map(({ label, id: value }) => ({ label, value }));
  return (
    <div>
      <Flex gap={20}>
        <StyledSearchInput
          type="value"
          name="search"
          value={searchInput || ''}
          onChange={handleSearchInputChange}
          placeholder="Введите данные для поиска"
        />
        <Select
          options={listOptions}
          onChange={contactsDashboardStore.handleFarmerAccStatusChange}
          //   disabled={fetchChartsCategories.isFetching}
        />
      </Flex>
      <Spacer space={20} />
      <Button variant="base" type="button" onClick={() => contactsDashboardStore.fetchWithReset()}>
        Поиск
      </Button>
    </div>
  );
});
