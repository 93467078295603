import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex, Typography } from 'uikit';
import Modal from 'react-bootstrap/esm/Modal';
import { ReactComponent as ChechIcon } from 'assets/img/check.svg';
import { ReactComponent as TrashIcon } from 'assets/img/trash.svg';
import { ReactComponent as AtantionIcon } from 'assets/img/atantion.svg';
import { ReactComponent as InfoIcon } from 'assets/img/info.svg';

interface IModal {
  isActive: boolean;
  isCreate?: boolean;
  isDelete?: boolean;
  isEdit?: boolean;
  text?: string;
  type?: string;
}

export const ModalCheck: React.FC<IModal> = ({
  isActive,
  isCreate = false,
  isDelete = false,
  isEdit = false,
  text = '',
  type = 'success',
}) => {
  const [showModalState, setShowModalState] = useState<any>(isActive);
  const handleModalClose = () => setShowModalState(false);

  const isError = type === 'error';
  const isSuccess = type === 'success';
  const isInfo = type === 'info';

  return (
    <Popup
      show={showModalState}
      centered
      onHide={handleModalClose}
      contentClassName="content-alternative"
    >
      {isSuccess && (
        <StyledModalBody>
          <Flex vAlignContent="center" hAlignContent="left">
            <div className="icon">
              {(isCreate || isEdit) && <ChechIcon />}
              {isDelete && <TrashIcon />}
            </div>
            <Typography className="text" size="md">
              {(isCreate || isDelete || isEdit) && <> Предложение успешно</>}
              {isCreate && <> создано</>}
              {isDelete && <> удалено</>}
              {isEdit && <> отредактировано</>}
              {text}
            </Typography>
          </Flex>
        </StyledModalBody>
      )}
      {isError && (
        <StyledModalBodyErr>
          <Flex vAlignContent="center" hAlignContent="left">
            <div className="icon">{isError && <AtantionIcon />}</div>
            <Typography className="text" size="md">
              {text}
            </Typography>
          </Flex>
        </StyledModalBodyErr>
      )}
      {isInfo && (
        <StyledModalBodyInfo>
          <Flex vAlignContent="center" hAlignContent="left">
            <div className="icon">{isInfo && <InfoIcon />}</div>
            <Typography className="text" size="md">
              {text}
            </Typography>
          </Flex>
        </StyledModalBodyInfo>
      )}
    </Popup>
  );
};

const Popup = styled(Modal)`
  .content-alternative {
    border: none;
    box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);
    border-radius: 10px;

    background-color: none;
    min-width: 380px;
  }
`;

const StyledModalBody = styled(Modal.Body)`
  background-color: #dff5e7;
  color: rgb(51, 51, 51);
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
  padding: 16px;

  .icon {
    width: 60px;
    height: 60px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 30px;
    color: #33bc62;
    font-size: 28px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
  .text {
    margin-left: 28px;
    max-width: 325px;
  }
`;
const StyledModalBodyErr = styled(StyledModalBody)`
  background-color: #ffeeec;
`;
const StyledModalBodyInfo = styled(StyledModalBody)`
  background-color: #f4e7c8;
`;
