/**
 * Иконка "Кружок прогресса".
 */

export const ProgressCircleIcon = () => {
  return (
    <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 14.126c3.862 0 7.062-3.192 7.062-7.062 0-3.862-3.2-7.061-7.069-7.061-3.862 0-7.055 3.2-7.055 7.061 0 3.87 3.2 7.062 7.062 7.062Zm0-1.388a5.654 5.654 0 0 1-5.667-5.674 5.642 5.642 0 0 1 5.66-5.667 5.66 5.66 0 0 1 5.68 5.667A5.66 5.66 0 0 1 7.5 12.738Z"
        fill="#E8E8E8"
      />
    </svg>
  );
};
