import { flow, types, getRoot, cast, Instance } from 'mobx-state-tree';
import { daylesfordService } from 'services';
import { ITeamMember } from 'services/models';
import { setSavedCalendar } from 'services/token-service';
import { IRootStore } from './Root';
import { isLeapYear, parse, compareAsc } from 'date-fns';

const { model, string, identifier, array, map } = types;

const CalendarYear = model('CalendarYear', {
  year: identifier,
  highlitedArr: array(types.Date),
});
export interface ICalendarYear extends Instance<typeof CalendarYear> {}

export const SettingsStore = model('SettingsStore', {
  calendarYears: map(CalendarYear),
  calendarCurrentYear: string,
  isLoading: false,
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .views((self) => ({
    get getCalendarHighlightedArr() {
      const year = self.calendarYears.get(self.calendarCurrentYear);
      return year ? year.highlitedArr : [];
    },
  }))
  .views((self) => ({
    get getCalendarWorkingDays() {
      const year = self.calendarYears.get(self.calendarCurrentYear);
      const daysInYear = isLeapYear(new Date(self.calendarCurrentYear)) ? 366 : 365;
      return year ? daysInYear - year.highlitedArr.length : daysInYear;
    },
  }))
  .views((self) => ({
    get getCalendarNonWorkingDays() {
      const year = self.calendarYears.get(self.calendarCurrentYear);
      return year ? year.highlitedArr.length : 0;
    },
  }))
  .views((self) => ({
    get startOfCurrentYear() {
      return parse(self.calendarCurrentYear, 'yyyy', new Date());
    },
  }))
  .views(() => ({
    get getCalendarYears() {
      const yearArray = [];
      for (let i = 20; i <= 30; i++) {
        yearArray.push(`20${i}`);
      }
      return yearArray.map((year) => {
        return { label: year, value: year };
      });
    },
  }))

  .actions((self) => {
    return {
      setSavedCalendar(value: any) {
        if (!value) return;
        Object.keys(value).map((year) => {
          self.calendarYears.put({ year, highlitedArr: cast(value[year].highlitedArr) });
        });
      },
    };
  })
  .actions((self) => {
    return {
      saveCalendar() {
        setSavedCalendar(self.calendarYears);
      },
    };
  })
  .actions((self) => {
    return {
      setCalendarHighlightedArr(value: Date) {
        const year = self.calendarYears.get(self.calendarCurrentYear);
        const exist = year?.highlitedArr.findIndex((val) => {
          const isExist = compareAsc(value, val);
          return isExist === 0;
        });
        let finalArr: Date[] = [];
        if (exist! > -1) {
          finalArr = year ? year.highlitedArr.filter((item, idx) => idx !== exist) : [];
        } else {
          const merged = year ? [...year.highlitedArr, value] : [value];
          finalArr = merged;
        }
        self.calendarYears.put({ year: self.calendarCurrentYear ?? '2022', highlitedArr: cast(finalArr) });
      },
    };
  })
  .actions((self) => {
    return {
      setCurrentYear(value: string) {
        self.calendarCurrentYear = value;
      },
    };
  })
  .actions((self) => {
    return {
      editTeamMember: flow(function* editTeamMember(request: ITeamMember): any {
        request.company = self.root.authStore.user?.company.id;
        const team = yield daylesfordService
          .editTeamMember(request)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Редактирование команды`, 'error'));
        return team.data;
      }),
    };
  })
  .actions((self) => {
    return {
      createTeamMember: flow(function* createTeamMember(request: ITeamMember): any {
        request.company = self.root.authStore.user?.company.id;
        const team = yield daylesfordService
          .createTeamMember(request)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Редактирование команды`, 'error'));
        return team.data;
      }),
    };
  });
