import React, { useRef, useState } from 'react';
import { YMaps, Map, Placemark, SearchControl } from '@pbe/react-yandex-maps';

import { Flex, Typography } from 'uikit';
import styled from 'styled-components';
import { StyledModal, ModalContent } from 'components/Orders/pages/OrderPage';
import Modal from 'react-bootstrap/esm/Modal';

export const DeliverySettings: React.FC = () => {
  const [showModalState, setShowModalState] = useState(false);
  const mapRef = useRef<HTMLInputElement>(null);

  const showModal = () => setShowModalState(true);
  const closeModal = () => setShowModalState(false);

  //   console.log('mapRef', mapRef);

  return (
    <Wrapper>
      <Flex column>
        <Typography size="xs">Точки назначения</Typography>
        <AddressBlock>
          <AddressTitle>Порт Новороссийск</AddressTitle>
          <AddressDescr>Санкт-Петербург, Савушкина 58а</AddressDescr>
        </AddressBlock>

        <NewAddress onClick={showModal}>Добавить точку</NewAddress>
        <StyledModal show={showModalState} onHide={closeModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton />
          <ModalContent>
            <Flex hAlignContent="center" vAlignContent="center" column>
              <YMaps>
                <div>
                  <Map
                    // @ts-ignore
                    instanceRef={mapRef}
                    width="600px"
                    height="400px"
                    defaultState={{ center: [55.75, 37.57], zoom: 9 }}
                  >
                    <Placemark defaultGeometry={[55.751574, 37.573856]} />
                    <SearchControl
                      options={{
                        float: 'right',
                      }}
                    />
                  </Map>
                </div>
              </YMaps>
            </Flex>
          </ModalContent>
        </StyledModal>
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 44px;
`;
const AddressBlock = styled.div`
  margin: 1rem 0;
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  background: #e7e2d4;
  border-radius: 10px;
`;
const AddressTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
`;
const AddressDescr = styled.span`
  color: #807e7b;
`;
const NewAddress = styled.span`
  color: #407ef5;
  cursor: pointer;
`;
