import { useQuery } from 'react-query';

const PROFILES_LIST_DATA_QUERY_KEY = 'PROFILES_LIST_DATA_QUERY_KEY';

export const useProfiles = (hasProfiles: boolean, getProfilesRequest: any) => {
  const result = useQuery([PROFILES_LIST_DATA_QUERY_KEY, hasProfiles], () => getProfilesRequest(), {
    enabled: !hasProfiles,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });
  return result;
};
