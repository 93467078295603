import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useMutation, useQuery } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { useStore } from 'store/Root';
import { IRouteParams } from 'utils/models';
import { Loader } from 'uikit/Loader';
import { useCultures } from 'hooks/useCultures';
import { BackIcon } from 'uikit/Icons/BackIcon';
import { routes } from 'routes/consts';
import {
  OrderTableName,
  OrderTableNote,
  OrderTableRow,
  OrderTableSubGrid,
  OrderTableValue,
  OrderTableWrapper,
} from './components/SpecOrdersDataList';
import { getProfile } from 'services/token-service';
import {
  DownloadLinkWrapper,
  MakeChangesButton,
  OrderDataHeader,
  OrderDataHeaderWrapper,
  OrderDataSubheader,
  OrderDataWrapper,
  SignedSpecHeader,
  SignedSpecHeaderWrapper,
  SignedSpecStatus,
  SpecBackground,
} from './SpecsOrderPage';
import {
  IndicatorsRow,
  IndicatorName,
  IndicatorValue,
  AwaitNotification,
  IndicatorValueLabel,
  IndicatorsHeader,
} from 'components/Inquiries/pages/InquiryPage';
import { DownloadIcon } from 'uikit/Icons/DownloadIcon';
import { ProgressCheckmarkIcon } from 'uikit/Icons/ProgressCheckmarkIcon';
import { downloadFile } from 'services/fileDownload';
import { SpecOrderReSignModal } from './components/SpecOrderReSignModal';
import { ISpecDmOrder } from 'store/SpecsOrdersStore';
import { toJS } from 'mobx';

export const SpecsOrderDmPage: React.FC = observer(() => {
  const { id }: IRouteParams = useParams();
  const { specsOrdersStore, culturesStore } = useStore();
  const [showReSignModalState, setShowReSignModalState] = React.useState(false);
  useCultures(culturesStore.hasCultures, culturesStore.getCultures);

  const navigate = useNavigate();
  const handleClickBack = (): void => {
    navigate(`../${generatePath(routes.specsOrders.list.path)}`);
  };

  const paramId = id ?? '';

  const specsOrderData = useQuery(['ORDER_DATA_QUERY_KEY', paramId], () => specsOrdersStore.getDmOrder(paramId), {
    enabled: !!paramId,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });

  interface resignVariables {
    id: string;
  }

  const resignMutation = useMutation((variables: resignVariables) => specsOrdersStore.resignDmOrder(variables.id), {
    onSuccess: (e, variables) => {
      const path = generatePath(routes.specsOrders.timeline.path, { id: variables.id });

      navigate(`../${path}`);
    },
  });

  useEffect(() => {
    if (specsOrderData.isSuccess) {
      const order = specsOrdersStore.dmOrders.get(paramId) as ISpecDmOrder;
      const culture =
        order.cultureId !== null && culturesStore.cultures.find((item) => item.id === Number(order.cultureId));
      if (culture && order.indicators !== null) {
        const indicators = culture.indicators;
        const orderIndicators = order.indicators;
        if (
          orderIndicators.length !== 0 &&
          orderIndicators.some((orderIndicator) => orderIndicator.limitValue === undefined)
        ) {
          const mergedIndicators = indicators.map((cultureIndicator) => {
            const orderIndicator = orderIndicators.find((orderIndicator) => orderIndicator.id === cultureIndicator.id);
            if (orderIndicator) {
              const newIndicator = {
                ...cultureIndicator,
                value: orderIndicator.value,
                name: orderIndicator.name,
                isCustom: false,
              };
              return newIndicator;
            } else {
              return cultureIndicator;
            }
          });
          specsOrdersStore.updateOrderIndicators(paramId, mergedIndicators);
          specsOrdersStore.updateOrderEditingIndicators(toJS(mergedIndicators));
        } else if (orderIndicators.length === 0)
          specsOrdersStore.updateOrderEditingIndicators(toJS(culture.indicators));
      }
    }
  }, [specsOrderData.isSuccess]);

  if (specsOrderData.isLoading || culturesStore.isLoading || specsOrdersStore.isLoading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    );
  }

  const {
    cultureId,
    culture,
    contractVolume,
    providerName,
    buyerName,
    date,
    price,
    buyers,
    taxable,
    priceWithNds,
    indicators,
    deliveryStartDate,
    deliveryEndDate,
    specDoc,
    destinationWarehouse,
    warehouseAddress,
    basis,
    silkOrderId,
  } = specsOrdersStore.dmOrders?.get(paramId) || {};

  const title = culture;
  const createdDate = format(new Date(date || ''), 'dd MMMM yyyy', { locale: ru });
  // if (silkOrderId !== null) setCurrentSilkId(silkOrderId || '');

  const profile = getProfile();
  const isOwner = profile?.type === 'owner';

  const buyersNames =
    isOwner && buyers !== null ? buyers?.map((buyer) => buyer.lastName + ' ' + buyer.firstName).join(', ') : '';

  const isCPT = basis === 'CPT';
  const noIndicatorsTable = cultureId === null && indicators === null;

  const extReg = /(?:\.([^.]+))?$/;

  const handleResignClick = () => {
    resignMutation.mutate({ id: silkOrderId! });
  };

  const renderIndicatorsTable = () => {
    if (noIndicatorsTable) return null;
    const cultureFromStore = culturesStore.cultures.find((item) => item.id === Number(cultureId));
    const orderIndicators = indicators && indicators.length > 0 ? indicators : cultureFromStore?.indicators;
    return (
      orderIndicators && (
        <OrderDataWrapper>
          <OrderDataHeaderWrapper>
            <OrderDataHeader>Показатели качества</OrderDataHeader>
            <OrderDataSubheader>Ограничение по качеству товара/</OrderDataSubheader>
          </OrderDataHeaderWrapper>
          <OrderTableWrapper>
            <IndicatorsHeader>
              <IndicatorName>Параметр</IndicatorName>
              <IndicatorValueLabel>Базис</IndicatorValueLabel>
              <IndicatorValueLabel>Ограничение</IndicatorValueLabel>
              <IndicatorValueLabel>Съёмы</IndicatorValueLabel>
            </IndicatorsHeader>
            {orderIndicators &&
              orderIndicators.map((indicator) => {
                const units = () => {
                  if (indicator.measure === 'percent') return '%';
                  if (indicator.name.includes(', ед')) return 'ед.';
                  return '';
                };
                if (indicator.measure === 'string')
                  return (
                    <IndicatorsRow key={indicator.id} fullWidth>
                      <IndicatorName>{indicator.name}</IndicatorName>

                      <IndicatorValue>{indicator.basisValue}</IndicatorValue>
                    </IndicatorsRow>
                  );
                else
                  return (
                    <IndicatorsRow key={indicator.id}>
                      <IndicatorName>{indicator.name}</IndicatorName>
                      <IndicatorValue>
                        {indicator.limitValue} {units()}
                      </IndicatorValue>
                      <IndicatorValue>0</IndicatorValue>
                      <IndicatorValue>0%</IndicatorValue>
                    </IndicatorsRow>
                  );
              })}
          </OrderTableWrapper>
        </OrderDataWrapper>
      )
    );
  };

  return (
    <PageWrapper>
      <BackLink onClick={handleClickBack}>
        <BackIcon />
        Назад
      </BackLink>
      <OrderWrapper>
        <OrderHeader>
          <HeaderWrapper>
            {title} {contractVolume} т
          </HeaderWrapper>
          <NumberWrapper>
            <span>{createdDate}</span>
          </NumberWrapper>
        </OrderHeader>
        <OrderTableWrapper>
          {isOwner && (
            <OrderTableRow fullWidth>
              <ManagerWrapper>
                <OrderTableName>Менеджер</OrderTableName>
                <ManagersNames>{buyersNames}</ManagersNames>
              </ManagerWrapper>
            </OrderTableRow>
          )}
          <OrderTableRow>
            <OrderTableName>Объем, т</OrderTableName>
            <OrderTableValue>{contractVolume} т</OrderTableValue>
          </OrderTableRow>
          <OrderTableRow>
            <OrderTableName>Продавец</OrderTableName>
            <OrderTableValue>{providerName}</OrderTableValue>
          </OrderTableRow>
          {isOwner && buyers !== null && (
            <OrderTableRow>
              <OrderTableName>Менеджер</OrderTableName>
              <OrderTableValue>{buyerName}</OrderTableValue>
            </OrderTableRow>
          )}
          <OrderTableRow>
            <OrderTableName>Цена продавца, руб</OrderTableName>
            {taxable ? (
              <OrderTableSubGrid>
                <OrderTableValue>
                  {price}
                  <OrderTableNote>без НДС</OrderTableNote>
                </OrderTableValue>
                <OrderTableValue>
                  {priceWithNds as string}
                  <OrderTableNote>c НДС</OrderTableNote>
                </OrderTableValue>
              </OrderTableSubGrid>
            ) : (
              <OrderTableValue>
                {price}
                <OrderTableNote>без НДС</OrderTableNote>
              </OrderTableValue>
            )}
          </OrderTableRow>
        </OrderTableWrapper>
      </OrderWrapper>
      {renderIndicatorsTable()}
      <OrderDataWrapper>
        <OrderDataHeaderWrapper>
          <OrderDataHeader>Данные для спецификации</OrderDataHeader>
          <OrderDataSubheader>Данные по условиям поставки и подписанту</OrderDataSubheader>
        </OrderDataHeaderWrapper>
        <OrderTableWrapper>
          {deliveryStartDate && (
            <OrderTableRow>
              <IndicatorName>Сроки поставки</IndicatorName>
              <IndicatorValue>
                {deliveryStartDate} г. - {deliveryEndDate} г.
              </IndicatorValue>
            </OrderTableRow>
          )}
          {isCPT ? (
            destinationWarehouse && (
              <OrderTableRow>
                <IndicatorName>Склад</IndicatorName>
                <IndicatorValue>{destinationWarehouse.title}</IndicatorValue>
              </OrderTableRow>
            )
          ) : (
            <OrderTableRow>
              <IndicatorName>Пункт погрузки</IndicatorName>
              <IndicatorValue>{warehouseAddress}</IndicatorValue>
            </OrderTableRow>
          )}
          <OrderTableRow>
            <IndicatorName>Юр. лицо</IndicatorName>
            <IndicatorValue>{providerName}</IndicatorValue>
          </OrderTableRow>
          {specDoc && specDoc.signatures && (
            <OrderTableRow>
              <IndicatorName>Подписант</IndicatorName>
              <IndicatorValue>{specDoc.signatures.at(-1)?.userName}</IndicatorValue>
            </OrderTableRow>
          )}
        </OrderTableWrapper>
      </OrderDataWrapper>
      {silkOrderId && (
        <OrderDataWrapper>
          {specDoc ? (
            <SpecBackground>
              <SignedSpecHeaderWrapper>
                <SignedSpecHeader>Спецификация</SignedSpecHeader>
                <SignedSpecStatus>
                  <ProgressCheckmarkIcon />
                  {specDoc.singStatus === 'provider' && <span>Подписан продавцом</span>}
                </SignedSpecStatus>
              </SignedSpecHeaderWrapper>
              <DownloadLinkWrapper
                as="span"
                onClick={() =>
                  downloadFile(`${process.env.REACT_APP_BACKEND_HOST}/api${specDoc.filePdf}`, specDoc.filePdf)
                }
              >
                <DownloadIcon />

                {/* @ts-ignore */}
                <span>Спецификация (.{extReg.exec(specDoc.filePdf)[1]})</span>
              </DownloadLinkWrapper>
            </SpecBackground>
          ) : (
            <SpecBackground>
              <OrderDataHeaderWrapper>
                <OrderDataHeader>Спецификация</OrderDataHeader>
              </OrderDataHeaderWrapper>
              <AwaitNotification>Ожидается подписание</AwaitNotification>
            </SpecBackground>
          )}
        </OrderDataWrapper>
      )}
      {specDoc && silkOrderId && (
        <MakeChangesButton style={{ margin: 0 }} type="button" onClick={() => setShowReSignModalState(true)}>
          Внести изменения и переподписать
        </MakeChangesButton>
      )}
      {showReSignModalState && (
        <SpecOrderReSignModal
          handleReSign={handleResignClick}
          showModalState={showReSignModalState}
          handleModalClose={() => setShowReSignModalState(false)}
        />
      )}
    </PageWrapper>
  );
});

const PageWrapper = styled.div`
  padding: 44px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const BackLink = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px;
  width: 187px;
  background-color: transparent;
  border: 1px solid #825dec;
  border-radius: 8px;
  color: #8e66fe;
  font-size: 16px;
  font-weight: 700;
`;

const OrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 24px;
  gap: 16px;
  width: 800px;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 16px;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.02);
`;

const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderWrapper = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #292929;
`;

const NumberWrapper = styled.span`
  font-size: 15px;
  color: #656565;
`;

const ManagerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ManagersNames = styled.span`
  color: #656565;
  font-size: 15px;
`;
