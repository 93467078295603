import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'store/Root';
import { ModalCheck } from '../uikit';

interface Props {
  children: React.ReactNode;
}
export const ModalsProvider: React.FC<Props> = observer(({ children }) => {
  const { alertsStore } = useStore();
  return (
    <>
      <>
        {alertsStore.modals?.map(({ text, type }, idx) => {
          return (
            <React.Fragment key={`${idx}-${text}`}>
              <ModalCheck isActive={true} text={text} type={type} />
            </React.Fragment>
          );
        })}
      </>
      {children}
    </>
  );
});
