import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { routes } from 'routes/consts';
import { ISaleRequest } from 'services/models';
import { useStore } from 'store/Root';
import styled from 'styled-components';
import { Flex, Spacer, Typography } from 'uikit';
import { Card } from 'uikit/Card/Card';
import { Loader } from 'uikit/Loader';
import { InquiriesCounter } from './components/InquiriesCounter';
import { InquiryCard } from './components/InquiryCard';
import { useCultures } from 'hooks/useCultures';
import { getProfile } from 'services/token-service';
import { InfoIcon } from 'uikit/Icons/InfoIcon';
import { InfoStatus } from 'components/InfoStatus';
import { useWarehouses } from 'hooks/useWarehouses';

export const InquiriesListPage: React.FC = observer(() => {
  const { inquiriesStore, culturesStore } = useStore();
  const navigate = useNavigate();
  useWarehouses(inquiriesStore.hasWarehouses, inquiriesStore.getBuyerWarehouses);

  useEffect(() => {
    inquiriesStore.getInquiries();
  }, [inquiriesStore.selectedStatus]);

  useCultures(culturesStore.hasCultures, culturesStore.getCultures);

  const noInquiriesMessage = 'Нет заявок соответствующих фильтру';
  const noInquiries = inquiriesStore.inquiriesList.length ? null : <Typography>{noInquiriesMessage}</Typography>;

  const noInquiriesStatus = inquiriesStore.isLoading ? <Loader /> : noInquiries;

  const handleCardClick = (id: string) => {
    navigate(`../${generatePath(routes.inquiries.inquiry.path, { id })}`);
  };

  const profile = getProfile();
  const isVerified = profile?.verified;
  const isOwner = profile?.type === 'owner';

  return (
    <Wrapper>
      <Typography size="lg2" bold>
        Заявки
      </Typography>
      <Spacer space={28} />
      {!isVerified ? (
        <InfoStatus>
          <div>
            <InfoIcon />
          </div>
          <div>
            Для работы с заявками необходимо пройти верификацию.{' '}
            <ContactLink target="_blank" rel="noreferrer" href="https://wa.me/79959054912">
              Напишите нам
            </ContactLink>{' '}
            в WhatsApp на +7 (995) 905-4912!
          </div>
        </InfoStatus>
      ) : (
        <>
          <InquiriesCounter />
          <Spacer space={20} />

          <Flex column gap="20px">
            {inquiriesStore.inquiriesList.length
              ? inquiriesStore.inquiriesList.map((req) => (
                  <Card key={req.id} noPadding transparent onClick={() => handleCardClick(req.id)}>
                    <InquiryCard inquiry={req as ISaleRequest} isOwner={isOwner} />
                  </Card>
                ))
              : noInquiriesStatus}
          </Flex>
        </>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 44px;
`;

const ContactLink = styled.a`
  color: #8e66fe;
  text-decoration: none;
`;
