import { castArray, mergeWith } from 'lodash-es';
import { flow, types, getRoot } from 'mobx-state-tree';
import { isPrimitive } from '../utils/utils';
import { daylesfordService } from '../services';
import { IRootStore } from './Root';
import { IPatchUser, IReqFarmers, IReqUsers } from 'services/models';
import { LIMIT_FETCH_RECORDS } from 'utils/consts';
import { toJS } from 'mobx';

const { identifier, model, map, string, boolean, maybeNull, maybe, reference, optional, enumeration } = types;

const User = model('User', {
  id: identifier,
  email: string,
  firstName: string,
  lastName: string,
  phone: maybeNull(string),
  verified: boolean,
  blocked: boolean,
  edmReleased: boolean,
  profileType: string,
});

export const ContactsDashboardStore = model('ContactsDashboardStore', {
  isLoading: true,
  hasNextPage: true,
  pageNumber: 1,
  users: map(User),
  searchInput: maybeNull(string),
  userStatusIsBlocked: maybeNull(boolean),
  userStatusIsVerified: maybeNull(boolean),
  userStatusIsEDMReleased: maybeNull(boolean),
  farmerAccStatus: maybeNull(enumeration(['new', 'accredited', 'denied', 'expired'])),
  selectedCategory: optional(enumeration(['Пользователи', 'Компании']), 'Пользователи'),
  selectedFarmer: maybeNull(reference(User)),
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .views((self) => ({
    get usersList() {
      return Array.from(self.users.values()).flat();
    },
  }))
  .views((self) => ({
    get filteredList(): any {
      return self.selectedCategory === 'Пользователи' ? self.usersList : self.root.farmersStore.farmersList;
    },
  }))
  .actions((self) => {
    return {
      resetFetchedLists() {
        self.users.clear();
        self.root.farmersStore.clearFarmers();
      },
    };
  })
  .actions((self) => {
    return {
      resetFilters() {
        self.userStatusIsBlocked = null;
        self.userStatusIsVerified = null;
        self.userStatusIsEDMReleased = null;
        self.farmerAccStatus = null;
        self.searchInput = '';
        self.resetFetchedLists();
      },
    };
  })
  .actions((self) => {
    return {
      setSelectedCategory(value: 'Пользователи' | 'Компании') {
        self.resetFilters();
        self.selectedCategory = value;
      },
    };
  })
  .actions((self) => {
    return {
      setUserStatusIsBlocked(value: 'null' | 'true' | 'false') {
        if (value === 'null') {
          self.userStatusIsBlocked = null;
        } else if (value === 'true') {
          self.userStatusIsBlocked = true;
        } else if (value === 'false') {
          self.userStatusIsBlocked = false;
        }
      },
    };
  })
  .actions((self) => {
    return {
      setUserStatusIsVerified(value: 'null' | 'true' | 'false') {
        if (value === 'null') {
          self.userStatusIsVerified = null;
        } else if (value === 'true') {
          self.userStatusIsVerified = true;
        } else if (value === 'false') {
          self.userStatusIsVerified = false;
        }
      },
    };
  })
  .actions((self) => {
    return {
      setUserStatusIsEDMReleased(value: 'null' | 'true' | 'false') {
        if (value === 'null') {
          self.userStatusIsEDMReleased = null;
        } else if (value === 'true') {
          self.userStatusIsEDMReleased = true;
        } else if (value === 'false') {
          self.userStatusIsEDMReleased = false;
        }
      },
    };
  })
  //   .actions((self) => {
  //     return {
  //       setUserStatusIsVerified(value: boolean | null) {
  //         self.userStatusIsVerified = value;
  //       },
  //     };
  //   })
  //   .actions((self) => {
  //     return {
  //       setUserStatusIsEDMReleased(value: boolean | null) {
  //         self.userStatusIsEDMReleased = value;
  //       },
  //     };
  //   })
  .actions((self) => {
    return {
      handleFarmerAccStatusChange({ label, value }: { label: string; value: string }) {
        self.farmerAccStatus = value as 'new' | 'accredited' | 'denied' | 'expired';
      },
    };
  })
  .actions((self) => {
    return {
      setSearchInput(value: string) {
        self.searchInput = value;
      },
    };
  })
  .actions((self) => {
    return {
      setHasNextPage(value: boolean) {
        self.hasNextPage = value;
      },
    };
  })
  .actions((self) => {
    return {
      setPageNumber(value: number) {
        self.pageNumber = value;
      },
    };
  })
  .actions((self) => {
    return {
      setSelectedFarmer(id: string | null) {
        self.selectedFarmer = !!id ? self.users.get(id) || null : null;
      },
    };
  })
  .actions((self) => ({
    // @ts-ignore
    process(data): any {
      const dataList = castArray(data);

      const mapped = dataList.map((user) => {
        user.id = user.id.toString();
        if (isPrimitive(user)) {
          return user;
        }

        const existing = self.users?.get(user.id);

        return existing
          ? mergeWith(existing, user, (_, next: any) => {
              if (Array.isArray(next)) return next;
              return;
            })
          : self.users.put(user);
      });
      return Array.isArray(data) ? mapped : mapped[0];
    },
  }))
  .actions((self) => {
    return {
      getUsers: flow(function* getUsers(): any {
        self.isLoading = true;
        const usersRequestOptions: IReqUsers = {
          limit: LIMIT_FETCH_RECORDS,
          offset: LIMIT_FETCH_RECORDS * (self.pageNumber - 1),
        };
        if (self.searchInput !== '' && self.searchInput !== null) usersRequestOptions.search = self.searchInput;
        if (self.userStatusIsBlocked !== null) usersRequestOptions.blocked = self.userStatusIsBlocked;
        if (self.userStatusIsVerified !== null) usersRequestOptions.verified = self.userStatusIsVerified;
        if (self.userStatusIsEDMReleased !== null) usersRequestOptions.edm_released = self.userStatusIsEDMReleased;

        const users = yield daylesfordService
          .getUsers(usersRequestOptions)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: список пользователей`, 'error'));
        self.setHasNextPage((users.data?.next ?? null) != null);
        self.isLoading = false;
        return self.process(users.data.results);
      }),
    };
  })
  .actions((self) => {
    return {
      fetchContacts(): any {
        if (self.selectedCategory === 'Пользователи') {
          self.getUsers();
        } else {
          self.root.farmersStore.getFarmers(self.searchInput || '', self.farmerAccStatus || '');
        }
      },
    };
  })
  .actions((self) => {
    return {
      fetchWithReset() {
        self.resetFetchedLists();
        self.fetchContacts();
      },
    };
  })

  .actions((self) => {
    return {
      updateUser: flow(function* updateUser(request: IPatchUser): any {
        self.isLoading = true;
        const user = yield daylesfordService
          .updateUser(request)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: данные о фермере`, 'error'));
        self.isLoading = false;
        console.log(toJS(user));

        self.root.alertsStore.addNotification(`${user.data.firstName}: данные о пользователе изменены`, 'success');
        return self.process(user.data);
      }),
    };
  });
