import React from 'react';
import styled from 'styled-components';
import { Flex, Typography, Spacer } from '..';

interface IprogressBar {
  currentValue: string;
  percent: number;
  tottalValue: string;
}

export const ProgressBar: React.FC<IprogressBar> = ({
  currentValue,
  percent,
  tottalValue,
}) => {
  return (
    <Flex column>
      <Typography size="lg">{currentValue}</Typography>
      <Spacer space={10} />
      <Grid>
        <Band>
          <Progress style={{ width: `${percent || 2.75}%` }}></Progress>
        </Band>
        <Typography size="md" color="#918F88">
          {tottalValue}
        </Typography>
      </Grid>
    </Flex>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  gap: 30px;
  align-items: center;
`;
const Band = styled.div`
  background: #e7e2d4;
  border-radius: 50px;
  width: 400px;
  height: 11px;
  position: relative;
`;
const Progress = styled.div`
  background: #8e66fe;
  border-radius: 50px;
  height: 11px;
  position: absolute;
  left: 0;
  top: 0;
`;
