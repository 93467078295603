import React from 'react';

interface IProps {
  size?: string;
}

/**
 * Иконка "Рубль".
 */
export const InvertedRoubleIcon = ({ size }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none">
    <path
      fill="#8863F2"
      d="M8 16.328c-4.352 0-7.969-3.617-7.969-7.969C.031 4 3.641.391 7.992.391 12.352.39 15.97 4 15.97 8.359c0 4.352-3.61 7.97-7.969 7.97ZM8 15a6.615 6.615 0 0 0 6.64-6.64c0-3.688-2.96-6.641-6.648-6.641a6.602 6.602 0 0 0-6.625 6.64A6.609 6.609 0 0 0 8 15Zm-1.328-2.734a.496.496 0 0 1-.516-.516v-.79H5.47c-.227 0-.383-.163-.383-.39a.37.37 0 0 1 .383-.383h.687V9.43h-.695c-.234 0-.383-.172-.383-.39 0-.22.149-.384.383-.384h.695v-3.36c0-.312.203-.523.508-.523h1.852c1.46 0 2.375.985 2.375 2.329 0 1.351-.922 2.32-2.383 2.32H7.172v.765H9c.227 0 .375.165.375.383 0 .227-.148.39-.375.39H7.172v.79c0 .305-.203.516-.5.516Zm.508-3.758 1.156.015c1.008 0 1.531-.546 1.531-1.414 0-.859-.515-1.398-1.531-1.398H7.18v2.797Z"
    />
  </svg>
);
