import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { routes } from './consts';

import { AuthPage } from '../components/Auth';
import { useStore } from 'store/Root';
import { observer } from 'mobx-react-lite';

import { OfferPage, OffersListPage } from 'components/Offers/pages';
import { AnalyticsPage } from 'components/Analytics';
import { SettingsPage } from 'components/Settings';
import { PassRecoveryPage } from 'components/PassRecoveryPage';
import { ChatsListPage, DialogPage } from 'components/Chat';
import { getProfile } from 'services/token-service';
import { ContactsDashboardPage } from 'components/ContactsDashboard';
import { InquiriesListPage, InquiryPage } from 'components/Inquiries/pages';
import { PriceMonitoringPage } from 'components/PriceMonitoring/pages';
import { SpecsOrdersListPage } from 'components/SpecsOrders/SpecsOrdersListPage';
import { SpecsOrderPage } from 'components/SpecsOrders/SpecsOrderPage';
import { PhonePage } from 'components/PhonePage';
import { ProvidersPage } from 'components/Providers';
import { SpecsOrderDmPage } from 'components/SpecsOrders/SpecsOrderDmPage';

const NotFound = () => <h3 style={{ padding: '40px' }}>404 Страница не найдена</h3>;

/**
 * Возвращает основную (корневую) карту роутов приложения.
 */
export const RootRoutes: React.FC = observer(() => {
  const { authStore } = useStore();
  const profile = getProfile();

  if (!!authStore.profileType && !!profile) profile.type = authStore.profileType;

  return authStore.isAuthenticated ? (
    <Routes>
      <Route path="/" element={<Navigate to={`${routes.specsOrders.path}/${routes.specsOrders.list.path}`} />} />

      {/* <Route path={`${routes.orders.path}/*`}>
        <Route path={routes.orders.list.path} element={<OrdersListPage />} />
        <Route path={routes.orders.timeline.path} element={<OrderPage />} />
      </Route> */}
      <Route path={`${routes.specsOrders.path}/*`}>
        <Route path={routes.specsOrders.list.path} element={<SpecsOrdersListPage />} />
        <Route path={routes.specsOrders.timeline.path} element={<SpecsOrderPage />} />
        <Route path={routes.specsOrders.dmTimeline.path} element={<SpecsOrderDmPage />} />
      </Route>
      <Route path={`${routes.offers.path}/*`}>
        <Route path={routes.offers.list.path} element={<OffersListPage />} />
        <Route path={routes.offers.create.path} element={<OfferPage />} />
        <Route path={routes.offers.edit.path} element={<OfferPage />} />
      </Route>
      <Route path={`${routes.inquiries.path}/*`}>
        <Route path={routes.inquiries.list.path} element={<InquiriesListPage />} />
        <Route path={routes.inquiries.inquiry.path} element={<InquiryPage />} />
      </Route>
      <Route path={`${routes.chats.path}/*`}>
        <Route path={routes.chats.list.path} element={<ChatsListPage />} />
        <Route path={routes.chats.dialog.path} element={<DialogPage />} />
      </Route>
      <Route path={routes.priceMonitoring.path} element={<PriceMonitoringPage />} />
      <Route path={routes.analytics.path} element={<AnalyticsPage />} />
      {/* <Route path={`${routes.farmers.path}/*`}>
        <Route path={routes.farmers.list.path} element={<FarmersListPage />} />
        <Route path={routes.farmers.view.path} element={<FarmerPage />} />
      </Route> */}
      <Route path={routes.providers.path} element={<ProvidersPage />} />
      <Route path={routes.settings.path} element={<SettingsPage />} />
      <Route
        path={routes.dash.path}
        element={profile.type !== 'admin' ? <Navigate to="/" /> : <ContactsDashboardPage />}
      />
      <Route path={routes.phone.path} element={profile.type !== 'admin' ? <Navigate to="/" /> : <PhonePage />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/">
        <Route index element={<AuthPage />} />
        <Route path="/reset_password" element={<PassRecoveryPage />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
});
