import { ModalContent, StyledModal } from 'components/Orders/pages/OrderPage';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { Check } from 'react-feather';
import { IUser } from 'services/models';
import styled from 'styled-components';
import { Button, Flex, Spacer, Typography } from 'uikit';
import { ID_DISPLAY_SYMBOLS } from 'utils/consts';
import { CheckButton } from './DashboardUserFilters';

interface IDashboardUserCardProps {
  user: IUser;
  onClick?: () => void;
  onStatusChange: (id: string, isVerified: boolean, isBlocked: boolean, isEdmReleased: boolean) => void;
}

export const DashboardUserCard: React.FC<IDashboardUserCardProps> = ({ user, onClick = () => {}, onStatusChange }) => {
  const displayID = user.id.slice(-ID_DISPLAY_SYMBOLS);
  const [isVerified, setIsVerified] = useState(user.verified);
  const [isEdmReleased, setIsEdmReleased] = useState(user.edmReleased);
  const [isBlocked, setIsBlocked] = useState(user.blocked);
  const [showModalState, setShowModalState] = useState(false);

  const fullName = user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName;

  const changeUserStatus = () => {
    onStatusChange(user.id, isVerified, isBlocked, isEdmReleased);
  };
  const handleBlockUser = () => {
    setIsBlocked(!isBlocked);
    changeUserStatus();
  };

  const renderBlockConfirm = () => {
    const textStatus = isBlocked ? 'разблокировать' : 'заблокировать';
    const caption = textStatus.toUpperCase();
    return (
      <StyledModal
        show={showModalState}
        onHide={() => setShowModalState(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton />
        <ModalContent>
          <Flex column vAlignContent="center" hAlignContent="center">
            <Spacer space={15} />
            <Typography bold size="lg">
              {caption} пользователя {fullName} ?
            </Typography>
            <Spacer space={35} />
            <Button variant={isBlocked ? 'actionGreen' : 'baseRed'} size="lg" onClick={handleBlockUser}>
              Да, {textStatus}
            </Button>
            <Button variant="link" size="lg" onClick={() => setShowModalState(false)}>
              Нет, отменить
            </Button>
          </Flex>
        </ModalContent>
      </StyledModal>
    );
  };

  return (
    <UserItemRootWrapper column gap={24} onClick={onClick}>
      <Typography size="lg2">{fullName}</Typography>

      <UserIdContainer color="#918F89" size="xs">
        id {displayID}
      </UserIdContainer>

      <Flex column gap={15}>
        <UserInfoRow>
          <Typography color="#918F89">Email</Typography>
          <Typography>{user.email}</Typography>
        </UserInfoRow>
        <UserInfoRow>
          <Typography color="#918F89">Phone</Typography>
          <Typography>{user.phone}</Typography>
        </UserInfoRow>
        <UserInfoRow>
          <Typography color="#918F89">Тип профиля</Typography>
          <Typography>{user.profileType}</Typography>
        </UserInfoRow>
        <Line />

        <UserInfoRow>
          <Typography className="title" color="#918F89">
            Статус верификации
          </Typography>
          <CheckButton variant="icon" onClick={() => setIsVerified(!isVerified)}>
            {isVerified && <Check />}
            <Typography>{isVerified ? 'Верифицирован' : 'Не верифицирован'}</Typography>
          </CheckButton>
          {user.verified !== isVerified && (
            <UserActionButton variant="actionGreen" type="button" onClick={() => changeUserStatus()}>
              Сохранить
            </UserActionButton>
          )}
        </UserInfoRow>
        <UserInfoRow>
          <Typography className="title" color="#918F89">
            Статус мобильной ЭЦП
          </Typography>
          <CheckButton variant="icon" onClick={() => setIsEdmReleased(!isEdmReleased)}>
            {isEdmReleased && <Check />}
            <Typography>{isEdmReleased ? 'Выпущена' : 'Не выпущена'}</Typography>
          </CheckButton>

          {user.edmReleased !== isEdmReleased && (
            <UserActionButton variant="actionGreen" type="button" onClick={() => changeUserStatus()}>
              Сохранить
            </UserActionButton>
          )}
        </UserInfoRow>

        <UserInfoRow>
          <UserActionButton
            variant={isBlocked ? 'actionGreen' : 'baseRed'}
            type="button"
            onClick={() => setShowModalState(true)}
          >
            {isBlocked ? 'Разблокировать' : 'Заблокировать'}
          </UserActionButton>
        </UserInfoRow>
      </Flex>
      {showModalState && renderBlockConfirm()}
    </UserItemRootWrapper>
  );
};

const UserActionButton = styled(Button)`
  padding: 10px 20px;
  height: auto;
  width: auto;
`;
const UserItemRootWrapper = styled(Flex)`
  position: relative;
  padding: 30px 30px 18px 30px;
  background: #f9f6ed;
  border-radius: 10px;
`;

const UserInfoRow = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  .title {
    min-width: 215px;
  }
  /* grid-template-columns: 200px 1fr; */
`;

const UserIdContainer = styled(Typography)`
  position: absolute;
  top: 17px;
  right: 20px;
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px solid #e7e2d1;
`;
