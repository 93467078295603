export const BackIcon: React.FC = () => {
  return (
    <svg width="16" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.096 6.336c0-.305.132-.625.343-.836L5.525.422c.242-.242.524-.36.797-.36.664 0 1.117.461 1.117 1.07 0 .345-.148.61-.359.813L5.33 3.703 3.603 5.297l1.704-.094h8.828c.703 0 1.172.453 1.172 1.133 0 .672-.47 1.133-1.172 1.133H5.307l-1.711-.094L5.33 8.969l1.75 1.75c.21.203.36.476.36.812 0 .61-.454 1.07-1.118 1.07-.273 0-.554-.117-.789-.351L.44 7.172a1.223 1.223 0 0 1-.343-.836Z"
        fill="#8E66FE"
      />
    </svg>
  );
};
