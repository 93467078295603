import {
  EMPTY_CHAR,
  SPACE_CHAR,
  activeProgressStatusesCPT,
  activeProgressStatusesEXW,
  completedProgressStatusesCPT,
  completedProgressStatusesEXW,
} from './consts';
import parseISO from 'date-fns/esm/parseISO';
import format from 'date-fns/esm/format';
import isValid from 'date-fns/esm/isValid';
import isString from 'lodash-es/isString';
import isEmpty from 'lodash-es/isEmpty';

/**
 * Формат даты для отображения
 * (формат, в котором происходит ввод в поле ввода даты).
 */
export const FRONTEND_DATE_FORMAT = 'dd.MM.yyyy';
export const FRONTEND_DATE_FORMAT_STEPS = 'HH:mm, dd.MM.yyyy';

export type MoneyValueType = number | string;

export const formatMoney = (value: MoneyValueType) => {
  const numberValue = Number(value);
  const fractionDigits = 2;

  if (Number.isNaN(numberValue) || value === null || value === '') return EMPTY_CHAR;

  return new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(numberValue);
};

/**
 * Форматирование даты в ISO формате.
 *
 * @param {string} isoDate Дата в ISO формате.
 * @param {string} [displayFormat] Формат, к которому необходимо преобразовать.
 */
export const formatDate = (isoDate: string, displayFormat: string = FRONTEND_DATE_FORMAT): string => {
  const parsedDate = parseISO(isoDate);

  return isValid(parsedDate) ? format(parsedDate, displayFormat) : '';
};

/**
 * Возвращает текст только с одинарными пробеламы внутри. Исключает null и undefined
 *
 * @param {string} value Исходный текст.
 */
export const getTrimText = (value: string) =>
  isString(value)
    ? value
        .replace(/null|undefined/g, EMPTY_CHAR)
        .replace(/\s+/g, SPACE_CHAR)
        .replace('()', EMPTY_CHAR)
        .trim()
    : EMPTY_CHAR;

export const numberWithSeparators = (x: string | number) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

/**
 * Произвести глубокую проверку аргумента и потенциально имеющихся
 * у него свойств на предмет отсутствия данных.
 *
 * @param {any} input Проверяемый на предмет отсутствия данных аргумент.
 */
export const isDeepEmpty = (input: any) => {
  if (isEmpty(input)) {
    return true;
  }
  if (typeof input === 'object') {
    for (const item of Object.values(input)) {
      if ((!!item && typeof item !== 'object') || !isDeepEmpty(item)) {
        return false;
      }
    }
    return true;
  }
  return isEmpty(input);
};

/**
 * парсинг параметров ответа списка предложений и списка сделок, для определения offset для бесконечной прокрутки.
 * ищет в полученной строке (из параметра) сочетание offset=ЧИСЛО, извлекает и возвращает это ЧИСЛО.
 * если совпадений не найдено, то возвращается 0
 * @param {string} str //
 */

export const getOffset = (str?: string | null): number => {
  const regexp = /offset=(\d*)/;
  const matchers = str?.match(regexp)?.[1];
  return matchers != undefined ? +matchers : 0;
};

/**
 * Проверка параметра на принадлежность к примитивам
 * (не является объектом или функцией)
 * @param {any} value //
 */

export function isPrimitive(value: any) {
  return (typeof value !== 'object' && typeof value !== 'function') || value === null;
}

import { castArray } from 'lodash-es';
import { getIdentifier, isStateTreeNode } from 'mobx-state-tree';
import { differenceInSeconds, intervalToDuration, isBefore } from 'date-fns';

/**
 *
 * @param instance A list of MST instances or a single MST Instance or any other value
 * @returns If the input param is a single MST instance the return value
 * is the instance identifier. If the input param is a list of MST instances,
 * the return value is a list of identifiers. If the input param is something else
 * entirely, the function will return it unchanged.
 */
export function getInstanceId(instance: any) {
  const instanceList = castArray(instance);
  const mapped = instanceList.map((instance) => {
    console.log('isStateTreeNode(instance)', isStateTreeNode(instance));

    if (isStateTreeNode(instance)) return getIdentifier(instance);
    return instance;
  });

  return Array.isArray(instance) ? mapped : mapped[0];
}

/**
 * Обрезка строки по количеству символов, в конце добавляется многоточие.
 *
 * @param {string} text Строка, которую нужно обрезать.
 * @param {string} limit Кол-во символов, которые нужно оставить.
 */

export const truncateText = (text: string, limit: number) => {
  text = text.trim();
  if (text.length <= limit) return text;
  text = text.slice(0, limit);
  return text.trim() + '...';
};

/**
 * Получение остатка времени
 * @param {string} deadline Дата в формате ISO
 * @param {number} bidDuration продолжительность в минутах
 *
 */

export const getCounterProgress = (deadline: string, bidDuration: number) => {
  const now = new Date();
  const deadDate = new Date(deadline);

  const before = isBefore(now, deadDate);

  let formattedDuration = '00:00';
  let percents = 0;
  if (before) {
    const diffInSeconds = differenceInSeconds(deadDate, now);
    const duration = intervalToDuration({
      start: now,
      end: deadDate,
    });
    percents = (diffInSeconds * 100) / (bidDuration * 60);

    const withHours = !!duration.hours ? `${duration.hours}:` : '';

    const zeroPad = (num: number) => String(num).padStart(2, '0');
    formattedDuration = `${withHours}${zeroPad(duration.minutes || 0)}:${zeroPad(duration.seconds || 0)}`;

    return { formattedDuration, percents };
  }

  return { formattedDuration, percents };
};

/**
 * Функция для получения статусов заказа
 *
 *
 * @param {string} orderBasis - основание заказа
 * @param {number} orderStatus - статус заказа
 *
 * @returns {object} - объект с массивом статусов и текущим статусом
 *
 */

export const getMergedStatuses = (orderBasis: string, orderStatus: number) => {
  const activeStatuses = orderBasis === 'CPT' ? activeProgressStatusesCPT : activeProgressStatusesEXW;
  const completedStatuses = orderBasis === 'CPT' ? completedProgressStatusesCPT : completedProgressStatusesEXW;
  const statuses = Object.keys(activeStatuses).map(Number);

  const currentStatus = statuses.findIndex((status) => {
    const statusWithSigned = status === 5 ? 10 : status;
    return statusWithSigned === orderStatus;
  });

  if (orderStatus === 60) {
    return { mergedStatuses: Object.values(completedStatuses), currentStatus };
  }

  const mergedStatuses = statuses.map((status) => {
    if (status <= statuses[currentStatus]) {
      return completedStatuses[status];
    } else {
      return activeStatuses[status];
    }
  });
  return { mergedStatuses, currentStatus };
};

export const getFilterStatus = (status: string) => {
  switch (status) {
    case 'Активные':
      return 'open';
    case 'Завершенные':
      return 'closed';
    case 'Отклоненные':
      return 'declined';
    case 'Отменённые':
      return 'declined';
    default:
      return 'open';
  }
};

export const formatDecimals = (value: number | string) => {
  return value.toString().replace(',', '.');
};
