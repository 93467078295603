import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import { voiceApiService } from 'services/voiceip';

export const PhonePage: React.FC = observer(() => {
  const [balance, setBalance] = React.useState<number | null>(null);
  const fetchBalance = useQuery(['BALANCE_QUERY_KEY'], voiceApiService.balance, {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
    staleTime: 60 * 1000,
  });

  const onClick = () => {
    fetchBalance.refetch();
  };
  useEffect(() => {
    if (fetchBalance.isSuccess) {
      setBalance(fetchBalance.data as unknown as number);
    }
  }, [fetchBalance.isSuccess, fetchBalance.data]);

  return (
    <Wrapper>
      <div>Телефоны</div>
      <button onClick={onClick}>{fetchBalance.isLoading ? 'Загрузка...' : 'Загрузить баланс'}</button>
      <div>Баланс: {balance}</div>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 20px;
`;
