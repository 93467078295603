import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Flex, Spacer, Typography } from 'uikit';
import { SpecOrdersDataList } from './components/SpecOrdersDataList';
import { SpecOrdersCounter } from './components/SpecOrdersCounter';
import { getProfile } from 'services/token-service';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/Root';
import { useProviders } from 'hooks/useProviders';
import { useCultures, useCulturesFilter } from 'hooks/useCultures';
import { Loader } from 'uikit/Loader';
import { InfoStatus } from 'components/InfoStatus';
import { InfoIcon } from 'uikit/Icons/InfoIcon';
import { Select } from 'uikit/Selects';
import { useWarehouses } from 'hooks/useWarehouses';
import { usePaymentProcedures } from 'hooks/usePaymentsProcedures';
import { isNumber } from 'lodash-es';
import { OptionProps, GroupBase } from 'react-select';
import { CustomOptionSelect } from 'uikit/Selects/Select';

export const SpecsOrdersListPage: React.FC = observer(() => {
  const { providersStore, culturesStore, specsOrdersStore, inquiriesStore } = useStore();

  const providersReq = useProviders(false, providersStore.getProviders);
  useCultures(culturesStore.hasCultures, culturesStore.getCultures);
  useCulturesFilter(specsOrdersStore.hasCulturesFilterOptions, specsOrdersStore.getCulturesFilterOptions);
  useWarehouses(inquiriesStore.hasWarehouses, inquiriesStore.getBuyerWarehouses);
  usePaymentProcedures(specsOrdersStore.hasPaymentProcedures, specsOrdersStore.getPaymentProcedures);

  const profile = getProfile();
  const isVerified = profile?.verified;
  const isOwner = profile?.type === 'owner';

  useEffect(() => {
    providersReq.refetch();
  }, [specsOrdersStore.selectedCultureFilter, specsOrdersStore.selectedStatus]);

  if (providersStore.isLoading || culturesStore.isLoading) return <Loader />;

  const handleProviderFilterChange = (provider: { label: string; value: string }) => {
    if (provider.value === 'all') return specsOrdersStore.resetProviderFilter();
    specsOrdersStore.setProviderFilter(provider.value);
  };
  const handleCulturesFilterChange = (culture: { label: string; value: string }) => {
    if (culture.value === 'all') return specsOrdersStore.resetSelectedCultureFilter();
    specsOrdersStore.setSelectedCultureFilter(culture.value);
  };

  const providerOptions = [
    { label: 'Все поставщики', value: 'all', data: '' },
    ...providersStore.providersDetailedList,
  ];
  const culturesOptions = [{ label: 'Все культуры', value: 'all' }, ...specsOrdersStore.culturesFilterOptionsList];

  const OptionWithInn: React.ComponentType<OptionProps<unknown, boolean, GroupBase<unknown>>> | undefined = ({
    innerProps,
    label,
    isDisabled,
    data,
  }) => {
    // @ts-ignore
    const inn = data.inn;
    // @ts-ignore
    const address = data.address;
    const isAll = label === 'Все поставщики';
    return !isDisabled ? (
      <div {...innerProps}>
        <StyledProviderSelect>
          <span style={{ fontSize: '16px', color: 'rgba(25, 25, 25, 1)', fontWeight: 'bold', paddingLeft: '8px' }}>
            {label}
          </span>
          {!isAll && (
            <span
              style={{
                color: 'rgba(101, 101, 101, 1)',
                fontSize: '16px',
                padding: '4px 8px 4px 8px',
                backgroundColor: 'rgba(243, 247, 249, 1)',
                borderRadius: '6px',
              }}
            >
              {address}
            </span>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {!isAll && <span style={{ fontSize: '16px', color: 'rgba(25, 25, 25, 1)', paddingLeft: '8px' }}>ИНН</span>}
            <span style={{ fontSize: '16px', color: 'rgba(101, 101, 101, 1)', paddingRight: '8px' }}>{inn}</span>
          </div>
        </StyledProviderSelect>
      </div>
    ) : null;
  };

  return (
    <Wrapper>
      <Flex column>
        <Typography size="lg2" bold>
          Сделки
        </Typography>
      </Flex>
      <Spacer space={24} />
      {!isVerified ? (
        <InfoStatus>
          <div>
            <InfoIcon />
          </div>
          <div>
            Для работы со сделками необходимо пройти верификацию.{' '}
            <ContactLink target="_blank" rel="noreferrer" href="https://wa.me/79959054912">
              Напишите нам
            </ContactLink>{' '}
            в WhatsApp на +7 (995) 905-4912!
          </div>
        </InfoStatus>
      ) : (
        <>
          <SpecOrdersCounter />
          <Spacer space={24} />
          <StyledFiltersWrapper>
            <Select placeholder="Все культуры" options={culturesOptions} onChange={handleCulturesFilterChange} />
            <CustomOptionSelect
              placeholder="Начните вводить название или ИНН"
              options={providerOptions}
              onChange={handleProviderFilterChange}
              customOption={OptionWithInn}
              filterOption={(option, rawInput) => {
                const isRawInputNumber = Number(rawInput);
                if (isNumber(isRawInputNumber) && !Number.isNaN(isRawInputNumber)) {
                  providersStore.setProvidersInnSearch(rawInput);
                  return true;
                }
                return option.label.toLowerCase().includes(rawInput.toLowerCase());
              }}
            />
          </StyledFiltersWrapper>

          <Spacer space={24} />
          <SpecOrdersDataList isOwner={isOwner} />
        </>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 44px;
`;

const ContactLink = styled.a`
  color: #8e66fe;
  text-decoration: none;
`;

const StyledFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: start;
  .Select__control {
    max-width: 400px;
  }

  .Select__menu {
    max-width: 400px;
  }
  & > div {
    width: 100%;
  }
`;

const StyledProviderSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  padding-top: 16px;
  border: 1px solid rgba(221, 221, 221, 1);
  :hover {
    background-color: rgba(243, 247, 249, 0.5);
  }
`;
