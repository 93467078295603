import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Flex } from 'uikit';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/Root';

export const OffersCounter: React.FC = observer(() => {
  const { offersStore } = useStore();

  useEffect(() => {
    offersStore.getCounter();
  }, [offersStore.selectedStatus]);

  const activeStatus = 'Активные';
  const archivedStatus = 'Закрытые';

  return (
    <Flex>
      <TabFilter
        isActive={activeStatus === offersStore.selectedStatus}
        onClick={() => {
          activeStatus !== offersStore.selectedStatus &&
            offersStore.offersCounter?.active &&
            offersStore.setSelectedStatus(activeStatus);
        }}
      >
        <span>{activeStatus}</span> - <span>{offersStore.offersCounter?.active || 0}</span>
      </TabFilter>
      <TabFilter
        isActive={archivedStatus === offersStore.selectedStatus}
        onClick={() => {
          archivedStatus !== offersStore.selectedStatus &&
            offersStore.offersCounter?.archived &&
            offersStore.setSelectedStatus(archivedStatus);
        }}
      >
        <span>{archivedStatus}</span> - <span>{offersStore.offersCounter?.archived || 0}</span>
      </TabFilter>
    </Flex>
  );
});

const TabFilter = styled.div<{ isActive?: boolean }>`
  font-size: 16px;
  opacity: ${({ isActive }) => (isActive ? 'initial' : 0.5)};
  cursor: pointer;
  margin-right: 30px;
`;
