import socket from 'services/socket';

export const ExampleWS = () => {
  return (
    <div>
      <h2>Send:</h2>
      <form
        id="emit"
        method="POST"
        action="#"
        onSubmit={(e: any) => {
          e.preventDefault();
          const data = e.target[0].value;
          socket.emit('echo_event', { data: data });
        }}
      >
        <input type="text" name="emit_data" id="emit_data" placeholder="Message" />
        <input type="submit" value="Echo" />
      </form>
      <form
        id="broadcast"
        method="POST"
        action="#"
        onSubmit={(e: any) => {
          e.preventDefault();
          const data = e.target[0].value;
          socket.emit('get_rooms');
        }}
      >
        <input type="text" name="broadcast_data" id="broadcast_data" placeholder="get_rooms" />
        <input type="submit" value="get_rooms" />
      </form>
      <form
        id="join"
        method="POST"
        action="#"
        onSubmit={(e: any) => {
          e.preventDefault();
          const msg = e.target[0].value;
          const offerId = e.target[1].value;
          const warehouseId = e.target[2].value;
          const price = e.target[3].value;
          const volume = e.target[4].value;

          socket.emit('create_room_with_bid', {
            offer_id: offerId || '0d00bdd4-222a-487b-b080-6ddc65622303',
            warehouse_id: warehouseId || '7404df06-79ab-4691-b63d-f4287a8b1af4',
            price: price || '13000',
            volume: volume || '50',
            data: msg,
          });
        }}
      >
        <input type="text" name="join_room" id="join_room" placeholder="message" />
        <input type="text" name="offer_id" id="join_room_offer_id" placeholder="offer id" />
        <input type="text" name="warehouse_id" id="join_room_warehouse_id" placeholder="warehouse_id" />
        <input type="text" name="price" id="join_room_price" placeholder="price" />
        <input type="text" name="volume" id="join_room_volume" placeholder="volume" />
        <input type="submit" value="create_room_with_bid" />
      </form>
      <form
        id="leave"
        method="POST"
        action="#"
        onSubmit={(e: any) => {
          e.preventDefault();
          const roomVal = e.target[0].value;

          socket.emit('leave', { room: roomVal });
        }}
      >
        <input type="text" name="leave_room" id="leave_room" placeholder="Room Name" />
        <input type="submit" value="Leave Room" />
      </form>
      <form
        id="send_room"
        method="POST"
        action="#"
        onSubmit={(e: any) => {
          e.preventDefault();
          const roomVal = e.target[0].value;
          const data = e.target[1].value;

          socket.emit('send_message', {
            type: 't',
            room: '9017c0bd-1554-4507-975a-8b4a52d4bbac',
            data: data,
          });
        }}
      >
        <input type="text" name="room_name" id="room_name" placeholder="Room Name" />
        <input type="text" name="room_data" id="room_data" placeholder="Message" />
        <input type="submit" value="Send to Room" />
      </form>
      <form
        id="close"
        method="POST"
        action="#"
        onSubmit={(e: any) => {
          e.preventDefault();
          const roomVal = e.target[0].value;

          socket.emit('close_room', { room: roomVal });
        }}
      >
        <input type="text" name="close_room" id="close_room" placeholder="Room Name" />
        <input type="submit" value="Close Room" />
      </form>
      <form
        id="disconnect"
        method="POST"
        action="#"
        onSubmit={(e: any) => {
          e.preventDefault();
          socket.emit('disconnect_request');
        }}
      >
        <input type="submit" value="Disconnect" />
      </form>
      <h2>Receive:</h2>
      <div>
        <p id="log"></p>
      </div>
    </div>
  );
};
