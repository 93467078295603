import React from 'react';
import { ISaleRequest } from 'services/models';
import styled from 'styled-components';
import { Spacer } from 'uikit';
import format from 'date-fns/esm/format';
import { ru } from 'date-fns/locale';
import { OrderTableNote, OrderTableRow } from 'components/SpecsOrders/components/SpecOrdersDataList';

interface IInquiryPageProps {
  inquiry: ISaleRequest;
  isOwner: boolean;
}

export const InquiryCard: React.FC<IInquiryPageProps> = ({ inquiry, isOwner }) => {
  // const displayID = inquiry.id.slice(-ID_DISPLAY_SYMBOLS);
  const createdDate = format(new Date(inquiry.createdAt || ''), 'hh:mm dd MMMM yyyy', { locale: ru });
  const buyersNames =
    isOwner && inquiry.buyers !== null
      ? inquiry.buyers?.map((buyer) => buyer.lastName + ' ' + buyer.firstName).join(', ')
      : '';
  return (
    <InquiryCardRootWrapper>
      <CardHeaderWrapper>
        <CardHeader>{inquiry.providerName}</CardHeader>

        <CardDate>{createdDate}</CardDate>
      </CardHeaderWrapper>
      <CardHeader>
        {inquiry.culture} {inquiry.subName ? inquiry.subName + '%' : ''}
      </CardHeader>

      <InquiryTableWrapper>
        {isOwner && inquiry.buyers !== null && (
          <OrderTableRow fullWidth>
            <ManagerWrapper>
              <InquiryTableName>Менеджер</InquiryTableName>
              <ManagersNames>{buyersNames}</ManagersNames>
            </ManagerWrapper>
          </OrderTableRow>
        )}
        <OrderTableRow>
          <InquiryTableName className="title" color="#918F89">
            Цена продавца, руб
          </InquiryTableName>
          <InquiryTableValue>
            {inquiry.price} ₽/т <OrderTableNote>без НДС</OrderTableNote>
          </InquiryTableValue>
        </OrderTableRow>
        <OrderTableRow>
          <InquiryTableName className="title" color="#918F89">
            Объем
          </InquiryTableName>
          <InquiryTableValue>{inquiry.volume}</InquiryTableValue>
        </OrderTableRow>
        <OrderTableRow>
          <InquiryTableName className="title" color="#918F89">
            Базис
          </InquiryTableName>
          <InquiryTableValue>{inquiry.basis}</InquiryTableValue>
        </OrderTableRow>
        <Spacer space={10} />
      </InquiryTableWrapper>
    </InquiryCardRootWrapper>
  );
};

const InquiryCardRootWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 24px;
  gap: 16px;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 16px;
`;

const InquiryInfoRow = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  .title {
    width: 215px;
  }
`;

const InquiryTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px solid #e7e2d1;
`;

const ManagerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ManagersNames = styled.span`
  color: #656565;
  font-size: 15px;
`;

const CardHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardHeader = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #292929;
`;

const CardDate = styled.span`
  font-size: 15px;
  font-weight: 400;
  color: #656565;
`;

const InquiryTableName = styled.span`
  color: #292929;
  font-weight: 600;
  font-size: 15px;
`;

const InquiryTableValue = styled.span`
  color: #292929;
  font-size: 15px;
`;
