import { Instance, types, unprotect } from 'mobx-state-tree';
import { createContext, useContext } from 'react';
import { getProfile, getSavedCalendar } from 'services/token-service';
import { AuthStore, IUser } from './AuthStore';
import { SettingsStore } from './SettingsStore';
import { FarmersStore } from './FarmersStore';
import { LinkedOrdersStore } from './LinkedOrdersStore';
import { AlertsStore } from './AlertsStore';
import { OffersStore } from './OffersStore';
import { OrdersStore } from './OrdersStore';
import { AnalyticsStore } from './AnalyticsStore';
import { ContactsDashboardStore } from './ContactsDashboardStore';
import { ChatStore } from './ChatStore';
import socket from 'services/socket';
import { InquiriesStore } from './InquiriesStore';
import { PriceMonitoringStore } from './PriceMonitoringStore';
import { CulturesStore } from './CulturesStore';
import { SpecsOrdersStore } from './SpecsOrdersStore';
import { ProvidersStore } from './ProvidersStore';

const RootModel = types
  .model({
    authStore: AuthStore,
    settingsStore: SettingsStore,
    offersStore: OffersStore,
    ordersStore: OrdersStore,
    specsOrdersStore: SpecsOrdersStore,
    providersStore: ProvidersStore,
    farmersStore: FarmersStore,
    linkedOrdersStore: LinkedOrdersStore,
    priceMonitoringStore: PriceMonitoringStore,
    analyticsStore: AnalyticsStore,
    chatStore: ChatStore,
    inquiriesStore: InquiriesStore,
    culturesStore: CulturesStore,
    contactsDashboardStore: ContactsDashboardStore,
    alertsStore: AlertsStore,
  })
  .actions((self) => ({
    afterCreate() {
      socket.on('connect_error', (err) => {
        if (err.message === 'invalid username') {
          self.alertsStore.addNotification('Ошибка авторизации', 'error');
        }
      });
      // @ts-ignore
      socket.on('connect', (data: any) => {
        if (!data) return;
        self.chatStore.setChatId(data.id);
        self.chatStore.setBidDuration(data.settings.bid_duration);
      });
    },
  }));

const initialState = RootModel.create({
  authStore: {
    status: 'idle',
  },
  settingsStore: {
    calendarCurrentYear: '2022',
  },
  offersStore: {
    isLoading: false,
    actualProductId: '',
    offersCounter: {
      active: 0,
      archived: 0,
    },
  },
  ordersStore: {
    isLoading: false,
    actualProductId: '',
    timelineStore: {
      isLoading: false,
      isDocsLoading: false,
    },
    ordersCounter: {
      active: 0,
      archived: 0,
      canceled: 0,
    },
  },
  specsOrdersStore: {
    isLoading: false,
    hasNextPage: true,
    dmHasNextPage: true,
    editingIndicators: [],
  },
  farmersStore: {
    isLoading: false,
  },
  providersStore: {
    isLoading: false,
  },
  contactsDashboardStore: {
    isLoading: false,
  },
  analyticsStore: {
    isLoading: false,
  },
  priceMonitoringStore: {
    isLoading: false,
    hasResults: false,
    EXWPrices: [],
    CPTPrices: [],
  },
  inquiriesStore: {
    isLoading: false,
  },
  culturesStore: {
    isLoading: false,
  },
  chatStore: {
    chatId: '',
    bidDuration: 0,
    newMessagesCount: 0,
    isLoading: false,
    hasNextPage: true,
    hasNextPageRooms: true,
    tradesCounter: {
      unreadMessages: 0,
      active: 0,
      archived: 0,
    },
  },
  linkedOrdersStore: {
    isLoading: false,
  },
  alertsStore: {},
});

if (typeof window !== 'undefined') {
  const user: IUser = getProfile();
  const savedCalendar = getSavedCalendar();

  if (!!user) {
    unprotect(initialState);
    initialState.authStore.setUser(user);
    initialState.settingsStore.setSavedCalendar(savedCalendar);
    initialState.authStore.setStatus('success');
  }
}

export const rootStore = initialState;

export type RootInstance = Instance<typeof RootModel>;
// @ts-ignore
export interface IRootStore extends Instance<typeof RootModel> {}

const RootStoreContext = createContext<null | RootInstance>(null);

export const Provider = RootStoreContext.Provider;
export function useStore() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}
