// Пустой символ.
export const SPACE_CHAR = ' ';

// Знак вопроса.
export const QUESTION_MARK_CHAR = '?';

// Символ выбора всех значений (звёздочка).
export const ASTERISK_CHAR = '*';

/**
 * Сивол "прочерк" (длинное тире).
 */
export const BLANK_CHAR = '—';

/**
 * Сивол "дефис".
 */
export const HYPHEN_CHAR = '-';

/**
 * Символ пустой строки.
 */
export const EMPTY_CHAR = '';

/**
 * Символ элипсиса/троеточия.
 */
export const ELLIPSIS_CHAR = '...';

/**
 * константы статус сделок
 */
export const ORDER_ACTIVE_STATUSES_LIST = [
  'step1',
  'step2_onway',
  'step2_tests_taken',
  'step2_quality',
  'step2_quality_accepted',
  'step3',
  'step3_docs_ready',
  'step3_sign_ready',
  'step3_prebill_send',
  'step4',
  'step4_plan_ready',
  'step4_selected_date',
  'step4_date_approved',
  'step4_loading_started',
  'step4_over_today',
  'step4_over_contract',
  'step4_done',
  'step5_verification_started',
  'step5_verificated',
  'step5_letter_for_refund_approved',
];
export const ORDER_FAILED_STATUSES_LIST = ['step2_quality_failed', 'order_not_signed'];
export const ORDER_FINISHED_STATUSES_LIST = ['step6_order_closed'];

/**
 * константы статус предложений
 */
export const OFFER_ACTIVE_STATUSES_LIST = ['active', 'pending', 'accepted', 'partial'];
export const OFFER_FINISHED_STATUSES_LIST = ['archived'];

export const ID_DISPLAY_SYMBOLS = 4;

export const WS_ERROR_MESSAGES = (errorCode: number) => {
  switch (errorCode) {
    case 100:
      return 'Пользователь не найден';
    case 101:
      return 'Компания не найдена';
    case 200:
      return 'Торг не найден';
    case 201:
      return 'Ошибка создания торгов';
    case 202:
      return 'Торг в архиве';
    case 230:
      return 'Только продавец может создать торг';
    case 300:
      return 'Неправильный тип сообщения';
    case 301:
      return 'Тип сообщения не найден';
    case 302:
      return 'Пустое сообщение';
    case 400:
      return 'Предложение не найдено';
    case 500:
      return 'Неправильная цена';
    case 501:
      return 'Ставка не активная';
    case 502:
      return 'Неправильный объем';
    case 900:
      return 'Что-то пошло не так';

    default:
      return 'Что-то пошло не так';
  }
};

export const accreditationOptions = [
  { id: 'new', label: 'Новый' },
  { id: 'accredited', label: 'Аккредитация пройдена' },
  { id: 'denied', label: 'Отказано в аккредитации' },
  { id: 'expired', label: 'Аккредитация истекла' },
];
export const blockUserOptions = [
  { value: 'null', label: 'Не выбрано' },
  { value: 'true', label: 'Заблокированные' },
  { value: 'false', label: 'Не заблокированные' },
];
export const verifiedUserOptions = [
  { value: 'null', label: 'Не выбрано' },
  { value: 'true', label: 'Верифицированные' },
  { value: 'false', label: 'Не верифицированные' },
];
export const edmStatusUserOptions = [
  { value: 'null', label: 'Не выбрано' },
  { value: 'true', label: 'ЭЦП Выпущена' },
  { value: 'false', label: 'ЭЦП не выпущена' },
];

export const LIMIT_FETCH_RECORDS = 10;

export const activeProgressStatusesCPT: { [key: number]: string } = {
  5: 'Спецификация согласовывается',
  20: 'Документы подписываются',
  35: 'Идет погрузка',
  45: 'Погрузка завершается',
  55: 'Ожидается оплата',
  60: 'Закрытие сделки',
};

export const completedProgressStatusesCPT: { [key: number]: string } = {
  5: 'Спецификация согласована',
  20: 'Документы подписаны',
  35: 'Погружено > 90%',
  45: 'Погрузка завершена',
  55: 'Оплата произведена',
  60: 'Сделка закрыта',
};
export const activeProgressStatusesEXW: { [key: number]: string } = {
  5: 'Спецификация согласовывается',
  20: 'Документы подписываются',
  30: 'Ожидается оплата',
  40: 'Идет погрузка',
  50: 'Погрузка завершается',
  60: 'Закрытие сделки',
};
export const completedProgressStatusesEXW: { [key: number]: string } = {
  5: 'Спецификация согласована',
  20: 'Документы подписаны',
  30: 'Оплата произведена',
  40: 'Погружено > 90%',
  50: 'Погрузка завершена',
  60: 'Сделка закрыта',
};
