import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { Flex, Spacer } from 'uikit';
import { CloseIcon } from 'uikit/Icons/CloseIcon';
import { InquiryHeader, InquirySubHeader } from 'components/Inquiries/pages/InquiryPage';
import { useState } from 'react';
import {
  ButtonsWrapper,
  DiscardChangesButton,
  ModalContent,
  ModalHeaderWrapper,
  StyledCloseIcon,
  StyledModal,
} from 'components/Inquiries/pages/components/InquiryDeclineModal';

interface IProps {
  showModalState: boolean;
  onAccept: (electronicSignature: boolean) => void;
  handleModalClose: () => void;
}

export const InquiryAcceptModal: React.FC<IProps> = ({ onAccept, showModalState, handleModalClose }) => {
  const [electronicSignatureChecked, setElectronicSignatureChecked] = useState(true);

  const handleAcceptClick = () => {
    onAccept(electronicSignatureChecked);
  };

  return (
    <StyledModal
      show={showModalState}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter" // что это?
      centered
    >
      <ModalHeaderWrapper>
        <Modal.Header closeButton />
        <StyledCloseIcon>
          <CloseIcon />
        </StyledCloseIcon>
      </ModalHeaderWrapper>
      <ModalContent>
        <Flex column>
          <InquiryHeader>Вы хотите принять заявку?</InquiryHeader>
          <SignatureWrapper>
            <SignatureToggle
              type="checkbox"
              id="electronicSignature"
              checked={electronicSignatureChecked}
              onChange={(e) => setElectronicSignatureChecked(!electronicSignatureChecked)}
            ></SignatureToggle>
            <SignatureLabel htmlFor="electronicSignature">Сделка подписывается ПЭП</SignatureLabel>
          </SignatureWrapper>
          <ButtonsWrapper>
            <AcceptButton type="button" onClick={handleAcceptClick}>
              Принять заявку
            </AcceptButton>
            <DiscardChangesButton type="button" onClick={handleModalClose}>
              {/* нет hover, active - нужно ли? */}
              Отмена
            </DiscardChangesButton>
          </ButtonsWrapper>
        </Flex>
      </ModalContent>
    </StyledModal>
  );
};

const AcceptButton = styled.button`
  background-color: #8e66fe;
  border-radius: 12px;
  border: none;
  padding: 18px 0px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  &:active {
    background-color: rgba(142, 102, 254, 0.5);
  }
  &:hover {
    background-color: #825dec;
  }
`;

const SignatureWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-top: 12px;
  padding: 5px 0;
`;

const SignatureToggle = styled.input`
  appearance: none;
  cursor: pointer;
  width: 48px;
  height: 28px;
  border: none;
  border-radius: 15px;
  background-color: #eae9e9;
  position: relative;
  ::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
  }
  &:checked {
    background-color: #8e66fe;
  }
  &:checked::after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
`;

const SignatureLabel = styled.label`
  font-size: 16px;
  color: #2e2b2b;
  font-weight: 600;
`;
