import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import format from 'date-fns/esm/format';
import { ru } from 'date-fns/locale';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ISaleRequest } from 'services/models';
import { useStore } from 'store/Root';
import styled from 'styled-components';
import { Button, CalendarIcon, Flex } from 'uikit';
import { Loader } from 'uikit/Loader';
import { ID_DISPLAY_SYMBOLS } from 'utils/consts';
import { useState } from 'react';
import { InquiryActionsModal } from './components/InquiryActionsModal';
import { routes } from 'routes/consts';
import { BackIcon } from 'uikit/Icons/BackIcon';
import { OrderTableNote } from 'components/SpecsOrders/components/SpecOrdersDataList';
import { downloadFile } from 'services/fileDownload';
import { useWarehouses } from 'hooks/useWarehouses';
import { InquiryDeclineModal } from './components/InquiryDeclineModal';
import { InquiryAcceptModal } from './components/InquiryAcceptModal';
import { InfoIcon } from 'uikit/Icons/InfoIcon';

export const InquiryPage: React.FC = observer(() => {
  const { inquiriesStore } = useStore();
  useWarehouses(inquiriesStore.hasWarehouses, inquiriesStore.getBuyerWarehouses);

  const [showModalState, setShowModalState] = useState<boolean>(false);
  const [showDeclineModalState, setDeclineShowModalState] = useState<boolean>(false);
  const [showAcceptModalState, setAcceptShowModalState] = useState<boolean>(false);

  const navigate = useNavigate();
  const handleClickBack = (): void => {
    navigate(`../${generatePath(routes.inquiries.list.path)}`);
  };

  const { id } = useParams();
  const paramId = id || '';
  const inquiryData = useQuery(['INQUIRY_DATA_QUERY_KEY', paramId], () => inquiriesStore.getInquiry(paramId), {
    enabled: !!paramId,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });

  const extReg = /(?:\.([^.]+))?$/;

  if (!!inquiryData.isLoading || !inquiryData.data) return <Loader />;
  const inquiry = inquiryData.data as ISaleRequest;
  // if (culturesStore.selectedCulture !== inquiry.cultureId)
  //   culturesStore.setSelectedCulture(inquiry.cultureId, inquiry.indicators as any[]);
  const displayID = inquiry.id.slice(-ID_DISPLAY_SYMBOLS);

  const createdDate = format(new Date(inquiry.createdAt || ''), 'dd MMMM yyyy', { locale: ru });
  const lastBid = inquiry.bids?.at(-1);
  const lastBidPrice = lastBid?.price || inquiry.price;
  const lastBidStartDate = lastBid ? lastBid.startDate : inquiry.startDate;
  const lastBidEndDate = lastBid ? lastBid.endDate : inquiry.endDate;
  const lastBidWarehouseEXW = lastBid ? lastBid.warehouseAddress : inquiry.warehouseAddress;

  const lastBidWarehouseCPT = lastBid ? lastBid.warehouse : inquiry.warehouse;

  const handleDeclineInquiry = (comment?: string) => {
    inquiriesStore.declineInquiry(inquiry.id, comment);
    setDeclineShowModalState(false);
  };

  const handleAcceptInquiry = (electronicSignature: boolean) => {
    inquiriesStore.acceptInquiry(inquiry.id, electronicSignature);
    setAcceptShowModalState(false);
  };

  if (lastBidWarehouseCPT === null)
    return (
      <PageWrapper>
        <BackLink onClick={handleClickBack}>
          <BackIcon />
          Назад
        </BackLink>
        <InquiryNumber>Заявка #{displayID}</InquiryNumber>
        <InquiryCardRootWrapper>
          <HeaderWrapper>
            В торге не указан склад, поэтому невозможно просмотреть детали заявки.
            <br />
            Пожалуйста, исправьте это, или обратитесь к администратору.
          </HeaderWrapper>
        </InquiryCardRootWrapper>
      </PageWrapper>
    );

  const cptWarehouse = inquiriesStore.warehousesList.find((warehouse) => warehouse.id === lastBidWarehouseCPT);

  const isCPT = inquiry.basis === 'CPT';

  const hasLinkedOrder = inquiry.order !== null;
  const handleMoveToOrder = () => {
    const dmOrderPath =
      inquiry.smartOrder === null
        ? ''
        : `../../specs-orders/${generatePath(routes.specsOrders.dmTimeline.path, {
            id: inquiry.smartOrder as string,
          })}`;
    const specOrderPath = `../../specs-orders/${generatePath(routes.specsOrders.timeline.path, {
      id: inquiry.order as string,
    })}`;
    navigate(inquiry.smartOrder === null ? specOrderPath : dmOrderPath);
    return;
  };

  return (
    <PageWrapper>
      <BackLink onClick={handleClickBack}>
        <BackIcon />
        Назад
      </BackLink>
      <InquiryNumber>Заявка #{displayID}</InquiryNumber>
      <InquiryCardRootWrapper>
        <HeaderWrapper>
          <InquiryCultureName>
            {inquiry.culture} {inquiry.subName ? inquiry.subName + '%' : ''} {inquiry.volume} т
          </InquiryCultureName>
          <NumberWrapper>
            <span>{createdDate}</span>
          </NumberWrapper>
        </HeaderWrapper>
        <InquiryTableWrapper>
          <InquiryTableRow>
            <InquiryTableName className="title" color="#918F89">
              Цена продавца, руб
            </InquiryTableName>
            <InquiryTableValue>
              {inquiry.price} ₽/т <OrderTableNote>без НДС</OrderTableNote>
            </InquiryTableValue>
          </InquiryTableRow>
          <InquiryTableRow>
            <InquiryTableName className="title" color="#918F89">
              Объем, т
            </InquiryTableName>
            <InquiryTableValue>{inquiry.volume}</InquiryTableValue>
          </InquiryTableRow>
          <InquiryTableRow>
            <InquiryTableName className="title" color="#918F89">
              Базис
            </InquiryTableName>
            <InquiryTableValue>{inquiry.basis}</InquiryTableValue>
          </InquiryTableRow>

          {!isCPT && (
            <InquiryTableRow>
              <InquiryTableName className="title" color="#918F89">
                Пункт погрузки
              </InquiryTableName>
              <InquiryTableValue>{inquiry.warehouseAddress}</InquiryTableValue>
            </InquiryTableRow>
          )}
          {!!inquiry.comment && (
            <InquiryTableRow>
              <InquiryTableName className="title" color="#918F89">
                Комментарий
              </InquiryTableName>
              <InquiryTableValue>{inquiry.comment}</InquiryTableValue>
            </InquiryTableRow>
          )}
          {hasLinkedOrder && (
            <MakeChangesButton type="button" onClick={() => handleMoveToOrder()}>
              Перейти в сделку
            </MakeChangesButton>
          )}
        </InquiryTableWrapper>
      </InquiryCardRootWrapper>
      <InquiryInfoWrapper>
        {inquiry.bids && inquiry.bids.length > 0 && (
          <Flex gap="12px" column>
            <Flex gap="4px" column>
              <InquiryHeader>Условия</InquiryHeader>
              <InquirySubtext>
                Именно эти показатели при принятии заявки пойдут в спецификацию к подписанию.
              </InquirySubtext>
            </Flex>
            {inquiry.status === 'declined' && inquiry.reason && (
              <DeclineReasonWrapper>
                <InfoIcon />
                <span>{inquiry.reason}</span>
              </DeclineReasonWrapper>
            )}
            <Flex column={!isCPT} gap="8px">
              <DatesOuter>
                <InquirySubHeader>Даты поставки</InquirySubHeader>
                <DatesWrapper>
                  <CalendarIcon />
                  <div>
                    {`${format(new Date(lastBidStartDate), 'dd.MM.yyyy', { locale: ru })}г. - ${format(
                      new Date(lastBidEndDate),
                      'dd.MM.yyyy',
                      { locale: ru },
                    )}г.`}
                  </div>
                </DatesWrapper>
              </DatesOuter>
              {/* <InquirySubHeader>{isCPT ? 'Склад' : 'Пункт погрузки'}</InquirySubHeader>
                <WarehouseName>{isCPT ? inquiry.warehouseName : inquiry.warehouseAddress}</WarehouseName> */}
              {isCPT ? (
                <WarehouseWrapper>
                  <InquirySubHeader>Склад</InquirySubHeader>
                  <WarehouseName>{cptWarehouse ? cptWarehouse.title : inquiry.warehouseName}</WarehouseName>
                </WarehouseWrapper>
              ) : (
                <WarehouseAddress>
                  <InquirySubHeader>Пункт погрузки</InquirySubHeader>
                  <span>{lastBidWarehouseEXW}</span>
                </WarehouseAddress>
              )}
            </Flex>
            <BidsHistory>
              {inquiry.bids.map((bid) => {
                const bidCreatedAt = format(new Date(bid.createdAt), 'hh:mm dd MMMM yyyy', { locale: ru });
                return (
                  <BidItem isApproved key={bid.createdAt}>
                    <Flex gap="2px" column>
                      <BidUser>{bid.user}</BidUser>
                      <BidTime>{bidCreatedAt}</BidTime>
                    </Flex>
                    <BidPrice>{bid.price} ₽/т</BidPrice>
                  </BidItem>
                );
              })}
            </BidsHistory>
            <Divider></Divider>
          </Flex>
        )}

        {inquiry.fileQuality && (
          <DownloadFile
            as="span"
            onClick={() =>
              downloadFile(
                `${process.env.REACT_APP_BACKEND_HOST}/api${inquiry.fileQuality}`,
                inquiry.fileQuality as string,
              )
            }
          >
            <DownloadFileButton>
              Фермер приложил файл
              {/* @ts-ignore */}
              <ExtensionIndicator>(.{extReg.exec(inquiry.fileQuality)[1]})</ExtensionIndicator>
            </DownloadFileButton>
            Скачать
          </DownloadFile>
        )}

        {inquiry.status === 'open' ? (
          <div>
            <ButtonsWrapper>
              <DeclineButton type="button" onClick={() => setDeclineShowModalState(true)}>
                Отклонить
              </DeclineButton>
              <MakeChangesButton type="button" onClick={() => setShowModalState(true)}>
                Предложить изменения
              </MakeChangesButton>
              <AcceptButton type="button" onClick={() => setAcceptShowModalState(true)}>
                Принять
              </AcceptButton>
            </ButtonsWrapper>
          </div>
        ) : (
          <div>
            {inquiry.status === 'accepted' ? (
              <ApprovedNotification>Согласовано</ApprovedNotification>
            ) : (
              <DeclinedNotification>Отклонено</DeclinedNotification>
            )}
          </div>
        )}
      </InquiryInfoWrapper>
      {showModalState && (
        <InquiryActionsModal
          isCPT={isCPT}
          inquiryId={inquiry.id}
          inquiryPrice={lastBidPrice}
          startDate={lastBidStartDate}
          endDate={lastBidEndDate}
          selectedWarehouse={isCPT ? { value: cptWarehouse!.id, label: cptWarehouse!.title } : inquiry.warehouseAddress}
          showModalState={showModalState}
          handleModalClose={() => setShowModalState(false)}
        />
      )}
      {showDeclineModalState && (
        <InquiryDeclineModal
          onDecline={handleDeclineInquiry}
          showModalState={showDeclineModalState}
          handleModalClose={() => setDeclineShowModalState(false)}
        />
      )}
      {showAcceptModalState && (
        <InquiryAcceptModal
          onAccept={handleAcceptInquiry}
          showModalState={showAcceptModalState}
          handleModalClose={() => setAcceptShowModalState(false)}
        />
      )}
    </PageWrapper>
  );
});

const PageWrapper = styled.div`
  padding: 44px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const BackLink = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px;
  width: 187px;
  background-color: transparent;
  border: 1px solid #825dec;
  border-radius: 8px;
  color: #8e66fe;
  font-size: 16px;
  font-weight: 700;
`;

const InquiryNumber = styled.span`
  font-size: 24px;
  font-weight: 500;
  color: #3b3c47;
`;

const UserActionButton = styled(Button)`
  padding: 10px 20px;
  height: auto;
  width: auto;
`;
const InquiryCardRootWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 24px;
  gap: 16px;
  width: 800px;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 16px;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.02);
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InquiryCultureName = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #292929;
`;

const NumberWrapper = styled.span`
  font-size: 15px;
  color: #656565;
`;

const InquiryTableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8px;
  padding: 8px;
  border-radius: 6px;
  &:nth-child(odd) {
    background-color: #f3f7f9;
  }
`;

const InquiryTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InquiryTableName = styled.span`
  color: #292929;
  font-weight: 600;
  font-size: 15px;
`;

const InquiryTableValue = styled.span`
  color: #292929;
  font-size: 15px;
`;

export const IndicatorsTable = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IIndicatorsRow {
  fullWidth?: boolean;
}

export const IndicatorsRow = styled.div<IIndicatorsRow>`
  display: grid;
  grid-template-columns: ${({ fullWidth }) => (fullWidth ? '1fr 456px' : '1fr 152px 152px 152px')};
  gap: 8px;
  padding: 4px 8px;
  border-radius: 6px;
  &:nth-child(odd) {
    background-color: #f3f7f9;
  }
`;

export const IndicatorsHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 152px 152px 152px;
  gap: 8px;
  padding: 4px 8px 16px;
  border-bottom: 1px solid rgba(205, 205, 205, 0.2);
`;

const InquiryInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 533px;
  padding: 16px 16px 32px 16px;
  margin-top: 11px;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 12px;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.02);
`;

const BidsHistory = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface BidItemProps {
  isApproved?: boolean;
}

const BidUser = styled.span`
  font-weight: 600;
  font-size: 15px;
`;

const BidTime = styled.span`
  font-weight: 500;
  font-size: 12px;
`;

const BidPrice = styled.span`
  font-weight: 400;
  font-size: 15px;
`;

const BidItem = styled.div<BidItemProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  color: #aeaeae;
  background-color: #f8f8f8;
  border: 1px solid #dddddd;
  border-radius: 10px;
  max-width: 343px;
  &:last-child {
    background-color: ${({ isApproved }) => (isApproved ? '#E1F3BC' : '#F4F0FF')};
    border: ${({ isApproved }) => (isApproved ? '1px solid #6DA000' : '1px solid #8E66FE')};
  }
  &:last-child ${BidUser} {
    color: #292929;
  }
  &:last-child ${BidPrice} {
    color: #656565;
  }
`;

export const InquiryHeader = styled.span`
  color: #191919;
  font-size: 20px;
  font-weight: 700;
`;
export const InquirySubHeader = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 124%;
  color: #292929;
`;

export const InquirySubtext = styled.span`
  color: #656565;
  font-weight: 500;
  font-size: 12px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(205, 205, 205, 0.2);
`;
export const IndicatorName = styled.span`
  color: #292929;
  font-weight: 600;
  font-size: 15px;
`;

export const IndicatorValue = styled.span`
  color: #656565;
  font-weight: 400;
  font-size: 15px;
  text-align: end;
`;
export const IndicatorOldValue = styled.span`
  color: #aeaeae;
`;
interface IIndicatorNewValueProps {
  isNew?: boolean;
}
export const IndicatorNewValue = styled.span<IIndicatorNewValueProps>`
  color: ${({ isNew }) => (isNew ? '#A485FE' : '#656565')};
  font-weight: ${({ isNew }) => (isNew ? '700' : '400')};
`;
export const IndicatorValueLabel = styled.span`
  color: #656565;
  font-weight: 600;
  font-size: 15px;
  text-align: end;
`;

export const AwaitNotification = styled.div`
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  background-color: #d2c2ff;
  border-radius: 12px;
  border: 1px solid #8e66fe;
  padding-top: 18px;
  padding-bottom: 18px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;
const DatesOuter = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 271px;
  gap: 4px;
`;
const DatesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: #f3f7f9;
  border: 1px solid #dcdee4;
  font-weight: 400;
  font-size: 15px;
  line-height: 124%;
  color: #292929;
  font-family: 'SFPro';
  border-radius: 12px;
  padding: 16px;
`;
const WarehouseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`;
const WarehouseAddress = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  gap: 4px;
`;
const WarehouseName = styled.div`
  background-color: #f3f7f9;
  border: 1px solid #dcdee4;

  font-weight: 400;
  font-size: 15px;
  line-height: 124%;
  color: #292929;
  font-family: 'SFPro';
  border-radius: 12px;
  padding: 16px;
`;

const AcceptButton = styled.button`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #e1f3bc;
  color: #6da000;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #6da000;
  border-radius: 12px;
  padding-top: 18px;
  padding-bottom: 18px;
`;

const DeclineButton = styled.button`
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: #fff;
  color: #e16060;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #e16060;
  border-radius: 12px;
  padding-top: 18px;
  padding-bottom: 18px;
`;

const MakeChangesButton = styled.button`
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: #8e66fe;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #8e66fe;
  border-radius: 12px;
  padding-top: 18px;
  padding-bottom: 18px;
`;

const ApprovedNotification = styled.div`
  display: flex;
  justify-content: center;
  color: #6da000;
  font-size: 18px;
  font-weight: 600;
  background-color: #e1f3bc;
  border-radius: 12px;
  border: 1px solid #6da000;
  padding-top: 18px;
  padding-bottom: 18px;
`;

const DeclinedNotification = styled.div`
  display: flex;
  justify-content: center;
  color: #8e8e8e;
  font-size: 18px;
  font-weight: 600;
  background-color: #f8f8f8;
  border-radius: 12px;
  border: 1px solid #8e8e8e;
  padding-top: 18px;
  padding-bottom: 18px;
`;

const DownloadFile = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 16px;
  color: #667efe;
  text-decoration: none;
  font-size: 14px;
  width: fit-content;
  font-weight: 400;
`;

const DownloadFileButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 28px;
  padding: 13px 16px;
  border: 1px solid #667efe;
  border-radius: 12px;
  background-color: #fff;
  color: #667efe;
  font-size: 14px;
  font-weight: 400;
`;

const ExtensionIndicator = styled.span`
  padding: 5px 16px;
  font-size: 11px;
  border: 1px solid #667efe;
  border-radius: 5px;
`;

const DeclineReasonWrapper = styled.div`
  padding: 20px;
  display: flex;
  gap: 16px;
  border-radius: 12px;
  color: #8e66fe;
  border: 1px solid #d2c2ff;
  background-color: #e8e0ff;
`;
