import React from 'react';
import { observer } from 'mobx-react-lite';

import { Button, Flex, Input, Spacer, Typography } from 'uikit';
import styled from 'styled-components';
import { useStore } from 'store/Root';
import { Formik, Form } from 'formik';
import { EMPTY_CHAR } from 'utils/consts';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Loader } from 'uikit/Loader';

export const TeamSettings: React.FC = observer(() => {
  const { settingsStore, authStore } = useStore();

  const companyData = useQuery(['COMPANY_DATA_QUERY_KEY'], (request) => authStore.getCompany(), {
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });

  const queryClient = useQueryClient();
  const createMutation = useMutation((request: any) => settingsStore.createTeamMember(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['COMPANY_DATA_QUERY_KEY']);
    },
  });
  const editMutation = useMutation((request: any) => settingsStore.editTeamMember(request), {
    onSuccess: (e) => {
      queryClient.invalidateQueries(['COMPANY_DATA_QUERY_KEY']);
    },
  });

  const handleEditTeamMember = (values: any) => {
    const hasMember = authStore.getStaffMember(values.type);
    if (hasMember) values.id = hasMember.id;
    hasMember ? editMutation.mutate(values) : createMutation.mutate(values);
  };

  const accountant = authStore.getStaffMember('senior accountant');
  const shipment = authStore.getStaffMember('shipment');
  const qualityControl = authStore.getStaffMember('quality control');
  const documentFlow = authStore.getStaffMember('document flow');

  const renderStaff = () => {
    if (!authStore.user) return;
    return (
      <StaffWrapper>
        <Staff>
          <Formik
            initialValues={{
              type: accountant ? accountant.type : 'senior accountant',
              fio: accountant ? accountant.fio : EMPTY_CHAR,
              phoneNumber: accountant ? accountant.phoneNumber : EMPTY_CHAR,
              emailOfHead: accountant ? accountant.emailOfHead : EMPTY_CHAR,
            }}
            onSubmit={handleEditTeamMember}
          >
            <Form>
              <Typography size="lg">Главный бухгалтер</Typography>

              <Flex column>
                <Input type="value" name="fio" label="ФИО" variant="dark" />
                <Spacer />
                <Input type="value" name="phoneNumber" label="Номер телефона" variant="dark" />
                <Spacer />
                <Input type="email" name="emailOfHead" label="E-mail" variant="dark" />
                <Spacer />
                <Spacer space={30} />
                <Button variant="base" type="submit">
                  {!!accountant ? 'Редактировать' : 'Сохранить'}
                </Button>
                <Spacer />
              </Flex>
            </Form>
          </Formik>
        </Staff>
        <Staff>
          <Formik
            initialValues={{
              type: shipment ? shipment.type : 'shipment',
              fio: shipment ? shipment.fio : EMPTY_CHAR,
              phoneNumber: shipment ? shipment.phoneNumber : EMPTY_CHAR,
              emailOfHead: shipment ? shipment.emailOfHead : EMPTY_CHAR,
            }}
            onSubmit={handleEditTeamMember}
          >
            <Form>
              <Typography size="lg">Отгрузка</Typography>

              <Flex column>
                <Input type="value" name="fio" label="ФИО" variant="dark" />
                <Spacer />
                <Input type="value" name="phoneNumber" label="Номер телефона" variant="dark" />
                <Spacer />
                <Input type="email" name="emailOfHead" label="E-mail" variant="dark" />
                <Spacer />
                <Spacer space={30} />
                <Button variant="base" type="submit">
                  {!!shipment ? 'Редактировать' : 'Сохранить'}
                </Button>
                <Spacer />
              </Flex>
            </Form>
          </Formik>
        </Staff>
        <Staff>
          <Formik
            initialValues={{
              type: qualityControl ? qualityControl.type : 'quality control',
              fio: qualityControl ? qualityControl.fio : EMPTY_CHAR,
              phoneNumber: qualityControl ? qualityControl.phoneNumber : EMPTY_CHAR,
              emailOfHead: qualityControl ? qualityControl.emailOfHead : EMPTY_CHAR,
            }}
            onSubmit={handleEditTeamMember}
          >
            <Form>
              <Typography size="lg">Контроль качества</Typography>

              <Flex column>
                <Input type="value" name="fio" label="ФИО" variant="dark" />
                <Spacer />
                <Input type="value" name="phoneNumber" label="Номер телефона" variant="dark" />
                <Spacer />
                <Input type="email" name="emailOfHead" label="E-mail" variant="dark" />
                <Spacer />
                <Spacer space={30} />
                <Button variant="base" type="submit">
                  {!!qualityControl ? 'Редактировать' : 'Сохранить'}
                </Button>
                <Spacer />
              </Flex>
            </Form>
          </Formik>
        </Staff>
        <Staff>
          <Formik
            initialValues={{
              type: documentFlow ? documentFlow.type : 'document flow',
              fio: documentFlow ? documentFlow.fio : EMPTY_CHAR,
              phoneNumber: documentFlow ? documentFlow.phoneNumber : EMPTY_CHAR,
              emailOfHead: documentFlow ? documentFlow.emailOfHead : EMPTY_CHAR,
            }}
            onSubmit={handleEditTeamMember}
          >
            <Form>
              <Typography size="lg">Документооборот</Typography>

              <Flex column>
                <Input type="value" name="fio" label="ФИО" variant="dark" />
                <Spacer />
                <Input type="value" name="phoneNumber" label="Номер телефона" variant="dark" />
                <Spacer />
                <Input type="email" name="emailOfHead" label="E-mail" variant="dark" />
                <Spacer />
                <Spacer space={30} />
                <Button variant="base" type="submit">
                  {!!documentFlow ? 'Редактировать' : 'Сохранить'}
                </Button>
                <Spacer />
              </Flex>
            </Form>
          </Formik>
        </Staff>
      </StaffWrapper>
    );
  };

  return (
    <Wrapper>
      <Flex column>
        <Typography size="lg">Сотрудники</Typography>
        <Spacer />
        {settingsStore.isLoading && <Loader />}
        {companyData.isSuccess && renderStaff()}
      </Flex>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 44px 22px;
`;
const StaffWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 50%;
`;

const Staff = styled.div`
  width: 398px;
  padding: 20px 25px;
  background: #f9f6ed;
  border: 0.5px solid #dbdbdb;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
`;
