import { observer } from 'mobx-react-lite';
import React, { FormEvent, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { generatePath } from 'react-router';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { routes } from 'routes/consts';

import { IFarmer, IReqFarmers } from 'services/models';
import { useStore } from 'store/Root';
import styled from 'styled-components';
import { Button, Flex, Spacer, Typography } from 'uikit';
import { Loader } from 'uikit/Loader';
import { EMPTY_CHAR } from 'utils/consts';
import { FarmerListItem } from './FarmerListItem';
import { useNavigate } from 'react-router-dom';

export const FarmersListPage: React.FC = observer(() => {
  const { farmersStore, authStore } = useStore();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState(EMPTY_CHAR);

  const handleFarmerClick = (id: IFarmer['id']) => navigate(`../${generatePath(routes.farmers.view.path, { id })}`);

  const fetchFarmers = useQuery<any>('FARMERS_LIST_DATA_QUERY_KEY', () => farmersStore.getFarmers(searchInput), {
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
    staleTime: 60 * 1000,
  });

  useEffect(() => {
    farmersStore.setSelectedFarmer(null);
  });

  const handleSearchInputChange = (event: any) => {
    setSearchInput(event.target.value);
  };
  const handleSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchFarmers.refetch();
  };

  const loadMore = function () {
    if (farmersStore.hasNextPage) {
      farmersStore.setPageNumber(farmersStore.pageNumber + 1);
      fetchFarmers.refetch();
    }
  };

  // TODO: handle error loading
  const [sentryRef] = useInfiniteScroll({
    loading: farmersStore.isLoading,
    hasNextPage: farmersStore.hasNextPage,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  // const isAllowed = authStore.profileType !== 'farmer' || 'provider';

  return (
    <FarmersRootWrapper>
      <Flex column fillWidth gap={50}>
        <Typography size="lg2">Мои поставщики</Typography>
        <Flex gap="10px">
          <form onSubmit={handleSearch}>
            <StyledSearchInput
              type="value"
              name="search"
              value={searchInput}
              onChange={handleSearchInputChange}
              placeholder="Введите данные для поиска"
            />
            <Button variant="base" type="submit">
              Поиск
            </Button>
          </form>
          <Spacer />
        </Flex>
        <Flex column fillWidth gap={24}>
          {farmersStore.farmersList.map((farmer) => (
            <FarmerListItem key={farmer.id} farmer={farmer} onClick={() => handleFarmerClick(farmer.id)} />
          ))}
          {(farmersStore.isLoading || farmersStore.hasNextPage) && (
            <div ref={sentryRef}>
              <Loader />
            </div>
          )}
        </Flex>
      </Flex>
    </FarmersRootWrapper>
  );
});

const FarmersRootWrapper = styled.div`
  padding: 38px;
  max-width: 1100px;
`;
export const StyledSearchInput = styled.input`
  text-align: left;
  padding-left: 20px;
  margin-right: 10px;
  width: 400px;
  height: 50px;
  border: 0px;
  border-radius: 10px;
  outline: none;
  background-color: #f5f2ea;
  color: #333333;
  box-shadow: 0 0 0 30px #f5f2ea inset !important;
`;
