import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { routes } from 'routes/consts';
import { ILinkedOrder } from 'services/models';
import styled from 'styled-components';
import { Typography } from 'uikit';

import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { ID_DISPLAY_SYMBOLS } from 'utils/consts';

export const LinkedOrdersItem: React.FC<{ order: ILinkedOrder; isViewAll?: boolean; selectedStatus?: string }> = ({
  order,
  isViewAll = false,
  selectedStatus,
  ...props
}) => {
  const navigate = useNavigate();

  const handleOrderClick = (id: string) =>
    navigate(`../../orders/${generatePath(routes.orders.timeline.path, { id })}`);

  const startDateFormat =
    order.dateStartOfSpec &&
    +format(new Date(order.dateStartOfSpec), 'M') === +format(new Date(order.dateFinishOfContract), 'M')
      ? 'dd'
      : 'dd MMMM';

  const displayID = order.id.slice(-ID_DISPLAY_SYMBOLS);

  return (
    <OrderItemWrapper
      onClick={() => handleOrderClick(order.id)}
      isFail={['fail', 'done'].includes(order.generalStatus) && isViewAll}
      isHidden={!selectedStatus?.includes(order.generalStatus)}
      {...props}
    >
      <Typography color="#918F89">#{displayID}</Typography>
      <Typography>{order.product.title}</Typography>
      <ReadableStatus isFail={order.generalStatus === 'fail'}>{order.readableStatus}</ReadableStatus>
      <StyledTypography color="#918F89">
        {order.dateStartOfSpec && format(new Date(order.dateStartOfSpec), startDateFormat, { locale: ru })}
        {order.dateStartOfSpec && order.dateFinishOfContract && ' - '}
        {order.dateFinishOfContract && format(new Date(order.dateFinishOfContract), 'dd MMMM yyyy', { locale: ru })}
      </StyledTypography>
    </OrderItemWrapper>
  );
};

const OrderItemWrapper = styled.div<{ isFail: boolean; isHidden: boolean }>`
  display: ${(props) => (!props.isHidden ? 'grid' : 'none')};
  grid-template-columns: 10% 37% 28% 25%;
  padding: 17px 32px;
  background: #f9f6ed;
  border: 0.5px solid #dbdbdb;
  border-radius: 10px;
  cursor: pointer;
  opacity: ${(props) => (props.isFail ? 0.38 : 1)};
`;

const ReadableStatus = (props: any & { isFail: boolean }) => (
  <Typography color={props.isFail ? '#FA3E3E' : '#918F89'} {...props} />
);

const StyledTypography = styled(Typography)`
  text-align: right;
  color: #918f89;
  white-space: nowrap;
`;
