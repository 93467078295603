import styled from 'styled-components';
import { ProgressCheckmarkIcon } from 'uikit/Icons/ProgressCheckmarkIcon';
import { ProgressCircleIcon } from 'uikit/Icons/ProgressCircleIcon';
import { ProgressClockIcon } from 'uikit/Icons/ProgressClockIcon';

interface IOrderProgressTrackerProps {
  statusArr: string[];
  currentStatus: number;
}

export const OrderProgressTracker: React.FC<IOrderProgressTrackerProps> = ({ statusArr, currentStatus }) => {
  const renderIcon = (index: number, isLast: boolean) => {
    if (index <= currentStatus || isLast) {
      return <ProgressCheckmarkIcon />;
    } else if (index === currentStatus + 1) {
      return (
        <div>
          <ProgressClockIcon />
        </div>
      );
    } else {
      return (
        <div>
          <ProgressCircleIcon />
        </div>
      );
    }
  };
  return (
    <ProgressTrackerWrapper>
      {statusArr.map((status, index) => {
        const isLast = currentStatus === statusArr.length - 1;
        const isCompleted = isLast || index <= currentStatus;

        const isActive = index === currentStatus + 1;
        const itemStatus = isCompleted ? 'completed' : isActive ? 'active' : 'inactive';
        const isNextStepCompleted = index + 1 < currentStatus;

        return (
          <ProgressStep status={itemStatus} isNextStepCompleted={isNextStepCompleted} key={status}>
            {renderIcon(index, isLast)}
            <ProgressStepTitle status={itemStatus}>{status}</ProgressStepTitle>
          </ProgressStep>
        );
      })}
    </ProgressTrackerWrapper>
  );
};

const ProgressTrackerWrapper = styled.div`
  display: flex;
  gap: 16px;
  position: relative;
  z-index: 0;
`;

interface IProgressStep {
  status: 'active' | 'completed' | 'inactive';
  isNextStepCompleted: boolean;
}

const statusToColor = (status: IProgressStep['status']) => {
  switch (status) {
    case 'active':
      return '#F4F0FF';
    case 'completed':
      return '#E1F3BC';
    case 'inactive':
      return 'linear-gradient(180deg, #E9EBF1 0%, rgba(233, 235, 241, 0) 100%)';
  }
};

const ProgressStep = styled.div<IProgressStep>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  padding: 8px 12px;
  border-radius: 8px;
  background: ${({ status }) => statusToColor(status)};
  word-break: normal;
  &:not(:first-child)::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -16px;
    width: 16px;
    height: 4px;
    background-color: #cdcdcd;
    z-index: 0;
  }
  &:not(:last-child)::after {
    content: ${({ status }) => (status === 'completed' ? '""' : 'none')};
    width: 16px;
    height: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -16px;
    z-index: 1;
    background: ${({ isNextStepCompleted }) =>
      isNextStepCompleted ? '#E1F3BC' : 'linear-gradient(90deg, #F0FFD1 -0.69%, #CDCDCD 99.31%)'};
  }
`;

interface IProgressStepTitle {
  status: 'active' | 'completed' | 'inactive';
}

const statusToTextColor = (status: IProgressStep['status']) => {
  switch (status) {
    case 'active':
      return '#292929';
    case 'completed':
      return '#6DA000';
    case 'inactive':
      return '#AEAEAE';
  }
};

const ProgressStepTitle = styled.span<IProgressStepTitle>`
  font-size: 15px;
  font-weight: 600;
  color: ${({ status }) => statusToTextColor(status)};
`;
