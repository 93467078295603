import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Flex } from 'uikit';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/Root';

export const TradesCounter: React.FC = observer(() => {
  const { chatStore } = useStore();

  // useEffect(() => {
  //   chatStore.getCounter();
  // });

  const activeStatus = 'Активные';
  const archivedStatus = 'Закрытые';

  return (
    <Flex>
      <TabFilter
        isActive={activeStatus === chatStore.selectedStatus}
        isDisabled={chatStore.isLoading && activeStatus != chatStore.selectedStatus}
        onClick={() => {
          activeStatus !== chatStore.selectedStatus &&
            chatStore.tradesCounter?.active &&
            chatStore.setSelectedStatus(activeStatus);
        }}
      >
        <span>
          {activeStatus}
          {` - ${chatStore.tradesCounter?.active}` || ''}
        </span>
      </TabFilter>
      <TabFilter
        isActive={archivedStatus === chatStore.selectedStatus}
        isDisabled={chatStore.isLoading && archivedStatus != chatStore.selectedStatus}
        onClick={() => {
          archivedStatus !== chatStore.selectedStatus && chatStore.setSelectedStatus(archivedStatus);
        }}
      >
        <span>
          {archivedStatus}
          {` - ${chatStore.tradesCounter?.archived}` || ''}
        </span>
      </TabFilter>
    </Flex>
  );
});

const TabFilter = styled.div<{ isActive?: boolean; isDisabled?: boolean }>`
  font-size: 16px;
  opacity: ${({ isActive }) => (isActive ? 'initial' : 0.5)};
  cursor: ${({ isDisabled }) => (isDisabled ? 'progress' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'initial')};
  margin-right: 30px;
`;
