import { FarmerListItem } from 'components/Farmer/pages/FarmerListItem';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useStore } from 'store/Root';
import styled from 'styled-components';
import { Flex, Spacer, Typography } from 'uikit';
import { Loader } from 'uikit/Loader';
import { DashboardCounter } from './partials/DashboardCounter';
import { DashboardFarmerFilters } from './partials/DashboardFarmerFilters';
import { DashboardUserCard } from './partials/DashboardUserCard';
import { DashboardUserFilters } from './partials/DashboardUserFilters';

export const ContactsDashboardPage: React.FC = observer(() => {
  const { contactsDashboardStore, farmersStore } = useStore();

  const isStatusUsers = contactsDashboardStore.selectedCategory === 'Пользователи';

  useEffect(() => {
    contactsDashboardStore.fetchContacts();
  }, [contactsDashboardStore.selectedCategory]);

  const setFarmerStatus = (id: string, accreditationStatus: string) => {
    farmersStore.updateFarmer({ id, accreditationStatus });
  };
  const setUserStatus = (id: string, isVerified: boolean, isBlocked: boolean, isEdmReleased: boolean) => {
    contactsDashboardStore.updateUser({ id, verified: isVerified, blocked: isBlocked, edmReleased: isEdmReleased });
  };

  const loadMore = function () {
    isStatusUsers
      ? contactsDashboardStore.setPageNumber(contactsDashboardStore.pageNumber + 1)
      : farmersStore.setPageNumber(farmersStore.pageNumber + 1);
    contactsDashboardStore.fetchContacts();
  };

  const isLoading = contactsDashboardStore.isLoading || farmersStore.isLoading;

  const hasNextPage = isStatusUsers ? contactsDashboardStore.hasNextPage : farmersStore.hasNextPage;

  // TODO: handle error loading
  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasNextPage,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  const renderList = () =>
    !!contactsDashboardStore.filteredList.length ? (
      <>
        {contactsDashboardStore.filteredList.map((entity: any) =>
          isStatusUsers ? (
            <DashboardUserCard onStatusChange={setUserStatus} key={entity.id} user={entity} />
          ) : (
            <FarmerListItem setFarmerStatus={setFarmerStatus} isEditable key={entity.id} farmer={entity} />
          ),
        )}
        {hasNextPage && (
          <div ref={sentryRef}>
            <Loader />
          </div>
        )}
      </>
    ) : isLoading ? (
      <Loader />
    ) : (
      'По запросу ничего не найдено, попробуйте изменить параметры поиска'
    );

  return (
    <Wrapper>
      <Flex column>
        <Typography size="lg2" bold>
          Пользователи и компании
        </Typography>
        <Spacer space={28} />
        <DashboardCounter />
        <Spacer space={20} />
        {isStatusUsers ? <DashboardUserFilters /> : <DashboardFarmerFilters />}
        <Spacer space={20} />
        <Flex column gap={20}>
          {renderList()}
        </Flex>
        {/* {isFirstBatchLoaded && contactsDashboardStore.hasNextPage && (
          <div ref={sentryRef}>
            <Loader />
          </div>
        )}  */}
        <Spacer space={20} />
        {/* <button onClick={handleLoadMore}>LoadMore</button> */}
      </Flex>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 44px;
`;
