import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { BusinessIcon, CalendarIcon, DocSignIcon, Flex, LocationIcon, PersonIcon, Spacer } from 'uikit';
import ru from 'date-fns/locale/ru';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useStore } from 'store/Root';
import { CloseIcon } from 'uikit/Icons/CloseIcon';
import { Select } from 'uikit/Selects';
import { format } from 'date-fns';
import { InquiryHeader } from 'components/Inquiries/pages/InquiryPage';
import { IOrderDetailsRequest } from 'services/models';

registerLocale('ru', ru);

interface IProps {
  isCPT: boolean;
  showModalState: boolean;
  handleModalClose: () => void;
  startDate: string;
  endDate: string;
  orderId: string;
  orderPrice: string;
  providerName: string;
  signer: string;
  docFoundation: string;
  selectedWarehouse:
    | {
        label: string;
        value: string;
      }
    | string;
}

type IDate = Date | null;

export const SpecOrderActionsModal: React.FC<IProps> = observer(
  ({
    isCPT,
    showModalState,
    handleModalClose,
    orderId,
    startDate,
    endDate,
    selectedWarehouse,
    providerName,
    signer,
    docFoundation,
  }) => {
    const { inquiriesStore, specsOrdersStore } = useStore();

    const [activeWarehouse, setActiveWarehouse] = useState<any>(selectedWarehouse);
    const [warehouseAddress, setWarehouseAddress] = useState<string>(selectedWarehouse as string);

    const [docFoundationInput, setDocFoundationInput] = useState<string>(docFoundation);
    const [dateRange, setDateRange] = useState<IDate[]>([new Date(startDate), new Date(endDate)]);
    const [activeStartDate, activeEndDate] = dateRange;

    const handleCounterOffer = () => {
      const dataToSend: IOrderDetailsRequest = {
        id: orderId,
        startDate: format(activeStartDate as Date, 'yyyy-MM-dd'),
        endDate: format(activeEndDate as Date, 'yyyy-MM-dd'),
        docFoundation: docFoundationInput,
      };
      if (isCPT) {
        dataToSend.destinationWarehouse = activeWarehouse.value;
      } else {
        dataToSend.warehouseAddress = warehouseAddress;
      }

      specsOrdersStore.updateOrderDetails(dataToSend);
      setTimeout(() => {
        handleModalClose();
      }, 1000);
    };

    const handleWarehouseSelectChange = (warehouse: { label: string; value: string }) => {
      setActiveWarehouse(warehouse);
    };

    const warehousesOptions = inquiriesStore.warehousesList.map((warehouse) => ({
      label: warehouse.title,
      value: warehouse.id,
    }));

    return (
      <StyledModal
        show={showModalState}
        onHide={handleModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeaderWrapper>
          <Modal.Header closeButton />
          <StyledCloseIcon>
            <CloseIcon />
          </StyledCloseIcon>
        </ModalHeaderWrapper>
        <ModalContent>
          <Flex column>
            <InnerHeading>
              <InquiryHeader>Данные для спецификации</InquiryHeader>
              <p>Данные по условиям поставки и подписанту</p>
            </InnerHeading>
            <InnerWrapper>
              <Flex column>
                <SubHeader>Даты поставки</SubHeader>
                <DatePickerWrapper>
                  <StyledRoubleIcon>
                    <CalendarIcon />
                  </StyledRoubleIcon>
                  <DatePicker
                    selectsRange={true}
                    startDate={activeStartDate}
                    endDate={activeEndDate}
                    calendarClassName="shipment-calendar"
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                  />
                </DatePickerWrapper>
              </Flex>
              <StyledWarehouseSelect>
                <SubHeader>{isCPT ? 'Склад' : 'Пункт погрузки'}</SubHeader>
                {isCPT ? (
                  <Select
                    placeholder={typeof selectedWarehouse !== 'string' ? selectedWarehouse.label : 'Склад'}
                    options={warehousesOptions}
                    onChange={handleWarehouseSelectChange}
                  />
                ) : (
                  <InputWrapper>
                    <StyledRoubleIcon>
                      <LocationIcon />
                    </StyledRoubleIcon>
                    <StyledInput
                      type="text"
                      name="warehouseAddress"
                      value={warehouseAddress}
                      onChange={(e) => setWarehouseAddress(e.target.value)}
                      placeholder="Введите адрес пункта погрузки"
                    />
                  </InputWrapper>
                )}
              </StyledWarehouseSelect>

              <Flex column marginTop={15}>
                <SubHeader>Компания</SubHeader>
                <InputWrapper>
                  <StyledRoubleIcon>
                    <BusinessIcon />
                  </StyledRoubleIcon>
                  <StyledInput
                    type="text"
                    name="company"
                    value={providerName}
                    disabled
                    onChange={(e) => console.log(e.target.value)}
                    placeholder="Название компании фермера"
                  />
                </InputWrapper>
                <SubHeader>Подписант</SubHeader>
                <InputWrapper>
                  <StyledRoubleIcon>
                    <PersonIcon />
                  </StyledRoubleIcon>
                  <StyledInput
                    type="text"
                    name="signer"
                    value={signer}
                    disabled
                    onChange={(e) => console.log(e.target.value)}
                    placeholder="Подписант"
                  />
                </InputWrapper>
                <SubHeader>Основания для подписанта</SubHeader>
                <InputWrapper>
                  <StyledRoubleIcon>
                    <DocSignIcon />
                  </StyledRoubleIcon>
                  <StyledInput
                    type="text"
                    name="docFoundation"
                    value={docFoundationInput}
                    onChange={(e) => setDocFoundationInput(e.target.value)}
                    placeholder="Документ основания для подписанта"
                  />
                </InputWrapper>
              </Flex>

              <ButtonsWrapper>
                <DiscardChangesButton type="button" onClick={handleModalClose}>
                  Отменить
                </DiscardChangesButton>

                <CounterOfferButton type="button" onClick={handleCounterOffer}>
                  Предложить изменения
                </CounterOfferButton>
              </ButtonsWrapper>
              <Spacer space={20} />
            </InnerWrapper>
          </Flex>
        </ModalContent>
      </StyledModal>
    );
  },
);

const InnerHeading = styled.div`
  padding-left: 8px;
`;
const InnerWrapper = styled.div`
  background-color: #f4f6f6;
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  border-radius: 12px;
`;

export const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 534px;
  }
  .modal-header {
    border: none;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }
  .btn-close {
    background: none;
    width: 40px;
    height: 40px;
    position: absolute;
    right: -11px;
    top: -8px;
    z-index: 1;
  }
`;

const ModalHeaderWrapper = styled.div`
  position: relative;
`;

const SubHeader = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 124%;
  padding-left: 8px;
  color: #292929;
`;
const DatePickerWrapper = styled.div`
  position: relative;
  z-index: 3;
  margin-top: 12px;
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
    input {
      padding: 17px 30px;
      padding-left: 48px;
      border-radius: 12px;
      border: 1px solid #dcdee4;
      width: 100%;
    }
  }
`;

const StyledCloseIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  cursor: pointer;
`;

const StyledWarehouseSelect = styled.div`
  margin-top: 12px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  .Select__control {
    max-width: 100%;
    border: 1px solid #dcdee4;
    max-height: 52px;
    border-radius: 12px;
  }
  .Select__value-container {
    padding: 10px 16px;
  }
`;

export const ModalContent = styled.div`
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 10px;
  max-width: 534px;
`;

const StyledInput = styled.input`
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 16px;
  padding-left: 48px;
  width: 100%;
  text-align: left;
  color: #656565;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #dcdee4;
  border-radius: 12px;
  &:disabled {
    background-color: #e9ebf1;
    color: #292929;
  }
`;

export const CounterOfferButton = styled.button`
  width: 50%;
  background-color: #8e66fe;
  border-radius: 12px;
  border: 1px solid #825dec;
  padding: 18px 0px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
`;

export const DiscardChangesButton = styled.button`
  width: 50%;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #825dec;
  padding: 18px 0px;
  color: #8e66fe;
  font-weight: 600;
  font-size: 18px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-top: 16px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const StyledRoubleIcon = styled.div`
  position: absolute;
  z-index: 1;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
`;
