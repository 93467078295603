import React, { useEffect, useState } from 'react';
import { Flex, Spacer, Typography } from 'uikit';
import styled from 'styled-components';
import { useStore } from 'store/Root';
import { TradesCounter } from './components/TradesCounter';
import { Room } from './components/Room';
import { observer } from 'mobx-react-lite';
import { ExampleWS } from 'components/Analytics/pages/partials/ExampleWS';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Loader } from 'uikit/Loader';

export const ChatsListPage: React.FC = observer(() => {
  const [isHiddenDebugOpen, setOpenHiddenDebug] = useState(false);
  const [isFirstBatchLoaded, setIsFirstBatchLoaded] = useState(false);
  const { chatStore } = useStore();
  chatStore.setSelectRoom('');

  useEffect(() => {
    !isFirstBatchLoaded && chatStore.getRooms();
    if (chatStore.roomsList.length) {
      setIsFirstBatchLoaded(true);
    }
  }, [chatStore.roomsList.length]);

  const toggleHiddenDebug = () => {
    setOpenHiddenDebug(!isHiddenDebugOpen);
  };

  const loadMore = function () {
    if (chatStore.hasNextPageRooms) {
      chatStore.getRooms(chatStore.roomsList.at(-1)!.message?.time);
    }
  };

  // TODO: handle error loading
  const [sentryRef] = useInfiniteScroll({
    loading: chatStore.isLoading,
    hasNextPage: chatStore.hasNextPageRooms,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  const noRoomsMessage = chatStore.selectedStatus === 'Закрытые' ? 'Нет закрытых торгов' : 'Нет активных торгов';
  const noRoomsStatus = chatStore.isLoading ? <Loader /> : <Typography>{noRoomsMessage}</Typography>;

  return (
    <Wrapper>
      <Flex column>
        <Typography size="lg2" bold>
          Сообщени<span onClick={toggleHiddenDebug}>я</span>
        </Typography>
        <Spacer space={28} />
        <TradesCounter />
        <Spacer space={20} />
        {isHiddenDebugOpen && <ExampleWS />}
        {!!chatStore.roomsList.length
          ? chatStore.roomsList.map((room) => <Room key={room.id} room={room} />)
          : noRoomsStatus}
        {isFirstBatchLoaded && chatStore.hasNextPageRooms && (
          <div ref={sentryRef}>
            <Loader />
          </div>
        )}
        <Spacer space={20} />
        {/* <button onClick={handleLoadMore}>LoadMore</button> */}
      </Flex>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 44px;
`;
