import { ILoginValues } from 'components/Auth/AuthPage';
import { flow, types, getRoot, Instance } from 'mobx-state-tree';
import { daylesfordService } from 'services';
import { IReqPasswordReset, IReqRecovery, ITeamMember } from 'services/models';
import { getProfile, logout, setProfile, setTokens } from 'services/token-service';
import { IRootStore } from './Root';

const { model, string, boolean, identifier, array, maybe, maybeNull, enumeration, number } = types;

const Company = model('Company', {
  id: identifier,
  addressSending: string,
  basisOfDoc: string,
  bik: string,
  correspondentAccount: string,
  emailOfHead: string,
  headOfProvider: string,
  inn: string,
  kpp: string,
  mailAddress: string,
  nameOfBank: string,
  nameOfProvider: string,
  fullName: string,
  ogrn: string,
  paymentAccount: string,
  phoneNumber: string,
  positionHeadOfProvider: string,
  shortFio: string,
  ulAddress: string,
  staff: array(
    model({
      id: identifier,
      type: string,
      fio: string,
      phoneNumber: string,
      emailOfHead: string,
      company: string,
    }),
  ),

  taxType: string,
});

export interface ICompany extends Instance<typeof Company> {}

const User = model('User', {
  id: identifier,
  snils: string,
  profileType: maybe(string),
  email: maybe(string),
  company: Company,
  verified: boolean,
}).actions((self) => {
  return {
    setUserCompany(value: ICompany) {
      self.company = value;
    },
  };
});

export interface IUser extends Instance<typeof User> {}

const Profile = model('Profile', {
  id: identifier,
  company: Company,
  edmReleased: boolean,
  phone: string,
  email: string,
  firstName: string,
  lastName: string,
  licensePayment: model({
    paymentBuyer: number,
    paymentProvider: number,
  }),
  type: string,
  autoSignDoc: boolean,
  docVerification: boolean,
  snils: string,
  docFoundation: string,
  active: boolean,
  verified: boolean,
});

export const AuthStore = model('AuthStore', {
  status: enumeration('Status', ['idle', 'loading', 'success', 'error']),
  user: maybeNull(User),
  profiles: maybeNull(array(Profile)),
  profileType: maybeNull(string),
  isLoading: false,
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .views((self) => ({
    getStaffMember(type: string) {
      return self.user !== null
        ? self.user.company.staff.find((member) => member.type === type)
        : { id: '?', type: type, fio: 'fio', emailOfHead: 'sample@email.com', phoneNumber: 'none' };
    },
  }))
  .views((self) => ({
    get isAuthenticated() {
      return !!self.user && (self.status === 'status' || 'idle');
    },
  }))
  .views((self) => ({
    get simpleUser() {
      return !!self.user
        ? { id: self.user.id, name: self.user.company.nameOfProvider, taxType: self.user.company.taxType }
        : { id: '1', name: 'mock', taxType: 'simple' };
    },
  }))
  .views((self) => ({
    get hasProfiles() {
      return !!self.profiles;
    },
  }))
  .views((self) => ({
    get profileCompanies() {
      return self.profiles
        ?.filter((profile) => profile.type !== 'provider')
        .map((profile) => ({
          value: profile.company.id,
          label: profile.company.nameOfProvider,
        }));
    },
  }))
  .actions((self) => {
    return {
      setUser(value: IUser) {
        self.user = value;
      },
    };
  })
  .actions((self) => {
    return {
      setProfileType(value: string) {
        self.profileType = value;
      },
    };
  })
  .actions((self) => {
    return {
      setProfiles(value: any) {
        self.profiles = value;
      },
    };
  })
  .actions((self) => {
    return {
      setStatus(value: 'idle' | 'loading' | 'success' | 'error') {
        self.status = value;
      },
    };
  })
  .actions((self) => {
    return {
      login: flow<ILoginValues, any[]>(function* login(creds): any {
        self.setStatus('loading');
        const user = yield daylesfordService
          .login(creds)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Авторизация`, 'error'));

        const profile = user.data.profile;
        profile.email = creds.email;
        self.setUser(profile);
        setTokens(user.data);
        setProfile(user.data.profile);
        // socket.connect();
        self.setStatus('success');
      }),
    };
  })
  .actions((self) => {
    return {
      requestRecovery: flow(function* requestRecovery(email: IReqRecovery): any {
        const request = yield daylesfordService
          .requestRecovery(email)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Восстановление пароля`, 'error'));
        return request;
      }),
    };
  })
  .actions((self) => {
    return {
      passwordReset: flow(function* passwordReset(options: IReqPasswordReset): any {
        self.setStatus('loading');
        const request = yield daylesfordService.passwordReset(options).catch((e) => {
          self.root.alertsStore.addNotification(`${e.message}: Восстановление пароля`, 'error');
          self.setStatus('error');
          return;
        });
        !!request && self.setStatus('success');

        return request;
      }),
    };
  })
  .actions((self) => {
    return {
      getMe: flow(function* getCompany(): any {
        self.isLoading = true;
        const me = yield daylesfordService
          .me()
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Получение пользователя`, 'error'));
        self.isLoading = false;

        self.setProfileType(me.data.profileType);
        return me.data;
      }),
    };
  })
  .actions((self) => {
    return {
      getProfiles: flow(function* getProfiles(): any {
        self.isLoading = true;
        const profiles = yield daylesfordService
          .getProfiles()
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Получение профилей`, 'error'));
        self.isLoading = false;

        self.setProfiles(profiles.data);
        return profiles.data;
      }),
    };
  })
  .actions((self) => ({
    afterCreate() {
      self.root.authStore.isAuthenticated && self.getMe();
    },
  }))
  .actions((self) => {
    return {
      getCompany: flow(function* getCompany(): any {
        self.isLoading = true;
        const team = yield daylesfordService
          .getCompany()
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Получение команды`, 'error'));
        self.user!.setUserCompany(team.data.company);
        self.isLoading = false;

        return team.data;
      }),
    };
  })
  .actions((self) => {
    return {
      editTeamMember: flow(function* editTeamMember(request: ITeamMember): any {
        request.company = self.user?.company.id;
        const team = yield daylesfordService
          .editTeamMember(request)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Редактирование команды`, 'error'));
        return team.data;
      }),
    };
  })
  .actions((self) => {
    return {
      createTeamMember: flow(function* createTeamMember(request: ITeamMember): any {
        request.company = self.user?.company.id;
        const team = yield daylesfordService
          .createTeamMember(request)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Редактирование команды`, 'error'));
        return team.data;
      }),
    };
  })
  .actions((self) => {
    return {
      logout() {
        self.setStatus('loading');
        self.user = null;
        self.profiles = null;
        self.root.chatStore.disconnect();
        logout();
        self.setStatus('idle');
      },
    };
  });
