import React from 'react';
import { observer } from 'mobx-react-lite';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';

import styled from 'styled-components';
import { Spacer, Typography } from 'uikit';

export interface PieChartProps {
  chartData: {
    title: string;
    legend: { value: string };
    data: [
      {
        name: string;
        color: string;
        value?: number;
      },
    ];
  };
}

export const PieChartAnalitycs: React.FC<PieChartProps> = observer(({ chartData }) => {
  const RADIAN = Math.PI / 180;
  // @ts-ignore
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const renderLegend = (props: any) => {
    const { payload } = props;
    return (
      <LegendWrapper>
        <Typography bold>{chartData.legend.value}:</Typography>
        <LegendList>
          {payload.map((entry: any, index: any) => (
            <li key={`item-${index}`}>
              {entry.value}:{' '}
              <span style={{ color: entry.color }}>{(parseFloat(entry.payload.percent) * 100).toFixed(2)}%</span>
            </li>
          ))}
        </LegendList>
      </LegendWrapper>
    );
  };

  return (
    <>
      <AnalitycsWrapper>
        <Typography bold size="lg2">
          {chartData.title}
        </Typography>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={300} height={200}>
            <Legend align="right" verticalAlign="middle" content={renderLegend} />
            <Pie
              data={chartData.data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={140}
              fill="#8884d8"
              dataKey="value"
            >
              {chartData.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={`#${entry.color}`} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </AnalitycsWrapper>
      <Spacer space={28} />
    </>
  );
});
const AnalitycsWrapper = styled.div`
  width: 390px;
  height: 400px;
`;

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: -125px;
  right: -60%;
  max-width: 200px;
`;
const LegendList = styled.ul`
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
`;
