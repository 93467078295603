import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getCounterProgress } from 'utils/utils';

export const Counter: React.FC<{ deadline: string; bidDuration: number }> = observer(({ deadline, bidDuration }) => {
  const [currentProgress, setCurrentProgress] = useState({ formattedDuration: '', percents: 0 });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const progress = getCounterProgress(deadline, bidDuration);
      setCurrentProgress(progress);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [deadline]);

  if (currentProgress.percents < 1) return null;

  return (
    <CounterStyled>
      <CounterFill style={{ width: `${currentProgress.percents}%` }} />
      <CounterDuration>{currentProgress.formattedDuration}</CounterDuration>
    </CounterStyled>
  );
});

const CounterFill = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #8e66fe;
  height: 100%;
  z-index: 1;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: 0.2s ease-in-out all;
`;
const CounterDuration = styled.span`
  z-index: 2;
  color: white;
  position: absolute;
  right: 5px;
  top: -1px;
`;
const CounterStyled = styled.div`
  position: relative;
  width: 100%;
  background: #b9a0ff;
  padding: 2px 5px;
  min-width: 100px;
  text-align: right;
  border-radius: 4px;
  min-height: 17px;
  overflow: hidden;
  flex: 0;
`;
