import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from 'routes/consts';
import { theme } from 'theme';
import { Flex, Spacer } from 'uikit';
import { NavSidebarItem } from './NavSidebarItem';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/Root';
import { getProfile } from 'services/token-service';

/**
 * Отображает разворачиваемую панель с основным меню.
 */
export const NavSidebar: React.FC = observer(() => {
  const { authStore, chatStore } = useStore();

  const profile = getProfile();
  const isOwner = profile?.type === 'owner';

  if (!!authStore.profileType && !!profile) profile.type = authStore.profileType;

  const navList = [
    // { path: '/orders/list', label: 'Сделки' },
    { path: 'specs-orders/list', label: 'Сделки Силк' },
    // { path: '/offers/list', label: 'Предложения' },
    // { path: '/messages/list', label: 'Торги', shouldRenderIndicator: true },
    { path: '/inquiries/list', label: 'Заявки' },
    { path: '/price-monitoring', label: 'Мониторинг цен' },
    // { path: '/analytics', label: 'Аналитика' },
    { path: '/providers', label: 'Мои поставщики', notAdmin: true, notOwner: isOwner },
    { path: '/contact-dashboard', label: 'Правки', restricted: true },
    { path: '/settings', label: 'Настройки' },
  ];

  const handleLogout = () => {
    authStore.logout();
  };

  return (
    <NavSidebarInner>
      <div>
        <LogoWrapper>
          <Logo></Logo>
          <LogoText>
            Силк x Daylesford
            <br />
            CRM
          </LogoText>
        </LogoWrapper>
        <Flex fillWidth>
          <NavList>
            {navList.map((item) => {
              if (profile.type === 'admin' && item.notAdmin) return null;
              if (profile.type === 'owner' && item.notOwner) return null;
              if (profile.type !== 'admin' && item.restricted) {
                // if (item.shouldRenderIndicator && item.label === 'Торги') {
                // return (
                //   <NavSidebarItem
                //     key={item.path}
                //     hasIndicator={chatStore.hasUnreads}
                //     label={item.label}
                //     path={item.path}
                //   />
                // );
                return null;
              }
              return <NavSidebarItem key={item.path} label={item.label} path={item.path} />;
            })}
          </NavList>
          <Spacer width={theme.spacings.s} />
        </Flex>
      </div>
      <StyledNavLink end to={routes.root.path} onClick={handleLogout}>
        <span>Выйти</span>
      </StyledNavLink>
      <StyledUsername as="span">
        <span>{authStore.user && authStore.user.email}</span>
      </StyledUsername>
    </NavSidebarInner>
  );
});

const StyledNavLink = styled(NavLink)`
  color: #656565;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
`;
const StyledUsername = styled.span`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  color: #666;
`;

const NavList = styled.ul`
  width: 100%;
`;

const NavSidebarInner = styled.div`
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 37px 32px;
  width: 276px;
  background-color: #ebedf2;
  white-space: nowrap;
  z-index: 1;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 95px;
`;

const Logo = styled.div`
  min-width: 41px;
  min-height: 41px;
  border-radius: 10px;
  background: url('/logo.png') no-repeat center;
  background-size: contain;
`;

const LogoText = styled.div`
  font-weight: 700;
  font-size: 18px;
`;
