import React from 'react';
import styled from 'styled-components';
import Linkify from 'react-linkify';
import { TwistedArrowIcon } from 'uikit';

interface IMessage {
  author: string;
  time: string;
  isOwn: boolean;
  data: { v: string; price?: string };
}

export const Message: React.FC<IMessage> = ({ data, isOwn }) => {
  return !data.price ? (
    <Wrapper isOwn={isOwn}>
      <Linkify>
        <Text>{data.v}</Text>
      </Linkify>
    </Wrapper>
  ) : (
    <>
      <Wrapper isOwn={isOwn}>
        <TextBidHeader>{isOwn ? 'Обновление предложения' : 'Новое предложение от продавца'} </TextBidHeader>
        <TextBid>
          <TwistedArrowIcon isWhite={isOwn} reverse={isOwn} />
          <Text>{data.price} ₽/т</Text>
        </TextBid>
      </Wrapper>
      {data.v.length > 0 && (
        <Wrapper isOwn={isOwn}>
          <Linkify>
            <Text>{data.v}</Text>
          </Linkify>
        </Wrapper>
      )}
    </>
  );
};

interface IProps {
  isOwn: boolean;
}
const Wrapper = styled.div<IProps>`
  padding: 15px 20px;
  gap: 5px;
  display: flex;
  border: 1px solid #ddd;
  border-radius: 5px;
  flex-flow: column nowrap;
  max-width: 650px;
  align-self: ${(p) => (p.isOwn ? 'end' : 'start')};
  color: ${(p) => (!p.isOwn ? '#191919' : '#fff')};
  background-color: ${(p) => (!p.isOwn ? '#E7DDBD' : '#4582DE')};
`;
const Text = styled.span`
  flex: 1;
  font-size: 16px;
`;
const TextBidHeader = styled.span`
  font-size: 12px;
  line-height: 12px;
`;
const TextBid = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const MessageTime = styled.span`
  flex: 1;
  align-self: end;
  font-size: 11px;
  color: #aaa;
`;
