/**
 * Иконка "Прогресс галочка".
 */
export const ProgressCheckmarkIcon = () => {
  return (
    <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 14.126c3.862 0 7.062-3.192 7.062-7.062 0-3.862-3.2-7.061-7.069-7.061-3.862 0-7.055 3.2-7.055 7.061 0 3.87 3.2 7.062 7.062 7.062Zm-.759-3.685c-.26 0-.471-.116-.663-.362L4.403 8.042a.757.757 0 0 1-.184-.485c0-.349.273-.636.629-.636.212 0 .376.082.547.3l1.319 1.675 2.83-4.525c.143-.24.335-.355.554-.355.341 0 .65.239.65.594 0 .158-.083.328-.179.479l-3.199 4.99c-.157.232-.376.362-.629.362Z"
        fill="#6DA000"
      />
    </svg>
  );
};
