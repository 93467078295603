import React, { useState } from 'react';
import { useStore } from 'store/Root';
import styled from 'styled-components';
import { Spacer, Flex, Typography, ModalTimelineTableCar } from 'uikit';
import { GridTable } from 'uikit/GridTable';
import { TitleText, Docs, ProgressBar } from 'uikit/TimeLineBlocks';

interface IBlocksInfo {
  blocks: any;
  isSubBlock?: boolean;
}
export const BlocksInfo: React.FC<IBlocksInfo> = ({ blocks, isSubBlock = false }) => {
  const { analyticsStore, ordersStore } = useStore();
  const [showTableModalState, setShowTableModalState] = useState<any>(false);

  const blocksArr = isSubBlock ? blocks : analyticsStore.getBlocksArr;
  const renderItemDay = () => {
    return (
      <ItemDayDiv>
        {blocksArr.map(
          (
            {
              value,
              type,
              dateTime,
              blocks: subBlock,
            }: {
              value: string;
              type: string;
              dateTime: string;

              blocks: any;
            },
            idx: number,
          ) => {
            const getSubBlocks = () => {
              return (
                <React.Fragment key={idx}>
                  <BlocksInfo isSubBlock blocks={subBlock} />
                </React.Fragment>
              );
            };

            const getProgressBar = () => {
              const { current: currentValue, percent, total: tottalValue } = JSON.parse(value) || {};
              return (
                <React.Fragment key={idx}>
                  <ProgressBar currentValue={currentValue} percent={percent} tottalValue={tottalValue} />
                </React.Fragment>
              );
            };

            const getTitleTable = () => {
              const { title: titleTable, table_name, tables } = JSON.parse(value) || {};

              return (
                <React.Fragment key={idx}>
                  <Flex>
                    <Typography size="lg">{titleTable}</Typography>
                    <Spacer width={8} />
                    <div onClick={() => setShowTableModalState(true)}>
                      <TypographyTable size="lg" color="#8E66FE">
                        {table_name}
                      </TypographyTable>
                    </div>
                  </Flex>
                  <Spacer space={20} />
                  {showTableModalState && (
                    <ModalTimelineTableCar
                      isActive={showTableModalState}
                      setShowModalState={setShowTableModalState}
                      tables={tables}
                    />
                  )}
                </React.Fragment>
              );
            };
            const getTable = () => {
              const { title: titleTable, row_names, col_names, additional_data, values } = JSON.parse(value) || {};

              return (
                <React.Fragment key={idx}>
                  <Flex column>
                    <Typography size="lg">{titleTable}</Typography>
                    <Spacer space={10} />
                    <GridTable values={values} rowNames={row_names} colNames={col_names} />
                    <Spacer space={10} />
                    {additional_data && <Typography size="lg">{additional_data}</Typography>}
                  </Flex>
                  <Spacer space={38} />
                </React.Fragment>
              );
            };

            switch (type) {
              case 'title':
                return (
                  <React.Fragment key={idx}>
                    <TitleText value={value} dateTime={dateTime || '2022-07-04T06:11:09'} />
                  </React.Fragment>
                );

              case 'docs':
                return (
                  <React.Fragment key={idx}>
                    <Docs onDownloadAllClick={ordersStore.timelineStore.getTimelineDocs} value={value} />
                    <Spacer space={10} />
                  </React.Fragment>
                );

              case 'progress-bar':
                return getProgressBar();

              case 'sub-block':
                return getSubBlocks();

              case 'title-with-table':
                return getTitleTable();
              case 'table':
                return getTable();

              default:
                return '';
            }
          },
        )}
      </ItemDayDiv>
    );
  };

  return (
    <React.Fragment>
      <Spacer space={10} />
      {renderItemDay()}
    </React.Fragment>
  );
};

const TypographyTable = styled(Typography)`
  cursor: pointer;
`;

const ItemDayDiv = styled.div`
  padding-left: 22px;
  max-height: 1800px;
`;
