import { useQuery } from 'react-query';

const CULTURES_LIST_DATA_QUERY_KEY = 'CULTURES_LIST_DATA_QUERY_KEY';
const CULTURES_FILTER_LIST_DATA_QUERY_KEY = 'CULTURES_FILTER_LIST_DATA_QUERY_KEY';

export const useCultures = (hasCultures: boolean, getCulturesRequest: any) => {
  const result = useQuery([CULTURES_LIST_DATA_QUERY_KEY, hasCultures], () => getCulturesRequest(), {
    enabled: !hasCultures,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });
  return result;
};

export const useCulturesFilter = (hasCulturesOptions: boolean, getCulturesFilterRequest: any) => {
  const result = useQuery([CULTURES_FILTER_LIST_DATA_QUERY_KEY, hasCulturesOptions], () => getCulturesFilterRequest(), {
    enabled: !hasCulturesOptions,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });
  return result;
};
