import React from 'react';
import { HeadingSteps, TypographyDate } from 'components/Orders/components/TimeLine/styles';
import { Flex } from '../index';
import { ITitleText } from 'services/models';
import { dateFormatTimeline } from 'components/Orders/pages/helpers';

export const TitleAdress: React.FC<ITitleText> = ({ value, dateTime }) => {
  return (
    <>
      <Flex hAlignContent="center">
        <HeadingSteps size="lg">{JSON.parse(value).title}</HeadingSteps>
        <TypographyDate size="sm" color="#918F88">
          {dateFormatTimeline(dateTime)}
        </TypographyDate>
      </Flex>
      <HeadingSteps size="lg">{JSON.parse(value).address}</HeadingSteps>
    </>
  );
};
