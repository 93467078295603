/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArray, Form, Formik } from 'formik';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import styled from 'styled-components';
import Modal from 'react-bootstrap/esm/Modal';
import isNumber from 'lodash-es/isNumber';
import format from 'date-fns/esm/format';
import { observer } from 'mobx-react-lite';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useStore } from 'store/Root';
import { routes } from 'routes/consts';

import { Button, Flex, Input, Spacer, Typography } from 'uikit';
import { FormikField } from 'uikit/Field';
import { Select } from 'uikit/Selects';
import { DatePickerField } from 'uikit/Datepicker/Datepicker';
import { Loader } from 'uikit/Loader';
import { Tooltip } from 'uikit/Tooltip';
import { ModalCheck } from 'uikit/Modals';

import { IRouteParams } from 'utils/models';
import { isDeepEmpty, truncateText } from 'utils/utils';
import { getSpecifications } from '../helpers';
import { LinkedOrders } from 'components/LinkedOrders';
import { Check } from 'react-feather';

export const OfferPage: React.FC = observer(() => {
  const { id }: IRouteParams = useParams();
  const paramId = id || '';

  const { offersStore } = useStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isFirstRender = useRef<boolean>(true);
  const formRef = useRef<any>();
  const costRef = useRef<React.Ref<string | undefined>>();
  const volumeRef = useRef<React.Ref<string | undefined>>();

  interface IProductDisplayObject {
    value: string;
    label: string;
  }
  interface IWarehouseDisplayObject {
    value: string;
    label: string;
  }
  type WarehouseDisplayObject = IWarehouseDisplayObject | undefined;

  const warehouseRef = useRef<React.Ref<WarehouseDisplayObject>>();

  const offerData = useQuery(['OFFER_DATA_QUERY_KEY', paramId], () => offersStore.getOffer(paramId), {
    enabled: !!paramId,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });

  const productsData = useQuery(['PRODUCT_LIST_DATA_QUERY_KEY'], () => offersStore.getProducts(), {
    enabled: !queryClient.getQueryData(['PRODUCT_LIST_DATA_QUERY_KEY']),
    retry: false,
  });

  const warehousesData = useQuery('WAREHOUSE_LIST_DATA_QUERY_KEY', () => offersStore.getWarehouses(), {
    enabled: !queryClient.getQueryData('WAREHOUSE_LIST_DATA_QUERY_KEY'),
    retry: false,
  });

  const [chosenProductId, setChosenProductId] = useState<string>();

  const [isOfferCreateSuccess, setIsOfferCreateSuccess] = useState(false);
  const [isOfferEditSuccess, setIsOfferEditSuccess] = useState(false);
  const [isOfferDeleteSuccess, setIsOfferDeleteSuccess] = useState(false);

  const [showModalState, setShowModalState] = useState(false);
  const [agreedToPrivacy, setAgreedToPrivacy] = useState(true);

  const togglePrivacyAgreement = () => {
    setAgreedToPrivacy(!agreedToPrivacy);
  };

  const isFetching = offersStore.isProductsLoading || offersStore.isWarehousesLoading || offersStore.isLoading;

  const { id: offerProductId } = offersStore.offers?.get(paramId)?.product || {};
  const offerWarehouseId = offersStore.offers?.get(paramId)?.warehouse.id;

  const handleProductChange = (product: IProductDisplayObject) => {
    if (!isFirstRender.current) {
      costRef.current = formRef?.current?.values?.cost;
      volumeRef.current = formRef?.current?.values?.volume;
      warehouseRef.current = formRef?.current?.values?.warehouse as React.Ref<WarehouseDisplayObject>;
    }
    setChosenProductId(product.value);
  };

  const handleCancel = (): void => {
    navigate(`../${generatePath(routes.offers.list.path)}`);
  };

  const editMutation = useMutation((request: any) => offersStore.editOffer(request), {
    onSuccess: (e) => {
      queryClient.invalidateQueries(['OFFER_LIST_DATA_QUERY_KEY']);
      const invalidateQueryOfferParams: Array<string | number> = ['OFFER_DATA_QUERY_KEY'];
      // @ts-ignore
      if (!!e?.data.id) invalidateQueryOfferParams.push(e?.data.id.toString());
      queryClient.invalidateQueries(invalidateQueryOfferParams);
    },
  });
  const delMutation = useMutation((id: string) => offersStore.deleteOffer(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['OFFER_LIST_COUNTER_QUERY_KEY']);
      queryClient.invalidateQueries(['OFFER_LIST_DATA_QUERY_KEY']);
    },
  });
  const createMutation = useMutation((request: any) => offersStore.createOffer(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['OFFER_LIST_COUNTER_QUERY_KEY']);
      queryClient.invalidateQueries(['OFFER_LIST_DATA_QUERY_KEY']);
    },
  });

  const handleDelete = () => {
    delMutation.mutateAsync(paramId, {
      onSuccess: () => {
        setShowModalState(false);
        setIsOfferDeleteSuccess(true);
      },
    });
  };

  const productOptions: IProductDisplayObject[] = useMemo(
    () => offersStore.productsList.map(({ title: label, id: value }) => ({ label, value })) || [],
    [offersStore.productsList],
  );

  const warehouseOptions: IWarehouseDisplayObject[] = useMemo(
    () => offersStore.warehousesList.map(({ title: label, id: value }) => ({ label, value })) || [],
    [offersStore.warehousesList, warehousesData],
  );

  const getProduct = (productId?: string) => productOptions?.find((option) => option?.value === productId);

  const actualProductId = useMemo(
    () => chosenProductId || offerProductId || productOptions?.[0]?.value,
    [chosenProductId, offerProductId, productOptions],
  );

  const convertToNumber = (value: string | number | undefined): number | undefined => {
    return typeof value === 'string' ? Number(value.split(' ').join('')) : value;
  };

  const handleSubmitForm = (values: any): void => {
    const {
      volume,
      cost,
      product,
      warehouse,
      dateStartShipment,
      totalVolume,
      dateFinishShipment,
    }: {
      volume: string;
      cost: string;
      product: IProductDisplayObject;
      warehouse: WarehouseDisplayObject;
      dateStartShipment: Date;
      totalVolume: string | number;
      dateFinishShipment: Date;
    } = values || {};

    const newFormData = {
      ...values,
      dateStartShipment: format(new Date(dateStartShipment || Date.now()), 'yyyy-MM-dd'),
      dateFinishShipment: format(
        new Date(dateFinishShipment || dateStartShipment || Date.now() + 1000 * 60 * 60 * 24 * 29),
        'yyyy-MM-dd',
      ),
      volume: convertToNumber(volume),
      totalVolume: convertToNumber(totalVolume),
      cost: convertToNumber(cost),
      product: { id: product.value },
      warehouse: { id: warehouse?.value },
      id: paramId,
      specifications: values?.specifications
        ?.map(({ max, min, id: specId }: any) => {
          const value = {
            max: Number(max) || undefined,
            min: Number(min) || undefined,
          };
          return {
            id: specId,
            value: !isDeepEmpty(value) ? JSON.stringify(value) : undefined,
          };
        })
        ?.filter((el: any) => el.value),
      basisSpecifications: values?.basisSpecifications
        ?.map(({ max, min, id: specId }: any) => {
          const value = {
            max: Number(max) || undefined,
            min: Number(min) || undefined,
          };
          return {
            id: specId,
            value: !isDeepEmpty(value) ? JSON.stringify(value) : undefined,
          };
        })
        ?.filter((el: any) => el.value),
    };

    if (isEdit) {
      editMutation.mutate(newFormData, {
        onSuccess: () => setIsOfferEditSuccess(true),
      });
    } else {
      createMutation.mutate(newFormData, {
        onSuccess: () => setIsOfferCreateSuccess(true),
      });
    }
  };

  const renderDeleteConfirm = () => (
    <StyledModal
      show={showModalState}
      onHide={() => setShowModalState(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton />
      <ModalContent>
        <Flex column vAlignContent="center" hAlignContent="center">
          <Typography bold size="lg">
            Удалить предложение?
          </Typography>
          <Spacer space={15} />
          <Typography>Вы не сможете восстановить его обратно</Typography>
          <Spacer space={25} />
          <Button variant="baseRed" size="lg" onClick={handleDelete}>
            Да, удалить
          </Button>
          <Button variant="link" size="lg" onClick={() => setShowModalState(false)}>
            Нет, отменить
          </Button>
        </Flex>
      </ModalContent>
    </StyledModal>
  );

  interface ISpecTooltip {
    max: number;
    min?: number;
  }

  const renderMinMaxTooltip = ({ max, min }: ISpecTooltip) => {
    return (
      <>
        {isNumber(max) && isNumber(min) ? (
          <Flex>
            <Typography size="sm">По умолчанию:</Typography>
            <Spacer width={4} />
            <Typography size="sm">от</Typography>
            <Spacer width={4} />
            <Typography size="sm" color="#407ef5">
              {min}
            </Typography>
            <Spacer width={4} />
            <Typography size="sm">до</Typography>
            <Spacer width={4} />
            <Typography size="sm" color="#407ef5">
              {max}
            </Typography>
            <Spacer width={4} />
          </Flex>
        ) : isNumber(max) ? (
          <Flex column>
            <Typography size="sm">По умолчанию не более:</Typography>
            <Typography size="sm" color="#407ef5">
              {max}
            </Typography>
          </Flex>
        ) : (
          isNumber(min) && (
            <Flex column>
              <Typography size="sm">По умолчанию не менее:</Typography>
              <Typography size="sm" color="#407ef5">
                {min}
              </Typography>
            </Flex>
          )
        )}
      </>
    );
  };

  const handleCloseModal = () => navigate(`../${generatePath(routes.offers.list.path)}`);

  useEffect(() => {
    if (!isOfferDeleteSuccess && !isOfferEditSuccess && !isOfferCreateSuccess) return;
    const timeOut = setTimeout(() => handleCloseModal(), 2500);
    return () => clearTimeout(timeOut);
  }, [isOfferDeleteSuccess, isOfferEditSuccess, isOfferCreateSuccess]);

  useEffect(() => {
    actualProductId && offersStore.setActualProductId(actualProductId);
  }, [actualProductId, productsData]);

  useEffect(() => {
    if (isFirstRender.current) isFirstRender.current = false;
  });

  const offerSchema = Yup.object().shape({
    cost: Yup.number().min(1).required('Поле обязательное'),
    volume: Yup.number().min(1).required('Поле обязательное'),
  });

  const initialValues = useMemo(() => {
    return {
      volume:
        volumeRef.current != undefined && !isFirstRender.current
          ? volumeRef.current
          : offersStore.offers?.get(paramId)?.availableVolume,
      cost:
        costRef.current != undefined && !isFirstRender.current
          ? costRef.current
          : offersStore.offers?.get(paramId)?.priceBuyer,
      product: getProduct(actualProductId),
      shipmentStart: formRef?.current?.values?.shipmentStart,
      shipmentEnd: formRef?.current?.values?.shipmentEnd,
      warehouse:
        warehouseRef.current != undefined && !isFirstRender.current
          ? warehouseRef.current
          : warehouseOptions?.find((option) => option?.value === offerWarehouseId) || warehouseOptions?.[0],
      specifications: getSpecifications(
        offersStore.productSpecifications,
        offersStore.offers?.get(paramId)?.specifications,
      ),
      basisSpecifications: getSpecifications(
        offersStore.productBasisSpecifications,
        offersStore.offers?.get(paramId)?.specifications,
        true,
      ),
    };
  }, [
    offerData,
    warehouseOptions,
    offersStore.productSpecifications,
    offersStore.productBasisSpecifications,
    actualProductId,
    productOptions,
    offersStore.offers?.get(paramId)?.dateStartShipment,
    offersStore.offers?.get(paramId)?.specifications,
  ]);

  const isEdit = !!paramId;
  const isArchived = offersStore.offers?.get(paramId)?.status === 'archived';
  const isActive = offersStore.offers?.get(paramId)?.status === 'active';
  const isPartial = offersStore.offers?.get(paramId)?.status === 'partial';
  const isAccepted = offersStore.offers?.get(paramId)?.status === 'accepted';
  const isPending = offersStore.offers?.get(paramId)?.status === 'pending';
  const isReadOnly = isPartial || isPending || isArchived || isAccepted;

  return isFetching ? (
    <Loader />
  ) : (
    <OfferRootWrapper>
      <BackButton variant="base" size="lg" onClick={handleCancel as () => void}>
        ❮ Назад
      </BackButton>

      <Spacer space={16} />

      <Heading size="lg2" bold>
        {`${isActive ? 'Редактировать' : isReadOnly ? 'Просмотр' : 'Создать'}  ${
          isReadOnly ? 'предложения' : 'предложение'
        } `}
      </Heading>

      <Spacer space={16} />

      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validationSchema={offerSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, errors, values }: any) => {
          const { cost: costError, volume: volumeError } = errors;
          return (
            <OfferViewWrapper showActionFooter={!isReadOnly}>
              <FormWrapper>
                <FormLeftSide>
                  <FormikField name="product" title="Культура">
                    <Select options={productOptions} onChange={handleProductChange} disabled={isReadOnly || isEdit} />
                  </FormikField>
                  <FormikField name="cost" title="Цена CNCPT на воротах порта, ₽/т">
                    <Input
                      name="cost"
                      type="masked"
                      disabled={isReadOnly}
                      isError={!!costError}
                      value={initialValues.cost}
                    />
                  </FormikField>
                  <FormikField name="volume" title="Объем, т">
                    <Input name="volume" type="masked" disabled={isReadOnly} isError={!!volumeError} />
                  </FormikField>
                  <FormikField name="periodShipment" title="Период поставки">
                    <DatePickerField
                      initialValues={{
                        start: offersStore.offers?.get(paramId)?.dateStartShipment,
                        end: offersStore.offers?.get(paramId)?.dateFinishShipment,
                      }}
                      startFieldName="dateStartShipment"
                      endFieldName="dateFinishShipment"
                      hasCounter
                      disabled={isReadOnly}
                    />
                  </FormikField>
                  <FormikField name="warehouse" title="Порт">
                    <Select options={warehouseOptions} disabled={isReadOnly} />
                  </FormikField>
                </FormLeftSide>

                <SpecsWrapper>
                  <FormLeftSide>
                    <FieldArray
                      name="specifications"
                      render={() => (
                        <>
                          {initialValues?.specifications?.map((specification: any, idx: number) => {
                            const {
                              GOST,
                              max,
                              min,
                              isEditableMax,
                              isEditableMin,
                              description,
                              defaultMin,
                              defaultMax,
                            } = specification || {};
                            const isSpecValuesEditable = isEditableMax || isEditableMin;
                            return (
                              <Fragment key={idx}>
                                <Flex fillWidth hAlignContent="right">
                                  <SpecsHeading>
                                    {idx === 0 && <Typography color="#918F88">Ограничение</Typography>}
                                  </SpecsHeading>
                                </Flex>
                                <Flex>
                                  <Flex column>
                                    {idx === 0 && <Typography color="#918F88">Показатели зерна</Typography>}
                                    <Spacer space={10} />
                                    <Input
                                      disabled
                                      name={`specifications[${idx}].nameOfSpecification`}
                                      variant="blank"
                                      size="lg"
                                      tooltipContent={GOST && `${GOST}`}
                                      tooltipPlacement="left-start"
                                    />
                                  </Flex>
                                  <Spacer width={15} />
                                  {isSpecValuesEditable ? (
                                    <>
                                      <Flex column>
                                        {idx === 0 && (
                                          <Typography align="center" color="#918F88">
                                            min
                                          </Typography>
                                        )}
                                        <Spacer space={10} />
                                        <FormikField name={`specifications[${idx}].min`}>
                                          <Input
                                            type="masked"
                                            variant={isReadOnly || !isEditableMin || !isNumber(min) ? 'blank' : 'light'}
                                            disabled={isReadOnly || !isEditableMin || !isNumber(min)}
                                            isHighlighed={
                                              // isNumber(min) &&
                                              Number(defaultMin) !== Number(values?.specifications?.[idx]?.min)
                                            }
                                            name={`specifications[${idx}].min`}
                                            size="sm"
                                            tooltipContent={
                                              !isReadOnly &&
                                              isEditableMin &&
                                              isNumber(defaultMin) &&
                                              renderMinMaxTooltip({
                                                max: defaultMax,
                                                min: defaultMin,
                                              })
                                            }
                                          />
                                        </FormikField>
                                      </Flex>
                                      <Spacer width={15} />
                                      <Flex column>
                                        {idx === 0 && (
                                          <Typography align="center" color="#918F88">
                                            max
                                          </Typography>
                                        )}

                                        <Spacer space={10} />
                                        <FormikField name={`specifications[${idx}].max`}>
                                          <Input
                                            type="masked"
                                            name={`specifications[${idx}].max`}
                                            variant={isReadOnly || !isEditableMax || !isNumber(max) ? 'blank' : 'light'}
                                            isHighlighed={
                                              // isNumber(max) &&
                                              Number(defaultMax) !== Number(values?.specifications?.[idx]?.max)
                                            }
                                            size="sm"
                                            disabled={isReadOnly || !isEditableMax || !isNumber(max)}
                                            tooltipContent={
                                              !isReadOnly &&
                                              isEditableMax &&
                                              isNumber(defaultMax) &&
                                              renderMinMaxTooltip({
                                                max: defaultMax,
                                                min: defaultMin,
                                              })
                                            }
                                          />
                                        </FormikField>
                                      </Flex>
                                    </>
                                  ) : !!description ? (
                                    <Tooltip tooltipContent={description} id={`${GOST}-${description}-${max}-${min}`}>
                                      <Flex vAlignContent="center" hAlignContent="center">
                                        <TruncatedText size="sm">{truncateText(description, 14)}</TruncatedText>
                                      </Flex>
                                    </Tooltip>
                                  ) : null}
                                </Flex>
                              </Fragment>
                            );
                          })}
                        </>
                      )}
                    />
                  </FormLeftSide>
                  <FormRightSide>
                    <FieldArray
                      name="basis_specifications"
                      render={() => (
                        <>
                          {initialValues?.basisSpecifications?.map((specification: any, idx: number) => {
                            const {
                              GOST,
                              max,
                              min,
                              isEditableMax,
                              isEditableMin,
                              description,
                              defaultMin,
                              defaultMax,
                            } = specification || {};
                            const isSpecValuesEditable = isEditableMax || isEditableMin;
                            return (
                              <Fragment key={idx}>
                                <Flex fillWidth hAlignContent="right">
                                  <SpecsHeading>
                                    {idx === 0 && <Typography color="#918F88">Базис</Typography>}
                                  </SpecsHeading>
                                </Flex>
                                <Flex>
                                  <Spacer width={15} />
                                  {isSpecValuesEditable ? (
                                    <>
                                      <Flex column>
                                        {idx === 0 && (
                                          <Typography align="center" color="#918F88">
                                            min
                                          </Typography>
                                        )}
                                        <Spacer space={10} />
                                        <FormikField name={`basisSpecifications[${idx}].min`}>
                                          <Input
                                            type="masked"
                                            variant={isReadOnly || !isEditableMin || !isNumber(min) ? 'blank' : 'light'}
                                            disabled={isReadOnly || !isEditableMin || !isNumber(min)}
                                            isHighlighed={
                                              // isNumber(min) &&
                                              Number(defaultMin) !== Number(values?.basisSpecifications?.[idx]?.min)
                                            }
                                            name={`basisSpecifications[${idx}].min`}
                                            size="sm"
                                            tooltipContent={
                                              !isReadOnly &&
                                              isEditableMin &&
                                              isNumber(defaultMin) &&
                                              renderMinMaxTooltip({
                                                max: defaultMax,
                                                min: defaultMin,
                                              })
                                            }
                                          />
                                        </FormikField>
                                      </Flex>
                                      <Spacer width={15} />
                                      <Flex column>
                                        {idx === 0 && (
                                          <Typography align="center" color="#918F88">
                                            max
                                          </Typography>
                                        )}

                                        <Spacer space={10} />
                                        <FormikField name={`basisSpecifications[${idx}].max`}>
                                          <Input
                                            type="masked"
                                            name={`basisSpecifications[${idx}].max`}
                                            variant={isReadOnly || !isEditableMax || !isNumber(max) ? 'blank' : 'light'}
                                            isHighlighed={
                                              // isNumber(max) &&
                                              Number(defaultMax) !== Number(values?.specifications?.[idx]?.max)
                                            }
                                            size="sm"
                                            disabled={isReadOnly || !isEditableMax || !isNumber(max)}
                                            tooltipContent={
                                              !isReadOnly &&
                                              isEditableMax &&
                                              isNumber(defaultMax) &&
                                              renderMinMaxTooltip({
                                                max: defaultMax,
                                                min: defaultMin,
                                              })
                                            }
                                          />
                                        </FormikField>
                                      </Flex>
                                    </>
                                  ) : !!description ? (
                                    <Tooltip tooltipContent={description} id={`${GOST}-${description}-${max}-${min}`}>
                                      <Flex vAlignContent="center" hAlignContent="center">
                                        <TruncatedText size="sm">{truncateText(description, 14)}</TruncatedText>
                                      </Flex>
                                    </Tooltip>
                                  ) : null}
                                </Flex>
                              </Fragment>
                            );
                          })}
                        </>
                      )}
                    />
                  </FormRightSide>
                </SpecsWrapper>
              </FormWrapper>

              {!!offersStore.offers?.get(paramId)?.id && (
                <LinkedOrders linkBy="offer" id={offersStore?.offers?.get(paramId)?.id || paramId} />
              )}

              {!isReadOnly && (
                <ActionsWrapper>
                  {!isEdit && (
                    <AgreementButton variant="icon" onClick={togglePrivacyAgreement}>
                      {agreedToPrivacy && <Check />}
                      <Typography bold size="lg">
                        Я согласен с{' '}
                        <a target="_blank" rel="noreferrer" href="https://company.silkagro.ru/privacy">
                          Лицензионным соглашением
                        </a>
                      </Typography>
                    </AgreementButton>
                  )}
                  <Typography bold size="lg">
                    {isEdit ? 'Сохранить изменения?' : 'Опубликовать предложение?'}
                  </Typography>

                  <Flex>
                    <StyledButton
                      disabled={!agreedToPrivacy}
                      variant="base"
                      size="lg"
                      type="submit"
                      onClick={handleSubmit as () => void}
                    >
                      {isEdit ? 'Сохранить' : 'Опубликовать'}
                    </StyledButton>
                    <Spacer width={10} />
                    <StyledButton variant="baseRed" size="lg" onClick={handleCancel as () => void}>
                      Отменить
                    </StyledButton>

                    {isEdit && (
                      <StyledButton variant="link" onClick={() => setShowModalState(true)}>
                        Удалить
                      </StyledButton>
                    )}
                    <Spacer />
                  </Flex>
                </ActionsWrapper>
              )}
            </OfferViewWrapper>
          );
        }}
      </Formik>

      {renderDeleteConfirm()}

      {(isOfferDeleteSuccess || isOfferEditSuccess || isOfferCreateSuccess) && (
        <ModalCheck
          isActive={true}
          isCreate={isOfferCreateSuccess}
          isDelete={isOfferDeleteSuccess}
          isEdit={isOfferEditSuccess}
        />
      )}
    </OfferRootWrapper>
  );
});

const OfferRootWrapper = styled.div`
  max-width: 1100px;
`;

const TruncatedText = styled(Typography)`
  overflow: hidden;
  max-width: 120px;
  height: 50px;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const ActionsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  height: 150px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  background-color: #efebde;
  padding-left: 46px;
  border-top: 1px solid #918f89;
  gap: 15px;
`;

const StyledModal = styled(Modal)`
  .modal-header {
    border: none;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }
  .close {
    color: #f9f6ed;
    text-shadow: none;
    opacity: 1;
    position: absolute;
    right: -20px;
    top: 0px;
  }
`;

const ModalContent = styled.div`
  padding: 32px;
  background-color: #f9f6ed;
  border-radius: 10px;
`;

const Heading = styled(Typography)`
  position: relative;
  display: inline-block;
  padding: 32px 0 0 42px;
`;

const FormWrapper = styled(Form)`
  padding: 0px 44px 38px 44px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  & > div {
    gap: 30px;
  }
`;
const SpecsHeading = styled.div`
  width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SpecsWrapper = styled.div`
  padding: 38px 44px 38px 44px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
`;

const FormLeftSide = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const FormRightSide = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 100%;
`;

const OfferViewWrapper = styled.div<{ showActionFooter?: boolean }>`
  display: block;
  padding-bottom: ${({ showActionFooter }) => (showActionFooter ? '150px' : '28px')};
`;

const StyledButton = styled(Button)`
  width: 210px;
  height: 40px;
`;

const AgreementButton = styled(Button)`
  position: relative;
  background: none;
  border: none;
  padding-left: 30px;
  border-radius: 0;
  width: 390px;
  &::before {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 11px;
    left: 0;
    content: '';
    border: 1px solid #222;
  }
  svg {
    position: absolute;
    top: 13px;
    left: 1px;
  }
`;
const BackButton = styled(StyledButton)`
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #407ef5;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  margin-top: 33px;
  margin-left: 46px;
  color: #407ef5;
`;
