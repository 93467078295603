import { useQuery } from 'react-query';

const WAREHOUSE_LIST_DATA_QUERY_KEY = 'WAREHOUSE_LIST_DATA_QUERY_KEY';

export const useWarehouses = (hasWarehouses: boolean, getWarehousesRequest: any) => {
  const result = useQuery([WAREHOUSE_LIST_DATA_QUERY_KEY, hasWarehouses], () => getWarehousesRequest(), {
    enabled: !hasWarehouses,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });
  return result;
};
